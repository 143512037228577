import React, { useCallback, useContext, useMemo } from 'react';

import { usePromise } from 'hooks';

import Select from 'components/Select';

import { APIContext } from 'lib/contexts';

type Props = {
  value?: number;
  onChange: (value: string) => void;
  disabled: boolean;
  allowNull?: boolean;
};

const UserSelect = ({ value, onChange, disabled, allowNull = false}: Props) => {
  const api = useContext(APIContext);

  const getUsers = useMemo(() => api.getUsers({ roles: ['staff-member'] }), [api]);
  const [users] = usePromise(getUsers, [], { component: 'UserSelect' });
  
  const onChangeFn = useCallback((selected) => {
    onChange(selected ? selected.value : null);
  }, [onChange]);

  let options = allowNull
   ? [{ value: null, label: 'None' }, ...users.map(user => ({ value: `${user.id}`, label: `${user.firstName} ${user.lastName} - ${user.email}` }))]
   : users.map(user => ({ value: `${user.id}`, label: `${user.firstName} ${user.lastName} - ${user.email}` }));

  return (
    <Select
      value={!value ? null : `${value}`}
      disabled={disabled}
      onChange={onChangeFn}
      options={options}
      placeholder={allowNull ? undefined : 'Select a user'}
    />
  );
};

export default UserSelect;