import { createValidator, maxLength, minLength, required } from 'lib/validation';

export default createValidator({
  title: [required, minLength(3, 'Position title'), maxLength(200, 'Position title')],
  summary: [required, maxLength(200, 'Summary')],
  location: [required],
  expiresAt: [required],
  description: [required, minLength(25, 'Job Ad text')],
  workTypes: [required],
  categories: [required],
  sectors: [required]
});
