import React, { useEffect, useState, useCallback, useContext, Fragment } from 'react';

import _ from 'lodash';
import moment from 'moment';
import cogoToast from 'cogo-toast';
import { useDispatch } from 'react-redux';
import { useParams, useHistory } from 'react-router-dom';
import { DragDropContext, Droppable } from 'react-beautiful-dnd';
import { FormControl } from 'react-bootstrap';

import { Heading } from '@ethical-jobs/design-system-react';
import { Job, Applicant, ApplicationNote, UpdateApplicantParams } from '@ethical-jobs/sdk-js/types';

import { APIContext, AmplitudeContext } from 'lib/contexts';
import { EXPIRATION_TIMES } from 'lib/constants';

import { cloneJob } from 'actions/jobs';

import DateTimeField from 'fields/DateTimeField';
import Modal from 'components/Modal';
import Icon from 'components/Icon';
import Button from 'components/Button';
import Tabs from 'components/Tabs';
import Stages from 'components/Stages';
import Select from 'components/Select';
import JobPreviewer from 'components/JobPreviewer';

import AddApplicantModal from './AddApplicantModal';
import ApplicantCard from './ApplicantCard';
import EmailApplicants from './EmailApplicants';

import { ApplicantStageContainer } from './ApplicantManagementJob.styles';

import './ApplicantManagementJob.css';
import ApplicantDetails from './ApplicantDetails';
import EmptyDisplay from './EmptyDisplay';

const APPLICANT_STAGES = [
  'Inbox',
  'Shortlist',
  'Interview',
  'Offer',
  'Accept'
];

const ApplicantManagementJob = () => {
  const params = useParams();
  const dispatch = useDispatch();
  const history = useHistory();

  // @ts-ignore
  const jobId = Number(params.jobId);

  const api = useContext(APIContext);
  const amplitude = useContext(AmplitudeContext);

  const [refresh, setRefresh] = useState(0);
  const [job, setJob] = useState<Job>(null);
  const [applicants, setApplicants] = useState<Array<Applicant & { notes: Array<ApplicationNote> }>>([]);
  const [filteredApplicants, setFilteredApplicants] = useState<Array<Applicant & { notes: Array<ApplicationNote> }>>([]);
  const [selectedTab, setSelectedTab] = useState('Inbox');
  const [numApplicants, setNumApplicants] = useState({ inbox: 0, shortlist: 0, interview: 0, offer: 0, accept: 0, notsuitable: 0 });
  const [query, setQuery] = useState('');
  const [selectedApplicantId, setSelectedApplicantId] = useState(undefined);
  const [sortOrder, setSortOrder] = useState('applicationDate');
  const [view, setView] = useState('applicants');
  const [isDragging, setIsDragging] = useState(false);
  const [checkedApplicants, setCheckedApplicants] = useState([]);
  const [showAddApplicantModal, setShowAddApplicantModal] = useState(false);

  const [showExtendAdModal, setShowExtendAdModal] = useState(false);
  const [expiresAt, setExpiresAt] = useState(null);
  const [isSubmittingExpiresAt, setIsSubmittingExpiresAt] = useState(false);

  useEffect(() => {
    const fn = async () => {
      let jobResponse = await api.getJob(jobId);
      setJob(jobResponse);
      setExpiresAt(jobResponse.expiresAt);

      let applicantsResponse = await api.getApplicants(jobId);
      setApplicants(applicantsResponse.map(applicant => ({
        ...applicant,
        notes: []
      })));
    };

    fn();
  }, [api, jobId, refresh]);

  useEffect(() => {
    let lcQuery = query.toLowerCase();

    const filterApplicants = (stage: string, q: string): number => applicants.filter(applicant => applicant.stage === stage && `${applicant.firstName} ${applicant.lastName}`.toLowerCase().includes(q)).length

    setNumApplicants({
      inbox: filterApplicants('Inbox', lcQuery),
      shortlist: filterApplicants('Shortlist', lcQuery),
      interview: filterApplicants('Interview', lcQuery),
      offer: filterApplicants('Offer', lcQuery),
      accept: filterApplicants('Accept', lcQuery),
      notsuitable: filterApplicants('Unsuitable', lcQuery)
    });
  }, [query, applicants]);

  const onSearchChange = useCallback((event) => setQuery(event.target.value), []);

  const reloadJob = useCallback(() => setRefresh(Math.random), []);

  const reloadApplicant = useCallback(async (applicationId: string) => {
    try {
      let applicant = await api.getApplicant(applicationId);
      setApplicants(prevApplicants => prevApplicants.map(prevApplicant => prevApplicant.id === applicationId ? { ...applicant, notes: [] } : prevApplicant));
    } catch (error) {
      console.log(error);
    }
  }, [api])

  const updateApplicant = useCallback(async (applicantId: string, params: UpdateApplicantParams) => {
    try {
      let updatedApplicant = await api.updateApplicant(applicantId, params);
      cogoToast.success('Successfully updated applicant', { hideAfter: 3, position: 'top-right' });
      reloadApplicant(applicantId);
      return updatedApplicant;
    } catch (error) {
      amplitude.logError('containers/_organisation/ApplicationManagementJob/ApplicantManagementJob:updateApplicant', error);
    }
  }, [api, amplitude, reloadApplicant]);

  useEffect(() => {
    let sApplicants = _.sortBy(applicants.filter(applicants => applicants.stage.toUpperCase() === selectedTab.toUpperCase()), applicant => {
      if (sortOrder === 'rating') {
        return applicant[sortOrder] * -1;
      }

      if (sortOrder === 'applicationDate') {
        return moment(applicant.createdAt).unix() * -1;
      }

      return applicant[sortOrder];
    });

    if (query === '') {
      setFilteredApplicants(sApplicants);
    }

    const lcQuery = query.toLowerCase();
    let fApplicants = sApplicants.filter(applicant => `${applicant.firstName} ${applicant.lastName}`.toLowerCase().includes(lcQuery));
    setFilteredApplicants(fApplicants);
  }, [applicants, query, selectedTab, sortOrder]);

  const onDragEnd = useCallback(async ({ draggableId, destination }) => {
    if (destination !== null) {
      updateApplicant(draggableId, { stage: destination.droppableId });
    }

    setIsDragging(false);
    setCheckedApplicants([]);
  }, [updateApplicant]);

  useEffect(() => {
    setCheckedApplicants([]);
  }, [selectedTab, selectedApplicantId])

  const onCheckedChange = useCallback((applicantId, isChecked) => setCheckedApplicants(prevCheckedApplicants => isChecked ? _.uniq([...prevCheckedApplicants, applicantId]) : prevCheckedApplicants.filter(app => app !== applicantId)), []);

  const selectedApplicant = filteredApplicants.find(app => app.id === selectedApplicantId) || (filteredApplicants.length > 0 ? filteredApplicants[0] : undefined);

  const updateExpiration = useCallback(async () => {
    setIsSubmittingExpiresAt(true);

    try {
      await api.updateJob(job.id, { expiresAt });
      setExpiresAt(expiresAt);
      cogoToast.success('Successfully update expiration date', { hideAfter: 3, position: 'top-right' });
      amplitude.logEvent('API', { function: 'updateJob', arguments: { expiresAt } });
      setShowExtendAdModal(false);
    } catch (error) {
      cogoToast.error('Error updating expiration date', { hideAfter: 3, position: 'top-right' });
      amplitude.logEvent('Error', { location: 'components/JobActions/OrgPreviewActions:updateExpiration', message: error.message });
    }

    setIsSubmittingExpiresAt(false);
    reloadJob();
  }, [job, expiresAt, amplitude, api, reloadJob]);

  const updateRating = useCallback(async (applicantId, rating) => {
    let previousRating = applicants.find(applicant => applicant.id === applicantId);
    let newRating = rating === previousRating ? 0 : rating;
    return updateApplicant(applicantId, { rating: newRating });
  }, [updateApplicant, applicants]);

  const duplicateJob = useCallback(async () => {
    try {
      if (!job.organisation) throw new Error('Invalid Organisation Id');

      let draftJob = await api.createJobDraft(job.organisation.id);

      const duplicateJobFields = {
        ...draftJob,
        ..._.pick(job, ['title', 'application', 'categories', 'description', 'isFeatured', 'isExpirationHidden', 'sectors', 'summary', 'workTypes', 'videoUrl', 'location', 'applyMethod', 'applyEmail', 'attachments']),
        expiresAt: undefined,
        applyButton: null // Clear the Apply Button as it will never be correct
      };

      dispatch(cloneJob(duplicateJobFields));
      history.push('/organisation/job/create');
    } catch (error) {
      amplitude.logEvent('Error', { location: 'containers/_organisation/ApplicantManagementJob/ApplicantManagementJob:duplicateJob', message: error.message });
    }
  }, [api, job, amplitude, history, dispatch]);

  const expireJob = useCallback(async () => {
    try {
      await api.expireJob(job.id);
      amplitude.logEvent('API', { function: 'expireJob', arguments: { jobId: job.id }});
    } catch (error) {
      amplitude.logEvent('Error', { location: 'containers/JobPreview/index/action:expireJob', message: error.message });
    }
    reloadJob();
  }, [api, amplitude, job, reloadJob]);

  if (!job) return null;

  return (
    <Fragment>
      <DragDropContext onDragStart={() => setIsDragging(true)} onDragEnd={onDragEnd}>
        <div className="dashboard-container">
          <div style={{ backgroundColor: 'white', padding: '30px 50px 0px 50px' }}>
            <div style={{ display: 'flex' }}>
              <div style={{ flex: 2 }}>
                <div style={{ display: 'flex', alignItems: 'center' }}>
                  <a href="/organisation">
                    <Icon type="home" style={{ color: 'grey' }} />
                  </a>
                  <p style={{
                      color: 'grey',
                      fontSize: 14,
                      // @ts-ignore
                      fontWeight: '300',
                      paddingLeft: 8,
                      marginBottom: 4
                    }}
                  >
                    {`>  `}<a href="/organisation" style={{ color: 'grey' }}>JOBS</a>{`  >  `}{job.title.toUpperCase()}
                  </p>
                </div>

                <p style={{ fontSize: 32, fontWeight: 'bold', marginBottom: 0 }}>{job.title}</p>
                <p style={{ color: '#999', fontSize: 16 }}>{job.author ? `Created by ${job.author.name}` : ''}</p>

                <div style={{ paddingTop: 10 }}>
                  <Tabs
                    activeTab={view}
                    onChange={setView}
                    fontStyle={{ fontSize: 18 }}
                    tabs={[{
                      id: 'job',
                      label: 'JOB LISTING',
                      activeColor: '#0079c1',
                      inactiveColor: '#555'
                    }, {
                      id: 'applicants',
                      label: 'APPLICANT MANAGEMENT',
                      activeColor: '#0079c1',
                      inactiveColor: '#555',
                      // disabled: job.status === 'PENDING'
                    }]}
                  />
                </div>
              </div>
              <div style={{ flex: 1, padding: '0px 10px', display: 'flex', flexDirection: 'column', justifyContent: 'space-between' }}>
                <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end' }}>
                  {!job.isExpired && (
                    <div style={{ paddingLeft: 10 }}>
                      <Button
                        kind="primary"
                        onClick={() => setShowExtendAdModal(true)}
                        label="Edit Expiration Date"
                        icon="alarm_on"
                        size="small"
                      />
                    </div>
                  )}

                  {!job.isExpired && job.status === 'APPROVED' && (
                    <div style={{ paddingLeft: 10 }}>
                      <Button
                        label="Expire Job"
                        kind="danger"
                        icon="delete"
                        size="small"
                        onClick={() => {
                          if (window.confirm('Are you sure you want to expire this job ad?')) {
                            expireJob();
                          }
                        }}
                      />
                    </div>
                  )}

                  {job.isExpired && (
                    <div style={{ paddingLeft: 10 }}>
                      <Button kind="primary" onClick={duplicateJob} label="Repost Job Ad" icon="post_add" size="small" />
                    </div>
                  )}
                </div>
                <div style={{ display: 'flex', justifyContent: 'space-around', alignItems: 'flex-start', paddingBottom: 15 }}>
                  <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                    <p style={{ fontSize: 14, marginBottom: -5 }}>VIEWS</p>
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                      <Icon type="remove_red_eye"/>
                      <p style={{ fontSize: 26, paddingLeft: 10, marginBottom: 0 }}>{job.views}</p>
                    </div>
                  </div>
                  <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                    <p style={{ fontSize: 14, marginBottom: -5 }}>CLICKS</p>
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                      <Icon type="mouse"/>
                      <p style={{ fontSize: 26, paddingLeft: 10, marginBottom: 0 }}>{job.clicks}</p>
                    </div>
                  </div>
                  <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                    <p style={{ fontSize: 14, marginBottom: -5 }}>APPLICANTS</p>
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                      <Icon type="person"/>
                      <p style={{ fontSize: 26, paddingLeft: 10, marginBottom: 0 }}>{applicants.length}</p>
                    </div>
                  </div>
                  <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                    <p style={{ fontSize: 14, marginBottom: -5 }}>EXPIRES</p>
                    <p style={{ fontSize: 26, paddingLeft: 5, marginBottom: 0, color: job.isExpired ? 'black' : '#0079c1' }}>{job.isExpired ? 'Expired' : `${moment().diff(job.expiresAt, 'days') * -1} days`}</p>
                  </div>
                </div>
              </div>
            </div>
          </div>

          {view === 'applicants' ? (
            <Fragment>
              <ApplicantStageContainer>
                <div style={{ display: 'flex', alignItems: 'center' }}>
                  <Stages
                    activeTab={selectedTab}
                    onChange={setSelectedTab}
                    isDroppable
                    fontStyle={{ fontSize: 15 }}
                    isDragging={isDragging}
                    tabs={[{
                      id: 'Inbox',
                      label: `${numApplicants.inbox} INBOX`
                    }, {
                      id: 'Shortlist',
                      label: `${numApplicants.shortlist} SHORTLIST`
                    }, {
                      id: 'Interview',
                      label: `${numApplicants.interview} INTERVIEW`
                    }, {
                      id: 'Offer',
                      label: `${numApplicants.offer} OFFER`
                    }, {
                      id: 'Accept',
                      label: `${numApplicants.accept} ACCEPT`,
                      icon: 'done'
                    }]}
                  />

                  <div style={{ paddingLeft: 50 }}>
                    <Stages
                      activeTab={selectedTab}
                      onChange={setSelectedTab}
                      isDroppable
                      fontStyle={{ fontSize: 15 }}
                      isDragging={isDragging}
                      tabs={[{
                        id: 'Unsuitable',
                        label: `${numApplicants.notsuitable} NOT SUITABLE`,
                        activeColor: '#E22A2A',
                        inactiveColor: '#E22A2A',
                        hoverColor: '#E22A2A',
                        icon: 'delete_forever'
                      }]}
                    />
                  </div>
                </div>

                <Button
                  label="Add Applicant"
                  icon="add"
                  onClick={() => setShowAddApplicantModal(true)}
                  kind="info"
                  size="small"
                />
              </ApplicantStageContainer>

              {applicants.filter(applicant => applicant.stage === selectedTab).length === 0 ? (
                <div style={{ padding: '20px 50px' }}>
                  <EmptyDisplay
                    stage={selectedTab}
                  />
                </div>
              ) : (
                <div style={{ display: 'flex', padding: '20px 50px' }}>
                  <div style={{ width: 380 }}>
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                      <div style={{ position: 'relative', flex: 1, marginRight: 10 }}>
                        <Icon type="search" style={{ color: '#57AD34', position: 'absolute', left: 5, top: 10 }} />
                        <FormControl
                          type="text"
                          value={query}
                          placeholder="Search applicants..."
                          onChange={onSearchChange}
                          style={{ paddingLeft: 30, margin: 0 }}
                        />
                      </div>

                      <Select
                        isSearchable={false}
                        isClearable={false}
                        value={sortOrder}
                        width={140}
                        label="Sort By"
                        options={[{
                          value: 'applicationDate',
                          label: 'Date'
                        }, {
                          value: 'name',
                          label: 'Name'
                        }, {
                          value: 'rating',
                          label: 'Rating'
                        }]}
                        onChange={(item: { label: string, value: string }) => setSortOrder(item.value)}
                      />
                    </div>

                    <div style={{ display: 'flex', alignItems: 'center', padding: '15px 0px 10px 0px' }}>
                      <input
                        type="checkbox"
                        checked={filteredApplicants.length > 0 && filteredApplicants.every(applicant => checkedApplicants.includes(applicant.id))}
                        onChange={({ target: { checked } }) => setCheckedApplicants(checked ? filteredApplicants.map(applicant => applicant.id) : [])}
                        style={{ marginRight: 10 }}
                      />
                      <p style={{ fontSize: 15, color: '#0079c1', marginBottom: 0, paddingRight: 20 }}>Select All</p>

                      {checkedApplicants.length > 0 && (
                        <p style={{ fontSize: 15, marginBottom: 0, fontWeight: 'bold' }}>{checkedApplicants.length} Applicant{checkedApplicants.length > 1 && 's'} Selected</p>
                      )}
                    </div>

                    <Droppable droppableId="dropApplicants" isDropDisabled>
                      {(provided) => (
                        <div ref={provided.innerRef}>
                          <div style={{ maxHeight: 1000, overflowY: 'scroll', scrollbarWidth: 'none' }}>
                            {filteredApplicants.map((applicant, index) => (
                              <ApplicantCard
                                key={applicant.id}
                                applicant={applicant}
                                index={index}
                                isDraggable={checkedApplicants.length === 0}
                                isChecked={checkedApplicants.includes(applicant.id)}
                                onCheckChange={(checked: boolean) => onCheckedChange(applicant.id, checked)}
                                isSelected={selectedApplicant.id === applicant.id}
                                onClick={() => setSelectedApplicantId(applicant.id)}
                                setStarRating={newRating => updateRating(applicant.id, newRating)}
                              />
                            ))}
                          </div>
                          {provided.placeholder}
                        </div>
                      )}
                    </Droppable>
                  </div>

                  <div style={{ flex: 1, paddingLeft: 30 }}>
                    {selectedApplicant && checkedApplicants.length === 0 && (
                      <ApplicantDetails
                        applicant={selectedApplicant}
                        jobQuestions={job.questions}
                        updateApplicant={updateApplicant}
                      />
                    )}

                    {checkedApplicants.length > 0 && (
                      <Fragment>
                        {selectedTab !== 'Unsuitable' && (
                          <div style={{ backgroundColor: 'white', borderRadius: 5, boxShadow: '0 4px 8px 0 rgba(0, 0, 0, 0.1)', display: 'flex', padding: '20px 25px', justifyContent: 'space-between', alignItems: 'center', marginBottom: 15 }}>
                            <p style={{ fontSize: 24, fontWeight: 'bold', marginBottom: 0 }}>
                              Move {checkedApplicants.length} applicant{checkedApplicants.length > 1 && 's'} to
                            </p>
                            <div style={{ display: 'flex', padding: 5 }}>
                              <Button
                                kind="primary"
                                onClick={() => {
                                  setApplicants(prevApplicants => prevApplicants.map(prevApplicant => checkedApplicants.includes(prevApplicant.id) ? {
                                    ...prevApplicant,
                                    stage: APPLICANT_STAGES[APPLICANT_STAGES.indexOf(prevApplicant.stage) + 1]
                                  } : prevApplicant));
                                }}
                                label={`Move to ${_.startCase(APPLICANT_STAGES[APPLICANT_STAGES.indexOf(selectedApplicant.stage) + 1])}`}
                                size="small"
                                icon="forward"
                                iconPosition="right"
                                style={{ marginRight: 10 }}
                              />
                              <Button
                                kind="danger"
                                onClick={() => {
                                  setApplicants(prevApplicants => prevApplicants.map(prevApplicant => checkedApplicants.includes(prevApplicant.id) ? {
                                    ...prevApplicant,
                                    stage: 'Unsuitable'
                                  } : prevApplicant));
                                }}
                                label="Not Suitable"
                                icon="delete_forever"
                                iconPosition="right"
                                size="small"
                              />
                            </div>
                          </div>
                        )}

                        <EmailApplicants
                          applicants={checkedApplicants.map(applicantId => applicants.find(applicant => applicant.id === applicantId))}
                          jobId={jobId}
                        />
                      </Fragment>
                    )}
                  </div>
                </div>
              )}
            </Fragment>
          ) : (
            <div style={{ padding: '25px 50px', display: 'flex', justifyContent: 'center' }}>
              {_.get(job, ['isFake'], false) ? (
                <img src="/job.png" alt="job" style={{ width: 1000 }} />
              ) : (
                <JobPreviewer job={job} />
              )}
            </div>
          )}
        </div>
      </DragDropContext>
      <AddApplicantModal
        jobId={job.id}
        organisationId={job.organisation.id}
        isVisible={showAddApplicantModal}
        close={() => setShowAddApplicantModal(false)}
        onSave={reloadJob}
      />
      <Modal
        show={showExtendAdModal}
        onHide={() => setShowExtendAdModal(false)}
      >
        <Fragment>
          <div style={{ padding: '15px 30px 5px 30px', display: 'flex', justifyContent: 'space-between', borderStyle: 'solid', borderWidth: 0, borderBottomWidth: 1, borderBottomColor: '#DDD' }}>
            <Heading size="medium">Edit Expiration Date</Heading>
            <div style={{ cursor: 'pointer' }} onClick={() => setShowExtendAdModal(false)}>
              <Icon type="close" />
            </div>
          </div>
          <div style={{ padding: '15px 30px 5px 30px', display: 'flex', justifyContent: 'space-between' }}>
            <DateTimeField
              value={job.expiresAt}
              minDate={moment.max([moment(job.approvedAt), moment().add(1, 'day')]).toDate()}
              maxDate={moment(job.approvedAt).add(45, 'days').endOf('day').toDate()}
              dateFormat="dddd Do MMMM, YYYY"
              times={EXPIRATION_TIMES.map(time => ({ label: time, value: time }))}
              onChange={setExpiresAt}
            />
            <Button
              label="Save"
              onClick={updateExpiration}
              kind="primary"
              loading={isSubmittingExpiresAt}
              disabled={isSubmittingExpiresAt}
              style={{ maxHeight: 50 }}
            />
          </div>
        </Fragment>
      </Modal>
    </Fragment>
  );
};

export default ApplicantManagementJob;
