import React from 'react';
import Select from 'components/Select';

const STATES = [
  { value: 'Victoria', label: 'Victoria' },
  { value: 'New South Wales', label: 'New South Wales' },
  { value: 'Queensland', label: 'Queensland' },
  { value: 'South Australia', label: 'South Australia' },
  { value: 'Western Australia', label: 'Western Australia' },
  { value: 'Tasmania', label: 'Tasmania' },
  { value: 'Northern Territory', label: 'Northern Territory' },
  { value: 'Australian Capital Territory', label: 'Australian Capital Territory' },
];

type Props = {
  onChange: (value: string) => void;
  value?: string;
  placeholder?: string;
};

const StatesSelectField = ({ onChange, value, placeholder }: Props) => (
  <Select
    options={STATES}
    onChange={(option: { value: string }) => onChange(option.value)}
    value={value}
    placeholder={placeholder || 'Select a state'}
  />
);

export default StatesSelectField;
