import React from 'react';
import _ from 'lodash';

type Props = {
  errors: {
    [key: string]: string | Array<string>
  }
};

const Messages = ({ errors = {} }: Props) => (
  <span>
    {_.flatten(Object.values(errors)).map(error => (
      <p key={error}>{error}</p>
    ))}
  </span>
);

export default Messages;
