import React, { useRef, useState, useEffect } from 'react';

import moment from 'moment';
import StarRatings from 'react-star-ratings';
import { Draggable } from 'react-beautiful-dnd';
import { Applicant } from '@ethical-jobs/sdk-js/types';

import Icon from 'components/Icon';

import { DraggingContainer } from './ApplicantCard.styles';

type Props = {
  applicant: Applicant;
  index: number;
  isSelected: boolean;
  isChecked: boolean;
  isDraggable: boolean;
  onCheckChange: (checked: boolean) => void;
  onClick: () => void;
  setStarRating: (rating: number) => void;
};

const ApplicantCard = ({ applicant, index, isSelected, onClick, setStarRating, isChecked, isDraggable, onCheckChange }: Props) => {
  const cardRef = useRef(null);

  const [cardWidth, setCardWidth] = useState(370);
  
  useEffect(() => {
    const width = cardRef.current ? cardRef.current.offsetWidth : 0;
    setCardWidth(width - 4);
  }, []);

  return (
    <div style={{ display: 'flex', alignItems: 'center' }}>
      <input
        type="checkbox"
        checked={isChecked}
        onChange={({ target: { checked } }) => onCheckChange(checked)}
        style={{ marginRight: 10}}
      />
      <div ref={cardRef} style={{ width: '100%', height: 60 }}>
        <Draggable
          key={applicant.id}
          draggableId={`${applicant.id}`}
          index={index}
          isDragDisabled={!isDraggable}
        >
          {(provided, snapshot) => (
            <div style={{ position: 'relative', width: 40, overflowX: 'visible' }}>
              <div
                {...provided.draggableProps}
                ref={provided.innerRef}
              >
                <DraggingContainer
                  width={cardWidth}
                  isDragging={snapshot.isDragging}
                  isSelected={isSelected}
                >
                  <div style={{ cursor: isDraggable ? 'grab' : 'not-allowed' }}>
                    <div {...provided.dragHandleProps} >
                      <Icon type="drag_indicator" style={{ color: '#DDD', fontSize: 30, paddingTop: 5 }} />
                    </div>
                  </div>

                  <div style={{ paddingLeft: 5, cursor: 'arrow', flex: 1 }} onClick={onClick}>
                    <p style={{ fontWeight: 'bold', cursor: 'arrow', marginBottom: 0, fontSize: 16, lineHeight: 1.2 }}>{applicant.firstName} {applicant.lastName}</p>
                    <p style={{ marginBottom: 0, cursor: 'arrow', color: '#999', fontSize: 14, paddingRight: 10 }}>{`Submitted ${moment(applicant.createdAt).format('DD/MM/YYYY')}`}</p>
                  </div>
                  <StarRatings
                    rating={applicant.rating || 0}
                    starRatedColor="#FFC700"
                    starEmptyColor="#DDD"
                    changeRating={setStarRating}
                    starDimension="18px"
                    starSpacing="1px"
                  />
                </DraggingContainer>
              </div>
              {
                // @ts-ignore
                provided.placeholder
              }
            </div>
          )}
        </Draggable>
      </div>
    </div>
  );
};

export default ApplicantCard;