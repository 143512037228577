import React, { useContext, useMemo, useState, useCallback } from 'react';

import _ from 'lodash';
import { useParams } from 'react-router-dom';
import { useSelector, shallowEqual } from 'react-redux';
import { Column } from 'react-virtualized';

import { Job, JobStatus, JobFields } from '@ethical-jobs/sdk-js/types';

import { usePromise } from 'hooks';
import { APIContext } from 'lib/contexts';

import Container from 'components/Container';
import Spinner from 'components/Spinner';
import DataTable from 'components/DataTable';

import DateTimeRenderer from 'components/DataTableRenderers/DateTimeRenderer';
import BooleanRenderer from 'components/DataTableRenderers/BooleanRenderer';
import RouteRenderer from 'components/DataTableRenderers/RouteRenderer';

import PageTitle from './PageTitle';

const JobCollection = () => {
  const api = useContext(APIContext);
  const { jobType = '' } = useParams();
  
  // @ts-ignore
  const organisation = useSelector(state => state.auth.user.organisation, shallowEqual);

  const [sort, setSort] = useState<{ field: string, direction: 'asc' | 'desc' }> ({ field: 'createdAt', direction: 'desc' });

  const getJobs = useMemo(() => {
    let fields: Array<JobFields> = ['id', 'organisationId', 'title', 'isFeatured', 'isExpired', 'location', 'status', 'createdAt', 'expiresAt'];

    return api.getJobs({
      status: jobType.toUpperCase() === 'EXPIRED' ? ['APPROVED', 'PENDING'] : jobType.toUpperCase() as JobStatus,
      includeExpired: jobType.toUpperCase() === 'EXPIRED',
      organisations: organisation.id,
      fields,
      limit: 100,
      useElasticSearch: false,
      orderBy: [sort]
    });
  }, [api, jobType, organisation, sort]);

  const [{ jobs }, , isFetching] = usePromise(getJobs, { jobs: [], usedFallback: false, total: 0, title: '' }, { component: 'JobCollection' });

  const onSortChange = useCallback((newSort: { sortBy: string; sortDirection: 'ASC' | 'DESC' }) => {
    // @ts-ignore
    setSort({ field: newSort.sortBy, direction: newSort.sortDirection.toLowerCase() });
  }, []);

  return (
    <Container className="jobs-container">
      <PageTitle jobType={jobType} />
      <div className="entity-listing-container">
        <div className="data-table-filters">
          <div className="meta clearfix">
            <div className="result">{`${jobs.length || 'No'} results`}</div>
            <Spinner show={isFetching} />
          </div>
        </div>
        <DataTable
          rows={jobs}
          // @ts-ignore
          defaultSort={{ sortBy: sort.field, sortDirection: sort.direction.toUpperCase() }}
          onSortChange={onSortChange}
        >
          <Column
            label="Title"
            dataKey="title"
            cellRenderer={data => (
              <RouteRenderer to={`/organisation/job/${data.rowData.id}/view`} text={data.cellData} />
            )}
            width={350}
            flexGrow={1}
          />
          <Column
            label="Featured"
            dataKey="isFeatured"
            cellRenderer={data => <BooleanRenderer cellData={data.cellData} />}
            width={70}
          />
          <Column
            label="Location"
            dataKey="location"
            cellRenderer={({ rowData }: { rowData: Job }) => <span>{_.get(rowData, ['location', 'title'], '')}</span>}
            width={100}
          />
          <Column
            label="Created On"
            dataKey="createdAt"
            key="createdAt"
            cellRenderer={data => <DateTimeRenderer timestamp={data.cellData} />}
            width={150}
          />
          <Column
            label="Expiry Date"
            dataKey="expiresAt"
            key="expiresAt"
            cellRenderer={data => <DateTimeRenderer timestamp={data.cellData} />}
            width={150}
          />
        </DataTable>
      </div>
    </Container>
  );
}

export default React.memo(JobCollection);
