import React from 'react';
import moment from 'moment';

import TableMetrics from 'components/TableMetrics';

const dateRanges = [0, 1, 2, 3, 4, 5].map(numWeeks => ({
  from: moment().startOf('week').subtract(numWeeks, 'weeks').add(9, 'hours').format('YYYY-MM-DD HH:mm:ss'),
  to: moment().startOf('week').subtract(numWeeks, 'weeks').add(9, 'hours').format('YYYY-MM-DD HH:mm:ss')
}));

const ApprovedJobsByLocationTable = () => (
  <TableMetrics
    className="metric-jobs-by-location"
    title="Approved jobs by location"
    headers={['','This week','+1 week','+2 weeks','+3 weeks','+4 weeks','+5 weeks']}
    showTotals
    query={{
      dateRanges,
      metric: 'metric:currentApprovedJobs',
      dimensions: ['dimension:locationId']
    }}
  />
);

export default ApprovedJobsByLocationTable;
