import React, { useContext } from 'react';

import { LinkContainer } from 'react-router-bootstrap';
import NavItem from 'react-bootstrap/lib/NavItem';
import { User, Organisation } from '@ethical-jobs/sdk-js/types';
import { ThemeContext, Text } from '@ethical-jobs/design-system-react';

import TopNav from 'components/TopNav';
import SideNav from 'components/SideNav';
import Footer from 'components/Footer';
import OrganisationMenu from 'components/OrganisationMenu';

type Props = {
  user: User & { organisation: Organisation };
  children: React.ReactNode;
  logout: () => void;
};

const Root = ({ user, children, logout }: Props) => {
  const { designSystemReact: { colors, spacing }} = useContext(ThemeContext);

  return (
    <div className="organisation-container">
      <TopNav>
        <OrganisationMenu organisationName={user.organisation.name} logout={logout} />
      </TopNav>
      <div className="left-column">
        <SideNav>
          <LinkContainer to="/organisation/purchase">
            <NavItem className="balance">
              Job Ad Credits
              <strong
                className={`balance-${user.organisation.numCredits}`}
              >
                {user.organisation.numCredits}
              </strong>
            </NavItem>
          </LinkContainer>

          <LinkContainer to="/organisation">
            <NavItem>
              <span className="material-icons md-26">home</span>
              <span>Dashboard</span>
            </NavItem>
          </LinkContainer>

          <NavItem className="section-title">Organisation</NavItem>

          <LinkContainer to="/organisation/profile">
            <NavItem>
              <span className="material-icons md-26">supervisor_account</span>
              <span>Profile</span>
            </NavItem>
          </LinkContainer>

          <LinkContainer to="/organisation/users">
            <NavItem>
              <span className="material-icons md-26">person_add</span>
              <span style={{ display: 'inline-flex' }}>
                Users
                <span style={{ backgroundColor: colors.main.appleGreen, borderRadius: spacing.borderRadius.medium, padding: '0px 10px', marginLeft: 20, verticalAlign: 'center' }}>
                  <Text style={{ fontSize: 12, color: colors.main.white, display: 'inline-block' }}>NEW</Text>
                </span>
              </span>
            </NavItem>
          </LinkContainer>

          <NavItem className="section-title">Job Ads</NavItem>

          <LinkContainer to="/organisation/job/create">
            <NavItem>
              <span className="material-icons md-26">add</span>
              <span>Post new ad</span>
            </NavItem>
          </LinkContainer>
          <LinkContainer to="/organisation/jobs/pending">
            <NavItem>
              <span className="material-icons md-26">list</span>
              <span>View pending ads</span>
            </NavItem>
          </LinkContainer>
          <LinkContainer to="/organisation/jobs/approved">
            <NavItem>
              <span className="material-icons md-26">list</span>
              <span>View live ads</span>
            </NavItem>
          </LinkContainer>
          <LinkContainer to="/organisation/jobs/expired">
            <NavItem>
              <span className="material-icons md-26">list</span>
              <span>View expired ads</span>
            </NavItem>
          </LinkContainer>

          <NavItem className="section-title">Job Ad Credits</NavItem>

          <LinkContainer to="/organisation/purchase">
            <NavItem>
              <span className="material-icons md-26">add</span>
              <span>Purchase credits</span>
            </NavItem>
          </LinkContainer>
          <LinkContainer to="/organisation/invoices">
            <NavItem>
              <span className="material-icons md-26">list</span>
              <span>View all invoices</span>
            </NavItem>
          </LinkContainer>
        </SideNav>
      </div>

      <div className="right-column" role="main">
        <div style={{ minHeight: 'calc(100vh - 181px)' }}>
          {children}
        </div>
        <Footer />
      </div>
    </div>
  );
};

export default Root;
