import React, { useMemo, useContext } from 'react';
import classNames from 'classnames';
import { ReportParams } from '@ethical-jobs/sdk-js/types';

import { usePromise } from 'hooks';
import { APIContext } from 'lib/contexts';
import Card from 'components/Card';
import Table from './Table';

type Props = {
  title: string;
  className?: string;
  headers: Array<string>;
  query: ReportParams;
  showTotals?: boolean;
};

const TableMetrics = ({ title, className, headers, query, showTotals }: Props) => {
  const api = useContext(APIContext);

  const getReportData = useMemo(() => api.getReportData({ ...query }), [query, api]);

  const [report] = usePromise(getReportData, [], { component: 'TableMetrics' });

  return (
    <Card subTitle={title} className={classNames("table-metrics", className)}>
      <Table rows={report as Array<{ [key: string]: string } | Array<{ [key: string]: string }>>} headers={headers} showTotals={showTotals} />
    </Card>
  );
}

export default React.memo(TableMetrics);