import React, { useState, useEffect } from 'react';
import Button from 'components/Button';
import Modal from 'components/Modal';

const PUBLIC_HOLIDAYS = [{
  begin: (new Date('April 19, 2019 01:00:00')),
  end: (new Date('April 23, 2019 01:00:00')),
  title: 'Easter Holiday.',
  backInOffice: 'Tuesday the 23rd of April, 2019',
},
{
  begin: (new Date('April 25, 2019 01:00:00')),
  end: (new Date('April 26, 2019 01:00:00')),
  title: 'ANZAC Day.',
  backInOffice: 'Friday the 26th of April, 2019',
},
{
  begin: (new Date('June 10, 2019 01:00:00')),
  end: (new Date('June 11, 2019 01:00:00')),
  title: 'Queen\'s Birthday.',
  backInOffice: 'Tuesday the 11th of June, 2019',
},
{
  begin: (new Date('September 20, 2019 13:30:00')),
  end: (new Date('September 20, 2019 16:00:00')),
  title: 'The team is attending the Global Climate Strike',
  backInOffice: '4:00pm Friday 20th of September',
},
{
  begin: (new Date('September 27, 2019 01:00:00')),
  end: (new Date('September 30, 2019 01:00:00')),
  title: 'AFL Grand Final',
  backInOffice: 'Monday 30th September, 2019',
},
{
  begin: (new Date('November 5, 2019 01:00:00')),
  end: (new Date('November 6, 2019 01:00:00')),
  title: 'Melbourne Cup',
  backInOffice: 'Wednesday 6th November, 2019',
},
{
  begin: (new Date('December 24, 2019 17:00:00')),
  end: (new Date('January 2, 2020 09:00:00')),
  title: 'Christmas Break',
  backInOffice: '9am Thursday 2nd January, 2020',
}, {
  begin: (new Date('March 6, 2020 17:00:00')),
  end: (new Date('March 10, 2020 09:00:00')),
  title: 'Labour Day',
  backInOffice: '9am Tuesday 10th March, 2020',
}, {
  begin: (new Date('April 9, 2020 17:00:00')),
  end: (new Date('April 14, 2020 09:00:00')),
  title: 'Easter',
  backInOffice: '9am Tuesday 14th April, 2020',
}, {
  begin: (new Date('June 5, 2020 17:00:00')),
  end: (new Date('June 9, 2020 09:00:00')),
  title: 'Queen\'s Birthday (VIC)',
  backInOffice: '9am Tuesday 9th June, 2020',
}];

const getCurrentHoliday = () => {
  const now = new Date();
  for (const holiday of PUBLIC_HOLIDAYS) {
    if (now >= holiday.begin && now <= holiday.end) return holiday;
  }
};

const currentHoliday = getCurrentHoliday();

const PublicHoliday = () => {
  const [showModal, setShowModal] = useState(false);

  useEffect(() => {
    if (currentHoliday) {
      setShowModal(true);
    }
  }, []);
  
  return (
    <Modal show={showModal} onHide={() => setShowModal(false)} className="welcome-modal">
      <h2 className="modal-title">We're out of the office</h2>
      <div className="modal-body">
        <h4 className="centered primary">
          {currentHoliday ? currentHoliday.title : ''}
        </h4>
        <p className="centered">
          Please feel free to post your job, but be aware that it will only appear on the EthicalJobs.com.au site once we are back in the office at { currentHoliday ? currentHoliday.backInOffice : '' }.
        </p>
        <p className="centered">
          Thanks for your understanding - have a great day! <br/><strong><em>The EthicalJobs.com.au team</em></strong>
        </p>
        <Button
          block
          kind="primary"
          onClick={() => setShowModal(false)}
          label="Okay"
          icon="done_all"
        />
      </div>
    </Modal>
  );
};

export default PublicHoliday;