import React from 'react';
import { LinkContainer } from 'react-router-bootstrap';
import Button from 'components/Button';
import classNames from 'classnames';

const RecoverPasswordLink = () => (
  <div className={classNames('recover-password-link')} >
    <LinkContainer to="/recover-password">
      <Button
        kind="link"
        size="small"
        className="btn-forgotten"
      >
        Forgotten your password? <span className="action">Click here.</span>
      </Button>
    </LinkContainer>
    <div className="tip">
      Forgot your password? Click above to create a new one.
    </div>
  </div>
);

export default RecoverPasswordLink;