import confetti from 'canvas-confetti';

// https://www.kirilv.com/canvas-confetti/
export const fireConfetti = () => {
  const end = Date.now() + (5 * 1000);
  const colors = ['#57AD34', '#0079C1'];

  (function frame() {
    confetti({
      particleCount: 2,
      angle: 60,
      spread: 55,
      origin: {
        x: 0
      },
      colors: colors
    });
    confetti({
      particleCount: 2,
      angle: 120,
      spread: 55,
      origin: {
        x: 1
      },
      colors: colors
    });
    confetti({
      particleCount: 1,
      startVelocity: 0,
      ticks: 300,
      origin: {
            x: Math.random(),
        // since they fall down, start a bit higher than random
        y: Math.random() - 0.4
      },
      colors: ['#57AD34'],
      shapes: ['circle']
    });
    confetti({
      particleCount: 1,
      startVelocity: 0,
      ticks: 300,
      origin: {
        x: Math.random(),
        // since they fall down, start a bit higher than random
        y: Math.random() - 0.4
      },
      colors: ['#0079C1'],
      shapes: ['circle']
    });

    if (Date.now() < end) {
      requestAnimationFrame(frame);
    }
  }());
}