import React, { useState, useCallback } from 'react';

import { Organisation } from '@ethical-jobs/sdk-js/types';

import Button from 'components/Button';
import MediaUpload from 'components/MediaUpload';

type Props = {
  organisation: Organisation;
  upload: (file: File, organisationId: number) => Promise<Organisation | undefined>;
};

const OrganisationLogoUpload = ({ organisation, upload }: Props) => {
  const [preview, setPreview] = useState(null);

  const uploadLogo = useCallback(file => {
    setPreview(file && file.preview);
    return upload(file, organisation.id);
  }, [organisation, upload]);

  const logoUrl = preview || organisation.logos.standard;

  return (
    <div className="organisation-logo-upload">
      <MediaUpload
        attach={uploadLogo}
        accept="image/gif, image/jpg, image/jpeg, image/png"
        showAttached={false}
      >
        {logoUrl ? (
          <img src={logoUrl} alt="Logo" className="logo" />
        ) : (
          <Button>Upload Your Logo</Button>
        )}
      </MediaUpload>
    </div>
  );
}

export default OrganisationLogoUpload;