import styled from 'styled-components';

export const DisplayContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  border-radius: 4px;
  border-width: 2px;
  border-style: solid;
  border-color: #CACACA;
  padding: 45px;
`;

export const IconContainer = styled.div`
  width: 140px;
  height: 140px;
  border-radius: 70px;
  border: 4px solid black;
  display: flex;
  align-items: center;
  justify-content: center;
`;