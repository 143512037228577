import React from 'react';

type Props = {
  qty: number;
};

const InvoiceQuantityRenderer = ({ qty }: Props) => (
  <span>{qty > 0 ? qty : 'N/A'}</span>
);

export default InvoiceQuantityRenderer;
