import React, { Fragment } from 'react';

import classNames from 'classnames';
import { formatMoney } from 'lib/formatters';

type Props = {
  creditPack: {
    volume: number;
    total: number;
    savings: number;
  };
  className: string;
};

const PurchaseTitle = ({ creditPack, className }: Props) => (
  <div className={classNames('selected-pack', className)}>
    {creditPack.volume <= 1 ? (
      <Fragment>
        <span className="preText">You're purchasing </span>
        <span className="volume">{creditPack.volume} job ad credit.</span>
      </Fragment>
    ) : (
      <Fragment>
        <span className="preText">You're purchasing a package of </span>
        <span className="volume">{creditPack.volume} job ad credits.</span>
      </Fragment>
    )}
    <br />
    <span className="postText">Total: </span>
    <span className="cost">{formatMoney(creditPack.total)}</span>
    <span className="gst"> inc GST.</span>
    {creditPack.savings > 0 && (
      <span className="savings"> you save {formatMoney(creditPack.savings)}</span>
    )}
  </div>
);

export default PurchaseTitle;
