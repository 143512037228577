import React, { useState, useEffect } from 'react';
import { usePreviousValue } from 'hooks';

type Props = {
  value?: string | null;
  defaultValue?: string;
  onChange: (value: string) => void;
};

// TODO: Validate hours handle 00:00
const splitTime = (time: string | null | undefined, defaultValue: string) => {
  let timeToSplit = time || defaultValue

  try {
    let [hours, rest] = timeToSplit.split(':');
    let [minutes, meridian] = rest.split(' ');

    return [Number(hours), Math.round(Math.round(Number(minutes) / 15) * 15), meridian];
  } catch (error) {
    return [12, 0, 'AM'];
  }
}

const TimeField = ({ value = '5:00 PM', defaultValue = "12:00 AM", onChange }: Props) => {
  const previousValue = usePreviousValue(value);

  const [h, m, me] = splitTime(value, defaultValue);

  const [hours, setHours] = useState(h);
  const [minutes, setMinutes] = useState(m);
  const [meridian, setMeridian] = useState(me);

  useEffect(() => {
    if (value !== previousValue && value !== `${hours}:${minutes.toString().padStart(2, '0')} ${meridian}`) {
      const [h, m, me] = splitTime(value, defaultValue);

      setHours(h);
      setMinutes(m);
      setMeridian(me);
    }
  }, [previousValue, value, defaultValue, hours, minutes, meridian]);

  useEffect(() => {
    onChange(`${hours}:${minutes.toString().padStart(2, '0')} ${meridian}`);
  }, [onChange, hours, minutes, meridian]);

  return (
    <div className="time-field">
      <div className="row">
        <div className="col-md-2">
          <select onChange={({ target: { value }}) => setHours(Number(value))} value={hours} style={{ minWidth: 50 }}>
            <option value={1}>1</option>
            <option value={2}>2</option>
            <option value={3}>3</option>
            <option value={4}>4</option>
            <option value={5}>5</option>
            <option value={6}>6</option>
            <option value={7}>7</option>
            <option value={8}>8</option>
            <option value={9}>9</option>
            <option value={10}>10</option>
            <option value={11}>11</option>
            <option value={12}>12</option>
          </select>
        </div>
        <div className="col-md-2">
          <select onChange={({ target: { value }}) => setMinutes(Number(value))} value={minutes} style={{ minWidth: 50 }}>
            <option value={0}>00</option>
            <option value={15}>15</option>
            <option value={30}>30</option>
            <option value={45}>45</option>
          </select>
        </div>
        <div className="col-md-2">
          <select onChange={({ target: { value }}) => setMeridian(value)} value={meridian} style={{ minWidth: 50 }}>
            <option value="AM">AM</option>
            <option value="PM">PM</option>
          </select>
        </div>
      </div>
    </div>
  );
};

export default TimeField;
