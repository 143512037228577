// @ts-nocheck
import React from 'react';
import classNames from 'classnames';

const RenderField = ({ input, meta, field, ...props }) => {
  // 1. Destructure props we dont want on the <input />
  const { wrapClassName, label, description, required, optional, ...inputProps } = props;

  // 2. Determine the wrapping class
  const _wrapClassName = classNames('form-field', inputProps.type, inputProps.name, wrapClassName, {
    error: meta.touched && meta.error, // checkbox fields dont get touched as there is no blur event
    valid: meta.touched && meta.valid,
  });

  // 3. Determine the <input /> class
  const _className = classNames(inputProps.className, 'form-control');
  
  return (
    <div className={_wrapClassName}>
      {label && (
        <label htmlFor={inputProps.id}>
          {required && <span className="required">*</span>}
          {label}
          {optional && <span className="optional"> (Optional)</span>}
        </label>
      )}
      {description && <p className="description">{description}</p>}
      {React.createElement(field, { ...input, ...inputProps, className: _className })}
      {meta.touched && meta.error && <div className="text-danger">{meta.error}</div>}
    </div>
  );
};

export default RenderField;
