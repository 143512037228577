import _ from 'lodash';
import React, { useContext, useMemo } from 'react';
import { usePromise } from 'hooks';
import { Questions as QuestionsType } from '@ethical-jobs/sdk-js/types';
import { useSelector } from 'react-redux';

import { Container } from './Questions.styles';
import { APIContext } from 'lib/contexts';
import QuestionsSection from './QuestionsSection';

const Questions = () => {
  const api = useContext(APIContext);
  const getQuestions: Promise<QuestionsType | null> = useMemo(() => api.getQuestions(), [api]);
  const applyMethod = useSelector(state => _.get(state, ['form', 'jobForm', 'values', 'applyMethod'], null));
  const [questions] = usePromise(getQuestions, {}, { component: 'Questions' });

  return applyMethod !== 'button' ? <Container><QuestionsSection questions={questions} /></Container> : null;
};

export default Questions;
