import { SubmissionError } from 'redux-form';

/**
 * Throws redux form submission errors from within a promise
 */
export default function submissionError(response: { statusCode: number, errors?: Object, message: string }) {
  if (response.statusCode / 100 !== 2) { // error code
    if (response.statusCode >= 500) {
      throw new SubmissionError({ _error: 'There has been an internal error, please contact us for further assistance.' });
    }
    if (!response.errors) {
      throw new SubmissionError({ _error: response.message });
    }

    // @ts-ignore
    throw new SubmissionError(response.errors);
  }
}
