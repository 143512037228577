import React from 'react';

import moment from 'moment';
import { Media } from '@ethical-jobs/sdk-js/types';

import Icon from 'components/Icon';

const onDetach = (event: React.MouseEvent<HTMLAnchorElement>, detach: (mediaId: number) => Promise<Array<Media> | undefined>, mediaItem: Media) => {
  event.preventDefault();
  return detach(mediaItem.id);
};

type Props = {
  media: Array<Media>;
  detach: (mediaId: number) => Promise<Array<Media> | undefined>;
};

const MediaList = ({ media, detach }: Props) => {
  if (!media || media.length === 0) {
    return null;
  }

  return (
    <ul className="media-list">
      {media.map(item => (
        <li key={item.id}>
          {/* eslint-disable-next-line */}
          <a onClick={event => onDetach(event, detach, item)} className="detach">
            <Icon type="clear" />
          </a>
          <a
            href={item.url}
            target="_blank"
            className="item"
            rel="noopener noreferrer"
          >
            {item.originalFileName}
          </a>
          <span> uploaded {moment(item.createdAt).format('DD/MM/YYYY @ H:mm a')}</span>
        </li>
      ))}
    </ul>
  );
};

export default MediaList;
