import { object, string, number, ref } from 'yup';

import { MESSAGE } from 'lib/validation';

export default object().shape({
  firstName: string().required(MESSAGE.REQUIRED('your first name')),
  lastName: string().required(MESSAGE.REQUIRED('your last name')),
  position: string().required(MESSAGE.REQUIRED('your position')),
  email: string()
    .email('The email you entered is not valid')
    .required(MESSAGE.REQUIRED('an email')),
  emailConfirmation: string()
    .oneOf([ref('email')], 'Emails do not match')
    .required(MESSAGE.REQUIRED('the email again')),
  username: string()
    .min(5, MESSAGE.MIN('Username'))
    .required(MESSAGE.REQUIRED('a username')),
  password: string()
    .min(8, MESSAGE.MIN('Password'))
    .required(MESSAGE.REQUIRED('a password')),
  name: string()
    .min(5, MESSAGE.MIN('Organisation name'))
    .max(60, MESSAGE.MAX('Organisation name'))
    .required(MESSAGE.REQUIRED('your organisation\'s name')),
  phone: string()
    .min(5, MESSAGE.MIN('Organisation phone number'))
    .max(25, MESSAGE.MAX('Organisation phone number'))
    .required(MESSAGE.REQUIRED('your organisation\'s phone number')),
  numPaidStaff: number()
    .typeError(MESSAGE.NUMBER)
    .integer(MESSAGE.INTEGER)
    .moreThan(-1, MESSAGE.POSITIVE)
    .required(MESSAGE.REQUIRED('the number of paid staff')),
  numVolunteers: number()
    .typeError(MESSAGE.NUMBER)
    .integer(MESSAGE.INTEGER)
    .moreThan(-1, MESSAGE.POSITIVE)
    .required(MESSAGE.REQUIRED('the number of volunteers')),
  logoId: number().required(MESSAGE.REQUIRED('organisation logo')),
  address: string().required(
    MESSAGE.REQUIRED('your organisation\'s street address')
  ),
  suburb: string().required(MESSAGE.REQUIRED('your organisation\'s suburb')),
  state: string()
    .min(2, MESSAGE.MIN('Organisation state'))
    .required(MESSAGE.REQUIRED('your organisation\'s state')),
  postcode: string()
    .min(3, MESSAGE.MIN('Postcode'))
    .required(MESSAGE.REQUIRED('your organisation\'s postcode')),
  country: string().required(MESSAGE.REQUIRED('your organisation\'s country'))
});
