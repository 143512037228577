import React from "react";

import Logo from "components/Logo";
import Card from "components/Card";
import Button from "components/Button";

const MobileUnavailable = () => (
  <div className="login-container auth-container container">
    <div className="row">
      <div className="col-md-offset-2 col-md-8">
        <Logo className="animated fadeInDown" />
        <Card
          title="Employer Sign In"
          subTitle="Welcome to the EthicalJobs.com.au employer portal. If you have any questions please contact us on (03) 9419 5979 – we'd love to hear from you!"
          className="animated fadeIn"
        />
        <h3 className="strike-through animated fadeIn">
          <span>&darr;</span>
        </h3>
        <Card
          title="Mobile Access"
          subTitle="Unfortunately our employer portal does not currently support mobile logins, so please login on a desktop computer to create, view and manage your job listings"
          className="animated fadeInUp"
        >
          <a href="https://www.ethicaljobs.com.au">
            <Button kind="primary" label="Visit EthicalJobs.com.au" block />
          </a>
        </Card>
      </div>
    </div>
  </div>
);

export default MobileUnavailable;

