import emailRegex from 'email-regex';

import validate from 'validate.js';
import { parse as uriParse, URIComponents } from 'uri-js';


export const getMailtoLinks = (html: string) => {
  const el = document.createElement('html');
  el.innerHTML = html;

  const links = el.getElementsByTagName('a');

  let mailToLinks: Array<URIComponents & { to: Array<string>, linkLabel: string }> = [];

  for (const link of links) {
    const parsed = uriParse(link.href);
    if (parsed.scheme === 'mailto') {
      mailToLinks.push({
        linkLabel: link.textContent || '',
        to: [],
        ...parsed
      });
    }
  }

  return mailToLinks;
};

export const getEmailAddresses = (text: string): Array<string> => {
  if (text === null || text === undefined || typeof text !== 'string') {
    return [];
  }

  const emails = text.match(emailRegex());

  if (!Array.isArray(emails)) {
    return [];
  }

  return emails;
};

/**
 * Remove punctuation around email addresses.
 *
 * Email addresses ending in punctuation are valid, which is annoying, but rare which is good.
 * This function can be used if you are pretty sure the punctuation isn't supposed to be there.
 */
export const cleanEmailAddress = (email: string): string => {
  const trimChars = ['.', ',', "'", '"', '`', '!', '?'];

  let cleaned = email;

  const firstChar = cleaned.substring(0, 1);
  for (const trimChar of trimChars) {
    if (firstChar === trimChar) {
      cleaned = cleaned.substring(1, cleaned.length);
      break;
    }
  }

  const lastChar = cleaned.substring(cleaned.length - 1, cleaned.length);
  for (const trimChar of trimChars) {
    if (lastChar === trimChar) {
      cleaned = cleaned.substring(0, cleaned.length - 1);
      break;
    }
  }

  return cleaned;
};

export const isReachableUrl = async (url: string): Promise<boolean> => {
  const fullUrl = url.toLowerCase().startsWith('http') ? url : 'https://' + url;

  if (validate({ url: fullUrl }, { url: { url: true } }) !== undefined) return false;

  try {
    let response = await fetch(fullUrl, { method: 'HEAD', mode: 'no-cors' });
    return response.status !== 404;
  } catch (error) {
    return false;
  }
}
