import React, { useCallback, useEffect, useState } from 'react';

type Props = {
  value?: Array<string>;
  options: Array<{ label: string, value: string }>;
  onChange: (value: Array<string>) => void;
  onBlur: (value: Array<string>) => void;
};

const CheckboxGroup = ({ value, options, onChange, onBlur }: Props) => {
  let [checked, setChecked] = useState(value || []);

  useEffect(() => {
    setChecked(value || []);
  }, [value]);

  const handleChange = useCallback((selectedValue) => {
    let checkedValues: Array<string> = checked.includes(selectedValue)
    ? checked.filter(item => item !== selectedValue)
    : [...checked, selectedValue];

    setChecked(checkedValues);
    onChange(checkedValues);
  }, [onChange, checked]);

  return (
    <ul className="checkbox-array">
      {options.map(option => (
        <li className="checkbox" key={option.value}>
          <input
            type="checkbox"
            value={option.value}
            onChange={() => handleChange(option.value)}
            onBlur={() => onBlur(checked)}
            checked={checked.includes(option.value)}
          />
          <label onClick={() => handleChange(option.value)}>
            {option.label}
          </label>
        </li>
      ))}
    </ul>
  );
};

export default CheckboxGroup;