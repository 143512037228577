import React, { useContext, useMemo } from 'react';

import _ from 'lodash';
import { Organisation } from '@ethical-jobs/sdk-js/types';

import { usePromise } from 'hooks';
import { APIContext } from 'lib/contexts';

import UserProfileSmall from 'components/UserProfileSmall';

import OrganisationList from './OrganisationList';

type Props = {
  filters: Object;
};

const StaffOrganisationAssigner = ({ filters }: Props) => {
  const api = useContext(APIContext);

  const getOrganisations = useMemo(() => api.getOrganisations({
    ..._.pickBy(filters, filter => !_.isEmpty(filter)),
    limit: 100
  }), [api, filters]);

  const [organisations] = usePromise(getOrganisations, [], { component: 'StaffOrganisationAssigner' });

  return (
    <div id="staff-organisation-assigner">
      <div className="row">
        <div className="col-md-5">
          <OrganisationList organisations={organisations as Array<Organisation>} />
        </div>
        <div className="col-md-5">
          <UserProfileSmall
            user={{
              id: 0,
              firstName: 'Sari',
              lastName: 'Kisilevsky',
              avatar: 'https://scontent.fsdv1-1.fna.fbcdn.net/v/t1.0-9/10389969_10152525184990903_420287957580763200_n.jpg?oh=d02cd5db133b6941b46e701cf4ed86c2&oe=5B02C980',
              email: 'sari.kisilevsky@qccuny.edu',
              username: 'sari',
              phone: '111',
              name: 'Sari Kisilevsky',
              bio: '',
              position: '',
              createdAt: '2020-01-01 12:00:00',
              updatedAt: '2020-01-01 12:00:00',
              deletedAt: null,
              lastLogin: '2020-01-01 12:00:00',
              roles: []
            }}
          />
          <OrganisationList organisations={[]} />
        </div>
      </div>
    </div>
  );
}

export default React.memo(StaffOrganisationAssigner);
