import React, { useMemo, useState, useContext, useCallback, useRef } from 'react';

import moment from 'moment';
import cogoToast from 'cogo-toast';
import { Text, Column, Select } from '@ethical-jobs/design-system-react';

import { APIContext, AmplitudeContext } from 'lib/contexts'
import { usePromise } from 'hooks';
import Icon from 'components/Icon';
import Button from 'components/Button';
import StaffAvatar from 'components/StaffAvatar';

const COMMENT_TYPES = [{
  label: 'None',
  value: null
}, {
  label: 'Blind Ad',
  value: 'Blind Ad'
}, {
  label: 'Multiple Ads In One',
  value: 'Multiple Ads In One'
}, {
  label: 'Question For Client',
  value: 'Question For Client'
}, {
  label: 'Ready For Review',
  value: 'Ready For Review'
}, {
  label: 'Rejected',
  value: 'Rejected'
}, { 
  label: 'Wait Until Date',
  value: 'Wait Until Date'
}];

export const COMMENT_ICONS = {
  'Blind Ad': 'remove_red_eye',
  'Question For Client': 'live_help',
  'Multiple Ads In One': 'filter_2',
  'Wait Until Date': 'alarm',
  'Rejected': 'close',
  'Ready For Review': 'search',
  null: 'comment'
};

const JobComments = ({ jobId }) => {
  const api = useContext(APIContext);
  const amplitude = useContext(AmplitudeContext);

  const commentTextRef = useRef(null);

  const [commentText, setCommentText] = useState('');
  const [commentType, setCommentType] = useState(null);
  const [doesCommentRequireAction, setDoesCommentRequireAction] = useState(true);
  const [refresh, setRefresh] = useState(0);
  const [isSubmitting, setIsSubmitting] = useState(false);

  // eslint-disable-next-line
  const getComments = useMemo(() => api.getJobComments(jobId), [jobId, api, refresh]);

  const [comments, , isLoading] = usePromise(getComments, []);

  const saveComment = useCallback(async () => {
    setIsSubmitting(true);
    try {
      await api.createJobComment(jobId, { comment: commentText, isResolved: !doesCommentRequireAction, type: commentType });
      cogoToast.success('Successfully added comment', { hideAfter: 3, position: 'top-right' });
      setCommentType(null);
      setCommentText('');
      commentTextRef.current.value = '';
      setDoesCommentRequireAction(false);
      setRefresh(Math.random());
      amplitude.logEvent('API', { function: 'createJobComment', jobId, arguments: { comment: commentText, isResolved: !doesCommentRequireAction, type: commentType } });
    } catch (error) {
      amplitude.logError('components/JobComment/index:saveComment', error);
    }

    setIsSubmitting(false);
  }, [jobId, commentText, commentTextRef, commentType, doesCommentRequireAction, api, amplitude]);

  const markCommentAsResolved = useCallback(async (commentId) => {
    try {
      await api.updateComment(commentId, { isResolved: true });
      cogoToast.success('Comment marked as resolved', { hideAfter: 3, position: 'top-right' });
      setRefresh(Math.random());
      amplitude.logEvent('API', { function: 'updateComment', commentId, arguments: { isResolved: true } });
    } catch (error) {
      amplitude.logError('components/JobComment/index:markCommentAsResolved', error);
    }
  }, [api, amplitude]);

  const deleteComment = useCallback(async (commentId) => {
    try {
      await api.deleteComment(commentId);
      cogoToast.success('Successfully deleted comment', { hideAfter: 3, position: 'top-right' });
      setRefresh(Math.random());
      amplitude.logEvent('API', { function: 'deleteComment', commentId });
    } catch (error) {
      amplitude.logError('components/JobComment/index:deleteComment', error);
    }
  }, [api, amplitude]);

  return (
    <Column gap="medium" style={{ backgroundColor: 'white' }}>
      {comments.length > 0 && (
        <div style={{ padding: 15, borderBottom: '1px solid #DDD' }}>
          {comments.map(comment => (
            <div key={comment.id} style={{ padding: '10px 15px 5px 15px', display: 'flex', backgroundColor: comment.isResolved ? 'initial' : '#CDDBE7', borderRadius: 5 }}>
              <div style={{ paddingRight: 30 }}>
                <StaffAvatar
                  src={comment.author.avatar}
                  userId={comment.author.id}
                  name={comment.author.name}
                  width={50}
                />
              </div>
              <div style={{ flex: 1 }}>
                <div style={{ display: 'flex', alignItems: 'center' }}>
                  <Text style={{ fontWeight: 'bold', fontSize: 14 }}>{comment.author.name}</Text>
                  <Text style={{ fontSize: 14, color: '#999', paddingLeft: 5 }}>({moment(comment.updatedAt).fromNow()})</Text>
                </div>
                <Text>{comment.comment}</Text>
                <div style={{ display: 'flex', alignItems: 'center', paddingTop: 5 }}>
                  {!comment.isResolved && (
                    <span onClick={() => markCommentAsResolved(comment.id)} style={{ cursor: 'pointer' }}>
                      <Text style={{ fontSize: 11, color: '#0079C1', marginRight: 15 }}>Mark As Resolved</Text>
                    </span>
                  )}
                  <span
                    style={{ cursor: 'pointer' }}
                    onClick={() => {
                      if (window.confirm('Are you sure you want to delete this comment?')) {
                        deleteComment(comment.id);
                      }
                    }}>
                    <Text style={{ fontSize: 11, color: '#9e2146' }}>Delete</Text>
                  </span>
                </div>
              </div>
              <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-end' }}>
                <Text style={{ fontSize: 14, color: '#999' }}>{moment(comment.updatedAt).format('MMM Do @ HH:mm A')}</Text>
                {comment.type && (
                  <span title={comment.type}>
                    <Icon
                      type={COMMENT_ICONS[comment.type]}
                      style={{ fontSize: 32, color: '#0079C1', paddingTop: 10 }}
                      
                    />
                  </span>
                )}
              </div>
            </div>
          ))}
        </div>
      )}
      <div style={{ padding: '0px 15px 15px 15px', display: 'flex' }}>
        <textarea
          ref={commentTextRef}
          onChange={({ target: { value } }) => setCommentText(value)}
          style={{ flex: 1, marginRight: 15, borderRadius: 5, border: '1px solid #CCC', padding: '5px 10px' }}
          placeholder="Add a comment..."
        />
        <div style={{ flex: 1, paddingLeft: 15, justifyContent: 'space-between' }}>
          <Select
            options={COMMENT_TYPES}
            onChange={item => setCommentType(item ? item.value : null)}
            value={commentType}
            label="Comment Type"
          />

          <div style={{ display: 'flex', paddingTop: 15, alignItems: 'center', justifyContent: 'space-between' }}>
            <label style={{ display: 'flex', alignItems: 'center' }}>
              <input type="checkbox" onChange={({ target: { checked } }) => setDoesCommentRequireAction(checked)} checked={doesCommentRequireAction}/>
              <Text style={{ paddingLeft: 5, fontWeight: 'initial' }}>Requires Action?</Text>
            </label>

            <Button
              onClick={saveComment}
              label="Save Comment"
              kind="primary"
              loading={isSubmitting}
              disabled={isSubmitting || isLoading || commentText.trim().length === 0}
            />
          </div>
        </div>
      </div>
    </Column>
  );
}

export default JobComments;