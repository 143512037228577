import React, { useState} from 'react';

import moment from 'moment';

import Icon from 'components/Icon';
import { ToastContainer, IconContainer } from './ActionToast.styles';

type Props = {
  action?: 'submit' | 'expire' | 'extend';
  expiresAt: string;
};

const ActionToast = ({ action, expiresAt }: Props) => {
  const [showToast, setShowToast] = useState(true);  

  if (!action || !showToast) return null;
    
  return (
    <ToastContainer>
      <div style={{ display: 'flex', alignItems: 'center' }}>
        <IconContainer>
          <Icon type="check" style={{ color: 'white', fontSize: 16, fontWeight: 'bold' }} />
        </IconContainer>
        <p style={{ margin: 0, paddingLeft: 15, fontSize: 18 }}>
          {action === 'submit' && 'Success! Your job ad is now pending approval.'}
          {action === 'expire' && 'Success! Your job ad is expired and no longer visible on EthicalJobs.com.au.'}
          {action === 'extend' && `Success! Your job ad has been extended and will expire in ${moment(expiresAt).diff(moment(), 'days')} days.`}
        </p>
      </div>
      <div data-testid="closeToast" style={{ cursor: 'pointer', display: 'flex', alignItems: 'center' }} onClick={() => setShowToast(false)}>
        <Icon type="close" style={{ color: '#575757' }} />
      </div>
    </ToastContainer>
  );
}

export default ActionToast;
