import React, { useCallback, useContext } from 'react';
import styled from 'styled-components';

import _ from 'lodash';
import { useHistory } from 'react-router';
import { useDispatch } from 'react-redux';
import { SubmissionError } from 'redux-form';
import { LinkContainer } from 'react-router-bootstrap';

import { APIContext, AmplitudeContext } from 'lib/contexts';
import { loadUserDetails } from 'actions/auth';

import OrganisationSignupForm from 'forms/OrganisationSignupForm';
import Button from 'components/Button';
import Logo from 'components/Logo';
import Card from 'components/Card';

// INFO: Force footer elements to be stacked lower than form elements (e.g. country dropdown, places-autocomplete menu)
const Footer = styled.footer`
  position: relative;
  z-index: -1;
`;

const initialValues = {
  country: 'AU'
};

const OrganisationSignup = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const api = useContext(APIContext);
  const amplitude = useContext(AmplitudeContext);

  const signup = useCallback(async (values) => {
    if (values.username.trim().includes(' ')) throw new SubmissionError({ username: 'Username cannot contain spaces', _error: 'Username cannot contain spaces' });

    try {
      let user = await api.createOrganisation({
        ...values,
        username: values.username.trim(),
        numPaidStaff: Number(values.numPaidStaff),
        numVolunteers: Number(values.numVolunteers),
        signin: true
      });

      amplitude.logEvent('API', { function: 'createOrganisation', arguments: { ..._.mapValues(values, (value, key) => key.includes('password') ? 'REDACTED' : value) } });
      await dispatch(loadUserDetails())
      history.push('/organisation/purchase/welcome');
      return user;
    } catch (error) {
      // If validation fails on the server side display a form error
      if (_.get(error, ['response', 'status'], '') === 422) {
        if (_.get(error, ['response', 'data', 'errors', 'user', 'username', '0'], '') === 'The user.username has already been taken.') {
          throw new SubmissionError({ username: 'Username is already in use', _error: 'Username is already in use' });
        }
      }

      amplitude.logError('containers/OrganisationSignup/index/signup', error);

      // INFO: Must rethrow so form can handle
      throw error;
    }
  }, [history, api, dispatch, amplitude]);

  return (
    <div className="organisation-signup-container auth-container container">
      <div className="row">
        <div className="col-md-offset-1 col-md-10">
          <Logo />

          <Card className="animated fadeInUp">
            <h5 style={{ marginTop: 0 }}>
              Has your organisation advertised on EthicalJobs.com.au before?
              <br/>
              Call us on (03) 9419 5979, or email <a href="mailto: info@ethicaljobs.com.au">info@ethicaljobs.com.au</a> to find your account details.
            </h5>

            <div style={{ width: '100%', height: 1, backgroundColor: 'lightGrey', margin: '20px 0px' }} />

            <OrganisationSignupForm
              signup={signup}
              uploadLogo={api.uploadMedia}
              initialValues={initialValues}
            />
          </Card>
          <hr />

          <Footer className="col-md-4">
            <LinkContainer to="/">
              <Button
                kind="link"
                className="animated fadeInUp"
                label="Already a member? Back to sign in &larr;"
              />
            </LinkContainer>
          </Footer>
        </div>
      </div>
    </div>
  );
};

export default React.memo(OrganisationSignup);
