export const formatMoney = (number: number) => {
  // @ts-ignore
  if (!isNaN(parseFloat(number)) && isFinite(number)) {
    return '$' + number.toFixed(2).replace(/(\d)(?=(\d{3})+\.)/g, '$1,');
  }
  return '$0.00';
}

export const numberWithCommas = (number: number) => {
  const parts = number.toString().split('.');
  parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ',');
  return parts.join('.');
}
