import React from 'react';

import _ from 'lodash';
import { Icon, Text, Button } from '@ethical-jobs/design-system-react';

import { DisplayContainer, IconContainer } from './EmptyDisplay.styles';

const STAGES = {
  Inbox: {
    icon: 'search',
    heading: 'No applicants yet',
    text: 'We haven\'t received any applications for your role. If you would like any guidance on how to increase your listing\'s view and application please contact us. We will help you feature job job ad at the top of the search results',
    action: <Button
      type="action"
      label="Contact Support To Feature Job Ad"
      onClick={() => {}}
    />
  },
  Shortlist: {
    icon: 'search',
    heading: 'No applicants shortlisted yet',
    text: 'You haven\'t shortlisted any applicants yet. You can move applicants to this section when you have found suitable candidates for your organisation purpose and the role you\'re advertising for',
    action: (
      <a href="https://www.ethicaljobs.com.au/blog/how-to-stay-fit-and-active-at-home-during-the-coronavirus-self-isolation" target="_blank" rel="noopener noreferrer" style={{ fontSize: 18, color: '#0079C1', paddingLeft: 5, display: 'flex', alignItems: 'center' }}>
        <Icon size="medium" type="attachment" color="#0079C1" />
        How to make the right choice in candidates
      </a>
    )
  },
  Interview: {
    icon: 'search',
    heading: 'No interview yet',
    text: 'You haven\'t any interviews planned yet. Move applicants to this section after shortlisting suitable candidates and when you\'re ready to contact them for an interview',
    action: (
      <a href="https://www.ethicaljobs.com.au/blog/how-to-stay-fit-and-active-at-home-during-the-coronavirus-self-isolation" target="_blank" rel="noopener noreferrer" style={{ fontSize: 18, color: '#0079C1', paddingLeft: 5, display: 'flex', alignItems: 'center' }}>
        <Icon size="medium" type="attachment" color="#0079C1" />
        How to champion the interview process
      </a>
    )
  },
  Offer: {
    icon: 'search',
    heading: 'No job offer yet',
    text: 'You haven\'t made a job offer to anyone yet. Move applicants you have successfully interviewed to this section.',
    action: (
      <a href="https://www.ethicaljobs.com.au/blog/how-to-stay-fit-and-active-at-home-during-the-coronavirus-self-isolation" target="_blank" rel="noopener noreferrer" style={{ fontSize: 18, color: '#0079C1', paddingLeft: 5, display: 'flex', alignItems: 'center' }}>
        <Icon size="medium" type="attachment" color="#0079C1" />
        How to attract candidates who match your mission and purpose
      </a>
    )
  },
  Accept: {
    icon: 'check',
    heading: 'No applicants accepted yet',
    text: 'You haven\'t accepted any applicants yet.',
    action: (
      <a href="https://www.ethicaljobs.com.au/blog/how-to-stay-fit-and-active-at-home-during-the-coronavirus-self-isolation" target="_blank" rel="noopener noreferrer" style={{ fontSize: 18, color: '#0079C1', paddingLeft: 5, display: 'flex', alignItems: 'center' }}>
        <Icon size="medium" type="attachment" color="#0079C1" />
        How to successfully onboard your employees
      </a>
    )
  }
}

type Props = {
  stage: string;
};

const EmptyDisplay = ({ stage }: Props) => {
  if (!_.has(STAGES, [stage])) return null;

  let { icon, heading, text, action } = STAGES[stage];

  return (
    <DisplayContainer>
      <IconContainer>
        <Icon type={icon} size="xlarge" color="black" style={{ color: 70 }} />
      </IconContainer>
      <h2 style={{ fontSize: 40 }}>{heading}</h2>
      <Text align="center" style={{ maxWidth: 600, paddingBottom: 30 }}>
        {text}
      </Text>
      {action}
    </DisplayContainer>
  );
};

export default EmptyDisplay;