import React from 'react';
import FormField from 'fields/FormField';
import EnumerablesSelect from 'fields/EnumerablesSelect';

const Address = () => (
  <div className="row">
    <FormField
      name="address"
      component="input"
      type="text"
      label="Street address"
      placeholder="Enter postal street address"
      wrapClassName="col-md-12"
    />
    <FormField
      name="suburb"
      component="input"
      type="text"
      label="Suburb / Town"
      placeholder="Suburb or town"
      wrapClassName="col-md-6"
    />
    <FormField
      name="postcode"
      component="input"
      type="text"
      label="Postcode"
      placeholder="Postcode"
      wrapClassName="col-md-6"
    />
    <FormField
      name="state"
      label="State"
      type="text"
      component="input"
      wrapClassName="col-md-6"
    />
    <FormField
      name="country"
      label="Country"
      component={EnumerablesSelect}
      enumerableType="countries"
      wrapClassName="col-md-6"
    />
  </div>
);

export default Address;
