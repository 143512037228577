import React, { useState, useMemo, useCallback, useContext } from 'react';

import { useParams } from 'react-router-dom';
import cogoToast from 'cogo-toast';

import { Invoice as InvoiceType } from '@ethical-jobs/sdk-js/types';

import { usePromise } from 'hooks';
import { APIContext, AmplitudeContext } from 'lib/contexts';

import Card from 'components/Card';
import PageTitle from 'components/PageTitle';
import Invoice from 'components/Invoice';
import Spinner from 'components/Spinner';
import CreditCardForm from 'forms/CreditCardForm';

const Payment = () => {
  const api = useContext(APIContext);
  const amplitude = useContext(AmplitudeContext);
  
  const params: { invoiceId?: string } = useParams();
  
  const invoiceId = Number(params.invoiceId);

  const [forceRefresh, setForceRefresh] = useState(0);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const getInvoice = useMemo(() => api.getInvoice(invoiceId), [api, invoiceId, forceRefresh]);
  const [invoice] = usePromise(getInvoice, null, { component: 'Payment' });

  const purchase = useCallback(async ({ token }) => {
    try {
      let invoice: InvoiceType = await api.payInvoice(invoiceId, token);
      cogoToast.success('Payment was successful', { hideAfter: 4, position: 'top-right' });
      setForceRefresh(Math.random());
      amplitude.logEvent('API', { function: 'payInvoice', arguments: { invoiceId }});
      return invoice;
    } catch (error) {
      amplitude.logError('containers/_admin/Payment/index/purchase', error);

      // INFO: Must rethrow so form can handle
      throw error;
    }
  }, [invoiceId, api, amplitude]);

  return (
    <div className="admin-payment-container">
      <PageTitle title="Invoice View" subTitle="Make a payment to fulfill an invoice." />
      <Spinner show={!invoice} className="container" />
      {invoice && (
        <div>
          <Card>
            <Invoice invoice={invoice} />
          </Card>
          {!invoice.paidAt && (
            <Card>
              <CreditCardForm purchase={purchase} />
            </Card>
          )}
        </div>
      )}
    </div>
  );
}

export default React.memo(Payment);
