import React, { Fragment, useMemo, useContext, useState } from 'react';

import { useParams } from 'react-router-dom';

import { Organisation, Media } from '@ethical-jobs/sdk-js/types';

import { usePromise } from 'hooks';
import { APIContext } from 'lib/contexts';
import PageTitle from 'components/PageTitle';

import InfoAndActions from './InfoAndActions';
import ManagementTabs from './ManagementTabs';

const OrganisationUpdate = () => {
  const params: { organisationId?: string } = useParams();
  const organisationId = params.organisationId ? Number(params.organisationId) : null;

  const [forceRefresh, setForceRefresh] = useState(0);

  const api = useContext(APIContext);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const getOrganisation: Promise<Organisation | null> = useMemo(() => organisationId ? api.getOrganisation(organisationId) : Promise.resolve(null), [api, organisationId, forceRefresh]);
  const [organisation, , isFetching] = usePromise(getOrganisation, null, { component: 'OrganisationUpdate' });

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const getLogos: Promise<Array<Media>> = useMemo(() => organisationId ? api.getMediaForOrganisation(organisationId) : Promise.resolve([]), [api, organisationId, forceRefresh]);
  const [logos] = usePromise(getLogos, [], { component: 'OrganisationUpdate' });

  return (
    <div className="organisation-view-container">
      <PageTitle title="Organisation dashboard" subTitle="Manage an organisation's account" />
      {organisation && (
        <Fragment>
          <InfoAndActions
            organisation={organisation}
            fetching={isFetching}
            refresh={() => setForceRefresh(Math.random())}
            logos={logos}
          />
          <ManagementTabs
            organisation={organisation}
            fetching={isFetching}
            refresh={() => setForceRefresh(Math.random())}
          />
        </Fragment>
      )}
    </div>
  );
}

export default React.memo(OrganisationUpdate);
