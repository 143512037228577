import React from 'react';

import { Link } from 'react-router-dom';

import env from 'lib/env';

import Container from 'components/Container';
import Card from 'components/Card';

const PurchaseSuccess = ({ serviceType }) => (
  <Container className="purchase-success-container">
    <Card
      title="Your purchase was successful!"
      subTitle="A tax invoice has been emailed to you."
      className="success-card"
    >
      <div className="tick">
        <img
          className="animated fadeInDown"
          src={`${env.EJ_CDN}/statics/ic_done_clr_650.png`}
          alt="Completed successfully"
        />
      </div>
      {serviceType.toLowerCase() === 'managed' ? (
        <a className="btn btn-primary" href="mailto:info@ethicaljobs.com.au"><i className="material-icons">email</i> Email us your job</a>
      ) : (
        <Link to="/organisation/job/create" className="btn btn-primary">Post a new Job &rarr;</Link>
      )}
    </Card>
  </Container>
);

export default PurchaseSuccess;
