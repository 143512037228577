import React from 'react';
import Modal, { Body as ModalBody } from 'react-bootstrap/lib/Modal';
import { Link } from 'react-router-dom';

type Props = {
  creditBalance: number;
};

const InsufficientCredits = ({ creditBalance }: Props) => (
  <Modal show={creditBalance === 0} bsSize="large" onHide={() => {}}>
    <ModalBody>
      <h3 className="modal-title">Looks like you don't have enough credits to post a new job</h3>
      <div className="modal-body">
        <h3 className="centered primary">Sorry, you&apos;ll need to purchase a job ad credit before you can post your ad.</h3>
        <br />
        <p className="centered">Simply click the button below to begin purchasing a job ad credit. You can pay immediately via credit card or simply generate an invoice ­- there&apos;s no need to complete payment before posting your ad.</p>
        <br />
        <Link to="/organisation/purchase" className="btn btn-block btn-primary">Continue to purchasing job ad credits &rarr;</Link>
      </div>
    </ModalBody>
  </Modal>
);

export default InsufficientCredits;
