import React from 'react';

import _ from 'lodash';
import styled from 'styled-components';

import { Button } from '@ethical-jobs/design-system-react';
import { Attachment as AttachmentType, AttachmentLinks } from '@ethical-jobs/sdk-js/types';

import FileIcon from './FileIcon';

const AttachmentWrapper = styled.div`
  .attachment {
    display: flex;
    align-items: center;
    .file-icon {
      color: #56AF31;
      width: 40px;
      height: 43px;
    }
    p {
      margin: 0;
      font-size: 14px;
      line-height: 14px;
    }
    .file-size {
      color: grey;
    }
  }
`;

const readibleFileSize = (item: AttachmentType): string => {
  if (!!item && _.isNumber(item.size)) {
    return item.size < 1048576
      ? `${_.round(item.size / 1024, 0)} KB`
      : `${_.round(item.size / (1024 * 1024), 2)} MB`;
  }
  
  return '0 KB';
};

const readibleFileName = (item: AttachmentType): string => item.name.length > 32 ? `${item.name.slice(0, 32)}...` : item.name;

interface AttachmentProps {
  attachment: AttachmentType & AttachmentLinks;
}

const Attachment = ({ attachment }: AttachmentProps) => (
  <AttachmentWrapper>
    <div className='attachment' style={{ alignItems: 'center' }}>
      <a href={attachment.downloadLink} target="_blank" rel="noopener noreferrer">
        <Button
          type="primary"
          label="Download"
          onClick={() => {}}
          size="small"
        />
      </a>
      &nbsp;
      &nbsp;
      <span style={{ color: '#0079C1', paddingTop: 3 }}>
        <FileIcon />
      </span>
      <div>
        <p>{readibleFileName(attachment)}</p>
        <p className='file-size'>{readibleFileSize(attachment)}</p>
      </div>
    </div>
  </AttachmentWrapper>
);

export default Attachment;