import React from 'react';

import _ from 'lodash';
import { useSelector, shallowEqual } from 'react-redux';

type Props = {
  placeholder?: string;
  value?: string;
  className?: string;
  onChange: (value: React.ChangeEvent<HTMLSelectElement>) => void;
  taxonomy: string;
};

const TaxonomySelect = ({ placeholder, value, className, onChange, taxonomy }: Props) => {
  // @ts-ignore
  let terms: Array<{ id: string | number, title: string }> = useSelector(state => _.sortBy(Object.values(_.get(state.taxonomies, [taxonomy], {})).filter(item => !item.archived), item => item.id), shallowEqual);

  // Remove australia-wide
  if (taxonomy === 'locations') {
    terms = terms.filter(term => term.id !== 16)
  }

  if (terms.length === 0) {
    return null;
  }

  return (
    <select value={value} className={className} onChange={onChange}>
      <option value={undefined}>{placeholder || 'Please select'}</option>
      {terms.map(term => (
        <option key={term.id} value={term.id}>
          {term.title}
        </option>
      ))}
    </select>
  );
};

export default TaxonomySelect;
