import React, { useCallback, useState } from 'react';

import { Media } from '@ethical-jobs/sdk-js/types';

import Button from 'components/Button';
import MediaUpload from 'components/MediaUpload';

type Props = {
  attach: (files: Array<File>) => Promise<Media>;
  onChange: (attachmentId: number) => void;
  onBlur: () => void;
  accept: string;
  placeholder: string;
};

const MediaField = ({ attach, onChange, onBlur, accept, placeholder = 'Click here to upload your document.' }: Props) => {
  const [isLoading, setIsLoading] = useState(false);
  const [item, setItem] = useState<Media | null>(null);

  const onDrop = useCallback(async (file) => {
    setIsLoading(true);
    let media = await attach(file);
    setIsLoading(false);
    onChange(media.id);
    onBlur();
    setItem(media);
    return media;
  }, [attach, onChange, onBlur]);

  return (
    <div className="media-field">
      {item && (
        <img src={item.url} style={{ width: 150, height: 150 }} alt="Uploaded Logo" />
      )}
      <MediaUpload accept={accept} attach={onDrop}>
        <Button
          kind="primary"
          label={placeholder}
          icon="cloud_upload"
          block
          loading={isLoading}
        />
      </MediaUpload>
    </div>
  );
};

export default MediaField;