import React from 'react';
import classNames from 'classnames';

type Props = {
  className?: string;
};

const Logo = ({ className }: Props) => (
  <div className={classNames('logo', className)}>
    <img src="https://static.ethicaljobs.com.au/statics/logo-transparent.png" alt="EthicalJobs - environmental and socially just jobs" />
  </div>
);

export default Logo;
