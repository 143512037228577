import _ from 'lodash';
import React from 'react';
import { useSelector } from 'react-redux';
import styled from 'styled-components';

import { Card } from '@ethical-jobs/design-system-react';
import config from 'config';

type Props = {
  children: React.ReactNode;
};

const ErrorMessage = styled.span`
  color: #d9534f;
`;

const { QUESTION_MAX } = config;

const QuestionCard = ({ children }: Props) => {
  const numQuestions = useSelector(
    state => _.get(state, ['form', 'jobForm', 'values', 'questions', 'length'],
    0
  ));

  if (numQuestions === 0) {
    return (
      <Card
        expandable
        title="Questions for your candidates (optional)"
        subtitle={(
          <span>
            You can select up to {QUESTION_MAX} questions to ask candidates applying to this job ad.<br />
            Candidates will be able to answer the questions by &quot;yes&quot; or &quot;no&quot;.
          </span>
        )}
      >
        {children}
      </Card>
    );
  }

  if (numQuestions >= QUESTION_MAX) {
    return (
      <Card
        expandable
        title={`${QUESTION_MAX}/${QUESTION_MAX} Questions to ask candidates`}
        subtitle={(
          <ErrorMessage>
            You&apos;ve reached the maximum number of questions to ask candidates applying to this job ad.
          </ErrorMessage>
        )}
      >
        {children}
      </Card>
    );
  }

  return (
    <Card
      expandable
      title={`${numQuestions}/${QUESTION_MAX} Questions to ask candidates`}
      subtitle={`You can add ${QUESTION_MAX - numQuestions} more questions to ask candidates applying to this job ad.`}
    >
      {children}
    </Card>
  );
};

export default QuestionCard;
