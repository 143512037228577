import styled from 'styled-components';

export const P = styled.p`
  color: #575757;
  font-size: 16px;
  line-height: 20px;
  font-weight: 300;
`;

interface LabelProps {
  invalid?: boolean
}
export const Label = styled.label`
  font-size: 18px;
  color: ${(props: LabelProps) => props.invalid ? "#d9534f" : "#575757"};
`;

interface InputProps {
  invalid?: boolean
}
export const Input = styled.input`
  width: 100%;
  padding: 0 10px;
  border-bottom: ${(props: InputProps) => props.invalid ? "2px solid red !important" : "pink"};
  background-color: ${(props: InputProps) => props.invalid ? "rgba(255,0,0,.1) !important" : "pink"};
`;

export const Span = styled.span`
  color: #333;
`;

export const HalfDiv = styled.div`
  width: 50%;
`;

export const FlexDiv = styled.div`
  display: flex;
`;

export const LeftDiv = styled.div`
  flex: 50%;
  margin-right: 10px;
  padding: 5px 0;
`;

export const RightDiv = styled.div`
  flex: 50%;
  margin-left: 10px;
  padding: 5px 0;
`;

export const SpacedDiv = styled.div`
  padding: 5px 0;
`;

export const ActionsDiv = styled.div`
  padding: 15px 0 20px;
`;

export const Asterix = styled.span`
  color: #d9534f;
`;

export const ErrorMessage = styled.p`
    background: rgba(255,0,0,.03);
    color: #d9534f;
    text-align: center;
    padding: 5px;
    font-size: 18px;
    margin: 0 0 10px;
`;
