import React, { useCallback, useState, useContext } from 'react';
import _ from 'lodash';
import cogoToast from 'cogo-toast';
import { APIContext, AmplitudeContext } from 'lib/contexts';
import InvitationForm from './InvitationForm';

type Props = {
  organisationId: number;
  onSubmit?: () => void;
};

export const InvitationFormContainer = ({ onSubmit, organisationId }: Props) => {
  const api = useContext(APIContext);
  const amplitude = useContext(AmplitudeContext);
  const [hasSubmitted, setHasSubmitted] = useState(false);
  const [email, setEmail] = useState('');

  const submitForm = useCallback(async () => {
    try {
      await api.createInvitation(email, organisationId);
      setHasSubmitted(true);
      cogoToast.success(`Invite successfully sent to ${email}`, { hideAfter: 4, position: 'top-right' });
      amplitude.logEvent('API', { function: 'inviteUser', arguments: { email, organisationId }});

      if (_.isFunction(onSubmit)) {
        onSubmit();
      }
      
      setTimeout(() => {
        setEmail('');
        setHasSubmitted(false);
      }, 4000);

    } catch(error) {
      amplitude.logError('containers/_organisation/UserCollection/index/invite', error);
      cogoToast.error(`Error creating invitation ${error.message}`, { hideAfter: 4, position: 'top-right' });
      setHasSubmitted(false);
    };
  }, [api, amplitude, email, onSubmit, organisationId]);

  return (
    <InvitationForm
      onSubmit={submitForm}
      onChange={setEmail}
      hasSubmitted={hasSubmitted}
      value={email}
    />
  );
};

export default InvitationFormContainer;