import React from 'react';

import StepProgressBar from 'components/StepProgressBar';

const STEPS = ['Fill in details', 'Preview', 'Done'];

type Props = {
  activeStep: number;
};

const JobAdStepProgressBar = ({ activeStep }: Props) => (
  <div style={activeStep === 3 ? { marginTop: 46 } : {}}>
    <StepProgressBar steps={STEPS} activeStep={activeStep} />
  </div>
);

export default JobAdStepProgressBar;
