import React, { useContext, useCallback } from 'react';

import { useSelector, shallowEqual } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import cogoToast from 'cogo-toast';

import { CreditPack } from '@ethical-jobs/sdk-js/types';

import { APIContext, AmplitudeContext } from 'lib/contexts';

import Purchase from './Purchase';

const PurchaseContainer = () => {
  const history = useHistory();
  const api = useContext(APIContext);
  const amplitude = useContext(AmplitudeContext);

  const { pathname } = useLocation();

  // @ts-ignore
  const organisation = useSelector(state => state.auth.user.organisation, shallowEqual);

  const purchase = useCallback(async (selectedCreditPack: CreditPack, method: 'invoice' | 'credit_card', params: Object) => {
    if (!selectedCreditPack) throw new Error('No credit pack selected');

    try {
      let invoice = await api.purchaseCredits(organisation.id, selectedCreditPack.id, method, params)
      cogoToast.success(`Credit purchase was successful`, { hideAfter: 4, position: 'top-right' })
      amplitude.logEvent('API', { function: 'purchaseCredits', arguments: { organisationId: organisation.id, creditPack: selectedCreditPack.id, method }});
      history.push(`/organisation/purchase/success/${selectedCreditPack.serviceType}`);
      return invoice;
    } catch (error) {
      amplitude.logError('containers/_organisation/Purchase/index/purchase', error);

      // INFO: Must rethrow so form can handle
      throw error;
    }
  }, [organisation, history, api, amplitude]);

  return (
    <Purchase
      purchase={purchase}
      showWelcome={pathname.includes('welcome')}
    />
  );
}

export default React.memo(PurchaseContainer);
