import React, { useCallback } from 'react';

import { reduxForm } from 'redux-form';
import submissionError from 'lib/submissionError';
import ValidationMessages from 'components/ValidationMessages';
import Button from 'components/Button';
import FormField from 'fields/FormField';
import UserSelect from 'fields/UserSelect';

type Props = {
  action: (values: Object) => Promise<Object>;
  handleSubmit: any;
  error?: string;
  submitting: boolean;
};

const OrganisationAdminForm = ({ action, handleSubmit, error, submitting }: Props) => {
  const submitForm = useCallback(async (values: { adminId: number }) => {
    try {
      await action(values);
    } catch (error) {
      submissionError(error);
    }
  }, [action]);

  return (
    <form className="organisation-admin-form form-horizontal" onSubmit={handleSubmit(submitForm)}>
      <div className="row">
        <div className="col-md-6">
          <FormField
            name="adminId"
            component={UserSelect}
            label="Select admin user"
            // @ts-ignore
            allowNull
          />
        </div>
        <div className="col-md-6">
          <Button
            kind="primary"
            type="submit"
            loading={submitting}
            icon="done_all"
            label="Update admin user"
            block
          />
        </div>
      </div>
      <ValidationMessages error={error} />
    </form>
  );
};

export default reduxForm({
  form: 'organisationAdminForm'
  // @ts-ignore
})(OrganisationAdminForm);
