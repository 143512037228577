import React from 'react';

import env from 'lib/env';

const NotFound = () => {
  return (
    <div className="container auth-container">
      <div className="row">
        <div className="col-md-offset-2 col-md-8">
          <div className="logo">
            <img src={`${env.EJ_CDN}/statics/logo-transparent.png`} className="logo" alt="EthicalJobs - environmental and socially just jobs" />
          </div>
          <div style={{ alignItems: 'center', justifyContent: 'center' }}>
            <h1>Oops, this page does not exist.</h1>
            <h4>You may have clicked a link or navigated here in error.</h4>
          </div>
        </div>
      </div>
    </div>
  );
};

export default NotFound;