import React, { Fragment, useCallback, useEffect, useState } from 'react';

import _ from 'lodash';
import moment from 'moment';
import { reduxForm, change as changeValue } from 'redux-form';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';

import { Organisation, Job } from '@ethical-jobs/sdk-js/types';

import JobAttachments from 'components/JobAttachments';
import ValidationMessages from 'components/ValidationMessages';
import Button from 'components/Button';
import Card from 'components/Card';
import FeatureFlag from 'components/FeatureFlag';
import submissionError from 'lib/submissionError';
import { formErrorsSelector, suggestedMailtoLinksSelector, suggestedTextTemplatesSelector, suggestedApplicationTemplatesSelector } from 'store/forms/selectors';

import validation from './validation';
import Taxonomies from './Taxonomies';
import Editors from './Editors';
import Expiration from './Expiration';
import Featured from './Featured';
import AdminControls from './AdminControls';
import Title from './Title';
import Video from './Video';
import ApplyNow from './ApplyNow';
import Questions from './Questions';

const suggestedMailtoLinks = suggestedMailtoLinksSelector('jobForm');
const suggestedTextTemplates = suggestedTextTemplatesSelector('jobForm');
const suggestedApplicationTemplates = suggestedApplicationTemplatesSelector('jobForm');

type Props = {
  isAdmin: boolean;
  organisation: Organisation;
  showCloneFeaturedMessage?: boolean;
  creditBalance: number;
  initialValues: Job | {};
  action: (values: Job) => Promise<Object>;
  handleSubmit: any;
  error?: string | { invalidToken: boolean };
  submitting: boolean;
};

export const JobForm = ({ isAdmin, organisation, creditBalance, initialValues, action, error, submitting, handleSubmit, showCloneFeaturedMessage = false }: Props) => {
  const dispatch = useDispatch();

  // @ts-ignore
  const formErrors = useSelector(state => formErrorsSelector('jobForm', state), shallowEqual);
  const userEmail = useSelector(state => _.get(state, ['auth', 'user', 'email'], ''));
  const quickLinks = useSelector(suggestedMailtoLinks, shallowEqual);
  const textTemplates = useSelector(suggestedTextTemplates, shallowEqual);
  const applicationTemplates = useSelector(suggestedApplicationTemplates, shallowEqual);

  const [hasAttemptedSubmit, setHasAttemptedSubmit] = useState(false);

  useEffect(() => {
    if (error) {
      window.scrollTo(0, document.body.scrollHeight);
    }
  }, [error]);

  useEffect(() => {
    // @ts-ignore
    if (!initialValues.videoUrl) dispatch(changeValue('jobForm', 'videoUrl', organisation ? organisation.videoUrl : ''));
    // @ts-ignore
    if (!initialValues.applyEmail) dispatch(changeValue('jobForm', 'applyEmail', isAdmin ? _.get(organisation, ['owner', 'email'], '') : userEmail));
  }, [initialValues, organisation, dispatch, isAdmin, userEmail]);

  const submitForm = useCallback(async (values) => {
    try {
      await action(values);
    } catch(err) {
      submissionError(err);
    }
  }, [action]);

  // @ts-ignore
  const updatingFeaturedWillChargeCredit = isAdmin && initialValues.status !== 'DRAFT' && initialValues.isFeatured === false && !initialValues.isExpired;

  return (
    <form
      className="job-form form-horizontal"
      onSubmit={handleSubmit(submitForm)}
    >
      <Title />
      <AdminControls admin={isAdmin} organisation={organisation} />
      <Editors
        quickLinks={isAdmin ? quickLinks : null}
        textTemplates={isAdmin ? textTemplates : null}
      />
      <ApplyNow
        isAdmin={isAdmin}
        quickLinks={isAdmin ? quickLinks : null}
        applicationTemplates={isAdmin ? applicationTemplates : null}
      />
      <FeatureFlag feature="jobQuestions">
        <Questions />
      </FeatureFlag>
      <Video />
      <Expiration
        // @ts-ignore
        approvedDate={initialValues.approvedAt ? moment(initialValues.approvedAt).format('YYYY-MM-DD HH:mm:ss') : undefined}
        isAdmin={isAdmin}
      />
      <Taxonomies />
      <JobAttachments jobId={_.get(initialValues, ['id'], undefined)} />
      <Featured
        creditBalance={creditBalance}
        admin={isAdmin}
        showCloneFeaturedMessage={showCloneFeaturedMessage}
        updatingFeaturedWillChargeCredit={updatingFeaturedWillChargeCredit}
      />
      {hasAttemptedSubmit && (
        <Fragment>
          <ValidationMessages
            // @ts-ignore
            error={error}
          />
          {formErrors && formErrors.length > 0 && (
            <Card
              title="There are some errors above, please correct them and try again"
              className="error"
            >
              <ul>
                {formErrors.map((error: string) => <li key={error}>{error}</li>)}
              </ul>
            </Card>
          )}
        </Fragment>
      )}
      <Card>
        <Button
          kind="primary"
          type="submit"
          loading={submitting}
          icon="arrow_forward"
          label="Next Step: Preview your job ad"
          block
          onClick={() => setHasAttemptedSubmit(true)}
        />
      </Card>
    </form>
  );
};

export default reduxForm({
  form: 'jobForm',
  validate: validation
  // @ts-ignore
})(JobForm);