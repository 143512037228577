import _ from 'lodash';
import React, { useCallback, useEffect, useState, useMemo } from 'react';
import styled from 'styled-components';

import { Question, Blanks } from '@ethical-jobs/sdk-js/types';
import CheckboxItem from './CheckboxItem';

const List = styled.ul`
  padding-left: 0;
`;

interface Selections {
  [index: number]: Selection;
}

interface Selection {
  id: number;
  blanks: Blanks;
  checked: boolean;
}

type Props = {
  value?: Array<Selection>;
  questions: Array<Question>;
  onChange: (value: any) => void;
  onBlur: (value: any) => void;
};

const transformSelections = (selections: Selections) => {
  return Object.values(selections)
    .filter(selection => selection.checked)
    .map(selection => ({ id: selection.id, blanks: selection.blanks }));
};

const QuestionsField = ({ value = [], questions, onChange, onBlur }: Props) => {
  const [selections, setSelections] = useState<Selections>({});

  const options: Array<{ label: string, value: number }> = useMemo(() =>
    Object.values(questions).map(({question, id}) => ({label: question, value: id})),
    [questions]
  );

  useEffect(() => {
    const formattedQuestions: Selections = _.transform(questions, (result, question) => {
      const selection = _.find(value, s => s.id === question.id);
      result[question.id] = selection ? {...selection, checked: true} : {id: question.id, checked: false, blanks: {}};
    });
    setSelections(formattedQuestions);
  // TODO: Add value to deps while still only accessing the initial value (job.questions)
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [questions]);

  const onCheckboxChange = useCallback((id: number): void => {
    const checked = selections[id]['checked'];
    const updatedSelections = _.set(selections, [id, 'checked'], !checked);
    setSelections(updatedSelections);
    onChange(transformSelections(updatedSelections));
  }, [onChange, selections]);

  const onBlankChange = useCallback((id: number, blanks: Blanks): void => {
    const updatedSelections = _.set(selections, [id, 'blanks'], blanks);
    onChange(transformSelections(updatedSelections));
  }, [onChange, selections]);

  const hasMaxSelections = value.length === 6;

  return (
    <List>
      {!_.isEmpty(selections) && options.map(option => {
        const selection = selections[option.value];
        const questionId = option.value;
        return (
          <CheckboxItem
            key={option.value}
            label={option.label}
            checked={selection.checked}
            disabled={!selection.checked && hasMaxSelections}
            onBlur={_.partial(onBlur, value)}
            blanks={selection.blanks}
            onCheckboxChange={_.partial(onCheckboxChange, questionId)}
            onBlankChange={_.partial(onBlankChange, questionId)}
          />
        );
      })}
    </List>
  );
};

export default QuestionsField;
