import React, { useEffect, useState, useContext } from 'react';

import _ from 'lodash';
import { useSelector } from 'react-redux';
import { APIContext, AmplitudeContext } from 'lib/contexts';

import FormField from 'fields/FormField';

const StepOne = () => {
  const api = useContext(APIContext);
  const amplitude = useContext(AmplitudeContext);

  const [displayDuplicateWarning, setDisplayDuplicateWarning] = useState(false);

  let email = useSelector(state => _.get(state, ['form', 'signup', 'values', 'email'], ''));

  useEffect(() => {
    setDisplayDuplicateWarning(false);

    const fn = async () => {
      let isValid = api.validateEmail(email);
      if (!isValid) return;

      let duplicateMessage = await api.checkForExistingUsers({ email });
      if (duplicateMessage.isValid && duplicateMessage.message) {
        let domain = _.get(email.split('@'), [1], '');

        amplitude.logEvent('User', { type: 'Duplicate Org Signup', email, domain });
        setDisplayDuplicateWarning(true);
      }
    };

    fn();
  }, [api, email, amplitude]);

  return (
    <div className="step-one">
      <h3 className="flat-top">Register as an employer</h3>
      <h3 className="sub-heading">Join over 5,000 ethical organisations advertising with us!</h3>
      <div className="row">
        <FormField
          name="firstName"
          component="input"
          type="text"
          placeholder="e.g. Mary"
          wrapClassName="col-md-6"
          label="First Name"
        />
        <FormField
          name="lastName"
          component="input"
          type="text"
          placeholder="e.g. Smith"
          wrapClassName="col-md-6"
          label="Last Name"
        />
        <FormField
          name="position"
          component="input"
          type="text"
          placeholder="e.g. Administration Officer"
          wrapClassName="col-md-12"
          label="Job Title"
        />
        <FormField
          name="email"
          component="input"
          type="text"
          placeholder="e.g. mary.smith@workplace.com.au"
          wrapClassName="col-md-6"
          label="Email Address"
        />
        <FormField
          name="emailConfirmation"
          component="input"
          type="text"
          placeholder="e.g. mary.smith@workplace.com.au"
          wrapClassName="col-md-6"
          label="Confirm Email"
        />
        {displayDuplicateWarning && (
          <div className="form-field col-md-12">
            <div style={{ borderStyle: 'solid', borderColor: '#0079C1', borderWidth: 2, borderRadius: 4, margin: '10px 0px', padding: '5px 15px' }}>
              <h4>Looks like your organisation already has an account</h4>
              <p style={{ fontSize: 15 }}>Hi! Looks like you're trying to create an account for an organisation that already has an account on <a href="https://www.ethicaljobs.com.au">EthicalJobs.com.au</a>. If you'd like to access your organisation's existing account, please call us on 03 9419 5979 or email <a href="mailto:info@ethicaljobs.com.au">info@ethicaljobs.com.au</a>. Or if you'd like to register an additional account, you can continue completing the signup.</p>
            </div>
          </div>
        )}
        <FormField
          name="username"
          component="input"
          type="text"
          placeholder="Please base this on your organisation's name"
          wrapClassName="col-md-6"
          label="Username"
        />
        <FormField
          name="password"
          component="input"
          type="password"
          placeholder="Password"
          wrapClassName="col-md-6"
          label="Password"
        />
      </div>
    </div>
  );
};

export default StepOne;
