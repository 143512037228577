import React, { useContext, useMemo } from 'react';

import _ from 'lodash';
import { usePromise } from 'hooks';
import { APIContext } from 'lib/contexts';
import { ReportParams } from '@ethical-jobs/sdk-js/types';

import classNames from 'classnames';
import Card from 'components/Card';
import LineChart from './LineChart';
import Figure from './Figure';

type Props = {
  title?: string;
  figures?: Array<{ icon: string, reportName: string, dataKey: string }>;
  charts?: Array<{ stroke: string, reportName: string, dataKey: string }>;
  query: ReportParams | { reports: Array<ReportParams> };
};

// TODO: Fix typings
const StatsPanel = ({ title = '', figures = [], charts = [], query }: Props) => {
  const api = useContext(APIContext);

  const getReports: Promise<Object> = useMemo(() => new Promise(async(resolve) => {
    // @ts-ignore
    if (query.reports) {
      // @ts-ignore
      let responses = await Promise.all(query.reports.map(reportQuery => api.getReportData({ ...reportQuery })));
      resolve(_.keyBy(responses, (v, i) => i));
    } else {
      // @ts-ignore
      let response = await api.getReportData({ ...query });
      resolve({ '0': response });
    }
  }), [api, query]);

  const [reports] = usePromise(getReports, [], { component: 'StatsPanel' });

  const classes = {
    'stats-panel': true,
    'has-many-figures': figures.length > 1,
    'has-many-charts': charts.length > 1,
  };

  return (
    <Card subTitle={title} className={classNames(classes)}>
      {figures.map(figure => (
        <Figure
          icon={figure.icon}
          report={reports[figure.reportName]}
          dataKey={figure.dataKey}
          key={figure.dataKey}
        />
      ))}
      {charts.map(figure => (
        <LineChart
          stroke={figure.stroke}
          report={reports[figure.reportName]}
          dataKey={figure.dataKey}
          key={figure.dataKey}
        />
      ))}
    </Card>
  );
}

export default React.memo(StatsPanel);