import React, { Fragment } from 'react';

import moment from 'moment';

import JobStatistic from './JobStatistic';
import { JobWithApplicants } from '../index';

type Props = {
  job: JobWithApplicants;
  activeTab: string;
  onClick: () => void;
};

const JobTile = ({ job, activeTab, onClick }: Props) => {
  // @ts-ignore
  const jobApplicantsText = `${job.numApplicants}${job.numNewApplicants > 0 ? ` (${job.numNewApplicants} new)` : ''}`;

  const daysTilExpiration = moment().diff(job.expiresAt, 'days') * -1;

  return (
    <div
      key={job.id}
      style={{ backgroundColor: 'white', cursor: 'pointer', borderRadius: 5, position: 'relative' }}
      onClick={onClick}
    >
      <div style={{ padding: 15, height: 120 }}>
        <p style={{ fontSize: 20, fontWeight: 'bold', marginBottom: 0, paddingRight: job.isFeatured ? 40 : 0 }}>{job.title.length < 50 ? job.title : `${job.title.substring(0, 50)}...`}</p>
        <p style={{ color: '#AAA', fontSize: 16, marginBottom: 0 }}>{`Created: ${moment(job.createdAt).format('DD/MM/YYYY')}`}</p>
      </div>

      {job.isFeatured && (
        <Fragment>
          <div style={{ position: 'absolute', top: 10, right: -10, backgroundColor: '#0079c1', padding: '3px 10px 3px 10px', borderTopLeftRadius: 5, borderBottomLeftRadius: 5 }}>
            <p style={{ color: 'white', fontSize: 12, margin: 0 }}>Featured</p>
          </div>
          <div style={{ position: 'absolute', top: 35, right: -10, width: 0, height: 0, borderTop: '10px solid #004E85', borderRight: '10px solid transparent' }} />
        </Fragment>
      )}

      <div style={{ height: 1, backgroundColor: '#DDD' }} />

      <div style={{ padding: 15, display: 'flex', justifyContent: 'space-between' }}>
        {activeTab !== 'expired' && (
          <JobStatistic
            label="Expires In"
            value={daysTilExpiration > 0 ? `${daysTilExpiration} days` : 'Today'}
            color={daysTilExpiration < 7 ? '#0079c1' : 'black'}
          />
        )}
        <JobStatistic
          label="Applicants"
          value={jobApplicantsText}
          icon="group"
        />
        <JobStatistic
          label="Views"
          value={`${job.views}`}
          icon="remove_red_eye"
          minWidth={60}
        />
      </div>
    </div>
  );
}

export default React.memo(JobTile);