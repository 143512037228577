import React from 'react';

import _ from 'lodash'

import Card from 'components/Card';
import Messages from './Messages';

type Props = {
  error?: Error | { _error: Error } | string
  heading?: string;
};

const ValidationMessages = ({ error, heading = 'Oops! We found some errors. Please address them before proceeding' }: Props) => {
  if (!error) {
    return null;
  } else if (_.has(error, ['_error'])) {
    // @ts-ignore
    error = error._error;
  }

  return (
    <div className="validation-messages">
      <Card className="error center">
        {heading && <p className="error-heading">{heading}</p>}
        {typeof error === 'string' && <p>{error}</p>}

        {typeof error === 'object' && (
          // @ts-ignore
          <Messages errors={error} />
        )}
      </Card>
    </div>
  );
};

export default ValidationMessages;