import React, { useContext, useState, useEffect } from 'react';

import moment from 'moment';
import { useParams } from 'react-router-dom';
import { Attachment as AttachmentType, AttachmentLinks, Attachment } from '@ethical-jobs/sdk-js/types';

import { APIContext, AmplitudeContext } from 'lib/contexts';

import ViewApplicant from './ViewApplicant';

const ViewApplicantContainer = () => {
  const api = useContext(APIContext);
  const amplitude = useContext(AmplitudeContext);

  const { applicationId } = useParams();
  
  const [applicant, setApplicant] = useState(null)
  const [attachments, setAttachments] = useState<Array<AttachmentType & AttachmentLinks>>([])
  const [isLoading, setIsLoading] = useState(false)

  useEffect(() => {
    const fn = async () => {
      setIsLoading(true);

      try {
        const applicantRecord = await api.getApplicant(applicationId);
        setApplicant(applicantRecord);

        const attachmentsWithLinks: Array<Attachment & AttachmentLinks> = await Promise.all(applicantRecord.attachments.map(async (attachment: any) => {
          let linkDetails = await api.getApplicationAttachmentLinks(applicationId, attachment.id, moment().add(10, 'minutes').format('YYYY-MM-DD HH:mm:ss'));
          return {
            ...attachment,
            ...linkDetails
          };
        }));
        setAttachments(attachmentsWithLinks);

      } catch (error) {
        amplitude.logError('containers/_organisation/ViewApplicant/index', error);
      }
      
      setIsLoading(false);
    };

    fn();
  }, [api, amplitude, applicationId]);
  
  return (
    <ViewApplicant
      applicant={applicant}
      isLoading={isLoading}
      attachments={attachments}
    />
  );
}

export default ViewApplicantContainer;