import React from 'react';

import _ from 'lodash';
import { useSelector, shallowEqual } from 'react-redux';

import CheckboxGroup from 'fields/CheckboxGroup';

type Props = {
  taxonomy: string;
  onBlur: (value: Array<string>) => void;
  onChange: (value: Array<string>) => void;
  value?: Array<string>;
};

const TaxonomyCheckboxGroup = ({ taxonomy, onBlur, onChange, value, ...otherProps }: Props) => {
  // @ts-ignore
  const terms = useSelector(state => _.sortBy(Object.values(_.get(state.taxonomies, [taxonomy], {})), item => item.title.toLowerCase()), shallowEqual);

  if (terms.length === 0) {
    return null;
  }

  return (
    <CheckboxGroup
      {...otherProps}
      value={value}
      onBlur={onBlur}
      onChange={onChange}
      // @ts-ignore
      options={terms.map(term => ({ value: term.id, label: term.title }))}
    />
  );
};

export default TaxonomyCheckboxGroup;
