import styled from 'styled-components';

export const HeadingContainer = styled.div`
  background-color: white;
  padding: 30px 50px 0px;
  border-width: 0;
  border-bottom-width: 1px;
  border-style: solid;
  border-color: #DDE3E8;
`;

type FilterContainerProps = {
  isFiltersSticky: boolean;
}

export const FilterContainer = styled.div<FilterContainerProps>`
  padding: 30px 0px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  z-index: 1;
  position: ${({ isFiltersSticky }) => isFiltersSticky ? 'sticky' : 'initial'};
  top: ${({ isFiltersSticky }) => isFiltersSticky ? 67 : 'initial'};
  background-color: #ECEDF2;
`;

type ViewIconContainerProps = {
  isSelected: boolean;
}

export const ViewIconContainer = styled.div<ViewIconContainerProps>`
  background-color: ${({ isSelected }) => isSelected ? '#cddbe7' : 'transparent'};
  display: flex;
  align-items: center;
  cursor: pointer;
  justify-content: center;
  padding: 10px;
  border-radius: 5px;
`;