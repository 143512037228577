import React, { useEffect, useContext, useState, useCallback } from 'react';

import { APIContext, AmplitudeContext } from 'lib/contexts';
import Button from 'components/Button';
import Card from 'components/Card';
import MediaUpload from 'components/MediaUpload';
import MediaList from 'components/MediaList';

type Props = {
  jobId: number;
};

const JobAttachments = ({ jobId }: Props) => {
  const api = useContext(APIContext);
  const amplitude = useContext(AmplitudeContext);

  const [attachments, setAttachments] = useState([]);
  
  const getAttachments = useCallback(async () => {
    try {
      if (jobId) {
        let job = await api.getJob(jobId);
        amplitude.logEvent('API', { function: 'getAttachments', arguments: { jobId } });
        setAttachments(job ? job.attachments : []);
      }
    } catch (error) {
      amplitude.logError('components/JobAttachments/index/getAttachments', error);
    }
  }, [jobId, api, amplitude]);

  useEffect(() => { getAttachments(); }, [getAttachments]); 

  const attachMedia = useCallback(async (file) => {
    if (!jobId) return null;

    try {
      let response = await api.attachMediaToJob(jobId, file);
      amplitude.logEvent('API', { function: 'attachMediaToJob', arguments: { jobId } });
      getAttachments();
      return response;
    } catch (error) {
      amplitude.logError('components/JobAttachments/index/attachMedia', error);
    }
  }, [api, getAttachments, jobId, amplitude]);

  const detachMedia = useCallback(async (mediaId) => {
    try {
      let response = await api.detachMediaFromJob(mediaId);
      amplitude.logEvent('API', { function: 'detachMediaFromJob', arguments: { mediaId } });
      getAttachments();
      return response;
    } catch (error) {
      amplitude.logError('components/JobAttachments/index/detachMedia', error);
    }
  }, [api, getAttachments, amplitude]);

  const disabled = attachments.length >= 2;

  return (
    <Card
      title="Attachments"
      subTitle="Upload up to two documents that you would like attached to your job ad, such as Position Description or Application Form."
    >
      <MediaList media={attachments} detach={detachMedia} />
      <label>Attach a supporting document</label>
      <MediaUpload
        attach={attachMedia}
        disabled={disabled}
        accept="image/*,application/pdf,.pdf,application/msword,.doc,application/vnd.openxmlformats-officedocument.wordprocessingml.template,.dotx,application/vnd.openxmlformats-officedocument.wordprocessingml.document,.docx,text/plain,.txt,application/rtf,.rtf"
        showAttached={false}
      >
        <Button
          kind="primary"
          disabled={disabled}
          label="Upload an attachment"
          block
        />
      </MediaUpload>
      {disabled && (
        <div className="disabled-text">
          There is a limit of two attachments, please remove one before
          attaching more documents.
        </div>
      )}
    </Card>
  );
}

export default JobAttachments;
