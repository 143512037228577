import styled from 'styled-components';

export const ToastContainer = styled.div`
  margin-top: 30px;
  padding: 15px 20px;
  background-color: white;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-radius: 2px;
`;

export const IconContainer = styled.div`
  background-color: #56AF31;
  width: 30px;
  height: 30px;
  border-radius: 15px;
  display: flex;
  align-items: center;
  justify-content: center;
`;


