import React, { useCallback, useContext, useMemo, FunctionComponent, SyntheticEvent, useEffect, useState } from 'react';
import { usePromise } from 'hooks';
import { APIContext } from 'lib/contexts';
import { RadioGroup } from '@ethical-jobs/design-system-react';
import { useSelector } from 'react-redux';
import { formValueSelector } from 'redux-form';
import _ from 'lodash';

// these need to be exported from the design system.
interface RadioChangeEvent {
  target: RadioChangeEventTarget;
}

interface RadioChangeEventTarget {
  value: string;
}

type Props = {
  value?: number;
  onChange: (value: number | undefined) => void;
};

const UserRadioGroup = ({ value, onChange}: Props) => {
  const api = useContext(APIContext);
  const onChangeFn = useCallback((value: number | null) => {
    onChange(value ? value : undefined);
  }, [onChange]);
  const organisationId: number | null = useSelector(state => formValueSelector('jobForm')(state, 'organisationId') || null);
  const getUsers = useMemo(() => {
    onChangeFn(null);
    if (organisationId) {
      return api.getUsers({ organisationId });
    } else {
      return Promise.resolve([]);
    }
  }, [api, organisationId]);
  useEffect(() => {
    (async () => {
      const users = await getUsers;
      if (_.isEmpty(value) && !_.isNumber(value)) {
        const defaultValue = users.map(user => user.id).find(() => true);
        onChangeFn(defaultValue);
      }
    })();
  }, [value, getUsers])
  const [users] = usePromise(getUsers, [], { component: 'UserRadioGroup' });
  const radioOptions = users.map(user => ({
    value: user.id,
    label: `${user.firstName} ${user.lastName}`,  
    subtitle: `${user.position}`,
  }));
  return (
    <RadioGroup
      onChange={(event: RadioChangeEvent) => onChangeFn(parseInt(event.target.value, 10))}
      value={value}
      options={radioOptions}
    />
  );
};

export default UserRadioGroup;