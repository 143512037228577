import React, { useContext, useMemo, useEffect } from 'react';

import { useSelector } from 'react-redux';
import { formValueSelector, isDirty, reduxForm } from 'redux-form';
import { useParams, Prompt } from 'react-router-dom';

import { Organisation } from '@ethical-jobs/sdk-js/types';

import { usePromise } from 'hooks';
import { APIContext, AmplitudeContext } from 'lib/contexts';
import { isSubmitting } from 'store/selectors';

import PageTitle from 'components/PageTitle';
import BaseJobUpdate from 'containers/JobUpdate';

const JobUpdate = () => {
  const api = useContext(APIContext);
  const amplitude = useContext(AmplitudeContext);

  const params: { jobId?: string, previewed?: string } = useParams();
  const jobId = params.jobId ? Number(params.jobId) : undefined;
  const previewed = params.previewed ? !!params.previewed : false;
  
  //@ts-ignore
  const userId: number = useSelector(state => state.auth.user.id);
  // @ts-ignore
  let organisationId: number | null = useSelector(state => formValueSelector('jobForm')(state, 'organisationId') || null);
  const isFormDirty: boolean = useSelector(isDirty('jobForm'));
  const isFormSubmitting: boolean = useSelector(isSubmitting('jobForm'));

  const getOrganisation: Promise<Organisation | null> = useMemo(() => organisationId ? api.getOrganisation(organisationId) : Promise.resolve(null), [api, organisationId]);
  const [organisation] = usePromise(getOrganisation, null, { component: 'JobUpdate' });

  useEffect(() => {
    const fn = async () => {
      if (!jobId) return;

      try {
        await api.assignUserToJob(userId, jobId);
        amplitude.logEvent('API', { function: 'assignUserToJob', arguments: { userId, jobId }});
    } catch (error) {
      amplitude.logError('containers/_admin/JobUpdate/index', error);
    }
    };

    fn();
  }, [jobId, userId, api, amplitude]);

  const blockMessage = !isFormSubmitting && (isFormDirty || previewed)
    ? "WARNING: Do you really want to leave? Your work will be lost."
    : "";

  return (
    <div className="admin-job-update-container">
      {jobId ? (
        <PageTitle title="Update Job Ad" subTitle="Update a Job ad listing" />
      ) : (
        <PageTitle title="Create Job Ad" subTitle="Create a new Job ad listing" />
      )}
      <BaseJobUpdate
        isAdmin
        jobId={jobId}
        organisation={organisation}
      />
      <Prompt when={!!blockMessage} message={blockMessage} />
    </div>
  );
}

export default reduxForm({
  form: 'jobForm'
})(JobUpdate);