import { useState, useEffect, useContext } from 'react';

import { AmplitudeContext } from 'lib/contexts';

import useMemoisedParam from './useMemoisedParam';

const usePromise = <T>(promise: Promise<T>, defaultValue: T, loggingInfo: Object = {}): [T, Error | null, boolean] => {
  const amplitude = useContext(AmplitudeContext);
  const [state, setState] = useState({ value: defaultValue, error: null })
  const [isLoading, setIsLoading] = useState(false);

  const memoisedDefaultValue = useMemoisedParam(defaultValue);
  const memoisedLoggingInfo = useMemoisedParam(loggingInfo);

  useEffect(() => {
    setIsLoading(true);
    let isSubscribed = true;

    const fn = async () => {
      let value = memoisedDefaultValue;
      let error = null;

      try {
        value = await promise;
      } catch (e) {
        error = e;
        amplitude.logError('hooks/useApi', error, memoisedLoggingInfo);
      }
        
      if (isSubscribed) {
        setState({
          value,
          error
        });

        setIsLoading(false);
      }
    }

    fn();
    
    return () => {
      isSubscribed = false;
    }
  }, [promise, amplitude, memoisedDefaultValue, memoisedLoggingInfo]);

  return [state.value, state.error, isLoading];
}

export default usePromise;