import EthicalJobsAPI from '@ethical-jobs/sdk-js';
import { Amplitude } from '@ethical-jobs/sdk-js/types/amplitude';

export const fetchAppData = () => async (dispatch: (action: { type: string, payload?: any }) => void, getState: () => Object, { api, amplitude }: { api: EthicalJobsAPI, amplitude: Amplitude }) => {
  try {
    let taxonomies = await api.getTaxonomies();

    dispatch({
      type: 'APP/FETCH_DATA',
      payload: taxonomies
    });
  } catch (error) {
    amplitude.logError('actions/app/index/fetchAppData', error);
  }
};