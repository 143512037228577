import React from 'react';

type Props = {
  list: Array<string>;
};

const ListRenderer = ({ list }: Props) => (
  <div className="list-renderer">
    {list.map(item => <span key={item}>{item}</span>)}
  </div>
);

export default ListRenderer;