import React from 'react';
import styled from 'styled-components';

const Container = styled.div`
  position: absolute;
  top: 50px;
  z-index: 10;
  width: 100%;
`;

const Content = styled.div`
  border: 1px solid #eee;
  border-radius: 4px;
`;

interface SuggestionItemProps {
  active: boolean;
  index: number;
  listLength: number;
}

const determineBorderRadius = (props: SuggestionItemProps): string => {
  if (props.index === 0) return '4px 4px 0 0';
  if (props.index === props.listLength - 1) return '0 0 4px 4px';
  return '0';
};

const SuggestionItem = styled.div<SuggestionItemProps>`
  padding: 10px;
  background-color: ${props => props.active ? '#efefef' : '#fff'};
  cursor: pointer;
  border-radius: ${props => determineBorderRadius(props)}
`;

type Props = {
  suggestions: any;
  getSuggestionItemProps: any;
};

const Menu = ({suggestions, getSuggestionItemProps}: Props) => (
  <Container>
    {suggestions.length > 0 && (
      <Content>
        {suggestions.map((suggestion, index: number) => {
          return (
            <SuggestionItem
              {...getSuggestionItemProps(suggestion)}
              active={suggestion.active}
              key={suggestion.description}
              data-testid="suggestion-item"
              index={index}
              listLength={suggestions.length}
            >
              <span>{suggestion.description}</span>
            </SuggestionItem>
          );
        })}
      </Content>
    )}
  </Container>
);

export default Menu;
