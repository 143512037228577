import styled from 'styled-components';

type DraggingContainerProps = {
  isSelected: boolean;
  isDragging: boolean;
  width: number;
}

export const DraggingContainer = styled.div<DraggingContainerProps>`
  opacity: ${({ isDragging }) => isDragging ? 0.8 : 1};
  width: ${({ width }) => `${width}px`};
  display: flex;
  margin: ${({ isSelected }) => isSelected ? '10px 2px' : '10px 0px'};
  padding: 5px 5px 5px 0px;
  background-color: white;
  border-radius: 5;
  align-items: center;
  justify-content: space-between;
  border-style: solid;
  border-width: ${({ isDragging }) => isDragging ? 1 : '0px 8px 0px 0px'};
  border-color: ${({ isDragging, isSelected }) => isDragging ? '#0079c1' : isSelected ? '#3E8420' : 'white'};
  box-shadow: ${({ isSelected }) => isSelected ? '0 2px 4px 0 rgba(0, 0, 0, 0.1)' : 'none'};
`;