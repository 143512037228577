import React, { useCallback } from 'react';

import _ from 'lodash';
import { reduxForm } from 'redux-form';

import submissionError from 'lib/submissionError';
import validation from './validation';
import FormField from 'fields/FormField';
import ValidationMessages from 'components/ValidationMessages';
import Button from 'components/Button';

type Props = {
  admin: boolean;
  action: (values: Object) => Promise<Object>;
  handleSubmit: any;
  error?: string;
  submitting: boolean;
};

const UserProfileForm = ({ admin = false, action, handleSubmit, error, submitting }: Props) => {
  const submitForm = useCallback(async (values) => {
    try {
      await action(values);
    } catch (error) {
      if (_.get(error, ['response', 'data', 'errors', 'username', '0'], '') === 'The username has already been taken.') {
        submissionError({ statusCode: 422, message: 'The username has already been taken.' });
        return;
      }

      submissionError(error);
    }
  }, [action]);

  return (
    <form className="user-profile-form form-horizontal" onSubmit={handleSubmit(submitForm)}>
      <div className="row">
        <FormField
          name="firstName"
          component="input"
          type="text"
          label="First name"
          wrapClassName="col-md-6"
        />
        <FormField
          name="lastName"
          component="input"
          type="text"
          label="Last name"
          wrapClassName="col-md-6"
        />
        <FormField
          name="email"
          component="input"
          type="text"
          label="Email address"
          wrapClassName="col-md-6"
        />
        <FormField
          name="username"
          component="input"
          type="text"
          label="Username"
          wrapClassName="col-md-6"
        />
        <FormField
          name="position"
          component="input"
          type="text"
          label="Role in the organisation"
          wrapClassName="col-md-12"
        />
      </div>
      <label>Update password</label>
      <div className="row">
        {!admin && (
          <FormField
            name="old_password"
            component="input"
            type="password"
            placeholder="Enter current password"
            wrapClassName="col-md-12"
          />
        )}
        <FormField
          name="new_password"
          component="input"
          type="password"
          placeholder="Enter a new password"
          wrapClassName="col-md-6"
        />
        <FormField
          name="new_password_confirmation"
          component="input"
          type="password"
          placeholder="Confirm new password"
          wrapClassName="col-md-6"
        />
      </div>
      <ValidationMessages error={error} heading="" />
      <Button
        kind="primary"
        type="submit"
        loading={submitting}
        icon="done_all"
        label="Update Contact Details"
        block
      />
    </form>
  );
};

export default reduxForm({
  form: 'userProfileForm',
  validate: validation
  // @ts-ignore
})(UserProfileForm);
