import React, { useCallback } from 'react';

import { reduxForm } from 'redux-form';
import submissionError from 'lib/submissionError';
import InvoiceFields from './InvoiceFields';
import OrganisationFields from './OrganisationFields';
import Card from 'components/Card';
import ValidationMessages from 'components/ValidationMessages';
import Button from 'components/Button';

type Props = {
  action: (values: Object) => Promise<Object>;
  isUpdate: boolean;
  handleSubmit: any;
  error?: string;
  submitting: boolean;
};

const InvoiceForm = ({ error, submitting, action, handleSubmit, isUpdate }: Props) => {
  const submitForm = useCallback(async(values) => {
    try {
      await action(values);
    } catch (error) {
      submissionError(error);
    }
  }, [action]);

  return (
    <form className="invoice-form form-horizontal" onSubmit={handleSubmit(submitForm)}>
      <InvoiceFields isUpdate={isUpdate} />
      <OrganisationFields isUpdate={isUpdate} />
      <ValidationMessages error={error} />
      <Card>
        <Button
          kind="primary"
          type="submit"
          loading={submitting}
          icon="done_all"
          label="Save invoice"
          block
        />
      </Card>
    </form>
  );
};

export default reduxForm({
  form: 'invoiceForm'
  // @ts-ignore
})(InvoiceForm);
