import React from 'react';
import {
  LineChart as BaseLineChart,
  Line,
  ResponsiveContainer
} from 'recharts';

type Props = {
  stroke: string;
  report: Array<Object>;
  dataKey: string;
};

const LineChart = ({ stroke, report, dataKey }: Props) => (
  <ResponsiveContainer width="75%" height={45}>
    <BaseLineChart data={report}>
      <Line
        type="monotone"
        dataKey={dataKey}
        stroke={stroke || "#f40bb2"}
        strokeWidth={2}
        dot={false}
      />
    </BaseLineChart>
  </ResponsiveContainer>
);

export default LineChart;
