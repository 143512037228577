import React from 'react';

type Props = {
  className?: string;
  show: boolean;
};

const Spinner = ({ className, show }: Props) => show ? (
  <div className={`${className} loading`}>
    <div className="sk-three-bounce">
      <div className="sk-child sk-bounce1" />
      <div className="sk-child sk-bounce2" />
      <div className="sk-child sk-bounce3" />
    </div>
  </div>
) : null;

export default Spinner;
