import _ from 'lodash';
import createReducer from 'lib/createReducer';

export const initialState = {
  categories: {},
  countries: {},
  creditPacks: [],
  jobSource: {},
  jobStatus: {},
  locations: {},
  orgTypes: {},
  sectors: {},
  userRoles: {},
  workTypes: {}
};

const taxonomies = createReducer(initialState, {
  'APP/FETCH_DATA': (state: Object, action: { type: string, payload: Object }) => ({ ..._.pick(action.payload, ['categories', 'countries', 'creditPacks', 'jobSource', 'jobStatus', 'locations', 'orgTypes', 'sectors', 'userRoles', 'workTypes']) }),
});

export default taxonomies;