import React from 'react';
import { Link } from 'react-router-dom';

type Props = {
  to: string;
  className?: string;
  style?: Object;
  text: string | React.ReactNode;
};

const RouteRenderer = ({ to, className, style, text}: Props) => (
  <Link
    to={to}
    className={className}
    style={style}
  >
    {text}
  </Link>
);

export default RouteRenderer;