import React from 'react';

import { LinkContainer } from 'react-router-bootstrap';
import { User, Organisation } from '@ethical-jobs/sdk-js/types';

import PasswordResetForm from 'forms/PasswordResetForm';
import Button from 'components/Button';
import Logo from 'components/Logo';
import Card from 'components/Card';

type Props = {
  isSuccess: boolean;
  resetPassword: ({ password, passwordConfirmation }: { password: string, passwordConfirmation: string }) => Promise<User & { organisation: Organisation }>;
  username: string;
  token: string;
};

const PasswordReset = ({ isSuccess, resetPassword, username, token }: Props) => (
  <div className="recover-container auth-container container">
    <div className="row">
      <div className="col-md-offset-2 col-md-8">
        <Logo />
        {isSuccess ? (
          <Card
            title="Your password has been succesfully reset"
            subTitle="Please click the link below to sign in with your new password."
            className="animated fadeInUp"
          >
            <LinkContainer to="/login">
              <Button
                kind="primary"
                label="&larr; Back to Sign in"
                block
              />
            </LinkContainer>
          </Card>
        ) : (
          <Card
            title="Reset your password"
            subTitle="To reset your password please fill in the fields below."
            className="animated fadeInUp"
          >
            <PasswordResetForm resetPassword={resetPassword} initialValues={{ username, token }} />
          </Card>
        )}
        <LinkContainer to="/login">
          <Button kind="link" label="&larr; Back to Sign in" />
        </LinkContainer>
      </div>
    </div>
  </div>
);

export default PasswordReset;
