import React from 'react';

import { Text, Icon, Row } from '@ethical-jobs/design-system-react';

import Button from 'components/Button';

type Props = {
  onSubmit: () => void;
  onChange: (email: string) => void;
  value: string;
  hasSubmitted: boolean;
};

export const InvitationForm = ({ onSubmit, onChange, value, hasSubmitted }: Props) => hasSubmitted ? (
  <Row align="left">
    <Icon type="check" size="xlarge" color="#3E8420" />
    <Text>User has been successfully invited to this organisation</Text>
  </Row>
) : (
  <>
  <div className="row">
    <div className="col-md-8">
      <input
        className="form-control"
        data-testid="invitation-email-input"
        name="email"
        type="text"
        placeholder="Email address"
        value={value}
        onChange={(event) => onChange(event.target.value)}
      />
    </div>
  </div>
  <div className="row">
    <div className="col-md-12">
      <Button
        data-testid="invitation-submit-button"
        kind="primary"
        type="submit"
        loading={hasSubmitted}
        disabled={hasSubmitted}
        icon="person_add"
        label="Invite"
        onClick={onSubmit}
      />
    </div>
  </div>
  </>
);

export default InvitationForm;
