import React, { Fragment } from 'react';

import { Heading, Text } from '@ethical-jobs/design-system-react';

import Card from 'components/Card';
import Logo from 'components/Logo';
import SignupForm from 'forms/SignupForm';

type Props = {
  signup: (values: Object) => Promise<void>;
  invitationStatus: null | 'valid' | 'expired' | 'invalid' | 'used';
  email?: string;
}

const Signup = ({ signup, invitationStatus, email }: Props) => (
  <div className="organisation-signup-container auth-container container">
    <div className="row">
      <div className="col-md-offset-1 col-md-10">
        <Logo />
        <Card className="animated fadeInUp">
          {invitationStatus === 'valid' && <SignupForm action={signup} />}
          {invitationStatus === 'expired' && (
            <Fragment>
              <Heading>Expired Invitation</Heading>
              <Text style={{ paddingTop: 15 }}>Oops - this invitation has expired. Please contact the account owner and request a new invitation. Sorry!</Text>
            </Fragment>
          )}
          {invitationStatus === 'invalid' && (
            <Fragment>
              <Heading>Invalid Invitation</Heading>
              <Text style={{ paddingTop: 15 }}>Oops - it appears to be an invalid invitation. Please check the link or contact the account owner and request a new invitation. Sorry!</Text>
            </Fragment>
          )}
          {invitationStatus === 'used' && (
            <Fragment>
              <Heading>Invitation Used</Heading>
              <Text style={{ paddingTop: 15 }}>Oops – looks like you’ve already activated an account for this email. Sorry!</Text>
            </Fragment>
          )}
        </Card>
      </div>
    </div>
  </div>
);

export default Signup;
