import React from 'react';
import styled from 'styled-components';
import reactStringReplace from 'react-string-replace';

type LabelProps = {
  disabled: boolean;
};

const StyledLabel = styled.label<LabelProps>`
  color: #333;
  padding: 15px 20px 15px 55px;
  position: relative;
  cursor: ${({ disabled }) => disabled ? 'not-allowed' : 'default'};
`;

const CheckboxWrapper = styled.div`
  position: absolute;
  left: 20px;
  top: 50%;
  transform: translateY(-50%);
`;

// TODO: Remove !important flags after we deal with global styles issue
const Input = styled.input`
  border-bottom: 1px solid #dce0e0 !important;
  display: inline;
  margin-bottom: 0 !important;
  width: auto;
  
  &.form-control[disabled] {
    pointer-events: none; // Let the label accept the click instead when the blank is disabled
    cursor: default;
  }
`;

export interface Blanks {
  [match: string]: string;
}

type Props = {
  blanks?: Blanks;
  checked: boolean;
  disabled: boolean;
  onBlankChange: (blanks: Blanks) => void;
  label: string;
  children: React.ReactNode;
};

const Label = ({label, onBlankChange, blanks = {}, checked, children, disabled}: Props) => {
  return (
    <StyledLabel data-testid="questions-field-label" disabled={disabled}>
      <CheckboxWrapper>
        {children}
      </CheckboxWrapper>
      {reactStringReplace(label,/<([\w+])>/g, match => {
        return (
          <Input
            data-testid="questions-field-blank-input"
            type="text"
            className="form-control"
            disabled={!checked}
            key={match}
            required
            onChange={({ target: { value } }) => {
              onBlankChange({...blanks, [match]: value})
            }}
            value={blanks[match] || ''}
          />
        );
      })}
    </StyledLabel>
  );
};

export default Label;
