import { SubmissionError } from 'redux-form';
import submissionError from 'lib/submissionError';

const generateSubmissionError = (response: Error & { statusCode: number }) => {
  switch (response.statusCode) {
    case 404:
      throw new SubmissionError({ _error: 'Sorry, We cant find any users matching that email.' });
    case 400:
      throw new SubmissionError({ _error: { message: 'Invalid token', invalidToken: true } });
    default:
      submissionError(response);
  }
};

export default generateSubmissionError;