import React from 'react';
import classNames from 'classnames';

const JOB_TYPES = ['APPROVED', 'PENDING', 'EXPIRED'];

type Props = {
  value: string;
  onChange: (jobType: 'APPROVED' | 'PENDING' | 'EXPIRED') => void;
};

const JobStatusFilter = ({ onChange, value }: Props) => (
  <div className="filter-component">
    {JOB_TYPES.map((type: 'APPROVED' | 'PENDING' | 'EXPIRED') => (
      // eslint-disable-next-line
      <a
        key={type}
        onClick={() => onChange(type)}
        className={classNames({ active: type === value })}
      >
        {type}
      </a>
    ))}
  </div>
);

export default JobStatusFilter;