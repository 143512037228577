export const APPROVED = 'APPROVED';

export const PENDING = 'PENDING';

export const DRAFT = 'DRAFT';

const EXPIRED = 'EXPIRED';

export const getFiltersByType = (type: string) => {
  switch (type) {
    case PENDING:
      return {
        status: PENDING,
        expired: undefined // clear
      };
    case EXPIRED:
      return {
        status: [APPROVED, PENDING],
        expired: true,
        limit: 2000
      };
    case DRAFT:
      return {
        status: DRAFT,
        expired: undefined, // clear
        limit: 2000
      };
    case APPROVED:
    default:
      return {
        status: APPROVED,
        expired: false
      };
  }
};
