import React from 'react';

import FormField from 'fields/FormField';

const Title = () => (
  <div className="row form-group">
    <FormField
      name="title"
      component="input"
      type="text"
      label="Position Title"
      placeholder="Enter a position title"
      wrapClassName="col-md-12"
      required
      style={{ fontSize: 24 }}
    />
  </div>
);

export default Title;
