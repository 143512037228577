import createReducer from 'lib/createReducer';

export const initialState = {
  admin: {
    organisations: {
      query: '',
      includeArchived: false,
      sort: { field: 'createdAt', direction: 'desc' }
    },
    jobs: {
      organisation: null,
      status: 'PENDING',
      query: '',
      sort: { field: 'submittedAt', direction: 'desc' }
    },
    invoices: {
      organisation: null,
      query: '',
      sort: { field: 'createdAt', direction: 'desc' }
    }
  }
};

const search = createReducer(initialState, {
  // @ts-ignore
  'SEARCH_ORGANISATIONS_ADMIN': (state: Object, action: { type: string, payload: Object }) => ({ ...state, admin: { ...state.admin, organisations: action.payload } }),
  // @ts-ignore
  'SEARCH_JOBS_ADMIN': (state: Object, action: { type: string, payload: Object }) => ({ ...state, admin: { ...state.admin, jobs: action.payload } }),
  // @ts-ignore
  'SEARCH_INVOICES_ADMIN': (state: Object, action: { type: string, payload: Object }) => ({ ...state, admin: { ...state.admin, invoices: action.payload } }),
});

export default search;