import React, { useState, Fragment, useCallback } from 'react';

import moment from 'moment';
import { Heading } from '@ethical-jobs/design-system-react';

import Button from 'components/Button';
import Modal from 'components/Modal';
import Icon from 'components/Icon';
import DateTimeField from 'fields/DateTimeField';

import { EXPIRATION_TIMES } from 'lib/constants';

import { HeaderContainer, FooterContainer } from './ExtendJobAdModal.styles';

type Props = {
  show: boolean;
  close: () => void;
  expiresAt: string;
  approvedAt: string;
  updateExpiration: (newExpirationDate: string) => Promise<void>;
};

const ExtendJobAdModal = ({ show, close, expiresAt, approvedAt, updateExpiration }: Props) => {
  const [updatedExpiresAt, setUpdatedExpiresAt] = useState(expiresAt);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const updateExpirationFn = useCallback(async () => {
    setIsSubmitting(true);
    await updateExpiration(updatedExpiresAt)
    setIsSubmitting(false);
  }, [updatedExpiresAt, updateExpiration]);

  return (
    <Modal
      show={show}
      onHide={close}
    >
      <Fragment>
        <HeaderContainer>
          <Heading size="medium">Extend This Job Ad</Heading>
          <div data-testId="closeIcon" style={{ cursor: 'pointer' }} onClick={close}>
            <Icon type="close" />
          </div>
        </HeaderContainer>
        <p style={{ padding: '15px 30px 5px', marginBottom: 0 }}>
          This job listing is due to expire on EthicalJobs.com.au at {moment(expiresAt).format('h:mm A [on] Do MMM, YYYY')}. You can extend this job listing at no extra cost - simply select a new expiry date up to 11:59 PM on {moment(approvedAt).add(45, 'days').format('Do MMM, YYYY')}.
        </p>
        <FooterContainer>
          <DateTimeField
            value={updatedExpiresAt}
            minDate={moment.max([moment(approvedAt), moment().add(1, 'day')]).toDate()}
            maxDate={moment(approvedAt).add(45, 'days').endOf('day').toDate()}
            dateFormat="dddd Do MMMM, YYYY"
            times={EXPIRATION_TIMES.map(time => ({ label: time, value: time }))}
            onChange={setUpdatedExpiresAt}
          />
          <Button
            label="Save"
            onClick={updateExpirationFn}
            kind="primary"
            loading={isSubmitting}
            disabled={isSubmitting}
            style={{ maxHeight: 50 }}
          />
        </FooterContainer>
      </Fragment>
    </Modal>
  );
};

export default ExtendJobAdModal;
