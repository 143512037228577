import React from 'react';
import Card from 'components/Card';

const PricingFeatures = () => (
  <table className="pricing-features">
    <thead>
      <tr>
        <th>Features</th>
        <th>Regular Ads</th>
        <th>Added Value Ads</th>
      </tr>
    </thead>
    <tbody>
      <tr>
        <td>Your job ad credits never expire – use them whenever you need them!</td>
        <td><i className="material-icons">done</i></td>
        <td><i className="material-icons">done</i></td>
      </tr>
      <tr>
        <td>Ads live on the site for up to 45 days</td>
        <td><i className="material-icons">done</i></td>
        <td><i className="material-icons">done</i></td>
      </tr>
      <tr>
        <td>All ads include your organisation’s logo</td>
        <td><i className="material-icons">done</i></td>
        <td><i className="material-icons">done</i></td>
      </tr>
      <tr>
        <td>New ads appear in our Weekly Email Update delivered to over 125,000 inboxes</td>
        <td><i className="material-icons">done</i></td>
        <td><i className="material-icons">done</i></td>
      </tr>
      <tr>
        <td>Unlimited space for text, plus the ability to attach position descriptions or other relevant documents</td>
        <td><i className="material-icons">done</i></td>
        <td><i className="material-icons">done</i></td>
      </tr>
      <tr>
        <td>Include your ad in multiple job categories – as many as appropriate</td>
        <td><i className="material-icons">done</i></td>
        <td><i className="material-icons">done</i></td>
      </tr>
      <tr>
        <td>Email and phone support for any questions and help using our service</td>
        <td><i className="material-icons">done</i></td>
        <td><i className="material-icons">done</i></td>
      </tr>
      <tr>
        <td>Free search optimisation to maximise views for each of your ads</td>
        <td><i className="material-icons">done</i></td>
        <td><i className="material-icons">done</i></td>
      </tr>
      <tr>
        <td>Email us your ads for quick and easy uploading to our site</td>
        <td><i className="material-icons clear">clear</i></td>
        <td><i className="material-icons">done</i></td>
      </tr>
      <tr>
        <td><strong>And for unlimited service subscribers:</strong></td>
        <td>&nbsp;</td>
        <td>&nbsp;</td>
      </tr>
      <tr>
        <td>Freedom to advertise all of your vacancies on EthicalJobs.com.au for 6 months (excludes unpaid volunteer & internship roles)</td>
        <td><i className="material-icons">done</i></td>
        <td><i className="material-icons">done</i></td>
      </tr>
      <tr>
        <td>Feature up to 5 ads on our homepage for extra exposure</td>
        <td><i className="material-icons">done</i></td>
        <td><i className="material-icons">done</i></td>
      </tr>
      <tr>
        <td>Ability to extend the closing date for your live ads</td>
        <td><i className="material-icons">done</i></td>
        <td><i className="material-icons">done</i></td>
      </tr>
      <tr>
        <td>Email us your ads for quick and easy uploading to our site</td>
        <td><i className="material-icons clear">clear</i></td>
        <td><i className="material-icons">done</i></td>
      </tr>
    </tbody>
  </table>
);

const PricingDetails = () => (
  <div>
    <ul className="pricing-info">
      <li>All prices exclude GST</li>
      <li>You can feature your job ad at the top of search results pages for just one additional job ad credit. Simply purchase more than one credit, then select "Feature this job" when you submit your ad.</li>
      <li>Are you a not-for-profit organisation advertising more than 30 jobs per year? Call us on 03 9419 5979 to find out about our special Unlimited ad package.</li>
    </ul>
  </div>
);

const PricingInfo = () => (
  <div>
    <PricingDetails />
    <Card>
      <PricingFeatures />
    </Card>
  </div>
);

export default PricingInfo;
