import { SubmissionError } from 'redux-form';
import libSubmissionError from 'lib/submissionError';

const submissionError = (response: Error & { statusCode: number }) => {
  switch (response.statusCode) {
    case 401:
      throw new SubmissionError({ _error: 'The email and password you entered don\'t match.' });
    default:
      libSubmissionError(response);
  }
};

export default submissionError;
