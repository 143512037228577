import React from 'react';

import { User } from '@ethical-jobs/sdk-js/types';

import StaffAvatar from 'components/StaffAvatar';
import RouteRenderer from 'components/DataTableRenderers/RouteRenderer';

type Props = {
  locked: boolean;
  lockedByAvatar: string;
  url: string;
  lockedUser: User;
};

const LockRenderer = ({ locked, lockedByAvatar, url, lockedUser }: Props) => locked ? (
  <StaffAvatar
    src={lockedByAvatar}
    name={lockedUser.name}
    userId={lockedUser.id}
  />
) : (
  <RouteRenderer
    to={url}
    text="Update"
  /> 
);

export default LockRenderer;
