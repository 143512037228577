import React from 'react';

import classNames from 'classnames';

const calculateRemaining = (limit: number, value: string) => (!value || !value.length) ? limit : limit - value.length;

type Props = {
  value: string;
  onChange: (value: string) => void;
  limit: number;
  id: string;
};

const TextArea = ({ onChange, limit, value, id, ...props }: Props) => {
  const remaining = calculateRemaining(limit, value);

  const bodyClass = classNames('textarea-with-counter', {
    invalid: remaining < 0,
    warning: remaining < 15 && remaining > -1
  });

  const remainingClass = classNames('remaining animated infinite', {
    bounce: remaining < 0
  });

  return (
    <div className={bodyClass}>
      <textarea
        id={id}
        value={value}
        {...props}
        onChange={({ target: { value }}) => onChange(value)}
      />
      <div className={remainingClass}>{remaining}</div>
    </div>
  );
};

export default TextArea;