import React, { useCallback, useContext } from 'react';

import _ from 'lodash';
import { useHistory } from 'react-router-dom';

import { APIContext, AmplitudeContext } from 'lib/contexts';
import OrganisationSignupForm from 'forms/OrganisationSignupForm';
import PageTitle from 'components/PageTitle';
import Card from 'components/Card';

const initialValues = {
  organisation: {
    country: 'AU'
  },
  signin: false
};

const RegisterOrganisation = () => {
  const history = useHistory();
  const api = useContext(APIContext);
  const amplitude = useContext(AmplitudeContext);

  const signup = useCallback(async (values) => {
    try {
      let user = await api.createOrganisation({
        ...values,
        signin: false
      });
      history.push('/admin/organisations');
      amplitude.logEvent('API', { function: 'createOrganisation', arguments: { ..._.mapValues(values, (value, key) => key.includes('password') ? 'REDACTED' : value) }});
      return user;
    } catch (error) {
      amplitude.logError('containers/_admin/RegisterOrganisation/index/signup', error);

      // INFO: Must rethrow so form can handle
      throw error;
    }
  }, [api, history, amplitude]);

  return (
    <div className="create-organisation-container">
      <PageTitle title="Create organisation" subTitle="New organisation registration" />
      <Card>
        <OrganisationSignupForm
          signup={signup}
          uploadLogo={api.uploadMedia}
          initialValues={initialValues}
        />
      </Card>
    </div>
  );
};

export default RegisterOrganisation;
