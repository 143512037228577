import React, { FunctionComponent } from 'react';

import { Link } from 'react-router-dom';

import { Organisation } from '@ethical-jobs/sdk-js/types';

import Card from 'components/Card';
import FormField from 'fields/FormField';
import OrganisationSelect from 'fields/OrganisationSelect';
import Select from 'components/Select';
import UserSelect from 'fields/UserSelect';
import UserRadioGroup from 'fields/UserRadioGroup';

type Props = {
  admin: boolean;
  organisation: Organisation;
};

const AdminControls = ({ admin, organisation }: Props) => {
  if (!admin) {
    return null;
  }

  return (
    <Card>
      <>
      <div className="row">
        <FormField
          name="organisationId"
          component={OrganisationSelect}
          label="Select an organisation"
          wrapClassName="col-md-12"
          required
        />
      </div>
      {organisation && (
        <>
          <Link to={`/admin/organisation/${organisation.id}/view`}>
            {organisation.name}
          </Link>
          <div className="row">
            <FormField
              name="contactId"
              component={UserRadioGroup}
              label="Select a job email contact"
              wrapClassName="col-md-12"
            />
          </div>
        </>
      )}
      </>
    </Card>
  );
};

export default AdminControls;