import React from 'react';

import Nav from 'react-bootstrap/lib/Nav';

import { useWindowDimensions } from 'hooks';

type Props = {
  children: React.ReactNode;
  admin?: boolean;
};

const SideNav = ({ children, admin = false }: Props) => {
  const { height } = useWindowDimensions();

  return (
    <div className="side-nav" style={{ maxHeight: admin ? height : height - 74, overflowY: 'scroll', scrollbarWidth: 'none', marginTop: admin ? -15 : 0 }}>
      <div className="menu" role="navigation">
        <Nav bsStyle="pills" stacked>
          {children}
        </Nav>
      </div>
    </div>
  );
};

export default SideNav;
