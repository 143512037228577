import React, { Fragment, useState } from 'react';

import { Column } from 'react-virtualized';
import { Select, Text, Modal } from '@ethical-jobs/design-system-react';
import { UserOptionalFields } from '@ethical-jobs/sdk-js/types';

import DataTable from 'components/DataTable';
import Card from 'components/Card';
import Icon from 'components/Icon';
import FinanceContactContainer from 'components/FinanceContact/FinanceContactContainer';

const PLACEHOLDER = 'invited';

type Props = {
  users: Array<UserOptionalFields>;
  isStaffMember: boolean;
  ownerId: number;
  authUserId: number;
  setUserRole: (userId: number, role: string) => void;
  archiveUser: (userId: number) => void;
  deleteInvitation: (inviteId: string) => void;
};

export const UserListDisplay = ({ users, isStaffMember, ownerId, authUserId, setUserRole, archiveUser, deleteInvitation }: Props) => {
  const [ownerChange, setOwnerChange] = useState<null | { userId: number, role: string }>(null);
  const [requestArchiveUser, setRequestArchiveUser] = useState<number | null>(null);
  const [reqeustDeleteInvitation, setRequestDeleteInvitation] = useState<string | null>(null);

  const showAdminOptions = (authUserId === ownerId) || isStaffMember;

  return (
    <Fragment>
      <div style={{ marginBottom: 45 }}>
      <Card title="All users">
        <div className="entity-listing-container user-list-table">
          <DataTable rows={users} maxHeight={500}>
            <Column
              flexGrow={1}
              label="Name"
              dataKey="name"
              width={200}
              cellRenderer={({ rowData }) => <span className="centered" style={rowData.name === PLACEHOLDER ? { color: 'red' } : {}}>{rowData.name}</span>}
            />
            <Column flexGrow={1} label="Job Title" dataKey="position" width={200} />
            {isStaffMember && (
              <Column flexGrow={1} label="Username" dataKey="username" width={180} />
            )}
            <Column flexGrow={1} label="Email" dataKey="email" key="email" width={250} />
            <Column
              dataKey="permissions"
              flexGrow={1}
              label="User Type"
              width={180}
              disableSort
              cellRenderer={({ rowData }) => (showAdminOptions && rowData.id !== authUserId && rowData.id !== ownerId && rowData.name !== PLACEHOLDER) ? (
                <Select
                  usePortal
                  value={rowData.id === ownerId ? 'owner' : 'standard'}
                  onChange={({ value }) => setOwnerChange({ userId: rowData.id, role: value })}
                  isClearable={false}
                  isSearchable={false}
                  options={[{
                    value: 'owner',
                    label: 'Owner'
                  }, {
                    value: 'standard',
                    label: 'Standard'
                  }]}
                  width={120}
                />
              ) : (
                <Text style={{ fontSize: 14 }}>{rowData.id === ownerId ? 'Owner' : 'Standard'}</Text>
              )}
            />
            {showAdminOptions && users.length > 1 && (
              <Column
                label="Actions"
                dataKey="actions"
                width={70}
                disableSort
                cellRenderer={({ rowData }) => (showAdminOptions && rowData.id !== authUserId && rowData.name !== PLACEHOLDER) ? (
                  <div style={{ display: 'flex', justifyContent: 'center' }}>
                    {/* eslint-disable-next-line */}
                    <a
                      onClick={() => setRequestArchiveUser(rowData.id)}
                      title="Delete User"
                    >
                      <Icon
                        type="delete"
                        style={{ color: 'red' }}
                      />
                    </a>
                  </div>
                ) : rowData.id !== ownerId && (
                  <div style={{ display: 'flex', justifyContent: 'center' }}>
                    <a
                      onClick={() => {
                        setRequestDeleteInvitation(rowData.id);
                      }}
                      title="Delete User"
                    >
                      <Icon
                        type="delete"
                        style={{ color: 'red' }}
                      />
                    </a>
                  </div>
                )}
              />
            )}
          </DataTable>
        </div>
      </Card>
      </div>
      {!isStaffMember && 
        <div style={{ marginBottom: 45 }}>
          <FinanceContactContainer />
        </div>
      }

      <Modal
        title="Warning!"
        visible={!!ownerChange}
        onClose={() => setOwnerChange(null)}
        size="medium"
        onConfirm={() => {
          setUserRole(ownerChange.userId, ownerChange.role);
          setOwnerChange(null);
        }}
      >
        <Text>You are about to make this user the Owner of your organisation's account. You will no longer be able to invite or delete new users to this account. Are you sure you want to proceed?</Text>
      </Modal>
      <Modal
        title="Warning!"
        visible={requestArchiveUser !== null}
        onClose={() => setRequestArchiveUser(null)}
        size="medium"
        onConfirm={() => {
          archiveUser(requestArchiveUser);
          setRequestArchiveUser(null);
        }}
      >
        <Text>You are about to permanently remove this user from your organisation's account. Are you sure you want to proceed?</Text>
      </Modal>
      <Modal
        title="Delete Request"
        visible={reqeustDeleteInvitation !== null}
        onClose={() => setRequestDeleteInvitation(null)}
        size="medium"
        onConfirm={() => {
          deleteInvitation(reqeustDeleteInvitation);
          setRequestDeleteInvitation(null);
        }}
      >
        <Text>You are about to permanently remove this invition. Are you sure you want to proceed?</Text>
      </Modal>
    </Fragment>
  );
};

export default UserListDisplay;