import React, { Fragment } from 'react';

import moment from 'moment';

import { Heading } from '@ethical-jobs/design-system-react';
import { Attachment as AttachmentType, Applicant, AttachmentLinks } from '@ethical-jobs/sdk-js/types';

import Container from 'components/Container';
import PageTitle from 'components/PageTitle';
import Card from 'components/Card';
import Spinner from 'components/Spinner';

import Attachment from './Attachment';

const InfoItem = ({ title, value }: { title: string, value: string }) => (
  <span style={{ display: 'flex' }}>
    <dt style={{ margin: 0, width: 130, padding: '2px 4px', color: '#2e3c54' }}>{title}:</dt>
    <dd style={{ margin: 0, padding: '2px 4px' }}>{value}</dd>
  </span>
);

type Props = {
  applicant: Applicant | null;
  attachments: Array<AttachmentType & AttachmentLinks>;
  isLoading?: boolean;
}

const ViewApplicant = ({ applicant, attachments, isLoading = false }: Props) => (
  <Container>
    <PageTitle title="Applicant Information" />
    <Card>
      {applicant && (
        <Fragment>
          <dl>
            <InfoItem title="First Name" value={applicant.firstName} />
            <InfoItem title="Last Name" value={applicant.lastName} />
            <InfoItem title="Recent Role" value={applicant.recentRole} />
            <InfoItem title="Recent Employer" value={applicant.recentEmployer} />
            <InfoItem title="Email" value={applicant.email} />
            <InfoItem title="Phone" value={applicant.phone} />
            <InfoItem title="Application Date" value={moment(applicant.createdAt).format('DD/MM/YYYY @ hh:mm a [AEST]')} />
          </dl>
          {applicant.resumeId && attachments.find(attachment => attachment.id === applicant.resumeId) && (
            <Fragment>
              <Heading size="xsmall">Resume / CV:</Heading>
              <Attachment attachment={attachments.find(attachment => attachment.id === applicant.resumeId)} />
            </Fragment>
          )}

          {attachments.filter(attachment => attachment.id !== applicant.resumeId).length > 0 && (
            <Heading size="xsmall" style={{ paddingTop: 20 }}>Other Documents:</Heading>
          )}
          {attachments.filter(attachment => attachment.id !== applicant.resumeId).map(attachment => (
            <Attachment key={attachment.id} attachment={attachment} />
          ))}
        </Fragment>
      )}
      <Spinner show={isLoading} />
    </Card>
  </Container>
);

export default ViewApplicant;