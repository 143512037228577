import { createValidator, required, integer, minLength } from 'lib/validation';

export default createValidator({
  name: [required, minLength(5)],
  numPaidStaff: [required, integer],
  numVolunteers: [required, integer],
  phone: [required],
  address: [required],
  suburb: [required],
  postcode: [required],
  state: [required],
  country: [required],
  uid: [required]
});
