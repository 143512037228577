import React from 'react';
import styled from 'styled-components';

import { Media } from '@ethical-jobs/sdk-js/types';

import FormField from 'fields/FormField';
import EnumerablesSelect from 'fields/EnumerablesSelect';
import CityAutocompleteField from 'fields/CityAutocompleteField';
import MediaField from 'fields/MediaField';

const AddressHeading = styled.label`
  margin-bottom: 10px;
`;

type Props = {
  uploadLogo: (logo: Array<File>) => Promise<Media>;
};

const StepTwo = ({uploadLogo}: Props) => (
  <div className="step-two">
    <div className="row">
      {/* Requires a hidden input for autocomplete to be disabled in chrome:
        * https://medium.com/paul-jaworski/turning-off-autocomplete-in-chrome-ee3ff8ef0908 */}
      <input type="hidden" value="hidden" />
      <FormField
        name="name"
        component="input"
        type="text"
        wrapClassName="col-md-6"
        label="Organisation Name"
      />
      <FormField
        name="phone"
        component="input"
        type="text"
        wrapClassName="col-md-6"
        label="Organisation Phone Number"
      />
      <FormField
        name="numPaidStaff"
        component="input"
        type="text"
        wrapClassName="col-md-6"
        label="Number of Paid Staff"
      />
      <FormField
        name="numVolunteers"
        component="input"
        type="text"
        wrapClassName="col-md-6"
        label="Number of Volunteers"
      />
      <FormField
        name="logoId"
        component={MediaField}
        accept="image/gif, image/jpg, image/jpeg, image/png"
        wrapClassName="col-md-12"
        label="Organisation Logo"
        placeholder="&uarr; Upload your logo"
        attach={uploadLogo}
      />
      <div className="col-md-12">
        <AddressHeading>Postal Address</AddressHeading>
      </div>
      <FormField
        name="address"
        component="input"
        type="text"
        label="Street address or P.O. Box"
        wrapClassName="col-md-12"
      />
      <FormField
        name="suburb"
        component={CityAutocompleteField}
        type="text"
        label="Town or suburb"
        wrapClassName="col-md-6"
      />
      <FormField
        name="postcode"
        component="input"
        type="text"
        label="Postcode"
        wrapClassName="col-md-6"
      />
      <FormField
        name="state"
        component="input"
        type="text"
        label="State"
        wrapClassName="col-md-6"
      />
      <FormField
        name="country"
        component={EnumerablesSelect}
        enumerableType="countries"
        wrapClassName="col-md-6"
        label="Select a country"
      />
    </div>
  </div>
);

export default StepTwo;