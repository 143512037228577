import React, { useContext, useMemo } from 'react';

import _ from 'lodash';
import moment from 'moment';
import { useSelector } from 'react-redux';
import { Column } from 'react-virtualized';

import { usePromise } from 'hooks';
import { APIContext } from 'lib/contexts';

import Container from 'components/Container';
import DataTable from 'components/DataTable';
import PageTitle from 'components/PageTitle';
import InvoiceQuantityRenderer from 'components/DataTableRenderers/InvoiceQuantityRenderer';
import MoneyRenderer from 'components/DataTableRenderers/MoneyRenderer';
import InvoiceLink from 'components/InvoiceLink';

const InvoiceCollection = () => {
  const api = useContext(APIContext);

  // @ts-ignore
  const organisationId = useSelector(state => _.get(state.auth.user, ['organisation', 'id'], null));

  const getInvoices = useMemo(() => api.getInvoices({
    limit: 100,
    orderBy: [{ field: 'createdAt', direction: 'desc' }],
    organisationId,
    fields: ['id', 'organisationUid', 'organisationId', 'serviceDescription', 'uid', 'quantity', 'total', 'createdAt', 'paidAt']
  }), [api, organisationId]);

  const [invoices] = usePromise(getInvoices, [], { component: 'InvoiceCollection' });

  return (
    <Container className="invoices-container">
      <PageTitle title="Invoices List" subTitle="Invoices from past purchases" />
      <div className="entity-listing-container">
        <DataTable rows={invoices}>
          <Column
            label="Invoice no."
            dataKey="uid"
            width={140}
          />
          <Column
            label="Description"
            dataKey="serviceDescription"
            width={140}
            flexGrow={1}
          />
          <Column
            label="Qty"
            dataKey="quantity"
            cellRenderer={data => <InvoiceQuantityRenderer qty={data.cellData} />}
            width={70}
          />
          <Column
            label="Total"
            dataKey="total"
            cellRenderer={data => <MoneyRenderer money={data.cellData} />}
            width={120}
          />
          <Column
            label="Purchased"
            dataKey="createdAt"
            cellRenderer={data => <span>{moment(data.cellData).format('DD/MM/YYYY')}</span>}
            width={150}
          />
          <Column
            label="Download"
            dataKey="download_url"
            cellRenderer={(data) => <InvoiceLink invoiceId={data.rowData.id} />}
            width={140}
          />
        </DataTable>
      </div>
    </Container>
  );
}

export default React.memo(InvoiceCollection);
