import React from 'react';

type Props = {
  src: string;
  alt: string;
  style?: Object;
  className?: string;
  title?: string;
};

const ImageRenderer = ({ src, alt, style, className, title }: Props) => (
  <img
    className={className}
    style={style}
    src={src}
    alt={alt}
    title={title}
  />
);

export default ImageRenderer;
