import React, { useState, Fragment, useCallback, useContext } from 'react';

import moment from 'moment';
import cogoToast from 'cogo-toast';
import { Job, User, Organisation } from '@ethical-jobs/sdk-js/types';

import Card from 'components/Card';
import Spinner from 'components/Spinner';
import Button from 'components/Button';

import InsufficientCredits from 'components/InsufficientCredits';

import { APIContext, AmplitudeContext } from 'lib/contexts';

import ExtendJobAdModal from './ExtendJobAdModal';

type Props = {
  fetching: boolean;
  action: (values: Object) => Promise<Object>;
  job: Job;
  currentUser: User & { organisation: Organisation }
};

const OrgPreviewJobActions = ({ fetching, action, job, currentUser }: Props) => {
  const api = useContext(APIContext);
  const amplitude = useContext(AmplitudeContext);

  const [showExtendAdModal, setShowExtendAdModal] = useState(false);
  const [showInsufficientCredits, setShowInsufficientCredits] = useState(false);

  const duplicateJob = useCallback(() => {
    if (currentUser.organisation.numCredits === 0) {
      setShowInsufficientCredits(true);
    } else {
      action('duplicateJobEmployer');
    }
  }, [action, currentUser]);

  const updateExpiration = useCallback(async (expiresAt) => {
    try {
      await api.updateJob(job.id, { expiresAt });
      cogoToast.success('Successfully update expiration date', { hideAfter: 3, position: 'top-right' });
      amplitude.logEvent('API', { function: 'updateJob', arguments: { expiresAt } });
      setShowExtendAdModal(false);
      action('extend');
    } catch (error) {
      cogoToast.error('Error updating expiration date', { hideAfter: 3, position: 'top-right' });
      amplitude.logError('components/JobActions/OrgPreviewActions:updateExpiration', error);
    }
  }, [job, api, amplitude, action]);

  return (
    <div style={{ paddingTop: 30 }}>
      <Card className="job-actions organisation">
        {job.status === "APPROVED" && !job.isExpired && (
          <Fragment>
            <h3 className="title">This job ad is currently live and visible on EthicalJobs.com.au</h3>
            <h5 className="sub-title flat-top">
              If you want to remove your job ad immediately from EthicalJobs.com.au, you can 
              click on the "Expire This Job Ad" button below. To make any other changes to your ad or for assistance, please{" "}
              <a href="https://www.ethicaljobs.com.au/?modal=contact" target="_blank" rel="noopener noreferrer">
                contact us
              </a>
              .
            </h5>
            <div style={{ display: 'flex', alignItems: 'center' }}>
              {/* Only display extends options if not already at the maximum */}
              {moment(job.expiresAt).isBefore(moment(job.approvedAt).add(45, 'days')) && (
                <span style={{ paddingRight: 15 }} title="Extend the expiration date/time for this job, up to 45 days from the date it was posted on EthicalJobs.com.au">
                  <Button
                    kind="primary"
                    disabled={fetching}
                    onClick={() => setShowExtendAdModal(true)}
                    label="Extend This Job Ad"
                    icon="alarm_on"
                  />
                </span>
              )}
              <span title="Expiring this job ad will remove it from the EthicalJobs.com.au site immediately">
                <Button
                  kind="danger"
                  disabled={fetching}
                  onClick={() => {
                    if (window.confirm('Please confirm you want to expire this job ad.')) {
                      action('expire');
                    }
                  }}
                  label="Expire This Job Ad"
                  icon="close"
                  style={{
                    backgroundColor: '#CA5c55'
                  }}
                />
              </span>
            </div>
          </Fragment>
        )}
        {job.status === 'PENDING' && !job.isExpired && (
          <Fragment>
            <h3 className="title">Your job ad is now pending approval</h3>
            <h5 className="sub-title flat-top" style={{ margin: 0 }}>
              You will be notified by email as soon as the ad is approved by the
              EthicalJobs.com.au team and is live on the site (generally within 2-3
              working hours). To make any changes to your ad or for assistance, please{" "}
              <a href="https://www.ethicaljobs.com.au/?modal=contact" target="_blank" rel="noopener noreferrer">
                contact us
              </a>
              .
            </h5>
            {false && (
              <span title="You can update the expiration date/time within 45 days of the date the job was originally posted on EthicalJobs.com.au">
                <Button
                  kind="primary"
                  disabled={fetching}
                  onClick={() => setShowExtendAdModal(true)}
                  label="Extend This Job Ad"
                  icon="alarm_on"
                />
              </span>
            )}
          </Fragment>
        )}
        {job.isExpired && (
          <Fragment>
            <h3 className="title">This job ad is expired and no longer visible on EthicalJobs.com.au</h3>
            <h5 className="sub-title flat-top">
              If you have any queries or need any other assistance, please{" "}
              <a href="https://www.ethicaljobs.com.au/?modal=contact" target="_blank" rel="noopener noreferrer">
                contact us
              </a>
              .
            </h5>
            <span title="Edit and re-post this job ad as a new listing on EthicalJobs.com.au">
              <Button
                kind="primary"
                disabled={fetching}
                onClick={duplicateJob}
                label="Repost This Job Ad"
                icon="post_add"
              />
            </span>
          </Fragment>
        )}
        <Spinner show={fetching} />
      </Card>
      <ExtendJobAdModal
        show={showExtendAdModal}
        close={() => setShowExtendAdModal(false)}
        approvedAt={job.approvedAt}
        expiresAt={job.expiresAt}
        updateExpiration={updateExpiration}
      />
      {showInsufficientCredits && (
        <InsufficientCredits creditBalance={currentUser.organisation.numCredits} />
      )}
    </div>
  );
};

export default OrgPreviewJobActions;
