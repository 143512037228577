import { createValidator, required, email, minLength, matches } from 'lib/validation';

export default createValidator({
  firstName: [required],
  lastName: [required],
  email: [required, email],
  position: [required],
  username: [required],
  newPassword: [minLength(8)],
  newPasswordConfirmation: [minLength(8), matches('newPassword')]
});
