import React, { useState, useCallback } from 'react';

import { useDropzone } from 'react-dropzone'
import classNames from 'classnames';

import Spinner from 'components/Spinner';
import ValidationMessages from 'components/ValidationMessages';

type Props = {
  attach: (file: File) => Promise<any>;
  showAttached?: boolean;
  showSpinner?: boolean;
  children?: React.ReactNode;
  accept: string;
  disabled?: boolean;
};

const MediaUpload = ({ attach, showAttached = false, showSpinner = false, children, accept = '', disabled = false }: Props) => {
  const [attached, setAttached] = useState(false);
  const [attaching, setAttaching] = useState(false);

  const [error, setError] = useState<Error | null>(null);  

  const onDrop = useCallback(async (files) => {
    setAttached(false);
    setAttaching(true);
    setError(null);

    try {
      await attach(files[0]);
      setAttached(true);
    } catch (error) {
      setError(error);
    }

    setAttaching(false);
  }, [attach]);

  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    multiple: false,
    accept: accept,
    disabled: disabled || attaching,
    noClick: disabled || attaching
  });

  return (
    <div className="media-upload">
      {attached && showAttached && <div className="show-attached">Your file as been uploaded</div>}
      <div
        className={classNames('dropzone', { disabled: disabled, attaching, attached, })}
        {...getRootProps()}
      >
        <input {...getInputProps()} />
        {showSpinner && <Spinner show={attaching && !error} />}
        {children}
      </div>

      {error && (
        <ValidationMessages
          // @ts-ignore
          error={error.errors || error.message}
        />
      )}
    </div>
  );
};

export default MediaUpload;