import React from 'react';

import MenuItem from 'react-bootstrap/lib/MenuItem';
import Dropdown, { Toggle as DropdownToggle, Menu as DropdownMenu } from 'react-bootstrap/lib/Dropdown';
import { Text } from '@ethical-jobs/design-system-react';
import { User } from '@ethical-jobs/sdk-js/types';

import StaffAvatar from 'components/StaffAvatar';

type Props = {
  user: User
  logout: () => void;
};

const AdminMenu = ({ user, logout }: Props) => (
  <div className="user-menu">
    <ul className="nav navbar-nav navbar-right">
      <li id="profile-menu">
        <Dropdown id="profile-menu-dropdown">
          <DropdownToggle noCaret>
            <div style={{ display: 'flex', alignItems: 'center', padding: '5px 15px' }}>
              <StaffAvatar
                src={user.avatar}
                userId={user.id}
                name={user.name}
              />
              <Text style={{ padding: '0px 10px' }}>{user.firstName}</Text>
              <i className="material-icons">more_vert</i>
            </div>
          </DropdownToggle>
          <DropdownMenu>
            <MenuItem
              href="https://www.ethicaljobs.com.au"
              target="_blank"
              rel="noopener noreferrer"
            >
              Open EthicalJobs.com.au
            </MenuItem>
            <MenuItem onClick={logout}>Sign out</MenuItem>
          </DropdownMenu>
        </Dropdown>
      </li>
    </ul>
  </div>
);

export default AdminMenu;
