import React, { useCallback, useEffect, useRef, useState, useContext } from 'react';

import moment from 'moment';
import { useSelector } from 'react-redux';
import { Button, Text, ThemeContext } from '@ethical-jobs/design-system-react';
import { ApplicationNote } from '@ethical-jobs/sdk-js/types';

import StaffAvatar from 'components/StaffAvatar';

type Props = {
  note: ApplicationNote;
  updateNote: (noteId: string, note: string) => Promise<void>;
  deleteNote: (noteId: string) => Promise<void>;
};

const ApplicantNotes = ({ note, updateNote, deleteNote }: Props) => {
  const { designSystemReact: { colors } } = useContext(ThemeContext);

  const notesRef = useRef(null);

  // @ts-ignore
  const userId = useSelector(state => state.auth.user.id);

  const [inEditMode, setInEditMode] = useState(false);

  const updateNoteFn = useCallback(async () => {
    updateNote(note.id, notesRef.current.value);
    setInEditMode(false);
    notesRef.current.value = '';
  }, [updateNote, notesRef, note]);

  useEffect(() => {
    if (inEditMode) {
      notesRef.current.value = note.body;
    }
  }, [inEditMode, note]);

  return (
    <div key={note.id} style={{ padding: '10px 15px 5px 15px', display: 'flex', borderRadius: 5 }}>
      <div style={{ paddingRight: 30 }}>
        <StaffAvatar
          src={note.author.avatar}
          userId={note.author.id}
          name={note.author.name}
          width={50}
        />
      </div>
      <div style={{ flex: 1 }}>
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <Text style={{ fontWeight: 'bold', fontSize: 14 }}>{note.author.name}</Text>
          <Text style={{ fontSize: 14, color: '#999', paddingLeft: 5 }}>({moment(note.updatedAt).fromNow()})</Text>
        </div>
        {inEditMode ? (
          <div>
            <textarea
              ref={notesRef}
              placeholder="Add a note..."
              maxLength={5000}
              style={{
                width: '100%',
                minHeight: 120,
                borderRadius: 5,
                fontSize: 14,
                border: `1px solid ${colors.main.silverGrey}`,
                padding: '0px 10px',
                fontFamily: 'Open Sans'
              }}
            />
            <div style={{ display: 'flex', justifyContent: 'flex-end', paddingTop: 5 }}>
              <span style={{ paddingRight: 15 }}>
                <Button size="small" label="Cancel" type="cancel" onClick={() => setInEditMode(false)} />
              </span>
              <Button size="small" label="Update" type="primary" onClick={updateNoteFn} />
            </div>
          </div>
        ) : (
          <Text>{note.body}</Text>
        )}
        {!inEditMode && (
          <div style={{ display: 'flex', alignItems: 'center', paddingTop: 5 }}>
            {userId === note.author.id && (
              <span
                style={{ cursor: 'pointer', paddingRight: 15 }}
                onClick={() => setInEditMode(true)}>
                <Text style={{ fontSize: 12, color: colors.main.oceanBlue }}>Edit</Text>
              </span>
            )}
            <span
              style={{ cursor: 'pointer' }}
              onClick={() => deleteNote(note.id)}>
              <Text style={{ fontSize: 12, color: '#9e2146' }}>Delete</Text>
            </span>
          </div>
        )}
      </div>
      <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-end' }}>
        <Text style={{ fontSize: 14, color: '#999' }}>{moment(note.updatedAt).format('MMM Do @ HH:mm A')}</Text>
      </div>
    </div>
  );
}

export default ApplicantNotes;