import React from 'react';

import { Organisation } from '@ethical-jobs/sdk-js/types';

import Card from 'components/Card';

type Props = {
  organisations: Array<Organisation>;
};

const OrganisationList = ({ organisations }: Props) => (
  <Card>
    {organisations.length === 0 ? (
      <span>No organisations</span>
    ) : (
      <table className="organisations-list">
        <tbody>
          {organisations.map(organisation => (
            <tr key={organisation.id}>
              <td>{organisation.name}</td>
              <td>{organisation.uid}</td>
            </tr>
          ))}
        </tbody>
      </table>
    )}
  </Card>
);

export default OrganisationList;
