import { useState, useEffect } from 'react';
import _ from 'lodash';

const useMemoisedParam = <T>(param: T): T=> {
  const [memoisedParam, setMemoisedParam] = useState(param);
  
  useEffect(() => {
    if (!_.isEqual(param, memoisedParam)) {
        setMemoisedParam(param);
    }
  }, [param, memoisedParam]);

  return memoisedParam;
}

export default useMemoisedParam;