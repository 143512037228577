import React from 'react';

import _ from 'lodash';
import { useSelector, shallowEqual } from 'react-redux';

import Select from 'components/Select';

type Props = {
  enumerableType: string;
  onChange: (value: string) => void;
  placeholder?: string;
  value?: string;
};

const EnumerablesSelect = ({ enumerableType, onChange, placeholder, value }: Props) => {
  // @ts-ignore
  let options = useSelector(state => _.orderBy(_.map(_.get(state.taxonomies, [enumerableType], {}), (item, key) => ({ value: key, label: item })), item => item.label), shallowEqual);

  return (
    <div className="select-field enumberable-select">
      <Select
        options={options}
        value={value}
        onChange={({ value }) => onChange(value)}
        placeholder={placeholder || `Select a ${enumerableType}`}
      />
    </div>
  );
};

export default React.memo(EnumerablesSelect);
