import React from 'react';
import PageTitle from 'components/PageTitle';
import * as JobStatuses from 'lib/jobStatuses';

type Props = {
  jobType: string;
};

const JobCollectionPageTitle = ({ jobType }: Props) => {
  switch (jobType && jobType.toUpperCase()) {
    case 'EXPIRED':
      return <PageTitle title="Expired Job Ads" subTitle="Ads past their expiry date" />;
    case JobStatuses.APPROVED:
      return <PageTitle title="Live Jobs Ads" subTitle="Approved and publicly visible ads" />;
    case JobStatuses.PENDING:
      return <PageTitle title="Job Ads Pending Approval" subTitle="You will be notified when an ad is approved" />;
    default:
      return <PageTitle title="Job Ads" subTitle="Collection of all your job ads" />;
  }
};

export default JobCollectionPageTitle;
