import React, { useEffect, useContext, useState } from 'react';
import _ from 'lodash';
import { useSelector, shallowEqual } from 'react-redux';
import Tabs from 'react-bootstrap/lib/Tabs';
import Tab from 'react-bootstrap/lib/Tab';
import { Organisation, User, Invoice, CreditPack, Contact } from '@ethical-jobs/sdk-js/types';
import InvoicePurchaseForm from 'forms/InvoicePurchaseForm';
import CreditCardForm from 'forms/CreditCardForm';
import Card from 'components/Card';
import PurchaseTitle from 'components/PurchaseTitle';
import { APIContext } from 'lib/contexts';

const EMPTY_STATE = {
  id: "",
  name: "",
  email: "",
  phone: ""
};

type Props = {
  purchase: (values: Object) => Promise<Invoice>;
  creditPack: CreditPack;
  organisation: Organisation;
};

const AdminPurchaseOptions = ({ purchase, creditPack, organisation }: Props) => {
  const api = useContext(APIContext);
  const owner = organisation.owner as User;
  const [financeContact, setFinanceContact] = useState<Contact>(EMPTY_STATE);

  useEffect(() => {
    if (organisation.financeContactId) {
      api.getFinanceContact(organisation.financeContactId, organisation.id).then(setFinanceContact);
    }
  }, [organisation]);

  return (
    <Tabs defaultActiveKey={1} id="org-dashboard-tabs">
      <Tab eventKey={1} title="Credit Card" className="payment-method cc-method">
        <Card>
          <PurchaseTitle creditPack={creditPack} className="modal-title" />
          <CreditCardForm purchase={purchase} />
        </Card>
      </Tab>
      <Tab eventKey={2} title="Invoice Payment" className="payment-method invoice-method">
        <Card>
          <PurchaseTitle creditPack={creditPack} className="modal-title" />
          <InvoicePurchaseForm
            purchase={purchase}
            enableReinitialize
            initialValues={{
              name: `${owner.firstName} ${owner.lastName}`,
              position: owner.position,
              email: owner.email,
              organisationName: organisation.name
            }}
            financeContact={financeContact}
          />
        </Card>
      </Tab>
    </Tabs>
  );
};

export default AdminPurchaseOptions;