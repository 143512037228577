import React, { useState, useCallback, useContext, useEffect } from 'react';

import { useLocation } from 'react-router-dom';

import { APIContext, AmplitudeContext } from 'lib/contexts';

import PasswordReset from './PasswordReset';

const PasswordResetContainer = () => {
  const api = useContext(APIContext);
  const amplitude = useContext(AmplitudeContext);

  const { pathname } = useLocation();

  let splitPath = pathname.split(/\//g);

  // INFO: Needed because token can include / with messes up react router
  // e.g. /reset-password/$2y$10$LAXO/zlI8jJrInaWXlQsh.MkFpi.xMWD2IOFhzkF5Lz28Hu7.AhWi/JKCooper
  let token = splitPath.length >= 3 ? splitPath.slice(2, splitPath.length - 1).join('') : '';
  let username = splitPath.length >= 3 ? splitPath[splitPath.length - 1] : '';

  const [isSuccess, setIsSuccess] = useState(false);

  useEffect(() => {
    amplitude.logEvent('User', { type: 'Open Reset Password', token, username });
  }, [amplitude, token, username]);

  const resetPassword = useCallback(async ({ password, passwordConfirmation }) => {
    try {
      let user = await api.resetPassword(token, username, password, passwordConfirmation);
      setIsSuccess(true);
      amplitude.logEvent('API', { function: 'resetPassword', arguments: { token, username }});
      return user;
    } catch (error) {
      amplitude.logError('containers/PasswordResetContainer/index:resetPassword', error);

      // INFO: Must rethrow so form can handle
      throw error;
    }
  }, [token, username, api, amplitude]);

  return (
    <PasswordReset
      isSuccess={isSuccess}
      resetPassword={resetPassword}
      username={username}
      token={token}
    />
  );
};

export default React.memo(PasswordResetContainer);
