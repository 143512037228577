import React, { useMemo, useContext, useState } from 'react';

import * as QueryString from 'query-string';
import { useParams, useLocation } from 'react-router-dom';

import { Job } from '@ethical-jobs/sdk-js/types';

import { usePromise } from 'hooks';
import { APIContext } from 'lib/contexts';

import BaseJobPreview from 'containers/JobPreview';
import Container from 'components/Container';
import Spinner from 'components/Spinner';
import OrgDraftJobActions from 'components/JobActions/OrgDraftJobActions';
import OrgPreviewJobActions from 'components/JobActions/OrgPreviewJobActions';

import ActionToast from './ActionToast';

const JobPreview = () => {
  const api = useContext(APIContext);
  const { jobId } = useParams();
  const { search } = useLocation();

  const query = QueryString.parse(search);

  const [forceRefresh, setForceRefresh] = useState(0);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const getJob: Promise<Job | null> = useMemo(() => jobId !== undefined ? api.getJob(Number(jobId)) : Promise.resolve(null), [api, jobId, forceRefresh]);
  const [job, , isFetching] = usePromise(getJob, null, { component: 'JobPreview' });

  return (
    <Container className="organisation-job-preview form-horizontal">
      <Spinner show={isFetching} className="container" />
      {job && (
        <>
          <ActionToast
            // @ts-ignore
            action={query.action}
            expiresAt={job.expiresAt}
          />
          <BaseJobPreview
            job={job}
            successRoute="/organisation/job/success"
            updateRoute={`/organisation/job/${jobId}/update/previewed`}
            // @ts-ignore
            Actions={job.status === 'DRAFT' ? OrgDraftJobActions : OrgPreviewJobActions}
            showProgressBar={job.status === 'DRAFT'}
            reloadJob={() => setForceRefresh(Math.random())}
          />
        </>
      )}
    </Container>
  );
};

export default React.memo(JobPreview);
