import React, { useState, useCallback, useContext, useMemo } from 'react';

import _ from 'lodash';
import moment from 'moment';
import cogoToast from 'cogo-toast';
import StarRatings from 'react-star-ratings';
import { Tabs as RBTabs, Tab as RBTab } from 'react-bootstrap';

import { Applicant, UpdateApplicantParams, Attachment, JobQuestion } from '@ethical-jobs/sdk-js/types';

import { usePromise } from 'hooks';
import { APIContext, AmplitudeContext } from 'lib/contexts';

import Icon from 'components/Icon';
import Button from 'components/Button';
import ApplicantNotes from './ApplicantNotes';
import ApplicantAttachments from './ApplicantAttachments';
import Answers from 'components/Answers';
import ApplicantEmails from './ApplicantEmails';

const APPLICANT_STAGES = [
  'Inbox',
  'Shortlist',
  'Interview',
  'Offer',
  'Accept'
];

type Props = {
  applicant: Applicant;
  jobQuestions: Array<JobQuestion>;
  updateApplicant: (applicantId: string, params: UpdateApplicantParams) => Promise<Applicant>;
};

const ApplicantDetails = ({ applicant, updateApplicant, jobQuestions }: Props) => {
  const api = useContext(APIContext);
  const amplitude = useContext(AmplitudeContext);

  const [refresh, setRefresh] = useState(0);

  const getNotes = useMemo(() => api.getApplicationNotes(applicant.id), [applicant, api, refresh]);
  const [notes] = usePromise(getNotes, []);

  const getEmails = useMemo(() => api.getApplicantEmails(applicant.id), [applicant.id, api, refresh]);
  const [emails] = usePromise(getEmails, []);

  const getAttachments = useMemo(() => {
    try {
      return Promise.all(applicant.attachments.map(async (attachment: Attachment) => {
        let attachmentLinks = await api.getApplicationAttachmentLinks(applicant.id, attachment.id, moment().add(5, 'minutes').format('YYYY-MM-DD HH:mm:ss'));
        return {
          ...attachment,
          ...attachmentLinks
        };
      }));
    } catch (error) {
      amplitude.logError('containers/_organisation/ApplicantManagementJob:getAttachments', error);
    }
  }, [applicant, api, refresh, amplitude]);

  const [attachments] = usePromise(getAttachments, []);

  const saveNote = useCallback(async (note) => {
    try {
      await api.createApplicationNote(applicant.id, note);
      setRefresh(Math.random());
      cogoToast.success('Saved new note to applicant', { hideAfter: 3, position: 'top-right' });
    } catch (error) {
      amplitude.logError('containers/ApplicantManagementJob/ApplicantDetail:saveNote', error);
      cogoToast.error('Error saving note', { hideAfter: 3, position: 'top-right' });
    }
  }, [applicant, api, amplitude]);

  const deleteNote = useCallback(async (noteId) => {
    try {
      await api.deleteApplicationNote(applicant.id, noteId);
      setRefresh(Math.random());
      cogoToast.success('Successfully deleted note', { hideAfter: 3, position: 'top-right' });
      // @ts-ignore
      amplitude.logEvent('AMS', { type: 'Deleted Application Note', applicantId: applicant.id, noteId });
    } catch (error) {
      amplitude.logError('containers/ApplicantManagementJob/ApplicantDetail:deleteNote', error);
      cogoToast.error('Error deleting note', { hideAfter: 3, position: 'top-right' });
    }
  }, [applicant, api, amplitude]);

  const updateNote = useCallback(async (noteId, note) => {
    try {
      await api.updateApplicationNote(applicant.id, noteId, note);
      setRefresh(Math.random());
      cogoToast.success('Successfully update note', { hideAfter: 3, position: 'top-right' });
      // @ts-ignore
      amplitude.logEvent('AMS', { type: 'Updated Application Note', applicantId: applicant.id, noteId });
    } catch (error) {
      amplitude.logError('containers/ApplicantManagementJob/ApplicantDetail:deleteNote', error);
      cogoToast.error('Error deleting note', { hideAfter: 3, position: 'top-right' });
    }
  }, [applicant, api, amplitude]);

  return (
    <div style={{ backgroundColor: 'white', boxShadow: '0 4px 8px 0 rgba(0, 0, 0, 0.1)', borderRadius: 5 }}>
      <div style={{ padding: '25px 30px' }}>
        <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'flex-start' }}>
          <div>
            <p style={{ fontWeight: 'bold', marginBottom: 0, fontSize: 20 }}>{applicant.firstName} {applicant.lastName}</p>
            <p style={{ marginBottom: 0, color: 'grey', fontSize: 15, paddingRight: 10 }}>{`Submitted ${moment(applicant.createdAt).format('DD/MM/YYYY')}`}</p>
          </div>
          {applicant.stage !== 'Unsuitable' && (
            <div style={{ display: 'flex' }}>
              <Button
                kind="primary"
                onClick={() => updateApplicant(applicant.id, { stage: APPLICANT_STAGES[APPLICANT_STAGES.indexOf(applicant.stage) + 1] })}
                label={`Move to ${_.startCase(APPLICANT_STAGES[APPLICANT_STAGES.indexOf(applicant.stage) + 1])}`}
                size="small"
                icon="forward"
                iconPosition="right"
                style={{ marginRight: 10 }}
              />
              <Button
                kind="danger"
                onClick={() => updateApplicant(applicant.id, { stage: 'Unsuitable' })}
                label="Not Suitable"
                icon="delete_forever"
                iconPosition="right"
                size="small"
              />
            </div>
          )}
        </div>
        <div style={{ display: 'flex', paddingTop: 10, justifyContent: 'space-between' }}>
          <div>
            <div style={{ display: 'flex', paddingBottom: 10, alignItems: 'center' }}>
              <Icon type="person" style={{ color: '#3E8420', fontSize: 18 }} />
              <p style={{ marginBottom: 0, fontSize: 16, paddingLeft: 5, color: 'grey' }}>{`${applicant.recentRole} @ ${applicant.recentEmployer}`}</p>
            </div>
            <div style={{ display: 'flex', paddingBottom: 10, alignItems: 'center' }}>
              <Icon type="email" style={{ color: '#3E8420', fontSize: 18 }} />
              <a href={`mailto:${applicant.email}`} style={{ marginBottom: 0, fontSize: 16, paddingLeft: 5 }}>{applicant.email}</a>
            </div>
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <Icon type="call" style={{ color: '#3E8420', fontSize: 18 }} />
              <p style={{ marginBottom: 0, fontSize: 16, paddingLeft: 5, color: 'grey' }}>{applicant.phone}</p>
            </div>
          </div>

          <div style={{ display: 'flex' }}>
            <p style={{ marginBottom: 0, color: '#999', fontSize: 15, paddingRight: 10 }}>Rating</p>
            <StarRatings
              rating={applicant.rating || 0}
              starRatedColor="#FFC700"
              starEmptyColor="#DDD"
              changeRating={(newRating: number) => updateApplicant(applicant.id, { rating: newRating === applicant.rating ? 0 : newRating })}
              starDimension="18px"
              starSpacing="1px"
            />
          </div>
        </div>
      </div>

      <RBTabs defaultActiveKey={0} id="applicant-tabs">
        <RBTab eventKey={0} title="Documents">
          <ApplicantAttachments attachments={attachments} />
        </RBTab>
        {applicant.answers.length > 0 && (
          <RBTab eventKey={1} title="Answers">
            <Answers answers={applicant.answers} jobQuestions={jobQuestions} />
          </RBTab>
        )}
        <RBTab eventKey={2} title="Communication">
          <ApplicantEmails
            emails={emails}
          />
        </RBTab>
        <RBTab eventKey={3} title={`Notes (${notes.length})`}>
          <ApplicantNotes
            notes={notes}
            saveNote={saveNote}
            updateNote={updateNote}
            deleteNote={deleteNote}
          />
        </RBTab>
      </RBTabs>
    </div>
  );
};

export default ApplicantDetails;
