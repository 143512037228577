import React from 'react';
import env from 'lib/env';

const Footer = () => (
  <div className="footer-container">
    <ul>
      <li>
        <a
          href="https://www.ethicaljobs.com.au/advertisingtermsofuse"
          target="_blank"
          rel="noopener noreferrer"
        >
          Terms & Conditions
        </a>
      </li>
      <li>
        <a
          href="https://www.ethicaljobs.com.au/privacypolicy"
          target="_blank"
          rel="noopener noreferrer"
        >
          Privacy Policy
        </a>
      </li>
      <li>
        <a
          href="https://www.ethicaljobs.com.au/services"
          target="_blank"
          rel="noopener noreferrer"
        >
          Advertising Rates
        </a>
      </li>
      <li>
        <a
          href="http://nfppeople.com.au/"
          target="_blank"
          rel="noopener noreferrer"
        >
          Not-For-Profit People
        </a>
      </li>
      <li>
        <a
          href="https://www.ethicaljobs.com.au/advertise#testimonials"
          target="_blank"
          rel="noopener noreferrer"
        >
          Success Stories
        </a>
      </li>
      <li>
        <a
          href="https://www.ethicaljobs.com.au/?modal=contact"
          target="_blank"
          rel="noopener noreferrer"
        >
          Contact Us
        </a>
      </li>
    </ul>
    <div className="copy-left">
      <span>&copy;</span>
      {` Ethicaljobs.com.au. ABN 89 236 272 208. ${env.VERSION_TAG && `(version ${env.VERSION_TAG})`}`}
    </div>
  </div>
);

export default Footer;
