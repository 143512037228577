import React, { useState, useContext, useEffect } from 'react';

import { Route } from 'react-router-dom';

import { APIContext } from 'lib/contexts';

import Root from 'containers/_admin/Root';
import Dashboard from 'containers/_admin/Dashboard';
import RegisterOrganisation from 'containers/_admin/RegisterOrganisation';
import OrganisationCollection from 'containers/_admin/OrganisationCollection';
import JobCollection from 'containers/_admin/JobCollection';
import JobPreview from 'containers/_admin/JobPreview';
import JobUpdate from 'containers/_admin/JobUpdate';
import OrganisationUpdate from 'containers/_admin/OrganisationUpdate';
import Purchase from 'containers/_admin/Purchase';
import Payment from 'containers/_admin/Payment';
import InvoiceCollection from 'containers/_admin/InvoiceCollection';
import InvoiceUpdate from 'containers/_admin/InvoiceUpdate';
import StaffCollection from 'containers/_admin/StaffCollection';
import StaffRelationsManager from 'containers/_admin/StaffRelationsManager';
import Exporter from 'containers/_admin/Exporter';
import ActivityLog from 'containers/_admin/ActivityLog';
import BDRM from 'containers/_admin/BDRM';


const AdminRoutes = () => {
  const [isBDRM, setIsBDRM] = useState(false);

  const api = useContext(APIContext);

  useEffect(() => {
    const fn = async () => {
      let response = await api.doesUserHaveRole(['admin', 'relations-team']);
      setIsBDRM(response);
    };

    fn();
  }, [api]);

  return (
    <Route path="/admin">
      <Root>
        <Route path="/admin" exact component={Dashboard} />
        <Route path="/admin/organisation/create" component={RegisterOrganisation} />
        <Route path="/admin/organisations" component={OrganisationCollection} />
        <Route path="/admin/jobs" exact component={JobCollection} />
        <Route path="/admin/jobs/:status" component={JobCollection} />
        <Route path="/admin/job/:jobId/view" component={JobPreview} />
        <Route path="/admin/job/create" component={JobUpdate} />
        <Route path="/admin/job/:jobId/update" component={JobUpdate} />
        <Route path="/admin/organisation/:organisationId/view" component={OrganisationUpdate} />
        <Route path="/admin/purchase" component={Purchase} />
        <Route path="/admin/activities" component={ActivityLog} />
        <Route path="/admin/invoices" component={InvoiceCollection} />
        <Route path="/admin/invoice/:invoiceId/payment" component={Payment} />
        <Route path="/admin/invoice/:invoiceId/update" component={InvoiceUpdate} />
        <Route path="/admin/invoice/create" component={InvoiceUpdate} />
        <Route path="/admin/staff" exact component={StaffCollection} />
        <Route path="/admin/relations-manager" component={StaffRelationsManager} />
        <Route path="/admin/export" component={Exporter} />
        {isBDRM && (
          <Route path="/admin/bdrm" component={BDRM} />
        )}
      </Root>
    </Route>
  );
}

export default AdminRoutes;
