import React from 'react';
import classNames from 'classnames';

type Props = {
  children?: React.ReactNode;
  className?: string;
  title?: string;
  subTitle?: string | React.ReactNode;
  type?: string;
  required?: boolean;
};

const Card = ({ children, className, title, subTitle, type, required = false }: Props) => {
  const hasTitle = !!title || !!subTitle || false;

  const computedClasses = classNames('card', className, {
    'has-title': hasTitle,
    [`type-${type}`]: !!type,
    'no-children': !children
  });

  return (
    <div className={computedClasses}>
      <div className="inner">
        {hasTitle && (
          <div className="titles">
            {title && (
              <div className="title">
                {required && <span className="required">*</span>}
                {title}
              </div>
            )}
            {subTitle && <div className="sub-title">{subTitle}</div>}
          </div>
        )}
        {children && <div className="content">{children}</div>}
      </div>
    </div>
  );
};

export default Card;
