import React, { Fragment } from 'react';

import { OverlayTrigger, Tooltip } from 'react-bootstrap';

import Icon from 'components/Icon';

import FormField from 'fields/FormField';

import ApplyNowOption from './ApplyNowOption';
import ButtonOption from './ButtonOption';

type Props = {
  value: 'email' | 'button' | null;
  onChange: (method: 'email' | 'button' | null) => void;
  isAdmin: boolean;
}

const ApplyMethodSelector = ({ value, onChange, isAdmin }: Props) => (
  <Fragment>
    <div style={{ display: 'flex', paddingBottom: value ? 10 : 0, flexWrap: 'wrap' }}>
      <ApplyNowOption
        label="Receive job applications through EthicalJobs.com.au"
        isSelected={value === 'email'}
        onClick={() => onChange('email')}
        showIcon
      />
      <ApplyNowOption
        label="Receive job applications through another recruitment platform"
        isSelected={value === 'button'}
        onClick={() => onChange('button')}
      />
      {isAdmin && (
        <ApplyNowOption
          label="None"
          // INFO: For some reason null value comes through as empty string
          isSelected={!value}
          onClick={() => onChange(null)}
        />
      )}
    </div>

    {value === 'email' && (
      <FormField
        required={value === 'email'}
        name="applyEmail"
        component="input"
        placeholder="john.smith@gmail.com"
        description="You'll receive an email each time an application is submitted"
        style={{ width: 500, margin: 0 }}
        label={(
          <span>
            Send applications to
            <OverlayTrigger
              placement="top"
              overlay={(
                <Tooltip id="email-help">
                  If you list an email address, you’ll receive an email from noreply-apply@ethicaljobs.com.au containing each of your applications. After clicking the "Apply Now" button on your job ad, candidates will need to enter their details and upload their cover letter and resume before an email is sent to you.
                </Tooltip>
              )}
            >
              <span>
                <Icon type="help" style={{ paddingLeft: 5, fontSize: 20 }} />
              </span>
            </OverlayTrigger>
          </span>
        )}
      />
    )}
    {value === 'button' && (
      <ButtonOption />
    )}
  </Fragment>
);

export default ApplyMethodSelector;