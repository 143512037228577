import React, { Fragment } from 'react';

import { Text } from '@ethical-jobs/design-system-react';

import { ApplicationEmail } from '@ethical-jobs/sdk-js/types';

import ApplicantEmail from './ApplicantEmail';

type Props = {
  emails: Array<ApplicationEmail>;
};

const ApplicantEmails = ({ emails }: Props) => {
  return (
    <div style={{ padding: '25px 30px', backgroundColor: 'white' }}>
      {emails.length === 0 && (
        <Text>No e-mails sent yet</Text>
      )}
      {emails.map((email, i) => (
        <Fragment>
          <ApplicantEmail
            key={email.id}
            email={email}
          />
          {i !== (emails.length - 1) && (
            <div style={{ height: 1, width: '100%', backgroundColor: '#DDD', margin: '5px 15px' }} />
          )}
        </Fragment>
      ))}
    </div>
  );
}

export default ApplicantEmails;