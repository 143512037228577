
import React, { useCallback } from 'react';

import _ from 'lodash';
import { reduxForm } from 'redux-form';

import FormField from 'fields/FormField';
import Button from 'components/Button';

import submissionError from './submissionError';
import validation from './validation';

type Props = {
  resetPassword: (values: Object) => Promise<Object>;
  handleSubmit: any;
  error?: string | { invalidToken: boolean };
  submitting: boolean;
};

const PasswordResetForm = ({ submitting, handleSubmit, error, resetPassword }: Props) => {
  const submit = useCallback(async (values: { username: string, password: string, passwordConfirmation: string }) => {
    try {
      await resetPassword(values);
    } catch (error) {
      submissionError(error);
    }
  }, [resetPassword]);

  return (
    <form className="reset-form" onSubmit={handleSubmit(submit)}>
      <FormField name="token" component="input" type="hidden" />
      <FormField
        name="username"
        component="input"
        type="text"
        disabled
        label="Reset password for username:"
        wrapClassName="form-group"
      />
      <FormField
        name="password"
        component="input"
        type="password"
        label="Enter your new password"
        wrapClassName="form-group"
      />
      <FormField
        name="passwordConfirmation"
        component="input"
        type="password"
        label="Confirm your new password"
        wrapClassName="form-group"
      />
      {error && (
        <div>
          {_.has(error, ['invalidToken']) ? (
            <p className="text-danger">
              This password reset link has expired or has already been used, please <a href="/recover-password">request a new one</a>
            </p>
          ) : (
            <p className="text-danger">{error}</p>
          )}
        </div>
      )}
      <Button
        loading={submitting}
        label="Reset your password"
        kind="primary"
        type="submit"
        block
      />
    </form>
  );
};

export default reduxForm({
  form: 'PasswordResetForm',
  validate: validation
  // @ts-ignore
})(PasswordResetForm);
