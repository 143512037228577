import { createStore, applyMiddleware } from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension';
import { routerMiddleware } from 'connected-react-router';
import { createBrowserHistory } from 'history';
import thunk from 'redux-thunk';

import createRootReducer from 'store/reducer';

import EthicalJobsAPI from '@ethical-jobs/sdk-js';
import { Amplitude } from '@ethical-jobs/sdk-js/types/amplitude';

export const history = createBrowserHistory();

const configureStore = (initialState: Object, thunkExtraArgument: { api: EthicalJobsAPI, amplitude: Amplitude }) => {
  const middlewares = [
    routerMiddleware(history),
    thunk.withExtraArgument(thunkExtraArgument)
  ];

  return createStore(
    createRootReducer(history),
    initialState,
    composeWithDevTools(applyMiddleware(...middlewares))
  );
}

export default configureStore;
