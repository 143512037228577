import React, { useState, useCallback, useContext, useEffect } from 'react';

import _ from 'lodash';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { LinkContainer } from 'react-router-bootstrap';
import Bowser from 'bowser';

import { APIContext } from 'lib/contexts';
import { login } from 'actions/auth';

import LoginForm from 'forms/LoginForm';
import Logo from 'components/Logo';
import Card from 'components/Card';
import Button from 'components/Button';
import RecoverPasswordLink from './RecoverPasswordLink';
import * as QueryString from 'query-string';
import { getWindowDimensions } from 'lib/window';

const Login = () => {
  const dispatch = useDispatch();
  const history = useHistory();

  const api = useContext(APIContext);

  // @ts-ignore
  const user = useSelector(state => state.auth.user, shallowEqual);

  const [displayUnsupportedBrowser, setDisplayUnsupportedBrowser] = useState(false);

  useEffect(() => {
    try {
      let browserDetails = Bowser.parse(window.navigator.userAgent);

      if (browserDetails.browser.name === 'Internet Explorer') {
        setDisplayUnsupportedBrowser(true);
      }
    } catch (error) {}
  }, []);

  useEffect(() => {
    const fn = async () => {
      const isUserLoggedIn = await api.isUserLoggedIn();

      if (isUserLoggedIn) {
        let parsed = QueryString.parse(window.location.search);
        let isUserAStaffMember = await api.doesUserHaveRole('staff-member');
        let isUserAnEmployer = await api.doesUserHaveRole('employer-member');

        if (_.isString(parsed.redirect_to) && ((parsed.redirect_to.includes('admin') && isUserAStaffMember) || (parsed.redirect_to.includes('organisation') && isUserAnEmployer))) {
          // @ts-ignore
          return window.location = parsed.redirect_to;
        }

        
        return history.push(isUserAStaffMember ? '/admin' : '/organisation');
      }
    };

    fn();
  }, [user, api, history]);

  const loginFn = useCallback(async ({ username, password }) => {
    let response = await dispatch(login(username, password));

    // @ts-ignore
    if (response.error) throw new Error(response.error);
  }, [dispatch]);

  if (getWindowDimensions().width < 800) {
    history.push('mobile-unavailable');
    return null;
  }

  return (
    <div className="login-container auth-container container">
      <div className="row">
        <div className="col-md-offset-2 col-md-8">
          <Logo className="animated fadeInDown" />
          <Card
            title="Employer Sign In"
            subTitle="Welcome to the EthicalJobs.com.au employer portal. If you have any questions please contact us on (03) 9419 5979 – we'd love to hear from you! Otherwise please sign in to your existing account, or create a new account below"
            className="animated fadeIn"
          >
            {displayUnsupportedBrowser && (
              <div style={{ padding: 10, borderRadius: 10, marginBottom: 20, backgroundColor: '#575757' }}>
                <p style={{ fontSize: 16, color: 'white', marginBottom: 0, fontWeight: 'bold' }}>Warning:</p>
                <p style={{ fontSize: 16, color: 'white', marginBottom: 0 }}>Your browser is not officially supported which means some functionality may not display correctly. Please use recent versions of Chrome, Firefox, Safari or Edge to guarantee the best performance, and please contact us if you see any compatibility issues </p>
              </div>
            )}
            <LoginForm login={loginFn} />
            <RecoverPasswordLink />
          </Card>
          <h3 className="strike-through animated fadeIn">
            <span>&darr;</span>
          </h3>
          <Card
            title="First time using EthicalJobs.com.au?"
            subTitle="Join over 5,000 ethical organisations advertising with us. Create your new account:"
            className="animated fadeInUp"
          >
            <LinkContainer to="/organisation-signup">
              <Button kind="primary" label="Register Now &rarr;" block />
            </LinkContainer>
          </Card>
        </div>
      </div>
    </div>
  );
}

export default React.memo(Login);
