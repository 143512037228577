import React, { useCallback } from 'react';

import classNames from 'classnames';

type Props = {
  placeholder?: string;
  className?: string;
  value?: string;
  defaultValue?: string;
  filterKey: string;
  onChange: (values: { [key: string]: string }) => void;
};

const QueryFilter = ({ placeholder, className, value, defaultValue, filterKey, onChange }: Props) => {
  const onChangeFn = useCallback(({ target: { value }}) => onChange({ [filterKey]: value }), [onChange, filterKey]);

  return (
    <input
      type="text"
      className={classNames('form-control', className)}
      placeholder={placeholder}
      onChange={onChangeFn}
      value={value}
      defaultValue={defaultValue}
    />
  );
};

export default QueryFilter;