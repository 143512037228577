import React, { useState, useEffect } from 'react';

import moment from 'moment';
import { Select } from '@ethical-jobs/design-system-react';

import TimeField from 'fields/TimeField';
import DateField from 'fields/DateField';

import './DateTimeField.css';

type Props = {
  label?: string;
  value?: number | string | moment.Moment | Date | null;
  onChange: (value: string | null) => void;
  minDate?: number | string | moment.Moment | Date | null;
  maxDate?: number | string | moment.Moment | Date | null;
  dateFormat?: string;
  onFocus?: () => void;
  times?: Array<{ label: string, value: string }>;
}

const DateTimeField = ({ label, value, onChange, minDate, maxDate, dateFormat, times }: Props) => {
  const [date, setDate] = useState<string | null>(value ? moment(value).format('YYYY-MM-DD') : null);
  const [time, setTime] = useState<string | null>(value ? moment(value).format('h:mm A') : '5:00 PM');

  useEffect(() => {
    setDate(value ? moment(value).format('YYYY-MM-DD') : null);
    setTime(value ? moment(value).format('h:mm A') : '5:00 PM');
  }, [value]);

  useEffect(() => {
    if (date === null) {
      onChange(null);
    } else {
      onChange(moment(`${date} ${time}`, 'YYYY-MM-DD h:mm A').format('YYYY-MM-DD HH:mm:ss'));
    }
  }, [onChange, date, time]);

  return (
    <div className="datetime-field row">
      <label>{label}</label>
      <div className="col-md-6 date">
        <DateField
          minDate={minDate}
          maxDate={maxDate}
          onChange={setDate}
          value={date}
          dateFormat={dateFormat}
        />
      </div>
      {times ? (
        <div className="col-md-4 time">
          <Select
            placeholder="Select Time"
            options={times}
            value={time}
            onChange={({ value }) => setTime(value)}
            isClearable={false}
          />
        </div>
      ) : (
        <div className="col-md-6 time">
          <TimeField
            onChange={setTime}
            value={time}
          />
        </div>
      )}
    </div>
  );
};

export default DateTimeField;
