import React, { useState, useCallback, useContext } from 'react';

import { Applicant } from '@ethical-jobs/sdk-js/types';

import { FormControl } from 'react-bootstrap';

import { APIContext, AmplitudeContext } from 'lib/contexts';
import Button from 'components/Button';

import EditableField from 'fields/EditableField';

type Props = {
  jobId: number;
  applicants: Array<Applicant>;
}

const EmailApplicants = ({ applicants, jobId }: Props) => {
  const api = useContext(APIContext);
  const amplitude = useContext(AmplitudeContext);

  const [subject, setSubject] = useState('');
  const [receiveReplies, setReceiveReplies] = useState(false);
  const [replyTo, setReplyTo] = useState('');
  const [body, setBody] = useState('');

  const sendEmail = useCallback(async () => {
    try {
      await api.sendApplicantEmail(jobId, applicants.map(applicant => applicant.id), subject, body, receiveReplies ? replyTo : undefined);
    } catch (error) {
      amplitude.logError('containers/_organisation/ApplicantManagementJob/EmailApplicants:sendEmail', error);
    }
  }, [api, jobId, applicants, subject, body, receiveReplies, replyTo, amplitude]);

  return (
    <div style={{ backgroundColor: 'white', borderRadius: 5, borderStyle: 'solid', borderWidth: 1, borderColor: '#DDD', boxShadow: '0 4px 8px 0 rgba(0, 0, 0, 0.1)' }}>
      <div style={{ display: 'flex', padding: '20px 25px', justifyContent: 'space-between', alignItems: 'center', borderStyle: 'solid', borderColor: '#DDD', borderWidth: 0, borderBottomWidth: 1 }}>
        <p style={{ fontSize: 24, fontWeight: 'bold', marginBottom: 0 }}>
          {applicants.length !== 1 ? 'Bulk email applicants' : `E-mail ${applicants[0].firstName} ${applicants[0].lastName}`}
        </p>
      </div>
      <div style={{ padding: '20px 25px', backgroundColor: '#EEEFF4' }}>
        {applicants.length === 1 && (
          <div>
            <label style={{ fontSize: 14, fontWeight: 'normal' }}>To</label>
            <FormControl
              type="text"
              value={applicants[0].email}
              onChange={() => null}
              disabled
            />
          </div>
        )}

        <span style={{ marginBottom: 5 }} onClick={() => setReceiveReplies(!receiveReplies)}>
          <input
            type="checkbox"
            checked={receiveReplies}
            onChange={({ target: { checked } }) => setReceiveReplies(checked)}
            style={{ marginRight: 10 }}
          />
          <label style={{ fontSize: 14, fontWeight: 'normal', cursor: 'pointer' }}>I would like to receive replies</label>
        </span>

        {receiveReplies && (
          <div>
            <label style={{ fontSize: 14, fontWeight: 'normal' }}>Reply To</label>
            <FormControl
              type="text"
              value={replyTo}
              // @ts-ignore
              onChange={({ target: { value } }) => setReplyTo(value)}
            />
          </div>
        )}

        <div>
          <label style={{ fontSize: 14, fontWeight: 'normal' }}>Email Subject</label>
          <FormControl
            type="text"
            value={subject}
            // @ts-ignore
            onChange={({ target: { value } }) => setSubject(value)}
          />
        </div>

        <div>
          <label style={{ fontSize: 14, fontWeight: 'normal' }}>Email Body</label>
          <EditableField
            id="emailBody"
            onChange={setBody}
            onBlur={() => null}
          />
        </div>

        <div style={{ paddingTop: 15, display: 'flex', justifyContent: 'flex-end' }}>
          <Button
            label={applicants.length !== 1 ? 'Send bulk e-mail' : 'Send e-mail'}
            onClick={sendEmail}
            kind="info"
          />
        </div>
      </div>
    </div>
  );
};

export default EmailApplicants;