import React from 'react';
import { sumArrayValues } from 'lib/arrays';

const renderColumns = (columns: { [key: string]: string }) => Object.entries(columns).map((column, index) => <td key={index}>{column[1]}</td>);

const renderRow = (row: { [key: string]: string } | Array<{ [key: string]: string }>, index: number) => {
  if (Array.isArray(row)) {
    return <tr key={index}>{row.map(columns => renderColumns(columns))}</tr>;
  }
  return <tr key={index}>{renderColumns(row)}</tr>;
}

const renderHeader = (header: string, index: number) => <th key={index}>{header}</th>;

const renderFooter = (rows: Array<{ [key: string]: string } | Array<{ [key: string]: string }>>) => sumArrayValues(rows).map((value, index) => <td key={index}>{value}</td>);

type Props = {
  rows: Array<{ [key: string]: string } | Array<{ [key: string]: string }>>;
  headers: Array<string>;
  showTotals?: boolean;
};

const Table = ({ rows, headers, showTotals = false }: Props) => (
  <table>
    <thead>
      <tr>
        {(headers || []).map((header, index) => renderHeader(header, index))}
      </tr>
    </thead>
    <tbody>
      {rows.map((row, index) => renderRow(row, index))}
    </tbody>
    {showTotals && (
      <tfoot>
        <tr>
          {renderFooter(rows)}
        </tr>
      </tfoot>
    )}
  </table>
);

export default Table;
