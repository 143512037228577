import React, { useCallback } from 'react';

import { reduxForm } from 'redux-form';
import submissionError from 'lib/submissionError';
import validation from './validation';
import Address from './Address';
import Details from './Details';
import ValidationMessages from 'components/ValidationMessages';
import Button from 'components/Button';

type Props = {
  action: (values: Object) => Promise<Object>;
  handleSubmit: any;
  error?: string;
  submitting: boolean;
};

const OrganisationProfileForm = ({ action, handleSubmit, error, submitting }: Props) => {
  const submitForm = useCallback(async (values) => {
    try {
      await action(values);
    } catch (error) {
      submissionError(error);
    }
  }, [action]);

  return (
    <form className="organisation-profile-form form-horizontal" onSubmit={handleSubmit(submitForm)}>
      <Details />
      <Address />
      <ValidationMessages error={error} />
      <Button
        kind="primary"
        type="submit"
        loading={submitting}
        icon="done_all"
        label="Update Organisation Profile"
        block
      />
    </form>
  );
}

export default reduxForm({
  form: 'organisationProfileForm',
  validate: validation
  // @ts-ignore
})(OrganisationProfileForm);
