import React, { useCallback, useState } from 'react';

import { CreditPack, Invoice } from '@ethical-jobs/sdk-js/types';

import Container from 'components/Container';
import Card from 'components/Card';
import PageTitle from 'components/PageTitle';
import PricingTable from 'components/PricingTable';
import PricingInfo from 'components/PricingInfo';
import PurchaseTitle from 'components/PurchaseTitle';
import Modal from 'components/Modal';
import OrganisationPurchaseOptions from 'components/OrganisationPurchaseOptions';
import Welcome from 'components/Welcome';

type Props = {
  showWelcome: boolean;
  purchase: (selectedCreditPack: CreditPack, method: 'invoice' | 'credit_card', params: Object) => Promise<Invoice>;
}

const Purchase = ({ showWelcome, purchase }: Props) => {
  const [displayModal, setDisplayModal] = useState(false);

  // @ts-ignore
  const [selectedCreditPack, setSelectedCreditPack] = useState<CreditPack | null>(null);

  const selectPack = useCallback((creditPack: CreditPack) => {
    setSelectedCreditPack(creditPack);
    setDisplayModal(true);
  }, []);

  const purchaseFn = useCallback(({ method, ...params }) => purchase(selectedCreditPack, method, params), [selectedCreditPack, purchase]);

  return (
    <Container className="purchase-container">
      <PageTitle
        title="Purchase Job Ads"
        subTitle="Reach over 230,000 ethical job-seekers each month. Please select the job ad package you'd like:"
      />
      <Card>
        <PricingTable selectPack={selectPack} />
      </Card>
      <PricingInfo />
      <Modal show={displayModal} onHide={() => setDisplayModal(false)} className="purchase-modal">
        {selectedCreditPack && (
          <PurchaseTitle creditPack={selectedCreditPack} className="modal-title" />
        )}
        <OrganisationPurchaseOptions purchase={purchaseFn} />
      </Modal>
      {showWelcome && (
        <Welcome
          buttonText="I’m ready to post a job"
          description={`To post your first ad you’ll first need to choose how many Job Ad Credits you want from this page. You can then post your first job ad. If you need any assistance along the way, just click the "Help" button at the top of your screen and we&apos;ll be more than happy to help out.`}
          title="Thanks for successfully creating your new account on EthicalJobs.com.au!"
        />
      )}
    </Container>
  );
}

export default Purchase;
