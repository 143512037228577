/**
 * Faster version of Number.prototype.mod
 * https://jsperf.com/negative-modulo/2
 * */
export const mod = (n: number, m: number) => {
  return ((n % m) + m) % m;
};

export const isNumeric = (value: any) => {
  return !isNaN(parseFloat(value)) && isFinite(value);
};
