import React, { useEffect } from 'react';

import { useDispatch } from 'react-redux';
import { LinkContainer } from 'react-router-bootstrap';

import { loadUserDetails } from 'actions/auth';

import Container from 'components/Container';
import Button from 'components/Button';
import JobAdStepProgressBar from 'components/JobAdStepProgressBar';
import Card from 'components/Card';

export const JobSuccess = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(loadUserDetails());
  }, [dispatch]);
 
  return (
    <Container className="job-success-container">
      <JobAdStepProgressBar activeStep={3}/>
      <Card className="center" title="Success!" subTitle="Thanks for posting your job ad to EthicalJobs.com.au">
        <p>You'll receive an email notification as soon as your job ad is live on the site. All job ads are approved manually by the EthicalJobs.com.au team, generally within two business hours (9am to 5pm Melbourne time, Monday to Friday). Any jobs posted outside of these business hours will be approved as soon as we're back in the office.</p>
        <p>If you need to make any changes to your job ad or require any other assistance, please don't hesitate to contact us on <a href="tel:0394195979">(03) 9419 5979</a> or <a href="mailto:info@ethicaljobs.com.au">info@ethicaljobs.com.au</a></p>
        <br />
        <div className="row">
          <div className="col-md-offset-2 col-md-3">
            <LinkContainer to="/organisation/job/create">
              <Button kind="primary" label="Post another job ad" />
            </LinkContainer>
          </div>
          <div className="col-md-offset-2 col-md-3">
            <LinkContainer to="/organisation/jobs/pending">
              <Button kind="primary" label="View pending job ads" />
            </LinkContainer>
          </div>
        </div>
      </Card>
    </Container>
  );
};

export default React.memo(JobSuccess);
