import createReducer from 'lib/createReducer';

export const initialState = {
  organisations: {}
};

const organisations = createReducer(initialState, {
  'LOAD_ORGANISATIONS': (state: Object, action: { type: string, payload: Object }) => ({ ...state, organisations: action.payload }),
});

export default organisations;