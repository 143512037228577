import React, {useContext, useCallback, useState, useRef } from 'react';

import Papa from 'papaparse';

import { Box, Row } from '@ethical-jobs/design-system-react';
import { APIContext } from 'lib/contexts';

import Card from 'components/Card';
import PageTitle from 'components/PageTitle';
import Button from 'components/Button';

const BDRM = () => {
  const api = useContext(APIContext);

  const unusedCreditsRef = useRef(null);
  const lowCreditsRef = useRef(null);

  const [loadingUnusedCredits, setLoadingUnusedCredits] = useState(false);
  const [loadingLowCredits, setLoadingLowCredits] = useState(false);
  const [unusedCreditsLink, setUnusedCreditsLink] = useState('');
  const [lowCreditsLink, setLowCreditsLink] = useState('');

  const getUnusedCredits = useCallback(async () => {
    setLoadingUnusedCredits(true);
    let orgList = await api.exportUnusedCredits();
    let csv = Papa.unparse(orgList, {
      columns: ['id', 'name', 'uid', 'state', 'suburb', 'adminId', 'adminName', 'numCredits', 'numJobs6Months', 'numJobs12Months']
    });

    let blob = new Blob([csv], { type: 'text/csv;charset=utf-8;' });
    setUnusedCreditsLink(window.URL.createObjectURL(blob));
    setLoadingUnusedCredits(false);

    setTimeout(() => {
      unusedCreditsRef.current.click();
    }, 500);
  }, [api]);

  const getLowCredits = useCallback(async () => {
    setLoadingLowCredits(true);
    let orgList = await api.exportLowCredits();
    let csv = Papa.unparse(orgList, {
      columns: ['id', 'name', 'uid', 'state', 'suburb', 'adminId', 'adminName', 'numCredits', 'numJobs12Months']
    });

    let blob = new Blob([csv], { type: 'text/csv;charset=utf-8;' });
    setLowCreditsLink(window.URL.createObjectURL(blob));
    setLoadingLowCredits(false);

    setTimeout(() => {
      lowCreditsRef.current.click();
    }, 500);
  }, [api]);

  return (
    <Box padding="large">
      <PageTitle title="BDRM" subTitle="Finally some data" />
      <Card title="CSV Exports">
        <Row>
          <Box>
            <Button
              label="Orgs With Unused Credits"
              kind="primary"
              onClick={getUnusedCredits}
              loading={loadingUnusedCredits}
              disabled={loadingUnusedCredits}
            />
            <p>
              An export of orgs that:
              <ul>
                <li>Aren't archived</li>
                <li>Have credits</li>
                <li>Haven't posted a job since August 2019</li>
              </ul>
            </p>
            {/* eslint-disable-next-line */}
            <a ref={unusedCreditsRef} href={unusedCreditsLink} target="_blank" download="unusedCredits.csv" rel="noopener noreferrer" />
          </Box>
          <Box>
            <Button
              label="Orgs With Low Credits"
              kind="primary"
              onClick={getLowCredits}
              loading={loadingLowCredits}
              disabled={loadingLowCredits}
            />
            <p>
              An export of orgs that:
              <ul>
                <li>Have less than 3 credits</li>
                <li>Have purchase a 5 pack or more since Feb 2019</li>
              </ul>
            </p>
            {/* eslint-disable-next-line */}
            <a ref={lowCreditsRef} href={lowCreditsLink} target="_blank" download="lowCredits.csv" rel="noopener noreferrer" />
          </Box>
        </Row>
      </Card>
    </Box>
  );
};

export default BDRM;
