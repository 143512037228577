import React, { useState } from 'react';

import { useSelector, shallowEqual } from 'react-redux';

import { isUserOrgOwner } from 'selectors/auth';

import Container from 'components/Container';
import Card from 'components/Card';
import InvitationForm from 'forms/InvitationForm';
import PageTitle from 'components/PageTitle';
import UserList from 'components/UserList';

const UserCollection = () => {
  const [forceRefresh, setForceRefresh] = useState(0);

  // @ts-ignore
  const organisation = useSelector(state => state.auth.user.organisation, shallowEqual);
  const isOwner = useSelector(isUserOrgOwner);

  return (
    <Container>
      <PageTitle title="Users" />
      {isOwner && (
        <div className="invitation-create-container">
          <Card
            title="Invite someone to join your organisation"
            subTitle="The new user will have the ability to buy credits and post job ads for this organisation."
          >
            <InvitationForm
              onSubmit={() => setForceRefresh(Math.random())}
              organisationId={organisation.id}
            />
          </Card>
        </div>
      )}
      <UserList
        organisationId={organisation.id}
        forceRefresh={forceRefresh}
        reloadUsers={() => setForceRefresh(Math.random())}
      />
    </Container>
  );
};

export default React.memo(UserCollection);