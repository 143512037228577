import 'magic-input/dist/magic-input.min.css';
import React from 'react';
import styled from 'styled-components';

// TODO: Remove !important flags after we deal with global styles issue
const StyledInput = styled.input`
  outline: none !important;
  width: 20px !important;
  height: 20px !important;
  
  &.mgc-lg:checked:after {
    height: 6px;
    width: 12px;
    top: 4px;
  }
  
  &.mgc:focus {
    box-shadow: none;
  }
`;

type Props = {
  checked: boolean;
  onChange: (value: any) => void;
  onBlur: () => void;
  disabled?: boolean;
};

const Checkbox = ({ checked, onChange, onBlur, disabled = false }: Props) => (
  <StyledInput
    className="mgc mgc-success mgc-lg"
    checked={checked}
    data-testid="checkbox-input"
    onChange={onChange}
    onBlur={onBlur}
    disabled={disabled}
    type="checkbox"
  />
);

export default Checkbox;
