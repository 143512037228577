import React from 'react';
import Card from 'components/Card';
import Spinner from 'components/Spinner';
import Button from 'components/Button';

type Props = {
  fetching: boolean;
  action: (choice: string) => Promise<Object>;
};

const OrgDraftJobActions = ({ fetching, action }: Props) => (
  <Card className="job-actions organisation-draft">
    <div className="actions">
      <Button
        kind="default"
        disabled={fetching}
        onClick={() => action('edit')}
        label="Continue editing"
        icon="undo"
      />
      <Button
        kind="primary"
        disabled={fetching}
        onClick={() => action('pending')}
        label="Submit for approval"
      />
    </div>
    <Spinner show={fetching} />
  </Card>
);

OrgDraftJobActions.displayName = 'OrgDraftJobActions';

export default OrgDraftJobActions;
