import styled from 'styled-components';

export const JobRowContainer = styled.div`
  background-color: white;
  padding: 15px;
  margin-top: 10px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
  position: relative;
`;

export const FeaturedTagContainer = styled.div`
  position: absolute;
  top: 10px;
  right: -10px;
  background-color: #0079c1;
  padding: 3px 10px;
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
`;

export const FeaturedTagCorner = styled.div`
  position: absolute;
  top: 35px;
  right: -10px;
  width: 0;
  height: 0;
  border-top: 10px solid #004E85;
  border-right: 10px solid transparent;
`