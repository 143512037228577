import React, { useMemo, useContext, useEffect, useState } from 'react';

import { useParams } from 'react-router-dom';

import { APIContext } from 'lib/contexts';
import { usePromise } from 'hooks';

import BaseJobPreview from 'containers/JobPreview';
import PageTitle from 'components/PageTitle';
import AdminJobActions from 'components/JobActions/AdminJobActions';
import Spinner from 'components/Spinner';

const JobPreview = () => {
  const api = useContext(APIContext);

  const [forceRefresh, setForceRefresh] = useState(0);

  const params: { jobId?: string } = useParams();
  const jobId = params.jobId ? Number(params.jobId) : undefined;

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const getJob = useMemo(() => jobId ? api.getJob(jobId) : Promise.resolve(null), [api, jobId, forceRefresh]);
  const [job, , isFetching] = usePromise(getJob, null, { component: 'JobPreview' });

  useEffect(() => {
    try {
      if (typeof window !== 'undefined') window.scrollTo(0, 0);
    } catch (error) {}
  }, []);

  return (
    <div className="admin-job-preview form-horizontal">
      <PageTitle title="Job ad preview" subTitle="Job ad information and preview" />
      <Spinner show={isFetching} className="container" />
      {job && (
        <BaseJobPreview
          job={job}
          updateRoute={`/admin/job/${job.id}/update`}
          // @ts-ignore
          Actions={AdminJobActions}
          showProgressBar={false}
          reloadJob={() => setForceRefresh(Math.random())}
        />
      )}
    </div>
  );
}

export default React.memo(JobPreview);
