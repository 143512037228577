import React, { useState, useEffect, useContext } from 'react';
import _ from 'lodash';
import { useSelector, shallowEqual } from 'react-redux';
import { Invoice, Contact } from '@ethical-jobs/sdk-js/types';
import CreditCardForm from 'forms/CreditCardForm';
import InvoicePurchaseForm from 'forms/InvoicePurchaseForm';
import { APIContext } from 'lib/contexts';

const EMPTY_STATE = {
  id: "",
  name: "",
  email: "",
  phone: ""
};

type Props = {
  purchase: (values: Object) => Promise<Invoice>;
};

const OrganisationPurchaseOptions = ({ purchase }: Props) => {
  const api = useContext(APIContext);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [financeContact, setFinanceContact] = useState<Contact>(EMPTY_STATE);

  const invoiceSubmitting = useSelector(state => _.get(state, ['form', 'invoicePurchaseForm', 'submitting'], false), shallowEqual);
  // @ts-ignore
  const owner = useSelector(state => state.auth.user, shallowEqual);

  useEffect(() => {
    if (_.has(owner, 'organisation.financeContactId') && !_.isEmpty(owner.organisation.financeContactId)) {
      const financeContactId = _.get(owner.organisation, 'financeContactId') as string;
      api.getFinanceContact(financeContactId, owner.organisation.id).then(c => setFinanceContact(c));
    }
  }, [purchase]);

  return (
    <div>
      <div className="payment-method cc-method">
        <h3>Pay via Credit Card</h3>
        <CreditCardForm
          disabled={invoiceSubmitting}
          purchase={purchase}
          onSubmittingChange={setIsSubmitting}
        />
      </div>
      <h3 className="strike-through">OR</h3>
      <div className="payment-method invoice-method">
        <h3>Pay via Invoice</h3>
        <InvoicePurchaseForm
          disabled={isSubmitting}
          purchase={purchase}
          initialValues={{
            name: `${owner.firstName} ${owner.lastName}`,
            position: owner.position,
            email: owner.email,
            organisationName: _.get(owner, ['organisation', 'name'], '')
          }}
          financeContact={financeContact}
        />
      </div>
    </div>
  );
};

export default React.memo(OrganisationPurchaseOptions);
