import React from 'react';

type Props = {
  title: string;
  subTitle?: string;
};

const PageTitle = ({ title, subTitle = "" }: Props) => (
  <div className="page-title">
    {title && <h2>{title}</h2>}
    {subTitle && <h3>{subTitle}</h3>}
  </div>
);

export default PageTitle;
