import React, { useCallback, useRef, useContext } from 'react';

import { Button, ThemeContext } from '@ethical-jobs/design-system-react';
import { ApplicationNote } from '@ethical-jobs/sdk-js/types';

import ApplicantNote from './ApplicantNote';

type Props = {
  notes: Array<ApplicationNote>;
  saveNote: (note: string) => Promise<void>;
  updateNote: (noteId: string, note: string) => Promise<void>;
  deleteNote: (noteId: String) => Promise<void>;
};

const ApplicantNotes = ({ notes, saveNote, updateNote, deleteNote }: Props) => {
  const { designSystemReact: { colors } } = useContext(ThemeContext);

  const notesRef = useRef(null);

  const saveNoteFn = useCallback(async () => {
    saveNote(notesRef.current.value);
    notesRef.current.value = '';
  }, [saveNote, notesRef]);

  return (
    <div style={{ padding: '25px 30px', backgroundColor: 'white' }}>
      <div style={{ padding: '0px 15px' }}>
        <textarea
          ref={notesRef}
          placeholder="Add a note..."
          maxLength={5000}
          style={{
            width: '100%',
            minHeight: 80,
            borderRadius: 5,
            border: `1px solid ${colors.main.silverGrey}`,
            padding: '5px 10px',
            fontFamily: 'Open Sans'
          }}
        />
        <div style={{ display: 'flex', justifyContent: 'flex-end', paddingTop: 5 }}>
          <Button size="small" label="Save" type="primary" onClick={saveNoteFn} />
        </div>
      </div>
      {notes.map(note => (
        <ApplicantNote
          key={note.id}
          note={note}
          updateNote={updateNote}
          deleteNote={deleteNote}
        />
      ))}
    </div>
  );
}

export default ApplicantNotes;