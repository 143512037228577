import React from 'react';
import Card from 'components/Card';
import FormField from 'fields/FormField';
import EnumerablesSelect from 'fields/EnumerablesSelect';

type Props = {
  isUpdate: boolean;
};

const OrganisationFields = ({ isUpdate }: Props) => (
  <Card subTitle={!isUpdate ? 'When creating an invoice, empty fields below are populated with data from the selected organisation.' : ''}>
    <div className="row">
      <FormField
        name="organisationName"
        component="input"
        type="text"
        label="Organisation name"
        placeholder="Invoiced organisation name"
        wrapClassName="col-md-12"
        required={isUpdate}
      />
      <FormField
        name="name"
        component="input"
        type="text"
        label="Contact name"
        placeholder="First and last name"
        wrapClassName="col-md-6"
        required={isUpdate}
      />
      <FormField
        name="position"
        component="input"
        type="text"
        label="Role / Position"
        placeholder="Role in organisation"
        wrapClassName="col-md-6"
        required={isUpdate}
      />
      <FormField
        name="email"
        component="input"
        type="text"
        label="Email address"
        placeholder="Enter a valid email address"
        wrapClassName="col-md-6"
        required={isUpdate}
      />
      <FormField
        name="phone"
        component="input"
        type="text"
        label="Phone number"
        placeholder="Phone number with area code"
        wrapClassName="col-md-6"
        required={isUpdate}
      />
      <FormField
        name="address"
        component="input"
        type="text"
        label="Address"
        placeholder="Street number and address"
        wrapClassName="col-md-12"
        required={isUpdate}
      />
      <FormField
        name="suburb"
        component="input"
        type="text"
        label="Suburb"
        placeholder="Suburb or town"
        wrapClassName="col-md-6"
        required={isUpdate}
      />
      <FormField
        name="state"
        component="input"
        type="text"
        label="State"
        placeholder="Complete state name"
        wrapClassName="col-md-6"
        required={isUpdate}
      />
      <FormField
        name="postcode"
        component="input"
        type="text"
        label="Postcode"
        placeholder="Postcode"
        wrapClassName="col-md-6"
        required={isUpdate}
      />
      <FormField
        name="country"
        component={EnumerablesSelect}
        enumerableType="countries"
        label="Country"
        wrapClassName="col-md-6"
        required
      />
    </div>
    <div className="row">
      <FormField
        name="description"
        component="input"
        label="Order number / Invoice description"
        wrapClassName="col-md-6"
      />
    </div>
  </Card>
);

export default OrganisationFields;
