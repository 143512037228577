import React, { useCallback } from 'react';

import _ from 'lodash';
import { Editor } from '@tinymce/tinymce-react';

import env from 'lib/env';

type Props = {
  id: string;
  onBlur: () => void;
  onChange: (value: string) => void;
  quickLinks?: Array<Object>;
  templates?: Array<Object>;
  value?: string;
};

const EditableField = ({ onBlur, onChange, quickLinks = [], templates = [], value, id }: Props) => {
  const onBlurFn = useCallback(() => {
    if (_.isFunction(onBlur)) onBlur();
    // @ts-ignore
    // eslint-disable-next-line no-undef
    onChange(tinymce.get(id) ? tinymce.get(id).getContent() : '');
  }, [onBlur, onChange, id]);

  return (
    <Editor
      key={JSON.stringify({ quickLinks, templates })} // when config changes re-initialise tinyMCE
      id={id}
      initialValue={value}
      onBlur={onBlurFn}
      init={{
        plugins: 'link image lists code template autolink',
        toolbar: 'undo redo | formatselect bullist numlist indent outdent | bold italic alignleft aligncenter | link image code | template',
        block_formats: 'Paragraph=p;Heading 3=h3',
        menubar: false,
        resize: true,
        min_height: 290,
        browser_spellcheck: true,
        body_class: 'editable-field-content',
        styles: ['Heading|h2', 'Subheading|h3'],
        valid_elements: 'h1,h2,h3,h4,h5,li,ul,ol,a[href|target=_blank],strong/b,div[align],p[align|style],em/i,br,span,img[*],iframe[*]',
        keep_styles: false,
        content_css: `${env.EJ_CDN}/statics/editor_content.css`,
        powerpaste_word_import: 'clean',
        external_plugins: {
          powerpaste: `${env.EJ_CDN}/assets/web-employers/powerpaste/plugin.min.js`
        },
        link_list: quickLinks,
        templates: templates
      }}
    />
  );
};

export default EditableField;