import React from 'react';

import StatesSelectField from 'fields/StatesSelectField';

type Props = {
  filters: {
    state?: string;
  };
  updateFilters: (filters: { state: string }) => void;
};

const StatesFilter = ({ updateFilters, filters }: Props) => (
  <div className="filter-component">
    <StatesSelectField
      value={filters.state}
      onChange={state => updateFilters({ state })}
      placeholder="Filter by state"
    />
  </div>
);

export default StatesFilter;
