import React, { useCallback, useContext, useMemo } from 'react';
import { useHistory } from 'react-router';

import { Job, User } from '@ethical-jobs/sdk-js/types';
import { Switch } from '@ethical-jobs/design-system-react';

import Card from 'components/Card';
import JobInfo from 'components/JobInfo';
import Spinner from 'components/Spinner';
import Button from 'components/Button';
import JobComments from 'components/JobComments';
import { usePromise } from 'hooks';
import { APIContext } from 'lib/contexts';

type Props = {
  fetching: boolean;
  action: (choice: string) => Promise<Job>;
  currentUser: User;
  author: User;
  job: Job;
};

const AdminJobActions = ({ fetching, action, job, currentUser, author }: Props) => {
  const history = useHistory();
  const api = useContext(APIContext);

  const onArchiveToggle = useCallback((checked) => {
    if (window.confirm('Are you sure?')) {
      if (checked) action('archive');
      else action('restore');
    }
  }, [action]);

  const duplicateJob = useCallback(() => action('duplicate'), [action]);

  const openOrganisation = useCallback((event) => {
    if (job.organisation) {
      let url = `/admin/organisation/${job.organisation.id}/view`;

      if (event.ctrlKey || event.metaKey) {
        let win = window.open(url, '_blank');
        win.focus();
      } else {
        history.push(url);
      }
    }
  }, [history, job]);

  const getApplicantCounts = useMemo(() => api.getApplicantCounts([job.id]), [api, job]);
  const emptyCounts = { inbox: 0, shortlist: 0, interview: 0, offer: 0, accept: 0, unsuitable: 0, total: 0 };
  const [applicantCountsByJob] = usePromise(getApplicantCounts, {[job.id]: emptyCounts}, { component: 'JobPreview' });

  return (
    <Card className="job-actions admin">
      <div className="actions">
        {job.isLocked ? (
          <Button
            kind="primary"
            disabled={fetching}
            onClick={() => action('unlock')}
            label="Unlock"
            icon="lock_open"
          />
        ) : (
          <Button
            kind="danger"
            disabled={fetching}
            onClick={() => action('lock')}
            label="Lock"
            icon="lock"
          />
        )}
        {job.isLocked && job.lockedBy !== currentUser.id ? (
          <Button
            disabled
            kind="danger"
            label={(
              <span>
                <img className="avatar" src={job.lockedByAvatar} alt="Avatar" />
                Locked
              </span>
            )}
          />
        ) : (
          <Button
            kind="default"
            disabled={fetching}
            onClick={() => action('edit')}
            label="Edit"
            icon="create"
          />
        )}
        {job.status !== 'DRAFT' ? (
          <Button
            kind="primary"
            disabled={fetching}
            onClick={() => action('update')}
            label="Save"
            icon="save"
          />
        ) : (
          <Button
            kind="primary"
            disabled={fetching || job.organisation.numCredits === 0}
            onClick={() => action('pending')}
            label="Submit"
            icon="publish"
          />
        )}
        <Button
          kind="primary"
          disabled={job.isDispatched || fetching}
          onClick={() => {
            if (!job.organisation.logos.standard) {
              window.alert('Cannot approve job as this organisation does not have a logo set');
              return;
            }

            if (job.organisation.jobs.numApproved + job.organisation.jobs.numExpired === 0) {
              if (window.confirm('This is the first job posted by this organisation. Are you sure the logo/UID is correct?')) {
                action('approve');
              }
            } else {
              action('approve');
            }
          }}
          label="Approve"
          icon="done_all"
        />
        <Button
          kind="primary"
          disabled={job.isExpired || fetching}
          onClick={() => window.confirm('Confirm you want to expire this job ad.')
            ? action('expire')
            : null}
          label="Expire"
          icon="alarm_on"
        />
        <Button
          kind="primary"
          disabled={job.organisation.numCredits === 0}
          onClick={() => {
            if (window.confirm('Are you sure you want to clone this job?')) {
              duplicateJob();
            }
          }}
          label="Clone"
          icon="library_add"
        />
        <Button
          kind="primary"
          disabled={!job.organisation}
          label="View Org"
          icon="remove_red_eye"
          onClick={openOrganisation}
        />
        <Switch
          label="Archived"
          checked={!!job.deletedAt}
          disabled={fetching}
          onChange={onArchiveToggle}
        />
      </div>
      <JobInfo job={job} author={author} applicantCounts={applicantCountsByJob[job.id]} />
      <JobComments jobId={job.id} />
      <Spinner show={fetching} />
    </Card>
  );
}

export default AdminJobActions;
