import _ from 'lodash';

export const sumArrayValues = (items: Array<Object>) => {
  const results: Array<number | string> = [];
  if (Array.isArray(items) && items.length) {
    Object.entries(items[0]).forEach((column, index) => {
      if (Number.isInteger(column[1])) {
        results[index] = _.sumBy(items, column[0]);
      } else {
        results[index] = '';
      }
    });
  }
  return results;
}
