import React, { useCallback, useContext } from 'react';

import _ from 'lodash';
import cogoToast from 'cogo-toast';
import { useDispatch, useSelector, shallowEqual  } from 'react-redux';
import Tabs from 'react-bootstrap/lib/Tabs';
import Tab from 'react-bootstrap/lib/Tab';

import { User, Organisation } from '@ethical-jobs/sdk-js/types';

import { APIContext, AmplitudeContext } from 'lib/contexts';
import { loadUserDetails } from 'actions/auth';

import OrganisationProfileForm from 'forms/OrganisationProfileForm';
import OrganisationMediaForm from 'forms/OrganisationMediaForm';
import UserProfileForm from 'forms/UserProfileForm';

import Container from 'components/Container';
import Card from 'components/Card';
import PageTitle from 'components/PageTitle';
import OrganisationLogoUpload from 'components/OrganisationLogoUpload';

const Profile = () => {
  const dispatch = useDispatch();
  const api = useContext(APIContext);
  const amplitude = useContext(AmplitudeContext);

  // @ts-ignore
  const user: User & { organisation: Organisation } = useSelector(state => state.auth.user, shallowEqual);

  const updateOrganisation = useCallback(async (values) => {
    try {
      let updatedOrganisation = await api.updateOrganisation(user.organisation.id, values);
      dispatch(loadUserDetails());
      cogoToast.success('Successfully updated organisation', { hideAfter: 3, position: 'top-right' });
      amplitude.logEvent('API', { function: 'updateOrganisation', arguments: { organisationId: user.organisation.id, ...values }});
      return updatedOrganisation
    } catch (error) {
      cogoToast.error('Error updating organisation', { hideAfter: 3, position: 'top-right' });
      amplitude.logError('containers/_organisation/Profile/index/updateOrganisation', error);

      // INFO: Must rethrow so form can handle
      throw error;
    }
  }, [dispatch, user, api, amplitude]);

  const updateUser = useCallback(async (values) => {
    try {
      let updatedUser = await api.updateUser(user.id, values);
      dispatch(loadUserDetails());
      amplitude.logEvent('API', { function: 'updateUser', arguments: { userId: user.id, ..._.mapValues(values, (value, key) => key.includes('password') ? 'REDACTED' : value) }});
      cogoToast.success('Successfully updated user', { hideAfter: 3, position: 'top-right' });
      return updatedUser;
    } catch (error) {
      cogoToast.error('Error updating user', { hideAfter: 3, position: 'top-right' });
      amplitude.logError('containers/_organisations/Profile/index/updateUser', error);

      // Needs to rethrow so form can handle errors
      throw error;
    }
  }, [api, dispatch, user, amplitude]);

  const submitLogo = useCallback(async (file, organisationId) => {
    try {
      let response = await api.attachMediaToOrganisation(organisationId, file);
      dispatch(loadUserDetails());
      cogoToast.success('Successfully updated logo', { hideAfter: 3, position: 'top-right' });
      amplitude.logEvent('API', { function: 'submitLogo', arguments: { organisationId }});
      return response;
    } catch (error) {
      cogoToast.error('Error updating logo', { hideAfter: 3, position: 'top-right' });
      amplitude.logError('containers/_organisation/Profile/index/submitLogo', error);
    }
  }, [api, dispatch, amplitude]);

  return (
    <Container className="organisation-profile-container">
      <PageTitle title="Update your organisation's profile" />
      <div className="row">
        <Card className="col-md-4">
          <OrganisationLogoUpload
            upload={submitLogo}
            organisation={user.organisation}
          />
        </Card>
        <div className="col-md-8">
          <h3 className="flat-top sub-heading">Update your logo</h3>
          <hr />
          <p className="muted">&larr; Click on your logo to select a new file to upload.</p>
          <p className="muted">Preferred dimensions 16:9. <em>Maximum file size 500KB.</em></p>
        </div>
      </div>
      <Tabs defaultActiveKey={1} id="org-profile-tabs">
        <Tab eventKey={1} title="Organisation Profile">
          <Card title="Your Organisation Profile" subTitle="Manage your organisation's profile information.">
            <OrganisationProfileForm
              action={updateOrganisation}
              initialValues={{
                ..._.pick(user.organisation, ['name', 'uid', 'phone']),
                numPaidStaff: `${user.organisation.staff.paid}`,
                numVolunteers: `${user.organisation.staff.volunteer}`,
                ...user.organisation.address,
                address: user.organisation.address.street
              }}
              enableReinitialize
            />
          </Card>
        </Tab>
        <Tab eventKey={2} title="Contact & Login Details">
          <Card title="Your Profile & Login Details" subTitle="Manage your profile and login details.">
            <UserProfileForm
              admin={false}
              action={updateUser}
              initialValues={user}
              enableReinitialize
            />
          </Card>
        </Tab>
        <Tab eventKey={3} key={3} title="Media">
          <Card title="Recruitment Video" subTitle="Default recruitment video for new job postings.">
            <OrganisationMediaForm
              action={updateOrganisation}
              initialValues={{ videoUrl: user.organisation.videoUrl }}
            />
          </Card>
        </Tab>
      </Tabs>
    </Container>
  );
};

export default React.memo(Profile);
