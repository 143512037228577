import React from 'react';
import moment from 'moment';
import LineChart from 'components/LineChart';
import { sortReportByCurrentMonth } from 'lib/date';

const QUERY = {
  dateRanges: [{
    from: moment().add(-1, 'years').format('YYYY-MM-DD HH:mm:ss'),
    to:  moment().format('YYYY-MM-DD HH:mm:ss')
  },
  {
    from:  moment().add(-2, 'years').format('YYYY-MM-DD HH:mm:ss'),
    to:  moment().add(-1, 'years').format('YYYY-MM-DD HH:mm:ss')
  }],
  metric: 'metric:totalPurchasesValue',
  dimensions: ['dimension:month']
};

const PurchasesByMonthChart = () => (
  <LineChart
    title="Total purchases by month"
    xAxisKey="month"
    primary={{ key: 'total_purchases_value_1', label: 'Current 12 months' }}
    secondary={{ key: 'total_purchases_value_2', label: 'Previous 12 months' }}
    // @ts-ignore
    reportCallback={report => sortReportByCurrentMonth(report, 'month', true)}
    query={QUERY}
  />
);

export default PurchasesByMonthChart;
