import React, { useCallback, useMemo, useContext, useState } from 'react';

import _ from 'lodash';
import { useSelector } from 'react-redux';
import { useParams, useHistory } from 'react-router-dom';
import cogoToast from 'cogo-toast';

import { Invoice } from '@ethical-jobs/sdk-js/types';
import { Switch } from '@ethical-jobs/design-system-react';

import { usePromise } from 'hooks';
import { APIContext, AmplitudeContext } from 'lib/contexts';
import { isNumeric } from 'lib/math';

import InvoiceForm from 'forms/InvoiceForm';
import PageTitle from 'components/PageTitle';
import Card from 'components/Card';

const InvoiceUpdate = () => {
  const history = useHistory();
  const api = useContext(APIContext);
  const amplitude = useContext(AmplitudeContext);

  const params: { invoiceId?: string } = useParams();

  const invoiceId: number | undefined = isNumeric(params.invoiceId) ? Number(params.invoiceId) : undefined;

  const [forceRefresh, setForceRefresh] = useState(0);

  const isStaffMember = useSelector(state => _.get(state, ['auth', 'user', 'roles'], []).includes('staff-member'));

  /* eslint-disable react-hooks/exhaustive-deps */
  const getInvoice: Promise<Invoice | null> = useMemo(() => {
    if (invoiceId) {
      return api.getInvoice(Number(invoiceId))
    }
    return Promise.resolve(null)
  }, [api, invoiceId, forceRefresh]);

  const [invoice] = usePromise(getInvoice, null, { component: 'InvoiceUpdate' });

  const updateInvoice = useCallback(async (values) => {
    if (!invoiceId) return;

    try {
      let invoice = await api.updateInvoice(invoiceId, values);
      cogoToast.success('Invoice successfully updated', { hideAfter: 4, position: 'top-right' });
      setForceRefresh(Math.random());
      amplitude.logEvent('API', { function: 'updateInvoice', arguments: { invoiceId, values }});
      return invoice;
    } catch (error) {
      amplitude.logError('containers/_admin/InvoiceUpdate/index/updateInvoice', error);

      // INFO: Must rethrow so form can handle
      throw error;
    }
  }, [invoiceId, api, amplitude]);

  const createInvoice = useCallback(async (values) => {
    try {
      let invoice = await api.createInvoice(values);
      cogoToast.success('Invoice successfully created', { hideAfter: 4, position: 'top-right' });
      history.push('/admin/invoices');
      amplitude.logEvent('API', { function: 'createInvoice', arguments: { values }});
      return invoice;
    } catch (error) {
      amplitude.logError('containers/_admin/InvoiceUpdate/index/createInvoice', error);

      // INFO: Must rethrow so form can handle
      throw error;
    }
  }, [api, history, amplitude]);

  const toggleArchive = useCallback(async (archived) => {
    if (!invoiceId) return;

    if (archived) {
      try {
        let invoice = await api.archiveInvoice(invoiceId);
        cogoToast.success('Invoice successfully archived', { hideAfter: 4, position: 'top-right' });
        amplitude.logEvent('API', { function: 'archiveInvoice', arguments: { invoiceId }});
        setForceRefresh(Math.random());
        return invoice;
      } catch (error) {
        amplitude.logError('containers/_admin/InvoiceUpdate/index/toggleArchive:archive', error);
      }
    } else {
      try {
        let invoice = await api.restoreInvoice(invoiceId);
        cogoToast.success('Invoice successfully restored', { hideAfter: 4, position: 'top-right' });
        amplitude.logEvent('API', { function: 'restoreInvoice', arguments: { invoiceId }});
        setForceRefresh(Math.random());
        return invoice;
      } catch (error) {
        amplitude.logError('containers/_admin/InvoiceUpdate/index/toggleArchive:restore', error);
      }
    }
  }, [api, invoiceId, amplitude]);

  return (
    <div className="admin-invoice-container">
      <PageTitle
        title={invoice ? 'Update invoice' : 'Create invoice'}
        subTitle="Invoice management view "
      />
      {isStaffMember && !!invoice && (
        <Card>
          <div className="invoice-actions">
            <Switch
              label="Archived"
              checked={!!invoice.deletedAt}
              onChange={toggleArchive}
            />
          </div>
        </Card>
      )}
      <InvoiceForm
        action={invoice ? updateInvoice : createInvoice}
        initialValues={invoice || { country: 'AU' }}
        enableReinitialize
        isUpdate={!!invoice}
      />
    </div>
  );
}

export default React.memo(InvoiceUpdate);
