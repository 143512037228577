import React, { useState, useEffect, useContext } from 'react';
import { Contact } from '@ethical-jobs/sdk-js/types';
import { shallowEqual, useSelector } from 'react-redux';
import ReadOnly from './ReadOnly';
import FinanceContact from '.';
import { APIContext } from 'lib/contexts';
import _ from 'lodash';
import Card from 'components/Card';
import Spinner from 'components/Spinner';

const EMPTY_STATE = {
  id: "",
  name: "",
  email: "",
  phone: ""
};

type FinanceContactContainerState = "work" | "edit" | "read";

const FinanceContactContainer = () => {
  const api = useContext(APIContext);
  const [state, setState] = useState<FinanceContactContainerState>("work");
  const [financeContact, setFinanceContact] = useState<Contact>(EMPTY_STATE);
  
  // @ts-ignore
  const organisation = useSelector(state => state.auth.user.organisation, shallowEqual);
  useEffect(() => {
    if (!_.isEmpty(_.get(organisation, 'financeContactId')) ) {
      const financeContactId = _.get(organisation, 'financeContactId') as string;
      api.getFinanceContact(financeContactId, organisation.id).then((response) => {
        setFinanceContact(response);
        setState("read");
      });
    } else {
      setState("edit");
    }
  }, []);

  return (
    <Card
      title="Finance contact"
      subTitle="Add a finance contact below – they'll automatically be sent a copy of any new invoices you create."
    >
      <Spinner show={state === "work"} />
        {(state === "read" && 
          <ReadOnly
            organisationId={organisation.id}
            financeContact={financeContact}
            onUpdate={() => setState("edit")}
            onDelete={() => {
              setState("edit");
              setFinanceContact(s => ({...EMPTY_STATE}));
            }}
          />
        )}
        {(state === "edit" && 
          <FinanceContact
            organisationId={organisation.id}
            financeContact={financeContact}
            onUpdate={setFinanceContact}
            onLoading={() => setState("work")}
            onCancel={() => setState("read")}
            onSave={() => setState("read")}
          />
        )}
    </Card>
  );
};

export default FinanceContactContainer;