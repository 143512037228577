import React from 'react';

import FormField from 'fields/FormField';

const Details = () => (
  <div className="row">
    <FormField
      name="name"
      component="input"
      type="text"
      label="Your organisation's name"
      wrapClassName="col-md-12"
    />
    <FormField
      name="uid"
      component="input"
      type="text"
      label="Unique Identifier (UID)"
      wrapClassName="col-md-12 uid"
    />
    <FormField
      name="numPaidStaff"
      component="input"
      type="text"
      label="Number of paid staff"
      placeholder="Please enter numbers only"
      wrapClassName="col-md-6"
    />
    <FormField
      name="numVolunteers"
      component="input"
      type="text"
      label="Number of volunteer staff"
      placeholder="Please enter numbers only"
      wrapClassName="col-md-6"
    />
    <FormField
      name="phone"
      component="input"
      type="text"
      label="Phone number"
      placeholder="Enter a primary contact phone number"
      wrapClassName="col-md-12"
    />
  </div>
);

export default Details;
