import React from 'react';

import { Modal as RBModal, Sizes } from 'react-bootstrap';

type Props = {
  size?: Sizes;
  className?: string;
  children?: React.ReactNode;
  show?: boolean;
  onHide: () => void;
};

const Modal = ({ size = 'large', className, children, show, onHide }: Props) => (
  <RBModal
    show={show}
    onHide={onHide}
    bsSize={size}
    dialogClassName={className}
  >
    <RBModal.Body>
      {children}
    </RBModal.Body>
  </RBModal>
);

export default Modal;