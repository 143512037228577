import React from 'react';

import env from 'lib/env';

import Icon from 'components/Icon';

import { OptionContainer, OptionCheckbox } from './ApplyNowOption.styles';

type Props = {
  label: string;
  isSelected: boolean;
  onClick: () => void;
  showIcon?: boolean;
}

const ApplyNowOption = ({ label, isSelected, onClick, showIcon = false }: Props) => (
  <OptionContainer isSelected={isSelected} onClick={onClick}>
    <OptionCheckbox isSelected={isSelected}>
      {isSelected && (
        <Icon type="check" style={{ color: 'white' }} />
      )}
    </OptionCheckbox>
    <p style={{ paddingLeft: 15, margin: 0, fontSize: 14, zIndex: 1 }}>{label}</p>
    {showIcon && (
      <img
        src={`${env.EJ_CDN}/assets/web-responsive/static/apple-touch-icon.png`}
        alt="EthicalJobs.com.au Logo"
        style={{ position: 'absolute', top: 1, right: 1, width: 40, height: 40 }}
      />
    )}
  </OptionContainer>
);

export default ApplyNowOption;