import React, { useCallback, useContext } from 'react';
import { Contact } from '@ethical-jobs/sdk-js/types';
import { Button } from '@ethical-jobs/design-system-react';
import { APIContext } from 'lib/contexts';
import cogoToast from 'cogo-toast';
import { loadUserDetails } from 'actions/auth';
import { useDispatch } from 'react-redux';
import { Label, Span, HalfDiv, FlexDiv, LeftDiv, RightDiv, SpacedDiv, ActionsDiv } from './ReadOnly.styles';

interface ReadOnlyProps {
  financeContact: Partial<Contact>;
  organisationId: number;
  onUpdate: () => void;
  onDelete: () => void;
}

const ReadOnly = (props: ReadOnlyProps) => {
  const { financeContact, organisationId, onUpdate, onDelete } = props;
  const api = useContext(APIContext);
  const dispatch = useDispatch();
  const deleteFinanceContact = useCallback(async () => {
    await api.deleteFinanceContact(financeContact.id, organisationId);
    cogoToast.success('Successfully removed finance contact.', { hideAfter: 3, position: 'top-right' });
    dispatch(loadUserDetails());
    onDelete();
  }, [financeContact]);
  return (
    <>
      <HalfDiv>
        <SpacedDiv>
          <Label>Name</Label><br />
          <Span>{financeContact.name}</Span>
        </SpacedDiv>
        
        <FlexDiv>
          <LeftDiv>
            <Label>Email</Label>
            <br />
            <Span>{financeContact.email}</Span>
          </LeftDiv>
          <RightDiv>
            <Label>Phone</Label><br />
            <Span>{financeContact.phone}</Span>
          </RightDiv>
        </FlexDiv>
        <ActionsDiv>
          <Button
            style={{ marginRight: '10px' }}
            type="primary"
            label="Update"
            onClick={onUpdate}
          />
          <Button
            style={{ 
              background: '#fff',
              color: 'rgb(8, 19, 38)',
              border: '1px solid rgb(8, 19, 38)'
            }}
            type="secondary"
            label="Remove"
            onClick={deleteFinanceContact}
          />
        </ActionsDiv>
      </HalfDiv>
    </>
  );
};

export default ReadOnly;