import React, { useEffect, useContext, useCallback, useState } from 'react';

import _ from 'lodash';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import * as QueryString from 'query-string';

import { APIContext, AmplitudeContext } from 'lib/contexts';
import { loadUserDetails } from 'actions/auth';

import Signup from './Signup';

const SignupContainer = () => {
  const dispatch = useDispatch();
  const history = useHistory();

  const api = useContext(APIContext);
  const amplitude = useContext(AmplitudeContext);

  const [invitationStatus, setInvitationStatus] = useState(null);
  const [email, setEmail] = useState('');

  const { token } = QueryString.parse(window.location.search);

  const signup = useCallback(async (values) => {
    try {
      
      await api.createUserFromInvitation({ ...values, email, token }, true);
      amplitude.logEvent('API', { function: 'createUserFromInvitation', arguments: { ..._.mapValues(values, (value, key) => key.includes('password') ? 'REDACTED' : value) } });

      await dispatch(loadUserDetails());
      history.push('/organisation/welcome');
    } catch (error) {
      amplitude.logError('containers/Signup/index/signup', error);

      // INFO: Must rethrow so form can handle
      throw error;
    }
  }, [api, dispatch, history, amplitude, token, email]);

  useEffect(() => {
    let fn = async () => {
      try {
        let invitation = await api.checkInvitationStatus(token as string);
       
        setInvitationStatus('valid');
        setEmail(invitation.email);
      } catch (error) {
        if (error.response.status === 404) {
          setInvitationStatus('invalid');
          return;
        }
        
        if (error.response.status === 410) {
          if (error.response.data.message === 'Invite already used.') {
            setInvitationStatus('used');
            return;
          }

          if (error.response.data.message === 'Invite expired.') {
            setInvitationStatus('expired');
            return;
          }
        }

        amplitude.logError('containers/Signup/index:checkInvitationStatus', error);
      }
    };

    fn();
  }, [api, token, amplitude]);


  return (
    <Signup
      signup={signup}
      invitationStatus={invitationStatus}
    />
  );

};

export default React.memo(SignupContainer);
