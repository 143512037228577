import React, { useEffect, useState, useContext } from 'react';

import { AmplitudeContext } from 'lib/contexts';

const CheckingAuth = () => {
  const amplitude = useContext(AmplitudeContext);

  const [displayError, setDisplayError] = useState(false);

  useEffect(() => {
    let timer = setTimeout(() => {
      setDisplayError(true);
      amplitude.logError('components/CheckingAuth', new Error('Time out on auth check'));
    }, 5000);

    return () => clearTimeout(timer);
  }, [amplitude]);

  return (
    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', padding: '100px 0px' }}>
      {displayError && (
        <p>It looks like there may have been an error. Please try refreshing the page</p>
      )}
    </div>
  );
};

export default CheckingAuth;