import React from 'react';

import Dropdown, {
  Toggle as DropdownToggle,
  Menu as DropdownMenu
} from 'react-bootstrap/lib/Dropdown';
import MenuItem from 'react-bootstrap/lib/MenuItem';
import { LinkContainer } from 'react-router-bootstrap';
import { Link } from 'react-router-dom';

type Props = {
  organisationName: string;
  logout: () => void;
};

const OrganisationMenu = ({ organisationName, logout }: Props) => (
  <div className="user-menu">
    <ul className="nav navbar-nav navbar-right" style={{ backgroundColor: 'white', height: 65 }}>
      <li className="highlighted">
        <Link to="/organisation/job/create" style={{ color: '#3E8420', paddingTop: 23 }}>Post a job ad</Link>
      </li>
      <li id="help-menu">
        <Dropdown id="help-menu-dropdown">
          <DropdownToggle noCaret>
            <span style={{ padding: '21px 20px', display: 'flex', borderLeft: '1px solid lightGrey', fontSize: 16, fontWeight: 300 }}>
              Help
            </span>
          </DropdownToggle>
          <DropdownMenu>
            <li role="presentation">
              <p>If you need any assistance, we'd be more than happy to help! Please contact us via one of the options below:</p>
            </li>
            <MenuItem href="tel:+61394195979"><strong>Call</strong> (03) 9419 5979</MenuItem>
            <MenuItem href="mailto:info@ethicaljobs.com.au"><strong>Email</strong> info@ethicaljobs.com.au</MenuItem>
          </DropdownMenu>
        </Dropdown>
      </li>
      <li id="profile-menu">
        <Dropdown id="profile-menu-dropdown">
          <DropdownToggle noCaret>
            <span style={{ padding: '21px 20px', display: 'flex', borderLeft: '1px solid lightGrey', fontSize: 16, fontWeight: 300 }}>
              {organisationName}
            </span>
          </DropdownToggle>
          <DropdownMenu>
            <LinkContainer to="/organisation/profile">
              <MenuItem>Your organisation profile</MenuItem>
            </LinkContainer>
            <MenuItem href="http://ethicaljobs.com.au" target="_blank">Visit EthicalJobs.com.au</MenuItem>
            <MenuItem onClick={logout}>Sign out</MenuItem>
          </DropdownMenu>
        </Dropdown>
      </li>
    </ul>
  </div>
);

export default OrganisationMenu;