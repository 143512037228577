import React, { useCallback, useState, useEffect } from 'react';

import moment from 'moment';
import { SingleDatePicker } from 'react-dates';

import { usePreviousValue } from 'hooks';

type Props = {
  value?: string | number | moment.Moment | Date | null;
  onChange: (value: string | null) => void;
  placeholder?: string;
  minDate?: string | number | moment.Moment | Date | null;
  maxDate?: string | number | moment.Moment | Date | null;
  disabled?: boolean;
  dateFormat?: string;
}

const DateField = ({ value, onChange, placeholder, minDate, maxDate, disabled = false, dateFormat = 'D/M/YYYY' }: Props) => {
  const [isFocused, setIsFocused] = useState(false);
  const [internalValue, setInternalValue] = useState<string | null>(value ? moment(value).format('YYYY-MM-DD') : null);
  const previousValue = usePreviousValue(value);

  useEffect(() => {
    if (value !== previousValue && moment(value).isSame(internalValue) === false) {
      setInternalValue(value ? moment(value).format('YYYY-MM-DD') : value as null);
    }
  }, [previousValue, internalValue, value]);

  const onDateChange = useCallback((date: moment.Moment | null) => {
    let newDate = date ? moment(date).format('YYYY-MM-DD') : date as null;
    setInternalValue(newDate);
    onChange(newDate);
  }, [onChange]);

  return (
    <div className="ej-date-picker">
      {/*
      // @ts-ignore */}
      <SingleDatePicker
        id="ej-date-picker"
        orientation="horizontal"
        anchorDirection="left"
        date={internalValue ? moment(internalValue) : null}
        onDateChange={onDateChange}
        placeholder={placeholder}
        showDefaultInputIcon
        displayFormat={dateFormat}
        onFocusChange={({ focused }) => setIsFocused(focused)}
        focused={isFocused}
        numberOfMonths={1}
        isOutsideRange={day => (minDate && moment(day).isBefore(minDate)) || (maxDate && moment(day).isAfter(maxDate))}
        disabled={disabled}
      />
    </div>
  );
};

export default DateField;