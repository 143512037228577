import React, { useState } from 'react';

import Modal, { Body as ModalBody } from 'react-bootstrap/lib/Modal';
import Button from 'react-bootstrap/lib/Button';

type Props = {
  buttonText: string;
  description?: string;
  title: string;
};

const Welcome = ({ buttonText, description = '', title }: Props) => {
  const [showModal, setShowModal] = useState(true);

  return (
    <Modal show={showModal} onHide={() => setShowModal(false)} bsSize="large" dialogClassName="welcome-modal">
      <ModalBody>
        <h2 className="form-title">Welcome!</h2>
        <div className="modal-body">
          <h3 className="flat-top primary centered">{title}</h3>
          <br />
          <p className="centered">{description}</p>
          <br />
          <Button block bsStyle="primary" type="submit" onClick={() => setShowModal(false)}>
            {buttonText}
          </Button>
        </div>
      </ModalBody>
    </Modal>
  );
};

export default Welcome;
