import React from 'react';

import env from 'lib/env';

const Error = () => (
  <div className="container auth-container error-container">
    <div className="row">
      <div className="col-md-offset-2 col-md-8">
        <div className="logo">
          <img
            src={`${env.EJ_CDN}/statics/logo-transparent.png`}
            className="logo"
            alt="EthicalJobs - environmental and socially just jobs"
          />
        </div>
        <div className="error-info">
          <h1>Error</h1>
          <div className="error-info-instructions">
            <p>Could not connect to EthicalJobs.com.au, please try again.</p>
            <p>If this error persists, please <a target="_blank" rel="noopener noreferrer" href="https://www.ethicaljobs.com.au/contact-info">contact
              us</a>.</p>
          </div>
        </div>
        <img
          src={`${env.EJ_CDN}/statics/gardening-trans.png`}
          className="graph"
          alt="Unable to connect"
        />
      </div>
    </div>
  </div>
);

export default Error;
