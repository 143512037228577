import React, { useState, useRef, useEffect } from 'react';

import _ from 'lodash';
import ReactSelect from 'react-select';

import './select.css';

type Props = {
  value?: string | null;
  options: Array<{ label: string, value: string }>;
  onChange: (value: { label: string, value: string }) => void;
  disabled?: boolean;
  placeholder?: string;
  width?: number;
  label?: string;
  multi?: boolean;
  isClearable?: boolean;
  isSearchable?: boolean;
  style?: Object;
};

const Select = ({ label, value = null, onChange, options, placeholder, disabled = false, multi = false, width = 350, isClearable = true, isSearchable = true, style = {} }: Props) => {
  // Hack to make it limit items in the list (without having to scan through the entire list)
  // https://github.com/JedWatson/react-select/issues/126#issuecomment-478603553
  const resultLimit = 100;
  let i = 0;

  const labelRef = useRef(null);

  const [labelWidth, setLabelWidth] = useState(0);
  
  useEffect(() => {
    const width = labelRef.current ? labelRef.current.offsetWidth : 0;
    setLabelWidth(width + 5);
  }, []);

  return (
    <div style={{ position: 'relative', ...style }}>
      <ReactSelect
        // Trigger re-render on programmatic clear: https://stackoverflow.com/questions/50412843/how-to-programmatically-clear-reset-react-select
        key={JSON.stringify(value)}
        className="select-component"
        options={options}
        filterOption={({ label }, query) => label.toLowerCase().indexOf(query.toLowerCase()) >= 0 && i++ < resultLimit}
        value={multi
          ? options.filter(option => _.isArray(value) && value.includes(option.value))
          : options.find(option => option.value === value)
        }
        isMulti={multi}
        isSearchable={isSearchable}
        isClearable={isClearable}
        isDisabled={disabled}
        onChange={onChange}
        placeholder={placeholder}
        onInputChange={() => { i = 0; }}
        ignoreAccents={false}
        inputProps={{
          autoComplete: 'off',
          autoCorrect: 'off',
          spellCheck: 'off'
        }}
        styles={{
          control: provided => ({
            ...provided,
            height: 42,
            minWidth: width,
            paddingLeft: labelWidth,
            transition: 'none'
          }),
          input: provided => ({
            ...provided,
            height: 34
          }),
          indicatorsContainer: provided => ({
            ...provided,
            backgroundColor: '#EEE',
            height: '100%',
            marginTop: -1,
            alignSelf: 'initial',
            borderTopRightRadius: 4,
            borderBottomRightRadius: 4
          }),
          indicatorSeparator: provided => ({
            ...provided,
            width: 0
          })
        }}
      />
      {!!label && (
        <p ref={labelRef} style={{ position: 'absolute', left: 10, top: 11, color: '#AAA', fontSize: 14, marginBottom: 0 }}>{label}</p>
      )}
    </div>
  );
};

export default Select;