import _ from 'lodash';
import React, { useCallback } from 'react';

import { reduxForm } from 'redux-form';

import FormField from 'fields/FormField';
import { schemaValidator } from 'lib/validation';
import submissionError from 'lib/submissionError';

import Button from 'components/Button';
import ValidationMessages from 'components/ValidationMessages';

import validation from './validation';

type Props = {
  action: (values: Object) => Promise<Object>;
  handleSubmit: any;
  error?: string;
  submitting: boolean;
};

const FORMATTED_ERRORS = {
  'Invitation is invalid': {
    message: 'This invitation is invalid, please request a new invite to the organisation'
  }
};

const SignupForm = ({ action, submitting, handleSubmit, error }: Props) => {
  const submitForm = useCallback(async (values) => {
    try {
      await action(values);
    } catch (error) {
      let errorMessage = _.get(error, ['response', 'data'], error);
      submissionError(FORMATTED_ERRORS[errorMessage.message] || errorMessage);
    }
  }, [action]);

  return (
    <form onSubmit={handleSubmit(submitForm)}>
      <div className="organisation-signup-form">
        <h3 className="flat-top">Activate your account on EthicalJobs.com.au</h3>
        <div className="row">
          <FormField
            label="Username"
            name="username"
            component="input"
            type="text"
            placeholder="Username"
            wrapClassName="col-md-6"
          />
          <FormField
            label="Password"
            name="password"
            component="input"
            type="password"
            placeholder="Password"
            wrapClassName="col-md-6"
          />
        </div>
        <div className="row">
          <FormField
            label="First Name"
            name="firstName"
            component="input"
            type="text"
            placeholder="e.g. Mary"
            wrapClassName="col-md-6"
          />
          <FormField
            label="Last Name"
            name="lastName"
            component="input"
            type="text"
            placeholder="e.g. Smith"
            wrapClassName="col-md-6"
          />
        </div>
        <div className="row">
          <FormField
            label="Job Title"
            name="position"
            component="input"
            type="text"
            placeholder="e.g. Administration Officer"
            wrapClassName="col-md-6"
          />
          <span style={{ display: 'flex', justifyContent: 'center', paddingTop: 15 }}>
            <Button
              disabled={submitting}
              loading={submitting}
              label="Activate your account"
              type="submit"
              className="register"
              kind="primary"
            />
          </span>
        </div>
        <ValidationMessages error={error} heading="" />
      </div>
    </form>
  );
};

export default reduxForm({
  form: 'signupForm',
  validate: schemaValidator(validation)
  // @ts-ignore
})(SignupForm);
