import React from 'react';

type Props = {
  style?: Object;
  cellData: boolean;
};

const BooleanRenderer = ({ style, cellData }: Props) => (
  <span style={style}>{cellData ? 'Yes' : 'No'}</span>
);

export default BooleanRenderer

