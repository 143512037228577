import React from 'react';

import { User } from '@ethical-jobs/sdk-js/types';

type Props = {
  className?: string;
  user?: User;
};

const UserProfileSmall = ({ className, user }: Props) => user ? (
  <div className={`${className} user-profile-small`}>
    <div className="row">
      <div className="col-md-12">
        <img className="avatar" src={user.avatar} alt="Avatar" />
        <h3>{`${user.firstName} ${user.lastName}`}</h3>
        <h5>{user.email}</h5>
      </div>
    </div>
  </div>
) : null;

export default UserProfileSmall;
