import 'core-js';

import React from 'react';
import ReactDOM from 'react-dom';

import { Provider } from 'react-redux';
import { ConnectedRouter } from 'connected-react-router';
import EthicalJobsAPI from '@ethical-jobs/sdk-js';
import { ThemeProvider } from '@ethical-jobs/design-system-react';

import '@ethical-jobs/design-system-react/dist/main.css';

import { loadUserDetails } from 'actions/auth';

import { fetchAppData } from 'actions/app';
import configureStore, { history } from './store';
import Error from 'containers/Error';

import { APIContext, AmplitudeContext } from 'lib/contexts';

import Routes from './routes';
import env from 'lib/env';

// provide ignore function to sdk-js amplitude logError
// if (error.message === 'Failed to load Stripe.js') return; // ignore interal error surfaced by stripe loading util...
// // INFO: Returns a 404 if users attempt to recover password for an account that doesn't exist
// if (location === 'forms/PasswordRecoverForm/index/submit' && error.message === "") return;

const api = new EthicalJobsAPI({ host: env.API_HOST, site: env.SITE, amplitudeKey: env.AMPLITUDE_KEY, fileDownloadSecret: env.FILE_DOWNLOAD_API_SECRET });

const { amplitude } = api.api;

const store = configureStore({}, { api, amplitude });

class App extends React.Component {
  componentDidCatch(error: Error, errorInfo: Object) {
    amplitude.logError('index/App', error, errorInfo);
  }

  render() {
    return (
      <AmplitudeContext.Provider value={amplitude}>
        <APIContext.Provider value={api}>
          <ThemeProvider>
            <Provider store={store}>
              <ConnectedRouter history={history}>
                <Routes />
              </ConnectedRouter>
            </Provider>
          </ThemeProvider>
        </APIContext.Provider>
      </AmplitudeContext.Provider>
    );
  }
}

function renderApp() {
  // @ts-ignore
  store.dispatch(fetchAppData());

  history.listen(location => {
    let route = location.pathname.split('/');

    amplitude.logEvent('Route', {
      type: 'Open Page',
      portal: route.length > 1 ? route[1] : undefined,
      page: route.length > 2 ? route[2] : undefined,
      params: route.length > 3 ? route.slice(3) : [],
    });
  });

  try {
    ReactDOM.render(<App />, document.getElementById('root'));
  } catch (error) {
    amplitude.logError('index/renderApp', error);
    ReactDOM.render(<Error />, document.getElementById('root'));
  }
}

async function bootstrap() {
  if (api.doesAuthTokenExist()) {
    // @ts-ignore
    await store.dispatch(loadUserDetails());
  }
  renderApp();
}

bootstrap();
