import React, { useContext, useState, useMemo, useCallback } from 'react';

import _ from 'lodash';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import { Column } from 'react-virtualized';
import { Checkbox } from '@ethical-jobs/design-system-react';

import { usePromise } from 'hooks';
import { APIContext } from 'lib/contexts';
import { searchOrganisationsAdmin } from 'actions/search';

import Button from 'components/Button';
import Spinner from 'components/Spinner';
import PageTitle from 'components/PageTitle';
import DataTable from 'components/DataTable';

import QueryFilter from 'components/CollectionViewControls/QueryFilter';
import RouteRenderer from 'components/DataTableRenderers/RouteRenderer';

const OrganisationCollection = () => {
  const api = useContext(APIContext);
  const dispatch = useDispatch();

  // @ts-ignore
  const defaultSearch = useSelector(state => state.search.admin.organisations, shallowEqual);

  const [query, setQuery] = useState<string>(defaultSearch.query);
  const [includeArchived, setIncludeArchived] = useState<boolean>(defaultSearch.includeArchived);
  const [sort, setSort] = useState<{ field: string, direction: 'asc' | 'desc' }> (defaultSearch.sort);

  const getOrganisations = useMemo(() => {
    dispatch(searchOrganisationsAdmin({ query, includeArchived, sort }));

    return api.getOrganisations({
      keywords: query,
      fields: ['id', 'createdAt', 'uid', 'name', 'numCredits', 'phone', 'ownerId', 'deletedAt', 'numApprovedJobs', 'numExpiredJobs'],
      include: ['owner'],
      orderBy: [sort],
      limit: 50,
      includeArchived
    });
  }, [api, query, includeArchived, sort, dispatch]);

  const [organisations, , isFetching] = usePromise(getOrganisations, [], { component: 'OrganisationCollection' });

  const onSortChange = useCallback((newSort: { sortBy: string; sortDirection: 'ASC' | 'DESC' }) => {
    // @ts-ignore
    setSort({ field: newSort.sortBy, direction: newSort.sortDirection.toLowerCase() });
  }, []);

  const debounceQuery = _.debounce(setQuery, 800);

  return (
    <div className="organisations-collection-container">
      <PageTitle title="Organisations List" subTitle="Filterble list of organisations" />
      <div className="entity-listing-container">
        <div className="data-table-filters">
          <div className="meta clearfix">
            <div className="result">{organisations.length || 'No'} results</div>
            <Spinner show={isFetching} />
          </div>
          <ul className="clearfix button-list" style={{ alignItems: 'center' }}>
            <li style={{ width: '50%' }}>
              <QueryFilter
                // @ts-ignore
                onChange={({ keywords }) => debounceQuery(keywords)}
                filterKey="keywords"
                placeholder="Search by keyword"
                className="search-filter"
                defaultValue={query}
              />
            </li>
            <li>
              <Checkbox
                label="Include Archived"
                checked={includeArchived}
                onChange={({ target: { checked } }) => setIncludeArchived(checked)}
              />
            </li>
            <li style={{ flexBasis: 0 }}>
              <Button
                icon="clear"
                label="Clear Filters"
                onClick={() => {
                  setIncludeArchived(false);
                  setQuery('');
                  setSort({ field: 'createdAt', direction: 'desc' });
                }}
                size="small"
              />
            </li>
          </ul>
        </div>
        <DataTable
          rows={organisations}
          // @ts-ignore
          defaultSort={{ sortBy: sort.field, sortDirection: sort.direction.toUpperCase() }}
          onSortChange={onSortChange}
        >
          <Column
            label="Name"
            dataKey="name"
            key="name"
            cellRenderer={({ rowData, cellData }) => (
              <RouteRenderer
                to={`/admin/organisation/${rowData.id}/view`}
                text={cellData}
              />
            )}
            width={300}
            flexGrow={1}
          />
          <Column
            label="Unique ID"
            dataKey="uid"
            key="uid"
            width={210}
          />
          <Column
            label="Primary Username"
            dataKey="ownerId"
            key="ownerId"
            cellRenderer={({ rowData }) => <span>{_.get(rowData, ['owner', 'username'], '[User Deleted]')}</span>}
            width={210}
          />
          <Column
            label="Credits"
            dataKey="numCredits"
            key="numCredits"
            width={60}
            disableSort
          />
          <Column
            label="# Jobs"
            dataKey="numJobs"
            width={60}
            disableSort
            cellRenderer={({ rowData }) => <span>{rowData.jobs.numApproved + rowData.jobs.numExpired}</span>}
          />
          <Column
            label="Phone"
            dataKey="phone"
            key="phone"
            width={100}
          />
          {includeArchived && (
            <Column
              label="Archived"
              dataKey="deletedAt"
              key="deletedAt"
              width={80}
              cellRenderer={({ rowData }) => <span style={{ padding: '0px 20px' }}>{rowData.deletedAt ? 'X' : ''}</span>}
            />
          )}
        </DataTable>
      </div>
    </div>
  );
};

export default React.memo(OrganisationCollection);
