import React from 'react';
import { Route } from 'react-router-dom';

import Root from 'containers/_organisation/Root';
import Dashboard from 'containers/_organisation/Dashboard';
import ApplicantManagementDashboard from 'containers/_organisation/ApplicantManagementDashboard';
import Profile from 'containers/_organisation/Profile';
import JobUpdate from 'containers/_organisation/JobUpdate';
import JobCollection from 'containers/_organisation/JobCollection';
import JobPreview from 'containers/_organisation/JobPreview';
import ApplicantManagementJob from 'containers/_organisation/ApplicantManagementJob';
import JobSuccess from 'containers/_organisation/JobSuccess';
import Purchase from 'containers/_organisation/Purchase';
import PurchaseSuccess from 'containers/_organisation/PurchaseSuccess';
import InvoiceCollection from 'containers/_organisation/InvoiceCollection';
import UserCollection from 'containers/_organisation/UserCollection';
import ViewApplicant from 'containers/_organisation//ViewApplicant';

import { useFeatureFlag } from 'hooks';

const OrgRoutes = () => {
  let isAMSEnabled = useFeatureFlag('AMS');

  return (
    <Route path="/organisation">
      <Root>
        <Route path="/organisation" exact component={isAMSEnabled ? ApplicantManagementDashboard : Dashboard} />
        <Route path="/organisation/welcome" component={isAMSEnabled ? ApplicantManagementDashboard : Dashboard} />
        <Route path="/organisation/profile" component={Profile} />
        <Route path="/organisation/users" component={UserCollection} />
        <Route path="/organisation/jobs" exact component={JobCollection} />
        <Route path="/organisation/job/create" component={JobUpdate} />
        <Route path="/organisation/job/success" component={JobSuccess} />
        <Route path="/organisation/jobs/:jobType" component={JobCollection} />
        <Route path="/organisation/job/:jobId/view" component={isAMSEnabled ? ApplicantManagementJob : JobPreview} />
        <Route path={["/organisation/job/:jobId/update/:previewed", "/organisation/job/:jobId/update"]} component={JobUpdate} />
        <Route path="/organisation/job/:jobId/applications/:applicationId" component={ViewApplicant} />
        <Route path="/organisation/purchase" exact component={Purchase} />
        <Route path="/organisation/purchase/welcome" component={Purchase} />
        <Route path="/organisation/purchase/success/:serviceType" component={PurchaseSuccess} />
        <Route path="/organisation/invoices" component={InvoiceCollection} />
      </Root>
    </Route>
  );
};

export default OrgRoutes;
