import styled from 'styled-components';

import { Theme } from '@ethical-jobs/design-system-react/dist/src/themes/types';

type OptionContainerProps = { isSelected: boolean, theme: Theme };

export const OptionContainer = styled.div<OptionContainerProps>`
    background-color: ${({ theme }: OptionContainerProps) => theme.designSystemReact.colors.main.white};
    position: relative;
    display: flex;
    align-items: center;
    width: 280px;
    height: 100px;
    padding: 0px 10px;
    border-width: 1px;
    border-radius: 5px;
    border-style: solid;
    border-color:  ${({ isSelected, theme }: OptionContainerProps) => isSelected ? theme.designSystemReact.colors.main.appleGreen : theme.designSystemReact.colors.main.greyLight};
    margin-right: 10px;
    cursor: pointer;
`;

type OptionCheckboxProps = { isSelected: boolean, theme: Theme };

export const OptionCheckbox = styled.div<OptionCheckboxProps>`
    display: flex;
    min-width: 30px;
    height: 30px;
    border-radius: 15px;
    background-color: ${({ isSelected, theme }: OptionCheckboxProps) => isSelected ? theme.designSystemReact.colors.main.appleGreen : theme.designSystemReact.colors.main.white};
    align-items: center;
    justify-content: center;
    border 1px solid ${({ isSelected, theme }: OptionCheckboxProps) => isSelected ? theme.designSystemReact.colors.main.appleGreen : theme.designSystemReact.colors.main.greyLight};
`;
