import React, { useMemo, useCallback } from 'react';

import _ from 'lodash'
import { useSelector, shallowEqual } from 'react-redux';

import Select from 'components/Select';

type Props = {
  value?: number | null;
  disabled?: boolean;
  includeArchived?: boolean;
  onChange: (value: number | null) => void;
  isClearable?: boolean;
};

const OrganisationSelect = ({ value = null, disabled = false, onChange, includeArchived = false, isClearable = false }: Props) => {
  // @ts-ignore
  let organisations = useSelector(state => state.organisations.organisations, shallowEqual);

  const onChangeFn = useCallback((selected: { value: string, videoUrl?: string, label: string }) => {
    onChange(selected ? Number(selected.value) : null);
  }, [onChange]);

  const filteredOrganisations = includeArchived
    ? Object.values(organisations)
    : _.filter(organisations, organisation => organisation.deletedAt === null);

  const options = useMemo(() => _.orderBy(filteredOrganisations.map(organisation => ({ value: `${organisation.id}`, label: `${organisation.name} (${organisation.uid})`.trim(), videoUrl: organisation.videoUrl })), item => item.label), [filteredOrganisations]);

  return (
    <Select
      options={options}
      value={value === null ? null : `${value}`}
      disabled={disabled}
      // @ts-ignore
      onChange={onChangeFn}
      isClearable={isClearable}
      placeholder="Select an organisation"
      label="Organisation"
    />
  );
};

export default React.memo(OrganisationSelect);