import createReducer from 'lib/createReducer';

export const initialState = {
  user: {}
};

const taxonomies = createReducer(initialState, {
  'AUTH/LOGIN': (state: Object, action: { type: string, payload: Object }) => ({ ...state, user: action.payload }),
  'AUTH/LOAD_USER_DETAILS': (state: Object, action: { type: string, payload: Object }) => ({ ...state, user: action.payload }),
  'AUTH/LOGOUT': () => initialState,
});

export default taxonomies;