import React from 'react';

import { LinkContainer } from 'react-router-bootstrap';
import Button from 'react-bootstrap/lib/Button';

import PasswordRecoverForm from 'forms/PasswordRecoverForm';
import Logo from 'components/Logo';
import Card from 'components/Card';

const PasswordRecover = () => (
  <div className="recover-container auth-container container">
    <div className="row">
      <div className="col-md-offset-2 col-md-8">
        <Logo />
        <Card title="Recover your password" className="animated fadeInUp">
          <PasswordRecoverForm />
        </Card>
        <LinkContainer to="/login">
          <Button bsStyle="link">&larr; Back to Login</Button>
        </LinkContainer>
      </div>
    </div>
  </div>
);

export default React.memo(PasswordRecover);
