import React from 'react';

import { loadStripe } from '@stripe/stripe-js';
import { Elements } from '@stripe/react-stripe-js';
import { Invoice } from '@ethical-jobs/sdk-js/types';

import env from 'lib/env';
import Icon from 'components/Icon';
import CardForm from './CardForm';

const stripePromise = loadStripe(env.STRIPE_KEY);

type Props = {
  disabled?: boolean;
  onSubmittingChange?: (isSubmitting: boolean) => void;
  purchase: (values: { method: string, token: string }) => Promise<Invoice>;
};

const CreditCardForm = ({ disabled = false, onSubmittingChange = () => {}, purchase }: Props) => (
  <div className="cc-purchase-form">
      <Elements stripe={stripePromise}>
        <CardForm
          disabled={disabled}
          onSubmittingChange={onSubmittingChange}
          purchase={token => purchase({ method: 'credit_card', token })}
        />
      </Elements>
    <p className="security">
      <Icon type="https" />
      Credit card transactions are secured via our encrypted HTTPS Gateway
    </p>
  </div>
);

export default CreditCardForm;