import React from 'react';
import { Answer, JobQuestion } from '@ethical-jobs/sdk-js/types';
import styled from 'styled-components';

import Icon from '../../Icon';

type ContainerProps = {
  isLast: boolean;
}

const Container = styled.div<ContainerProps>`
  align-items: center;
  display: flex;
  margin-bottom: ${props => props.isLast ? 0 : '20px'};
`;

const AnswerText = styled.p`
  margin-bottom: 0;
  font-size: 18px;
  padding-left: 10px;
`;

type Props = {
  answer: Answer;
  isLast: boolean;
  jobQuestions: Array<JobQuestion>;
}

const AnswerItem = ({jobQuestions, answer, isLast}: Props) => {
  const question = jobQuestions.find(jq => jq.id === answer.questionId);

  if (!question) return null;

  const isYes = answer.answer === 'Yes';

  return (
    <Container key={answer.id} isLast={isLast}>
      <AnswerIcon isYes={isYes} />
      <AnswerText>{question.displayText}</AnswerText>
    </Container>
  );
};

type AnswerIconProps = {
  isYes: boolean;
}

const AnswerIcon = ({isYes}: AnswerIconProps) => (
  isYes ?
    <Icon type="check" style={{ color: '#3E8420', fontSize: 30 }} /> :
    <Icon type="close" style={{ color: '#E22A2A', fontSize: 30 }} />
);

export default AnswerItem;
