import React, { useState, useCallback, useContext } from 'react';

import _ from 'lodash';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';

import { Job, User, Organisation } from '@ethical-jobs/sdk-js/types';

import { fireConfetti } from 'lib/confetti';
import { APIContext, AmplitudeContext } from 'lib/contexts';
import submissionError from 'lib/submissionError';

import { loadUserDetails } from 'actions/auth';
import { cloneJob } from 'actions/jobs';

import JobPreviewer from 'components/JobPreviewer';
import ValidationMessages from 'components/ValidationMessages';
import Card from 'components/Card';
import JobAdStepProgressBar from 'components/JobAdStepProgressBar';

type Props = {
  job: Job;
  Actions: React.FunctionComponent;
  successRoute?: string;
  updateRoute?: string;
  fetching?: boolean;
  showProgressBar?: boolean;
  reloadJob: () => void;
};

const JobPreview = ({ job, successRoute, updateRoute, Actions, fetching = false, showProgressBar, reloadJob }: Props) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();
  const api = useContext(APIContext);
  const amplitude = useContext(AmplitudeContext);

  // @ts-ignore
  const user: User & { organisation: Organisation } = useSelector(state => state.auth.user, shallowEqual);

  const [error, setError] = useState(null);

  const onError = useCallback(response => {
    try {
      submissionError(response);
    } catch (error) {
      setError(error.errors);
    }
  }, []);

  const onSuccess = useCallback(() => {
    if (successRoute) {
      return history.push(successRoute);
    }
  }, [successRoute, history]);

  const setVideoAsDefault = useCallback(async (organisationId: number, videoUrl: string) => {
    try {
      let updatedOrganisation = await api.updateOrganisation(organisationId, { videoUrl });
      amplitude.logEvent('API', { function: 'updateOrganisation', arguments: { organisationId, videoUrl }});
      return updatedOrganisation;
    } catch (error) {
      amplitude.logError('containers/JobPreview/index/setVideoAsDefault', error);
    }
  }, [api, amplitude]);

  const action = useCallback(async (actionName) => {
    const jobId = job.id;
    const userId = user.id;

    if (actionName === 'reload') reloadJob();
    if (actionName === 'edit' && _.isString(updateRoute)) history.push(updateRoute);
    if (actionName === 'update') {
      try {
        let updatedJob = await api.updateJob(jobId, job);
        amplitude.logEvent('API', { function: 'updateJob', arguments: { jobId, ...job }});
        onSuccess();
        reloadJob();
        return updatedJob;
      } catch (error) {
        amplitude.logError('containers/JobPreview/index/action:update', error);
        onError(error);
      }
    }
    if (actionName === 'pending') {
      try {
        let updatedJob = await api.updateJob(jobId, { status: 'PENDING' });
        amplitude.logEvent('API', { function: 'updateJob', arguments: { jobId, status: 'PENDING' }});
        await setVideoAsDefault(_.get(job, ['organisation', 'id'], 0), job.videoUrl);
        dispatch(loadUserDetails());
        
        if (user.roles.includes('staff-member')) {
          reloadJob();
        } else {
          history.push(`/organisation/job/${jobId}/view?action=submit`);

          // Needed because react router doesn't trigger a re-render if you're already on that location
          if (location.pathname === `/organisation/job/${jobId}/view`) {
            window.location.reload();
            window.scrollTo(0, 0);
          }
        }

        return updatedJob;
      } catch (error) {
        amplitude.logError('containers/JobPreview/index/action:pending', error);
        onError(error);
      }
    }
    if (actionName === 'approve') {
      try {
        let updatedJob = await api.approveJob(jobId);

        // Fire some confetti every so often
        if (Math.random() < 0.02) {
          fireConfetti();
        }

        amplitude.logEvent('API', { function: 'approveJob', arguments: { jobId }});
        reloadJob();
        return updatedJob;
      } catch (error) {
        amplitude.logError('containers/JobPreview/index/action:approve', error);
        onError(error);
      }
    }
    if (actionName === 'expire') {
      try {
        let updatedJob = await api.expireJob(jobId);
        amplitude.logEvent('API', { function: 'expireJob', arguments: { jobId }});

        if (user.roles.includes('staff-member')) {
          reloadJob();
        } else {
          history.push(`/organisation/job/${jobId}/view?action=expire`);

          // Needed because react router doesn't trigger a re-render if you're already on that location
          if (location.pathname === `/organisation/job/${jobId}/view`) {
            window.location.reload();
          }
        }

        return updatedJob;
      } catch (error) {
        amplitude.logError('containers/JobPreview/index/action:expireJob', error);
        onError(error);
      }
    }

    if (actionName === 'extend') {
      amplitude.logEvent('API', { function: 'extendJob', arguments: { jobId }});
      if (user.roles.includes('staff-member')) {
        reloadJob();
      } else {
        history.push(`/organisation/job/${jobId}/view?action=extend`);

        // Needed because react router doesn't trigger a re-render if you're already on that location
        if (location.pathname === `/organisation/job/${jobId}/view`) {
          window.location.reload();
        }
      }
    }

    if (actionName === 'archive') {
      try {
        let updatedJob = await api.archiveJob(jobId);
        amplitude.logEvent('API', { function: 'archiveJob', arguments: { jobId }});
        reloadJob();
        return updatedJob;
      } catch (error) {
        amplitude.logError('containers/JobPreview/index/action:archiveJob', error);
        onError(error);
      }
    }

    if (actionName === 'restore') {
      try {
        let updatedJob = await api.restoreJob(jobId);
        amplitude.logEvent('API', { function: 'restoreJob', arguments: { jobId }});
        reloadJob();
        return updatedJob;
      } catch (error) {
        amplitude.logError('containers/JobPreview/index/action:restoreJob', error);
        onError(error);
      }
    }

    if (actionName === 'lock') {
      try {
        let updatedJob = await api.lockJob(jobId, userId);
        amplitude.logEvent('API', { function: 'lockJob', arguments: { jobId, userId }});
        reloadJob();
        return updatedJob;
      } catch (error) {
        amplitude.logError('containers/JobPreview/index/action:lockJob', error);
        onError(error);
      }
    }

    if (actionName === 'unlock' && _.isNumber(job.lockedBy)) {
      try {
        let updatedJob = await api.unlockJob(jobId, job.lockedBy);
        amplitude.logEvent('API', { function: 'unlockJob', arguments: { jobId, userId: job.lockedBy }});
        reloadJob();
        return updatedJob;
      } catch (error) {
        amplitude.logError('containers/JobPreview/index/action:unlockJob', error);
        onError(error);
      }
    }

    if (actionName === 'duplicate') {
      try {
        if (!job.organisation) throw new Error('Invalid Organisation Id');

        let draftJob = await api.createJobDraft(job.organisation.id);

        const duplicateJobFields = {
          ...draftJob,
          ..._.pick(job, ['title', 'application', 'categories', 'description', 'isFeatured', 'isExpirationHidden', 'sectors', 'summary', 'workTypes', 'videoUrl', 'location', 'applyMethod', 'applyEmail', 'attachments']),
          expiresAt: undefined,
          applyButton: null, // Clear the Apply Button as it will never be correct
          applyMethod: draftJob.applyMethod || 'email' // Default to email if applyMethod isn't set
        };

        dispatch(cloneJob(duplicateJobFields));
        history.push('/admin/job/create');
        amplitude.logEvent('API', { function: 'duplicateJob', arguments: { jobId, isAdmin: true }});
      } catch (error) {
        amplitude.logError('containers/JobPreview/index/action:duplicateJob', error);
        onError(error);
      }
    }

    if (actionName === 'duplicateJobEmployer') {
      try {
        if (!job.organisation) throw new Error('Invalid Organisation Id');

        let draftJob = await api.createJobDraft(job.organisation.id);

        const duplicateJobFields = {
          ...draftJob,
          ..._.pick(job, ['title', 'application', 'categories', 'description', 'isFeatured', 'isExpirationHidden', 'sectors', 'summary', 'workTypes', 'videoUrl', 'location', 'applyMethod', 'applyEmail', 'attachments']),
          expiresAt: undefined,
          applyButton: null, // Clear the Apply Button as it will never be correct
          applyMethod: draftJob.applyMethod || 'email' // Default to email if applyMethod isn't set
        };

        dispatch(cloneJob(duplicateJobFields));
        amplitude.logEvent('API', { function: 'duplicateJob', arguments: { jobId, isAdmin: false }});
        history.push('/organisation/job/create');
      } catch (error) {
        amplitude.logError('containers/JobPreview/index/action:duplicateJobEmployer', error);
        onError(error);
      }
    }
  }, [api, onError, job, user, dispatch, setVideoAsDefault, onSuccess, history, updateRoute, amplitude, reloadJob, location]);

  return (
    <div className={`job-preview-${job.status}`}>
      {showProgressBar ? (
        <div>
          <JobAdStepProgressBar activeStep={1} />
          <Card
            className="job-actions organisation-draft"
            title="Your job ad is not yet submitted"
            subTitle="Please review your job ad. If you are happy with your changes, click 'Submit For Approval' below. Otherwise, click 'Continue Editing' to go back and make changes."
          />
        </div>
      ) : (Actions && (
        <Actions
          // @ts-ignore
          action={action}
          author={job.author}
          job={job}
          fetching={!!fetching}
          isAdmin={user.roles.includes('staff-member')}
          currentUser={user}
        />
      ))}
      <ValidationMessages error={error} />
      <JobPreviewer job={job} />
      {showProgressBar && Actions && (
        <Actions
          // @ts-ignore
          action={action}
          author={job.author}
          job={job}
          fetching={!!fetching}
          isAdmin={user.roles.includes('staff-member')}
          currentUser={user}
        />
      )}
    </div>
  );
}

export default React.memo(JobPreview);
