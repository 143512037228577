import React from 'react';
import FormField from 'fields/FormField';
import Card from 'components/Card';
import ReactPlayer from 'react-player';
import { reduxForm, formValueSelector } from 'redux-form';
import { useSelector, shallowEqual } from 'react-redux';

const selector = formValueSelector('jobForm');

const Video = () => {
  const videoUrl = useSelector(state => selector(state, 'videoUrl'), shallowEqual);

  return (
    <Card title="Recruitment Video">
      <div className="row form-group video">
        <FormField
          name="videoUrl"
          component="input"
          type="text"
          label="Show this recruitment video at the bottom of my ad"
          description="Supported sources include YouTube, Facebook, Twitch, SoundCloud, Streamable, Vimeo, Wistia, Mixcloud, and DailyMotion"
          placeholder="E.g. https://www.youtube.com/watch?v=MDMWsTXON-E"
          wrapClassName="col-md-12"
          optional
        />
      </div>
      {videoUrl && (
        <div>
          <ReactPlayer url={videoUrl} controls />
        </div>
      )}
    </Card>
  );
};

export default reduxForm({
  form: 'jobForm'
})(Video);