import React from 'react';
import Select from 'components/Select';

type Props = {
  options: Array<{ value: string, label: string }>;
  value: Array<string>;
  onChange: (values: Array<string>) => void;
  multi: boolean;
  disabled: boolean;
  placeholder: string;
};

const MultiSelectField = ({ options, value, onChange, multi = false, disabled = false, placeholder }: Props) => (
  <Select
    options={options}
    multi={multi}
    // @ts-ignore
    value={value}
    disabled={disabled}
    placeholder={placeholder}
    // @ts-ignore
    onChange={selected => onChange(selected ? selected.value : null)}
  />
);

export default MultiSelectField;