import React from 'react';

const FileIcon = () => {
  return (
    <svg
      viewBox="0 0 24 24"
      width={40}
      height={40}
      role="img"
    >
      <path
        d="M6 2c-1.1 0-1.99.9-1.99 2L4 20c0 1.1.89 2 1.99 2H18c1.1 0 2-.9 2-2V8l-6-6H6zm7 7V3.5L18.5 9H13z"
        fill="currentColor"
      />
      <path d="M0 0h24v24H0z" fill="none" />
    </svg>
  );
};

export default FileIcon;