import React, { useContext, useMemo } from 'react';

import _ from 'lodash';
import {
  LineChart as LineChartCmp,
  Line,
  ResponsiveContainer,
  CartesianGrid,
  XAxis,
  YAxis,
  Tooltip,
  Legend
} from 'recharts';
import { ReportParams } from '@ethical-jobs/sdk-js/types';

import { APIContext } from 'lib/contexts';
import { usePromise } from 'hooks';
import { numberWithCommas } from 'lib/formatters';
import Card from 'components/Card';

type Props = {
  title: string;
  xAxisKey: string;
  primary: {
    key: string;
    label: string;
  };
  secondary: {
    key: string;
    label: string;
  };
  query: ReportParams;
  reportCallback: (report: Array<Object>) => Array<Object>;
};

const LineChart = ({ title, xAxisKey, primary, secondary, query, reportCallback }: Props) => {
  const api = useContext(APIContext);
  
  const getReportData = useMemo(() => api.getReportData({ ...query }), [query, api]);

  const [report] = usePromise(getReportData, [], { component: 'LineChart' });

  let processedReport = _.isFunction(reportCallback)
        ? reportCallback(report)
        : report;

  return (
    <Card subTitle={title} className="line-chart">
      <ResponsiveContainer width="100%" height={300}>
        <LineChartCmp data={processedReport}>
          <Line
            type="monotone"
            dataKey={primary.key}
            name={primary.label}
            stroke="#56AF31"
          />
          {secondary && (
            <Line
              type="monotone"
              dataKey={secondary.key}
              name={secondary.label}
              stroke="#6eaede"
            />
          )}
          <CartesianGrid stroke="#ecedf2" />
          <XAxis dataKey={xAxisKey} />
          <YAxis tickFormatter={value => numberWithCommas(Number(value))} />
          <Legend />
          <Tooltip formatter={value => numberWithCommas(Number(value))} />
        </LineChartCmp>
      </ResponsiveContainer>
    </Card>
  );
}

export default React.memo(LineChart);