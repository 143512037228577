export type FeatureFlag = 'AMS' | 'jobQuestions';

const TEST_ORGS = [1352, 10265, 10734];

const featureFlags = {
  production: {
    AMS: {
      organisations: [...TEST_ORGS]
    },
    jobQuestions: {
      organisations: [10265]
    },
  },
  staging: {
    AMS: {
      organisations: [...TEST_ORGS, -1, 4943]
    },
    jobQuestions: {
      organisations: [10265, 4943]
    },
  }
};

export default featureFlags;
