import React, { Fragment } from 'react';

import _ from 'lodash';
import moment from 'moment';
import { useSelector } from 'react-redux';

import FormField from 'fields/FormField';
import EditableField from 'fields/EditableField';
import Card from 'components/Card';
import Icon from 'components/Icon';

import ApplyMethodSelector from './ApplyMethodSelector';

type Props = {
  isAdmin?: boolean;
  quickLinks: Array<string> | null;
  applicationTemplates: Array<Object> | null;
}

const ApplyNow = ({ isAdmin = false, quickLinks, applicationTemplates }: Props) => {
  const applyMethod = useSelector(state => _.get(state, ['form', 'jobForm', 'values', 'applyMethod'], null))

  return (
    <Fragment>
      <Card title="How would you like to manage candidates for this job listing?">
        {moment().isBefore('2020-07-01') && (
          <div style={{ borderStyle: 'solid', borderWidth: 2, borderColor: '#3E8420', padding: '10px 15px', borderRadius: 5, marginBottom: 15, backgroundColor: '#DBEED3' }}>
            <p style={{ fontSize: 15, fontWeight: 'bold' }}>
              Hi there! We’ve made a small change to this page
            </p>
            <p style={{ fontSize: 15 }}>
              Just letting you know that candidates can now apply for your jobs directly through the EthicalJobs.com.au platform by clicking an "Apply Now" button on the job, filling in their details and uploading their cover letter and resume.
            </p>
            <p style={{ fontSize: 15 }}>
              Below you’ll be prompted to enter either a) an email address; or b) an external link where you’d like to receive your applications.
            </p>
            <p style={{ fontSize: 15 }}>
              If you enter an email, your application instructions will now appear on the job’s application form, instead of on the job ad itself (as they previously did.)
            </p>
            <p style={{ fontSize: 15 }}>
              Simply hover over the <Icon type="help" style={{ fontSize: 17, top: 3 }} /> icon below for more details or contact us at <a href="mailto:info@ethicaljobs.com.au" style={{ color: '#3E8420' }}>info@ethicaljobs.com.au</a> or on 03 9419 5979 if you have any questions.
            </p>
          </div>
        )}

        <div className="row">
          <FormField
            name="applyMethod"
            component={ApplyMethodSelector}
            wrapClassName="col-md-12"
            isAdmin={isAdmin}
          />
        </div>
      </Card>
      {applyMethod !== 'button' && (
        <Card>
          <FormField
            required
            component={EditableField}
            name="application"
            id="application"
            label="Application Instructions"
            description="Please include instructions for jobseekers on what should be included in the application. This will appear on the application form page."
            limit={999999}
            quickLinks={quickLinks || []}
            templates={applicationTemplates || []}
          />
        </Card>
      )}
    </Fragment>
  );
};

export default ApplyNow;