import React from 'react';

import GeoPattern from 'geopattern';

import { Text } from '@ethical-jobs/design-system-react';

type Props = {
  src?: string;
  userId: number;
  name: string;
  width?: number;
};

const StaffAvatar = ({ src, userId, name, width = 40 }: Props) => {
  const initials = name.split(' ').slice(0, 2).map(w => w.charAt(0));

  return (!!src && src.startsWith('https://www.gravatar.com/avatar/') === false) ? (
    <img
      src={src}
      style={{
        width: width,
        height: width,
        borderRadius: '50%'
      }}
      alt="Locked by User"
      title={name}
    />
  ) : (
    <div style={{ width: width, height: width, borderRadius: '50%', backgroundColor: GeoPattern.generate(userId.toString()).color, display: 'flex', alignItems: 'center', justifyContent: 'center' }} title={name}>
      <Text style={{ color: 'white', fontSize: 18 }}>{initials}</Text>
    </div>
  );
}

export default StaffAvatar;