import createReducer from 'lib/createReducer';

export const initialState = {
  clonedJob: null
};

const jobs = createReducer(initialState, {
  'CLONE_JOB': (state: Object, action: { type: string, payload: Object }) => ({ ...state, clonedJob: action.payload }),
});

export default jobs;