import React, { useEffect, useState, Fragment, useMemo } from 'react';

import { useHistory } from 'react-router-dom';

import _ from 'lodash';
import moment from 'moment';

import { useScrollPosition } from 'hooks';

import { Select, Button, Icon, Input } from '@ethical-jobs/design-system-react';

import { Job } from '@ethical-jobs/sdk-js/types';
import Tabs from 'components/Tabs';

import { HeadingContainer, FilterContainer, ViewIconContainer } from './ApplicantManagementDashboard.styles';

import JobTile from './JobTile';
import JobRow from './JobRow';

import { JobWithApplicants } from './index';

const getJobTab = (job: Job) => {
  if (job.status === 'PENDING') return 'pending';
  if (job.status === 'APPROVED') {
    return moment().isAfter(job.expiresAt)
      ? 'expired'
      : 'live';
  }
}

type Props = {
  jobs: Array<JobWithApplicants>;
  userFirstName: string;
}

const ApplicantManagementDashboard = ({ jobs, userFirstName }: Props) => {
  const history = useHistory();

  const [filteredJobs, setFilteredJobs] = useState([]);

  const [query, setQuery] = useState('');
  const [selectedTab, setSelectedTab] = useState('live');
  const [inTabView, setInTabView] = useState(true);
  const [sortOrder, setSortOrder] = useState('alphabetical');
  const [filterState, setFilterState] = useState('all');
  const [filterJobCreator, setFilterJobCreator] = useState('all');
  const [isFiltersSticky, setIsFiltersSticky] = useState(false);
  const [numJobs, setNumJobs] = useState({ pending: 0, live: 0, expired: 0 });

  useScrollPosition(({ currPos }) => {
    if (currPos.y < -110 && !isFiltersSticky) setIsFiltersSticky(true);
    if (currPos.y > -110 && isFiltersSticky) setIsFiltersSticky(false);
  }, [isFiltersSticky], null, false, 50);

  useEffect(() => {
    setNumJobs({
      live: jobs.filter(job => getJobTab(job) === 'live').length,
      pending: jobs.filter(job => getJobTab(job) === 'pending').length,
      expired: jobs.filter(job => getJobTab(job) === 'expired').length
    });
  }, [jobs]);

  useEffect(() => {
    let sJobs = _.sortBy(jobs.filter(job =>
        getJobTab(job) === selectedTab &&
        (filterState === 'all' || job.location.title === filterState) && 
        (filterJobCreator === 'all' || job.author?.name === filterJobCreator)), job => {
      if (sortOrder === 'expiry') return moment(job.expiresAt).unix();
      if (sortOrder === 'created') return moment(job.createdAt).unix();
      return job.title;
    });

    if (query === '') {
      setFilteredJobs(sJobs);
    }

    const lcQuery = query.toLowerCase();
    let fJobs = sJobs.filter(job => job.title.toLowerCase().includes(lcQuery));
    setFilteredJobs(fJobs);
  }, [jobs, query, selectedTab, sortOrder, filterState, filterJobCreator]);

  const jobStates = useMemo(() => {
    return [{ value: 'all', label: 'All' }, ..._.sortBy(jobs.reduce((acc, job) => {
      if (!acc.find(item => item.value === job.location.title)) {
        acc.push({
          value: job.location.title,
          label: job.location.title
        });
      }

      return acc;
    }, []), item => item.value)];
  }, [jobs]);

  const jobCreators = useMemo(() => {
    return [{ value: 'all', label: 'All' }, ..._.sortBy(jobs.reduce((acc, job) => {
      if (job.author && !acc.find(item => item.value === job.author.name)) {
        acc.push({
          value: job.author.name,
          label: job.author.name
        });
      }

      return acc;
    }, []), item => item.value)];
  }, [jobs]);
  
  return (
    <div className="dashboard-container">
      <HeadingContainer>
        <Icon type="home" style={{ color: 'grey' }} />
        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', paddingBottom: 10 }}>
          <p style={{ fontSize: 32, fontWeight: 'bold' }}>{`Welcome back ${userFirstName}`}</p>
          <Button
            type="primary"
            onClick={() => history.push('/organisation/job/create')}
            label="Create new job"
            icon="add"
            size="medium"
          />
        </div>

        <Tabs
          activeTab={selectedTab}
          onChange={setSelectedTab}
          tabs={[{
            id: 'pending',
            label: `${numJobs.pending} job ads pending approval`
          }, {
            id: 'live',
            label: `${numJobs.live} live job ads`
          }, {
            id: 'expired',
            label: `${numJobs.expired} expired job ads`
          }]}
        />
      </HeadingContainer>

      <div style={{ padding: '0px 60px' }}>
        <FilterContainer isFiltersSticky={isFiltersSticky}>
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <div style={{ width: 300 }}>
              <Input
                type="text"
                placeholder={`Search ${selectedTab} jobs...`}
                onChange={setQuery}
                icon="search"
                size="large"
              />
            </div>

            <Select
              isClearable={false}
              label="Sort"
              value={sortOrder}
              width={240}
              options={[{
                value: 'alphabetical',
                label: 'Alphabetical'
              }, {
                value: 'created',
                label: 'Created Date'
              }, {
                value: 'expiry',
                label: 'Expiry Date'
              }]}
              onChange={({ value }: { value: string }) => setSortOrder(value)}
              style={{ marginLeft: 30 }}
            />

            {jobStates.length > 1 && (
              <Select
                isClearable={false}
                value={filterState}
                width={240}
                label="State"
                options={jobStates}
                onChange={({ value }) => setFilterState(value)}
                style={{ marginLeft: 30 }}
              />
            )}
            {jobCreators.length > 1 && (
              <Select
                isClearable={false}
                value={filterJobCreator}
                label="Creator"
                width={240}
                options={jobCreators}
                onChange={({ value }) => setFilterJobCreator(value)}
                style={{ marginLeft: 30 }}
              />
            )}
          </div>
          <div style={{ display: 'flex' }}>
            <ViewIconContainer
              isSelected={inTabView}
              onClick={() => setInTabView(true)}
            >
              <Icon type="grid" size="large" style={{ color: '#0079c1' }} />
            </ViewIconContainer>
            <ViewIconContainer
              isSelected={!inTabView}
              onClick={() => setInTabView(false)}
            >
              <Icon type="list" size="large" style={{ color: '#0079c1' }} />
            </ViewIconContainer>
          </div>
        </FilterContainer>

        <div style={{ paddingBottom: 30 }}>
          {inTabView ? (
            <div style={{ display: 'grid', gridGap: 25, gridTemplateColumns: 'repeat(auto-fill, 400px)' }}>
              {filteredJobs.map(job => (
                <JobTile
                  key={job.id}
                  onClick={() => history.push(`/organisation/job/${job.id}/view`)}
                  job={job}
                  activeTab={selectedTab}
                />
              ))}
            </div>
          ) : (
            <Fragment>
              <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', padding: '0px 15px' }}>
                <p style={{ flex: 3, marginBottom: 0, color: 'grey', fontSize: 16 }}>Job title</p>
                <p style={{ flex: 1, marginBottom: 0, color: 'grey', fontSize: 16, textAlign: 'center' }}>Date created</p>
                <p style={{ flex: 1, marginBottom: 0, color: 'grey', fontSize: 16, textAlign: 'center' }}>Expires in</p>
                <div style={{ flex: 1, display: 'flex', justifyContent: 'center' }}>
                  <Icon type="group" style={{ color: '#3E8420' }} />
                  <p style={{ paddingLeft: 5, marginBottom: 0, color: 'grey', fontSize: 16, textAlign: 'center' }}>Applicants</p>
                </div>
                <p style={{ flex: 1, marginBottom: 0, color: 'grey', fontSize: 16, textAlign: 'center' }}>Views</p>
              </div>
              {filteredJobs.map(job => (
                <JobRow
                  key={job.id}
                  onClick={() => history.push(`/organisation/job/${job.id}/view`)}
                  job={job}
                  activeTab={selectedTab}
                />
              ))}
            </Fragment>
          )}
        </div>
      </div>
    </div>
  );
};

export default ApplicantManagementDashboard;
