import React from 'react';

import { Droppable } from 'react-beautiful-dnd';

import { Tab } from 'components/Tabs';
import Stage from './Stage';



type Props = {
  activeTab: string;
  tabs: Array<Tab>;
  onChange: (selectedTab: string) => void;
  isDroppable?: boolean;
  isDragging?: boolean;
  fontStyle?: Object
};

const Stages = ({ tabs, activeTab, onChange, isDroppable = false, isDragging = false, fontStyle = {} }: Props) => (
  <div style={{ display: 'flex' }}>
    {tabs.map((tab, index) => isDroppable ? (
        <Droppable key={tab.id} droppableId={tab.id} isDropDisabled={tab.id === activeTab}>
        {(provided, snapshot) => (
          <div ref={provided.innerRef}>
            <Stage
              key={tab.id}
              label={tab.label}
              activeColor={tab.activeColor}
              inactiveColor={tab.inactiveColor}
              hoverColor={tab.hoverColor}
              icon={tab.icon}
              onClick={() => onChange(tab.id)}
              isActiveTab={activeTab === tab.id}
              index={index}
              numTabs={tabs.length}
              isDraggingOver={snapshot.isDraggingOver}
              isDragging={isDragging}
              fontStyle={fontStyle}
              disabled={tab.disabled}
            />
          </div>
        )}
      </Droppable>
    ) : (
      <Stage
        key={tab.id}
        label={tab.label}
        activeColor={tab.activeColor}
        inactiveColor={tab.inactiveColor}
        onClick={() => onChange(tab.id)}
        isActiveTab={activeTab === tab.id}
        index={index}
        numTabs={tabs.length}
        fontStyle={fontStyle}
        disabled={tab.disabled}
      />
    ))}
  </div>
);

export default Stages;
