import React, { useCallback } from 'react';

import { reduxForm } from 'redux-form';
import { Media } from '@ethical-jobs/sdk-js/types';

import submissionError from 'lib/submissionError';
import { schemaValidator } from 'lib/validation';
import schema from './schema';
import StepOne from './StepOne';
import StepTwo from './StepTwo';
import ValidationMessages from 'components/ValidationMessages';
import Button from 'components/Button';

import './styles.css';

type Props = {
  uploadLogo: (files: Array<File>) => Promise<Media>;
  signup: (values: Object) => Promise<Object>;
  handleSubmit: any;
  error?: string;
  submitting: boolean;
};

const OrganisationSignupForm = ({ signup, uploadLogo, submitting, handleSubmit, error }: Props) => {
  const submit = useCallback(async (values) => {
    try {
      await signup(values);
    } catch(error) {
      submissionError(error);
    }
  }, [signup]);

  return (
    <form
      className="organisation-signup-form form-horizontal"
      onSubmit={handleSubmit(submit)}
    >
      <StepOne />

      <div style={{ width: '100%', height: 1, backgroundColor: 'lightGrey', margin: '20px 0px' }} />

      <StepTwo uploadLogo={uploadLogo} />

      <Button
        loading={submitting}
        icon="check"
        label="Complete your registration"
        type="submit"
        className="register"
        kind="primary"
        block
      />

      <ValidationMessages error={error} />
    </form>
  );
};

export default reduxForm({
  form: 'signup',
  validate: schemaValidator(schema)
  // @ts-ignore
})(OrganisationSignupForm);
