import React, { useContext, useMemo } from 'react';

import { useSelector, shallowEqual } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { JobFields, User, Organisation } from '@ethical-jobs/sdk-js/types';

import { usePromise } from 'hooks';
import { APIContext } from 'lib/contexts';
import PageTitle from 'components/PageTitle';
import JobList from 'components/JobList';
import Welcome from 'components/Welcome';
import Container from 'components/Container';

const Dashboard = () => {
  const api = useContext(APIContext);
  const { pathname } = useLocation();

  // @ts-ignore
  const user: User & { organisation: Organisation } = useSelector(state => state.auth.user, shallowEqual);

  const getJobs = useMemo(async () => {
    const defaultFilters = {
      organisations: user.organisation.id,
      limit: 5,
      fields: ['id', 'organisationId', 'title', 'status', 'isExpired'] as Array<JobFields>,
      useElasticSearch: false
    };
    
    // TODO: Remove catch when no results stops throwing 404
    let results = await Promise.all([
      api.getJobs({ ...defaultFilters, status: 'APPROVED', includeExpired: false }).catch(() => ({ jobs: [], total: 0, title: '' })),
      api.getJobs({ ...defaultFilters, status: 'PENDING' }).catch(() => ({ jobs: [], total: 0, title: '' })),
      api.getJobs({ ...defaultFilters, includeExpired: true }).catch(() => ({ jobs: [], total: 0, title: '' }))
    ]);

    return results.map(result => result.jobs);
  }, [api, user]);

  const [[liveJobs, pendingJobs, expiredJobs], , isFetching] = usePromise(getJobs, [[], [], []], { component: 'Dashboard' });

  return (
    <Container className="dashboard-container">
      <PageTitle
        title="Dashboard"
        // INFO: Only show the welcome message the first time the user logs in
        subTitle={user.lastLogin === null ? `Welcome ${user.firstName}.` : undefined}
      />
      <div className="row">
        <div className="col-lg-4 col-md-12 col-sm-12">
          <JobList
            fetching={isFetching}
            title="Job ads pending approval"
            to="pending"
            jobs={pendingJobs}
          />
        </div>
        <div className="col-lg-4 col-md-12 col-sm-12">
          <JobList
            title="Live job ads"
            fetching={isFetching}
            to="approved"
            jobs={liveJobs}
          />
        </div>
        <div className="col-lg-4 col-md-12 col-sm-12">
          <JobList
            title="Expired job ads"
            fetching={isFetching}
            to="expired"
            jobs={expiredJobs}
          />
        </div>
      </div>
      {pathname.includes('welcome') && (
        <Welcome
          buttonText="Get started!"
          description='You can now post your first job ad. If you need any assistance along the way, just click the "Help" button at the
            top of your screen and we&apos;ll be more than happy to help out.'
          title="Welcome to EthicalJobs.com.au!"
        />
      )}
    </Container>
  );
};

export default React.memo(Dashboard);
