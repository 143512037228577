import React from 'react';

import { Button as BsButton, Sizes } from 'react-bootstrap';
import Spinner from '../Spinner';
import Icon from '../Icon';

import './button.css';

type Props = {
  icon?: string;
  kind?: string;
  size?: Sizes;
  label?: string | React.ReactNode;
  children?: React.ReactNode;
  loading?: boolean;
  type?: string;
  disabled?: boolean;
  className?: string;
  block?: boolean;
  iconPosition?: 'left' | 'right';
  onClick?: (event: React.MouseEvent<BsButton, MouseEvent>) => void;
  style?: Object;
};

const Button = ({ icon, iconPosition = 'left', kind = 'default', size, label, children, style, loading = false, type, disabled = false, className, block, onClick, ...props }: Props) => (
  <BsButton
    bsStyle={kind}
    bsSize={size}
    type={type}
    disabled={disabled}
    className={className}
    block={block}
    onClick={onClick}
    style={style}
    {...props}
  >
    {loading ? (
      <Spinner show />
    ) : (
      <span>
        {icon && iconPosition === 'left' && <Icon type={icon} style={{ fontSize: size === 'xsmall' ? 18 : 24, padding: 0, paddingRight: 10 }} />}
        {label}
        {children}
        {icon && iconPosition === 'right' && <Icon type={icon} style={{ fontSize: size === 'xsmall' ? 18 : 24, padding: 0, paddingLeft: 10 }} />}
      </span>
    )}
  </BsButton>
);

export default Button;
