import _ from 'lodash';
import * as Yup from 'yup';
import * as set from 'object-set';
import validate from 'validate.js';

export const MESSAGE = {
  INTEGER: 'Please enter whole numbers only',
  MAX: (value: string) => `${value} must be \${max} or less characters long`,
  MIN: (value: string) => `${value} must be \${min} or more characters long`,
  NUMBER: 'Please enter numbers only',
  POSITIVE: 'Please enter positive numbers only',
  REQUIRED: (value: string) => `Please enter ${value}`,
};

// @ts-ignore
const join = rules => (value, data, key) => rules.map(rule => rule(value, data, key)).filter(error => !!error)[0];

// @ts-ignore
export function email(value) {
  if (!_.isEmpty(value) && validate({ from: value }, { from: { email: true } })) {
    return 'Invalid email address';
  }
}

// @ts-ignore
export function required(value, data, fieldName) {
  if (_.isEmpty(value)) {
    return fieldName ? `${fieldName} is required` : 'This field is required';
  }
}

export const minLength = (min: number, fieldName?: string) => (value: string) => {
  if (!_.isEmpty(value) && value.length < min) {
    return fieldName
      ? `${fieldName} must be at least ${min} characters`
      : `Must be at least ${min} characters`;
  }
};


// @ts-ignore
export const maxLength = (max: number, fieldName: string) => (value: string) => {
  if (!_.isEmpty(value) && value.length > max) {
    return fieldName
      ? `${fieldName} must be no more than ${max} characters`
      : `Must be no more than ${max} characters`;
  }
};

// @ts-ignore
export function integer(value, fieldName) {
  if (!Number.isInteger(Number(value))) {
    return fieldName ? `${fieldName} must be a number` : 'Must be a number';
  }
}


export const oneOf = (enumeration: Array<string>, fieldName: string) => (value: string) => {
  if (!enumeration.includes(value)) {
    const enumStr = enumeration.join(', ');
    return fieldName
      ? `${fieldName} must be one of: ${enumStr}`
      : `Must be one of: ${enumStr}`;
  }

  return '';
};

// @ts-ignore
export const matches = (field: string) => (value, data) => (data && data[field] !== value ? 'Do not match' : undefined);

// @ts-ignore
export const createValidator = (rules) => (data = {}) => {
  const errors: { [key: string]: string } = {};
  Object.keys(rules).forEach((key) => {
    const rule = join([].concat(rules[key])); // concat enables both functions and arrays of functions
    // @ts-ignore
    const error = rule(data[key], data, key);
    if (error) {
      errors[key] = error;
    }
  });
  return errors;
};

// @ts-ignore
export const createDeepValidator = (groups: Object) => (data = {}) => _.mapValues(groups, (group, key) => createValidator(group)(data[key]));

// @ts-ignore
export const schemaValidator = schema => values => {
  const formErrors = {};
  try {
    schema.validateSync(values, { abortEarly: false });
  } catch (exception) {
    if (exception instanceof Yup.ValidationError) {
      exception.inner.forEach(error => {
        set(formErrors, error.path, error.message);
      });
    } else {
      throw exception;
    }
  }
  return formErrors;
};
