import React, { useState, useCallback, useEffect } from 'react';

import _ from 'lodash';
import classNames from 'classnames';
import { useSelector, shallowEqual } from 'react-redux';

type Props = {
  value: string;
  roles?: Array<{ name: string, description: string }>;
  onChange: (role: string) => void;
};

const UserRolesFilter = ({ value, roles, onChange }: Props) => {
  // @ts-ignore
  const userRoles = useSelector(state => state.taxonomies.userRoles, shallowEqual);

  const [selectedRole, setSelectedRole] = useState('staff-member');

  useEffect(() => { setSelectedRole(value); }, [value]);

  const onChangeFn = useCallback(({ name }) => {
    setSelectedRole(name)
    onChange(name);
  }, [onChange]);

  return (
    <div className="filter-component">
      {_.map(roles || userRoles, role => (
        // eslint-disable-next-line
        <a
          key={role.name}
          onClick={() => onChangeFn(role)}
          className={classNames({ active: selectedRole === role.name })}
        >
          {role.description}
        </a>
      ))}
    </div>
  );
}

export default UserRolesFilter;