import React from 'react';

import Card from 'components/Card';
import FormField from 'fields/FormField';
import EditableField from 'fields/EditableField';
import TextArea from 'fields/TextArea';

type Props = {
  quickLinks: Array<string> | null;
  textTemplates: Array<Object> | null;
}

const Editors = ({ quickLinks, textTemplates }: Props) => (
  <Card>
    <div className="row form-group editors">
      <FormField
        required
        name="summary"
        component={TextArea}
        label="Summary for Search Results"
        description="This short summary of your job will be displayed on the search results page. 200 character limit."
        wrapClassName="col-md-12"
        limit={200}
        style={{ minHeight: 150 }}
      />
      <FormField
        required
        component={EditableField}
        name="description"
        id="description"
        label="Job Ad Text"
        wrapClassName="col-md-12"
        quickLinks={quickLinks || []}
        templates={textTemplates || []}
      />
    </div>
  </Card>
);

export default Editors;