import React, { useContext, useCallback, useState } from 'react';

import { AmplitudeContext, APIContext } from '../../lib/contexts';
import Spinner from 'components/Spinner';

type Props = {
  invoiceId: number;
}

const InvoiceLink = ({ invoiceId }: Props) => {
  const [loading, setLoading] = useState(false);
  const api = useContext(APIContext);
  const amplitude = useContext(AmplitudeContext);

  const onClick = useCallback(async () => {
    setLoading(true);

    try {
      await api.downloadInvoice(invoiceId);
    } catch (error) {
      amplitude.logError('components/InvoiceLink/index/onClick', error);

      throw error;
    }
    setLoading(false);
  }, [api, amplitude, invoiceId]);

  return loading ? <Spinner show /> : <a onClick={onClick}>Download PDF</a>;
};

export default InvoiceLink;
