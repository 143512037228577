import React, { useCallback, useEffect, useRef } from 'react';

import _ from 'lodash';
import moment from 'moment';

import { useDispatch, useSelector } from 'react-redux';
import { change } from 'redux-form';

import Card from 'components/Card';
import Button from 'components/Button';

import FormField from 'fields/FormField';
import DateTimeField from 'fields/DateTimeField';

import { EXPIRATION_TIMES } from 'lib/constants';

type Props = {
  approvedDate?: string;
  isAdmin: boolean;
};

const Expiration = ({ approvedDate, isAdmin }: Props) => {
  const dispatch = useDispatch();

  const inputRef = useRef();

  const expiresAt = useSelector(state => _.get(state, ['form', 'jobForm', 'values', 'expiresAt'], null));

  const setMaxExpiration = useCallback(() => {
    dispatch(change('jobForm', 'expiresAt', moment(approvedDate).add(45, 'days').hours(17).minutes(0).seconds(0).format('YYYY-MM-DD HH:mm:ss')));
  }, [approvedDate, dispatch]);

  // Set the expiry to round off to the next 30 minute block
  useEffect(() => {
    if (!expiresAt) return;

    const roundedUp = moment(expiresAt).minute(Math.ceil(moment(expiresAt).minute() / 30) * 30).format('YYYY-MM-DD HH:mm:ss');

    // Handle 11:59 PM
    if (expiresAt !== roundedUp && moment(expiresAt).format('HH:mm A') !== '11:59 PM') {
      if (moment(roundedUp).format('HH:mm A') === '00:00 AM') {
        dispatch(change('jobForm', 'expiresAt', moment(roundedUp).subtract(1, 'minute').format('YYYY-MM-DD HH:mm:ss')));
      } else {
        dispatch(change('jobForm', 'expiresAt', roundedUp));
      }
    }
  }, [expiresAt, dispatch]);

  const scrollIntoView = useCallback(() => {
    if (inputRef.current) {
      // @ts-ignore
      inputRef.current.scrollIntoView({
        behavior: 'smooth',
        block: 'start'
      });
    }
  }, [inputRef]);

  return (
    <div ref={inputRef}>
      <Card
        title="Application Closing Date"
        subTitle="Your ad will remain visible on EthicalJobs.com.au until this date. Maximum listing period is 45 days."
      >
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <div style={{ flex: 1 }}>
            <FormField
              name="expiresAt"
              component={DateTimeField}
              minDate={moment(approvedDate).toDate()}
              maxDate={moment(approvedDate).add(45, 'days').endOf('day').toDate()}
              label="Expiration date and time"
              required
              dateFormat="dddd Do MMMM, YYYY"
              onFocus={scrollIntoView}
              times={EXPIRATION_TIMES.map(time => ({ label: time, value: time }))}
            />
          </div>
          {isAdmin && (
            <Button kind="primary" label="Set To Max" style={{ height: 50 }} onClick={setMaxExpiration} />
          )}
        </div>
        <hr />
        <FormField
          name="isExpirationHidden"
          component="input"
          type="checkbox"
          label="Hide this date from job-seekers? (for jobs where there is no application deadline)"
        />
      </Card>
    </div>
  );
};

export default React.memo(Expiration);
