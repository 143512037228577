import React, { Fragment } from 'react';

import _ from 'lodash';
import { useSelector, shallowEqual } from 'react-redux';
import { CreditPack } from '@ethical-jobs/sdk-js/types';

import Button from 'components/Button';
import { formatMoney } from 'lib/formatters';

type State = {
  taxonomies: {
    creditPacks: {
      [id: string]: CreditPack
    }
  }
};

const selectCreditPacks = (state: State) => {
  let sortedCreditPacks = _.sortBy(state.taxonomies.creditPacks, creditPack => creditPack.price);
  return _.groupBy(sortedCreditPacks, creditPack => creditPack.volume)
};

type Props = {
  selectPack: (selectedPack: CreditPack) => void;
};

const PricingTable = ({ selectPack }: Props) => {
  const groupedCreditPacks = useSelector(selectCreditPacks, shallowEqual);

  return (
    <table className="pricing-table">
      <thead>
        <tr>
          <th>Number of Job Ads<span>&nbsp;</span></th>
          <th colSpan={2}>Regular Ads<span>Post a job ad yourself</span></th>
          <th colSpan={2}>Added Value Ads<span>Email us your job ad to post for you</span></th>
        </tr>
      </thead>
      <tbody>
        {_.map(groupedCreditPacks, (creditPacks, volume) => (
          <tr>
            <td>{volume}</td>
            {creditPacks.map(creditPack => (
              <Fragment key={creditPack.id}>
                <td>
                  {formatMoney(creditPack.price)} {creditPack.savings > 0 && <span>(Save {formatMoney(creditPack.savings)})</span>}
                </td>
                <td>
                  <Button
                    onClick={() => selectPack(creditPack)}
                    label="Select"
                    className="btn-secondary"
                  />
                </td>
              </Fragment>
            ))}
          </tr>
        ))}
      </tbody>
    </table>
  );
}


export default PricingTable;
