import React, { useMemo, useContext, useCallback, useEffect, useState } from 'react';

import _ from 'lodash';
import { useSelector, shallowEqual, useDispatch } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import validate from 'validate.js';

import { Organisation, Job, Category, Sector, WorkType } from '@ethical-jobs/sdk-js/types';

import { APIContext, AmplitudeContext } from 'lib/contexts';
import { usePromise } from 'hooks';
import { cloneJob } from 'actions/jobs';

import JobForm from 'forms/JobForm';
import Spinner from 'components/Spinner';

type Props = {
  isAdmin: boolean;
  jobId?: number;
  organisation: Organisation | null;
};

const JobUpdate = ({ isAdmin, jobId, organisation }: Props) => {
  const history = useHistory();
  const location = useLocation();
  const dispatch = useDispatch();
  const api = useContext(APIContext);
  const amplitude = useContext(AmplitudeContext);

  const [hasClonedAttachments, setHasClonedAttachments] = useState(false);
  const [isCloning, setIsCloning] = useState(false);

  // @ts-ignore
  const clonedJob: Job | null = useSelector(state => state.jobs.clonedJob, shallowEqual);

  const getJob: Promise<Job | {}> = useMemo(() => {
    if (clonedJob) {
      return Promise.resolve(clonedJob);
    } else if (jobId) {
      return api.getJob(jobId);
    } else if (organisation) {
      return api.createJobDraft(organisation.id);
    }

    return Promise.resolve(null);
  }, [api, jobId, organisation, clonedJob]);

  const [job, , isFetching] = usePromise(getJob, null, { component: 'JobUpdate' });

  const updateAndPreview = useCallback(async(jobParams) => {
    // @ts-ignore
    let id = job.id;

    if (!id) throw new Error('No Job ID');

    if (jobParams.applyMethod !== 'button' && (jobParams.application || '').trim().length < 25) throw new Error('Please enter some Application Instructions (minimum length 25 characters)');

    if (jobParams.applyMethod === 'email') {
      if (!jobParams.applyEmail || validate({ email: jobParams.applyEmail }, { email: { email: true } })) throw new Error('Invalid e-mail for receiving applications');
      jobParams.applyButton = undefined;
    }

    if (jobParams.applyMethod === 'button') {
      if (!jobParams.applyButton) {
        throw new Error('Invalid recruitment platform URL');
      }

      if (validate({ url: jobParams.applyButton }, { url: { url: true } })) {
        if (jobParams.applyButton.startsWith('http') === false && validate({ url: 'https://' + jobParams.applyButton }, { url: { url: true } }) === undefined) {
          jobParams.applyButton = 'https://' + jobParams.applyButton;
        } else {
          throw new Error('Invalid recruitment platform URL');
        }
      }

      jobParams.applyEmail = undefined;
    }

    if (!jobParams.applyMethod) {
      jobParams.applyEmail = undefined;
      jobParams.applyButton = undefined;
      jobParams.applyMethod = null;
    }

    try {
      let updatedJob = await api.updateJob(id, {
        ...jobParams,
        location: jobParams.location ? Number(jobParams.location) : jobParams.location
      });

      // @ts-ignore
      if (isAdmin && updatedJob.status !== 'DRAFT' && updatedJob.isFeatured && job.isFeatured === false && !updatedJob.isExpired) {
        await api.deductCredits(updatedJob.organisation.id, 1, `[System] Featured Job ${updatedJob.id}`);
      }

      amplitude.logEvent('API', { function: 'updateJob', arguments: { jobId: id, ...jobParams }});

      if (isAdmin) {
        history.push(`/admin/job/${id}/view`);
      } else {
        history.push(`/organisation/job/${id}/update/previewed`);
        history.push(`/organisation/job/${id}/view`);
      }
      return updatedJob;
    } catch (error) {
      amplitude.logError('containers/JobUpdate/index/updateAndPreview', error);

      // INFO: Must rethrow so form can handle
      throw error;
    }
  }, [api, history, isAdmin, amplitude, job]);

  useEffect(() => {
    const fn = async () => {
      setIsCloning(true);
      try {
        await Promise.all(clonedJob.attachments.map(async attachment => {
          let file = await fetch(attachment.url);
          let blob: any = await file.blob();
          let newFile = new File([blob], attachment.originalFileName);
          await api.attachMediaToJob(clonedJob.id, newFile);
        }));
      } catch (error) {
        amplitude.logError('containers/JobUpdate/index:cloneAttachments', error);
      }
      setHasClonedAttachments(true);
      setIsCloning(false);
    };

    // INFO: Check needed so it only add attachments once
    if (clonedJob && !location.pathname.includes('previewed')) fn();
    else setHasClonedAttachments(true);

    return () => dispatch(cloneJob(null));
  }, [dispatch, amplitude, api, clonedJob, location.pathname]);

  // Needs to be memoised so the form doesn't get incorrectly re-initialised
  const initialValues = useMemo(() => ({
    applyButton: '',
    applyEmail: '',
    applyMethod: isAdmin ? null : 'email',
    ...job,
    contactId: _.get(job, ['emailContact', 'id'], undefined),
    categories: _.get(job, ['categories'], []).map((item: Category) => item.id),
    sectors: _.get(job, ['sectors'], []).map((item: Sector) => item.id),
    workTypes: _.get(job, ['workTypes'], []).map((item: WorkType) => item.id),
    location: _.get(job, ['location', 'id'], "").toString(),
    questions: _.get(job, ['questions'], []),
    organisationId: _.get(job, ['organisation', 'id'], null),
  }), [isAdmin, job]);

  return (
    <div className="job-update-container">
      <Spinner show={isFetching || isCloning} />
      {((!jobId && !clonedJob) || (!_.isEmpty(job) && hasClonedAttachments)) && (
        <JobForm
          initialValues={initialValues}
          isAdmin={isAdmin}
          showCloneFeaturedMessage={!!clonedJob && clonedJob.isFeatured}
          organisation={organisation}
          action={updateAndPreview}
          creditBalance={_.get(organisation, ['numCredits'], 0)}
        />
      )}
    </div>
  );
};

export default React.memo(JobUpdate);
