import React from 'react';

type Props = {
  className?: string;
  type: string;
  style?: Object;
};

const Icon = ({ className = '', type, style }: Props) => (
  <i className={`${className} material-icons`} style={style}>{type}</i>
);

export default Icon;
