import React from 'react';

import classNames from 'classnames';

import { Job } from '@ethical-jobs/sdk-js/types';

import RouteRenderer from './RouteRenderer';

type Props = {
  job: Job
};

const JobNameRenderer = ({ job }: Props) => {
  const expiredAndPending = job.status === 'PENDING' && job.isExpired;
  return (
    <div className={classNames('job-name-renderer', expiredAndPending ? 'has-icon' : null )}>
      {expiredAndPending && (
        <i
          className="pending-and-expired-indicator material-icons"
          title="Pending and expired"
        >
          access_alarm
        </i>
      )}
      <div className="main-content">
        <RouteRenderer
          to={`/admin/job/${job.id}/view`}
          text={job.title}
        />
      </div>
    </div>
  )
};

export default JobNameRenderer;