import React from 'react';
import { Questions } from '@ethical-jobs/sdk-js/types';

import FormField from 'fields/FormField';
import QuestionsField from 'fields/QuestionsField';
import QuestionsCard from './QuestionsCard';

type Props = {
  questions: Questions;
};

const QuestionsSection = ({ questions }: Props) => (
  <QuestionsCard>
    <FormField
      name="questions"
      component={QuestionsField}
      questions={questions}
    />
  </QuestionsCard>
);

export default QuestionsSection;
