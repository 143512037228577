// @ts-nocheck
import React from 'react';

import moment from 'moment';
import classNames from 'classnames';

import { Organisation } from '@ethical-jobs/sdk-js/types';

import OrganisationLogoUpload from 'components/OrganisationLogoUpload';

type Props = {
  organisation: Organisation;
  uploadLogo: (file: File, organisationId: number) => Promise<Organisation>;
  isArchived: boolean;
};

const OrganisationInfo = ({ organisation, uploadLogo, isArchived }: Props) => (
  <div className={classNames('organisation-info row', { archived: isArchived })}>
    <div className="col-md-4">
      <OrganisationLogoUpload
        upload={uploadLogo}
        organisation={organisation}
      />
    </div>
    <div className="col-md-8 info-panel">
      <h3>
        <span>{organisation.name}</span>
        {organisation.owner.lastLogin && (
          <small>Last logged in {moment(organisation.owner.lastLogin).fromNow()} ago</small>
        )}
      </h3>
      {!organisation.deletedAt ? (
        <div className="stats">
          <div className="stat">
            <span>{organisation.numCredits}</span>
            <h5>Credit Balance</h5>
          </div>
          <div className="stat">
            <span>{organisation.numInvoices}</span>
            <h5>Total Invoices</h5>
          </div>
          <div className="stat">
            <span>{organisation.jobs.numApproved}</span>
            <h5>Approved Jobs</h5>
          </div>
          <div className="stat">
            <span>{organisation.jobs.numPending}</span>
            <h5>Pending Jobs</h5>
          </div>
          <div className="stat">
            <span>{organisation.jobs.numExpired}</span>
            <h5>Expired Jobs</h5>
          </div>
        </div>
      ) : (
        <div className="row">
          <div className="col-md-12">
            <h4>This organisation was archived on {moment(organisation.deletedAt).format('DD/MM/YYYY @ h:mm a')}.</h4>
            <p className="muted"><em>Thats approximately {moment(organisation.deletedAt).fromNow()} ago.</em></p>
          </div>
        </div>
      )}
    </div>
  </div>
);

export default React.memo(OrganisationInfo);