export const searchOrganisationsAdmin = ({ query, includeArchived, sort }) => ({
  type: 'SEARCH_ORGANISATIONS_ADMIN',
  payload: {
    query,
    includeArchived,
    sort
  }
});

export const searchJobsAdmin = ({ query, organisation, status, sort }) => ({
  type: 'SEARCH_JOBS_ADMIN',
  payload: {
    query,
    organisation,
    sort,
    status
  }
});

export const searchInvoicesAdmin = ({ query, organisation, sort }) => ({
  type: 'SEARCH_INVOICES_ADMIN',
  payload: {
    query,
    organisation,
    sort
  }
});
