import React, { useState, useCallback, useContext, useRef } from 'react';

import moment from 'moment';
import Papa from 'papaparse';
import { DateRangePicker, SingleDatePicker, FocusedInputShape } from 'react-dates';
import { Column } from '@ethical-jobs/design-system-react';

import { APIContext } from 'lib/contexts';

import Card from 'components/Card';
import PageTitle from 'components/PageTitle';
import Select from 'components/Select';
import OrganisationSelect from 'fields/OrganisationSelect';

import 'react-dates/initialize';
import 'react-dates/lib/css/_datepicker.css';
import './DateRangePicker.css';
import Button from 'components/Button';

const Exporter = () => {
  const api = useContext(APIContext);

  const topLocationJobsRef = useRef(null);

  const [type, setType] = useState<'jobs' | 'organisations' | 'invoices'>('jobs');
  const [organisation, setOrganisation] = useState<number | null>(null);
  const [focusedDateInput, setFocusedDateInput] = useState<FocusedInputShape>(null);
  const [dates, setDates] = useState({ startDate: null, endDate: null });

  const [loadingTopLocationJobs, setLoadingTopLocationJobs] = useState(false);
  const [topLocationJobsLink, setTopLocationJobsLink] = useState('');
  const [topLocationExpiresAfter, setTopLocationExpiresAfter] = useState(null);
  const [topLocationsDatePickerFocused, setTopLocationsDatePickerFocused] = useState(false);

  const runExport = useCallback(() => {
    let from = moment(dates.startDate).startOf('day').valueOf();
    // INFO: Adding hours to cover utc offset. Not the most accurate way but valid for 99.9% of use cases
    let to = type === 'jobs'
      ? moment(dates.endDate).endOf('day').add(10, 'hours').valueOf()
      : moment(dates.endDate).endOf('day').valueOf();

    let timeValues = type === 'jobs'
      ? {
        approvedFrom: from,
        approvedTo: to
      } : {
        dateFrom: from,
        dateTo: to
      };

    let url = api.getExportUrl(type, {
      status: ['approved'],
      archived: 'exclude',
      ...timeValues,
      organisations: organisation || undefined
    });

    // HOT FIX FOR https://ethicaljobs.atlassian.net/browse/ETH-502. Can be removed once fix is in sdk-js
    url = url.replace('https://api.ethicalstaging.com.auexports', 'https://api.ethicalstaging.com.au/exports');
    url = url.replace('https://api.ethicaljobs.com.auexports', 'https://api.ethicaljobs.com.au/exports');

    window.open(url, '_blank');
  }, [api, type, organisation, dates]);

  const getTopLocationJobs = useCallback(async () => {
    if (!topLocationExpiresAfter) return;

    setLoadingTopLocationJobs(true);
    let orgList = await api.exportTopJobLocations(topLocationExpiresAfter.format('YYYY-MM-DD'));
    let csv = Papa.unparse(orgList);

    let blob = new Blob([csv], { type: 'text/csv;charset=utf-8;' });
    setTopLocationJobsLink(window.URL.createObjectURL(blob));
    setLoadingTopLocationJobs(false);

    setTimeout(() => {
      topLocationJobsRef.current.click();
    }, 500);
  }, [api, topLocationExpiresAfter]);

  return (
    <div className="exporter-container">
      <PageTitle title="Exporter" subTitle="Export custom datasets" />
      <Card title="CSV Data exporter" subTitle="Use the form below to refine your export criteria.">
        <div style={{ display: 'flex' }}>
          <div style={{ paddingRight: 30 }}>
            <label>Type</label>
            <Select
              value={type}
              // @ts-ignore
              onChange={({value }) => setType(value)}
              width={180}
              options={[{
                value: 'jobs',
                label: 'Jobs'
              }, {
                value: 'organisations',
                label: 'Organisations'
              }, {
                value: 'invoices',
                label: 'invoices'
              }]}
            />
          </div>
          <div style={{ paddingRight: 30 }}>
            <label>Restrict to an organisation</label>
            <OrganisationSelect
              value={organisation}
              onChange={setOrganisation}
              isClearable
            />
          </div>
          <div style={{ flex: 1 }}>
            <label>Export Date Range</label>
            <div style={{ paddingBottom: 10 }}>
              <DateRangePicker
                displayFormat="DD/MM/YYYY"
                startDate={dates.startDate}
                startDateId="startDate"
                endDate={dates.endDate}
                endDateId="endDate"
                onDatesChange={setDates}
                focusedInput={focusedDateInput}
                onFocusChange={setFocusedDateInput}
                required
                showClearDates
                noBorder
                isOutsideRange={() => false}
              />
            </div>
          </div>
          
          <div style={{ height: 50, alignSelf: 'center' }}>
            <Button
              kind="primary"
              label="Run Export"
              onClick={runExport}
            />
          </div>
        </div>
      </Card>

      <Card title="Useful Exports" subTitle="Look how easy it to get the data now">
        <Column gap="small">
          <div style={{ width: 207 }}>
            <SingleDatePicker
              id="ej-date-picker"
              date={topLocationExpiresAfter}
              onDateChange={setTopLocationExpiresAfter}
              placeholder="Get Jobs Expiring After"
              displayFormat="ddd Do MMM YYYY"
              numberOfMonths={1}
              block
              focused={topLocationsDatePickerFocused}
              onFocusChange={({ focused }) => setTopLocationsDatePickerFocused(focused)}
              isOutsideRange={() => false}
            />
          </div>
          <Button
            label="Top Location Jobs"
            kind="primary"
            onClick={getTopLocationJobs}
            loading={loadingTopLocationJobs}
            disabled={loadingTopLocationJobs}
          />
          {/* eslint-disable-next-line */}
          <a ref={topLocationJobsRef} href={topLocationJobsLink} target="_blank" download="topLocationJobs.csv" rel="noopener noreferrer" />
        </Column>
      </Card>
    </div>
  );
};

export default Exporter;
