import styled from 'styled-components';

export const HeadingContainer = styled.div`
  display: flex;
  padding: 20px 25px;
  justify-content: space-between;
  align-items: center;
  border-style: solid;
  border-color: #DDD;
  border-width: 0;
  border-bottom-width: 1px;
`;