import React, { Fragment } from 'react';

import moment from 'moment';
import { Job } from '@ethical-jobs/sdk-js/types';

import { JobRowContainer, FeaturedTagContainer, FeaturedTagCorner } from './JobRow.styles';

import { JobWithApplicants } from '../index';

type Props = {
  job: JobWithApplicants;
  activeTab: string;
  onClick: () => void;
};

const JobRow = ({ job, activeTab, onClick }: Props) => {
  const daysTilExpiration = moment().diff(job.expiresAt, 'days') * -1;

  return (
    <JobRowContainer onClick={onClick}>
      <p style={{ flex: 3, marginBottom: 0, fontWeight: 'bold', fontSize: 18 }}>{job.title}</p>
      <p style={{ flex: 1, marginBottom: 0, color: '#999', fontSize: 16, textAlign: 'center' }}>{moment(job.createdAt).format('DD/MM/YYYY')}</p>
      <p style={{ flex: 1, marginBottom: 0, color: daysTilExpiration < 7 ? '#0079c1' : '#999', fontSize: 16, textAlign: 'center' }}>{daysTilExpiration > 0 ? `${daysTilExpiration} days` : 'Today'}</p>
      <div style={{ flex: 1, display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
        <p style={{ marginBottom: 0, paddingLeft: 5, fontSize: 16}}>{job.numApplicants}</p>
        {job.numNewApplicants > 0 && (
          <p style={{ marginBottom: 0, paddingLeft: 5, fontSize: 16}}> (<strong>{job.numNewApplicants} new</strong>)</p>
        )}
      </div>
      <p style={{ flex: 1, marginBottom: 0, color: '#999', fontSize: 16, textAlign: 'center' }}>{job.views}</p>

      {job.isFeatured && (
        <Fragment>
          <FeaturedTagContainer>
            <p style={{ color: 'white', fontSize: 12, margin: 0 }}>Featured</p>
          </FeaturedTagContainer>
          <FeaturedTagCorner />
        </Fragment>
      )}
    </JobRowContainer>
  );
};

export default React.memo(JobRow);