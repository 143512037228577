import React from 'react';

import { Column, Row, Heading } from '@ethical-jobs/design-system-react';
import { useSelector } from 'react-redux';

import CurrentApprovedJobsFigure from 'metrics/CurrentApprovedJobsFigure';
import JobViewsAndClicksFigure from 'metrics/JobViewsAndClicksFigure';
import PurchasesFigure from 'metrics/PurchasesFigure';
import ApprovedJobsByMonthChart from 'metrics/ApprovedJobsByMonthChart';
import PurchasesByMonthChart from 'metrics/PurchasesByMonthChart';
import ApprovedJobsByLocationTable from 'metrics/ApprovedJobsByLocationTable';

const Dashboard = () => {
  // @ts-ignore
  const firstName = useSelector(state => state.auth.user.firstName);

  return (
    <Column padding="large" gap="large">
      <Column gap="small">
        <Heading size="large">Dashboard</Heading>
        <Heading size="medium">{`Welcome back ${firstName}.`}</Heading>
      </Column>
      <Row gap="large" align="spread">
        <CurrentApprovedJobsFigure />
        <PurchasesFigure />
        <JobViewsAndClicksFigure />
      </Row>
      <Row gap="large" align="spread">
        <ApprovedJobsByMonthChart />
        <PurchasesByMonthChart />
      </Row>
      <ApprovedJobsByLocationTable />
    </Column>
  );
};

export default React.memo(Dashboard);
