import _ from 'lodash';
import React, { useState } from 'react';
import styled from 'styled-components';
import moment from 'moment';
import classNames from 'classnames';
import { ApplicantCount, Job, User } from '@ethical-jobs/sdk-js/types';

import Icon from 'components/Icon';

const ApplicationsTable = styled.table`
  td {
    padding: 5px 15px;
  }
  
  tr:not(:last-of-type) td {
    border-bottom: 0;
  }
  
  tr td:nth-child(2) {
    width: 120px
  }
`;

type Props = {
  job: Job;
  author?: User;
  applicantCounts: ApplicantCount;
};

const JobInfo = ({ job, author, applicantCounts }: Props) => {
  const [show, setShow] = useState(false);

  if (!job) {
    return null;
  }

  return (
    <div className={classNames('job-info', { show })}>
      {/* eslint-disable-next-line */}
      <a className="toggle" onClick={() => setShow(prevShow => !prevShow)}>
        {show ? (
          <span>
            <Icon type="keyboard_arrow_up" />
            Hide job details
          </span>
        ) : (
          <span>
            <Icon type="keyboard_arrow_down" />
            View job details
          </span>
        )}
      </a>
      {show && (
        <div>
          <div className="row">
            <div className="col-md-6">
              <table>
                <tbody>
                  <tr>
                    <td>Status</td>
                    <td>{job.status}</td>
                  </tr>
                  <tr>
                    <td>Author</td>
                    <td>
                      {_.get(author, ['name'], '')}
                    </td>
                  </tr>
                  <tr>
                    <td>Email Contact</td>
                    <td>{job.emailContact && job.emailContact.email}</td>
                  </tr>
                  <tr>
                    <td>Provider</td>
                    <td>{job.sourceProvider || '-'}</td>
                  </tr>
                  <tr>
                    <td>Source</td>
                    <td>{job.source}</td>
                  </tr>
                </tbody>
              </table>
              <br />
              <table>
                <tbody>
                  <tr>
                    <td>Slug</td>
                    <td>{job.slug || 'N/A'}</td>
                  </tr>
                  <tr>
                    <td>URL</td>
                    <td>
                      {job.url && (
                        <a
                          href={job.url}
                          target="_blank"
                          className="btn btn-xs btn-tertiary"
                          rel="noopener noreferrer"
                        >
                          View job ad &rarr;
                        </a>
                      )}
                      {!job.url && 'N/A'}
                    </td>
                  </tr>
                </tbody>
              </table>
              <br />
              <table>
                <tbody>
                  <tr>
                    <td>Views</td>
                    <td>{job.views}</td>
                  </tr>
                  <tr>
                    <td>Clicks</td>
                    <td>{job.clicks}</td>
                  </tr>
                </tbody>
              </table>
              <br />
              <ApplicationsTable>
                <tbody>
                  <tr>
                    <td>Applications</td>
                    <td><b>Total</b></td>
                    <td>{applicantCounts.total}</td>
                  </tr>
                  <tr>
                    <td />
                    <td>Inbox</td>
                    <td>{applicantCounts.inbox}</td>
                  </tr>
                  <tr>
                    <td />
                    <td>Shortlist</td>
                    <td>{applicantCounts.shortlist}</td>
                  </tr>
                  <tr>
                    <td />
                    <td>Interview</td>
                    <td>{applicantCounts.interview}</td>
                  </tr>
                  <tr>
                    <td />
                    <td>Offer</td>
                    <td>{applicantCounts.offer}</td>
                  </tr>
                  <tr>
                    <td />
                    <td>Accept</td>
                    <td>{applicantCounts.accept}</td>
                  </tr>
                  <tr>
                    <td />
                    <td>Unsuitable</td>
                    <td>{applicantCounts.unsuitable}</td>
                  </tr>
                </tbody>
              </ApplicationsTable>
            </div>
            <div className="col-md-6">
              <table>
                <tbody>
                  <tr>
                    <td>Featured</td>
                    <td>{job.isFeatured ? 'Yes' : 'No'}</td>
                  </tr>
                  <tr>
                    <td>Archived</td>
                    <td>{job.deletedAt ? moment(job.deletedAt).format('DD/MM/YYYY @ hh:mm a') : 'No'}</td>
                  </tr>
                </tbody>
              </table>
              <br />
              <table>
                <tbody>
                  <tr>
                    <td>Approved</td>
                    <td>{job.approvedAt ? moment(job.approvedAt).format('DD/MM/YYYY @ hh:mm a') : 'No'}</td>
                  </tr>
                  <tr>
                    <td>Approved by</td>
                    <td>{job.approver ? job.approver.name : 'N/A'}</td>
                  </tr>
                </tbody>
              </table>
              <br />
              <table>
                <tbody>
                  <tr>
                    <td>Expiration</td>
                    <td>{job.expiresAt ? moment(job.expiresAt).format('DD/MM/YYYY @ HH:mm a') : 'Not Set'}</td>
                  </tr>
                  <tr>
                    <td>Expiration Hidden</td>
                    <td>{job.isExpirationHidden ? 'Yes' : 'No'}</td>
                  </tr>
                </tbody>
              </table>
              <br />
              <table>
                <tbody>
                  <tr>
                    <td>Created</td>
                    <td>{moment(job.createdAt).format('DD/MM/YYYY @ HH:mm a')}</td>
                  </tr>
                  <tr>
                    <td>Updated</td>
                    <td>{job.updatedAt ? moment(job.updatedAt).format('DD/MM/YYYY @ HH:mm a') : 'N/A'}</td>
                  </tr>
                </tbody>
              </table>

              <br />
              <table>
                <tbody>
                  <tr>
                    <td>Apply Method</td>
                    <td>{job.applyMethod || 'None'}</td>
                  </tr>
                  <tr>
                    <td>Apply E-mail</td>
                    <td>{job.applyEmail || '-'}</td>
                  </tr>
                  <tr>
                    <td>Apply Link</td>
                    <td>{job.applyButton
                      ? <a href={job.applyButton}>{job.applyButton}</a>
                      : '-'}
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default JobInfo;
