import React from 'react';

import EthicalJobsAPI from '@ethical-jobs/sdk-js';
import { Amplitude } from '@ethical-jobs/sdk-js/types/amplitude';

import env from './env';

const api = new EthicalJobsAPI({ host: env.API_HOST, site: env.SITE, amplitudeKey: env.AMPLITUDE_KEY, fileDownloadSecret: env.FILE_DOWNLOAD_API_SECRET });

const { amplitude } = api.api;

const APIContext: React.Context<EthicalJobsAPI> = React.createContext(api);

const AmplitudeContext: React.Context<Amplitude> = React.createContext(amplitude);

export {
  APIContext,
  AmplitudeContext,
  amplitude,
  api
};