import React, { useState, useCallback, useContext, useEffect } from 'react';

import { useSelector, useDispatch, shallowEqual } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { LinkContainer } from 'react-router-bootstrap';
import NavItem from 'react-bootstrap/lib/NavItem';

import env from 'lib/env';
import { APIContext } from 'lib/contexts';
import { logout } from 'actions/auth';
import { loadOrganisations } from 'actions/organisations';

import TopNav from 'components/TopNav';
import SideNav from 'components/SideNav';
import Footer from 'components/Footer';
import AdminMenu from 'components/AdminMenu';

type Props = {
  children: React.ReactNode
};

const Root = ({ children }: Props) => {
  const dispatch = useDispatch();
  const history = useHistory();

  const api = useContext(APIContext);

  // @ts-ignore
  const user = useSelector(state => state.auth.user, shallowEqual);

  const [checkedAuth, setCheckedAuth] = useState(false);
  const [isBDRM, setIsBDRM] = useState(false);

  const logoutFn = useCallback(() => {
    dispatch(logout());
    history.push('/login');
  }, [dispatch, history]);

  useEffect(() => {
    const fn = async () => {
      const isUserABDRM = await api.doesUserHaveRole(['admin', 'relations-team']);
      setIsBDRM(isUserABDRM);

      const isUserAStaffMember = await api.doesUserHaveRole('staff-member');
      if (!isUserAStaffMember) {
        history.push(`/login?redirect_to=${window.location.pathname}`);
      } else {
        dispatch(loadOrganisations());
        setCheckedAuth(true);
      }
    }

    fn();
  }, [api, history, dispatch]);
  
  if (!checkedAuth) return null;

  return (
    <div className="system-container">
      <div className="left-column">
        <SideNav admin>
          <LinkContainer to="/admin">
            <NavItem>
              <img src={`${env.EJ_CDN}/statics/logo-transparent.png`} alt="EthicalJobs.com.au" style={{ paddingTop: 15 }} />
            </NavItem>
          </LinkContainer>

          <LinkContainer to="/admin">
            <NavItem>
              <span className="material-icons md-26">home</span>
              <span>Dashboard</span>
            </NavItem>
          </LinkContainer>

          <NavItem className="section-title">Organisations</NavItem>
          <LinkContainer to="/admin/organisation/create">
            <NavItem>
              <span className="material-icons md-26">add</span>
              <span>Register organisation</span>
            </NavItem>
          </LinkContainer>
          <LinkContainer to="/admin/organisations">
            <NavItem>
              <span className="material-icons md-26">list</span>
              <span>View organisations</span>
            </NavItem>
          </LinkContainer>

          <NavItem className="section-title">Job Ads</NavItem>
          <LinkContainer to="/admin/job/create">
            <NavItem>
              <span className="material-icons md-26">add</span>
              <span>Post job ad</span>
            </NavItem>
          </LinkContainer>
          <LinkContainer to="/admin/jobs">
            <NavItem>
              <span className="material-icons md-26">list</span>
              <span>View all jobs</span>
            </NavItem>
          </LinkContainer>

          <NavItem className="section-title">Invoices & Credits</NavItem>
          <LinkContainer to="/admin/purchase">
            <NavItem>
              <span className="material-icons md-26">add</span>
              <span>Purchase credits</span>
            </NavItem>
          </LinkContainer>
          <LinkContainer to="/admin/invoice/create">
            <NavItem>
              <span className="material-icons md-26">add</span>
              <span>Custom invoice</span>
            </NavItem>
          </LinkContainer>
          <LinkContainer to="/admin/invoices">
            <NavItem>
              <span className="material-icons md-26">list</span>
              <span>View invoices</span>
            </NavItem>
          </LinkContainer>
          <LinkContainer to="/admin/activities">
            <NavItem>
              <span className="material-icons md-26">announcement</span>
              <span>View activity</span>
            </NavItem>
          </LinkContainer>
          <NavItem className="section-title">Staff members</NavItem>
          <LinkContainer to="/admin/staff">
            <NavItem>
              <span className="material-icons md-26">list</span>
              <span>View staff</span>
            </NavItem>
          </LinkContainer>
          <LinkContainer to="/admin/relations-manager">
            <NavItem>
              <span className="material-icons md-26">list</span>
              <span>View staff</span>
            </NavItem>
          </LinkContainer>
          {isBDRM && (
            <LinkContainer to="/admin/bdrm">
              <NavItem>
                <span className="material-icons md-26">business</span>
                <span>BDRM</span>
              </NavItem>
            </LinkContainer>
          )}

          <NavItem className="section-title">Tools</NavItem>
          <LinkContainer to="/admin/export">
            <NavItem>
              <span className="material-icons md-26">playlist_add_check</span>
              <span>Exporter</span>
            </NavItem>
          </LinkContainer>
        </SideNav>
      </div>

      <div className="right-column" role="main">
        <div className="child-container container-fluid">
          <TopNav>
            <AdminMenu user={user} logout={logoutFn} />
          </TopNav>
          <div className="children">{children}</div>
          <Footer />
        </div>
      </div>
    </div>
  );
};

export default Root;
