import React from 'react';
import Card from 'components/Card';

import { withStyles, MuiThemeProvider, createMuiTheme, Theme } from '@material-ui/core/styles';
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import StepConnector from '@material-ui/core/StepConnector';

const styles = (theme: Theme) => ({
  connectorActive: {
    '& $connectorLine': {
      borderColor: '#56AF31'
    }
  },
  connectorCompleted: {
    '& $connectorLine': {
      borderColor: '#56AF31'
    }
  },
  connectorDisabled: {
    '& $connectorLine': {
      borderColor: theme.palette.grey[100]
    }
  },
  connectorLine: {
    transition: theme.transitions.create('border-color')
  },
  iconContainer: {
    transform: 'scale(1.5)'
  },
  step: {
    '& $active': {
      color: '#FFCC00'
    },
    '& $completed': {
      color: '#56AF31'
    },
    '& $disabled': {
      color: theme.palette.grey[100]
    }
  },
  active: {},
  completed: {},
  disabled: {}
});

const theme = createMuiTheme({
  typography: {
    htmlFontSize: 10
  }
});

type Props = {
  steps: Array<string>;
  activeStep?: number;
  classes: {
    active: string;
    completed: string;
    step: string;
    iconContainer: string;
    connectorLine: string;
    connectorDisabled: string;
    connectorActive: string;
    connectorCompleted: string;
  };
};

const StepProgressBar = ({ steps, activeStep = 0, classes }: Props) => (
  <div>
    <Card>
      <MuiThemeProvider theme={theme}>
        <Stepper
          activeStep={activeStep}
          alternativeLabel
          connector={(
            <StepConnector
              classes={{
                active: classes.connectorActive,
                completed: classes.connectorCompleted,
                disabled: classes.connectorDisabled,
                line: classes.connectorLine
              }}
            />
          )}
        >
          {steps.map(label => (
            <Step
              classes={{
                root: classes.step,
                completed: classes.completed
              }}
              key={label}
            >
              <StepLabel
                classes={{ iconContainer: classes.iconContainer }}
                StepIconProps={{
                  classes: {
                    root: classes.step,
                    completed: classes.completed,
                    active: classes.active
                  }
                }}
              >
                {label}
              </StepLabel>
            </Step>
          ))}
        </Stepper>
      </MuiThemeProvider>
    </Card>
  </div>
);

export default withStyles(styles)(StepProgressBar);
