import React from 'react';
import { formatMoney } from 'lib/formatters';

type Props = {
  className?: string;
  style?: Object;
  money: number;
};

const MoneyRenderer = ({ className, style, money }: Props) => (
  <span
    className={className}
    style={style}
  >
    {formatMoney(money)}
  </span>
);

export default MoneyRenderer;
