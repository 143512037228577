import _ from 'lodash';
import React, {useCallback} from 'react';
import PlacesAutocomplete, {geocodeByAddress, getLatLng} from 'react-places-autocomplete';
import {change} from 'redux-form';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';

import Menu from './Menu';

const Content = styled.div`
  position: relative;
`;

type Props = {
  onChange: (value: string) => void;
  value?: string;
  placeholder: string;
};

const MAPPINGS = {
  'locality': 'city',
  'colloquial_area': 'city',
  'administrative_area_level_1': 'state',
  'country': 'country',
  'postal_code': 'postcode',
};

// https://github.com/lodash/lodash/issues/528
const findKeyByPrimitive = (countries, country: string) => {
  return _.findKey(countries, _.partial(_.isEqual, country));
};

const CityAutocompleteField = ({ onChange, value, placeholder }: Props) => {
  const formatResults = useCallback(results => {
    const addressComponents = _.get(results, [0, 'address_components']);
    return addressComponents.reduce((acc, component) => {
      const type = _.get(component, ['types', 0]);
      return MAPPINGS[type] ? {...acc, [MAPPINGS[type]]: component.long_name} : acc;
    }, {});
  }, []);

  const dispatch = useDispatch();
  const countries = useSelector(state => _.get(state, ['taxonomies', 'countries']));

  // INFO: Suburbs with multiple postcodes (e.g. Melbourne, Sydney) do not return postcode info from geocodeByAddress
  // Must get more specific lat/lng value and request location using that
  const geocodeAddress = useCallback(async (address: string) => {
    if (!_.has(window, 'google')) {
      throw new Error('Google Maps JavaScript API library must be loaded.');
    }

    // @ts-ignore
    const geocoder = new window.google.maps.Geocoder();
    // @ts-ignore
    const OK = window.google.maps.GeocoderStatus.OK;

    const resultsByAddress = await geocodeByAddress(address);
    const latlng = await getLatLng(resultsByAddress[0]);

    return new Promise(function (resolve, reject) {
      geocoder.geocode({'location': latlng}, function(resultsByLatLng, status) {
        if (status !== OK) {
          reject(status);
        }
        resolve(resultsByLatLng);
      });
    });
  }, []);

  const handleSelect = useCallback(async address => {
    const results = await geocodeAddress(address);
    const location = formatResults(results);

    onChange(location.city);
    dispatch(change('signup', 'state', location.state));

    const countryCode = findKeyByPrimitive(countries, location.country);
    dispatch(change('signup', 'country', countryCode));

    // NOTE: Only auto-populate postcode for Australia, as many other countries do not assign postcode by suburb
    if (countryCode === 'AU') {
      dispatch(change('signup', 'postcode', location.postcode));
    } else {
      dispatch(change('signup', 'postcode', ''));
    }
  }, [countries, dispatch, formatResults, onChange, geocodeAddress]);

  return (
    <PlacesAutocomplete
      value={value}
      onChange={onChange}
      onSelect={handleSelect}
      searchOptions={{types: ['(cities)']}}
    >
      {({ getInputProps, suggestions, getSuggestionItemProps }) => {
        const inputProps = getInputProps({placeholder});
        return (
          <Content>
            <input
              {...inputProps}
              /* Requires a semantic autocomplete value for autocomplete to be disabled in chrome:
               * https://medium.com/paul-jaworski/turning-off-autocomplete-in-chrome-ee3ff8ef0908 */
              autoComplete="new-password"
              onKeyDown={async (e) => {
                const tabKeyCode = 9;
                if (e.keyCode === tabKeyCode) {
                  const activeSuggestion = suggestions.find(suggestion => suggestion.active === true);
                  if (activeSuggestion) {
                    await handleSelect(activeSuggestion.description);
                  }
                }
                inputProps.onKeyDown(e);
              }}
              className="form-control"
            />
            <Menu suggestions={suggestions} getSuggestionItemProps={getSuggestionItemProps} />
          </Content>
        )
      }}
    </PlacesAutocomplete>
  );
};

export default CityAutocompleteField;
