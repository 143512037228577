import React from 'react';
import styled from 'styled-components';

import Checkbox from './Checkbox';
import Label from './Label';
import { Blanks } from '@ethical-jobs/sdk-js/types';

type ContainerProps = {
  checked: boolean;
  disabled: boolean;
};

// TODO: Remove !important flags after we deal with global styles issue
const Container = styled.li<ContainerProps>`
  align-items: center;
  background-color: ${({ checked }) => checked ? '#f2f3f4' : 'transparent'};
  border: 1px solid ${({ checked }) => checked ? '#8eb87d' : '#d7d8d7'};
  border-radius: 4px;
  display: flex;
  margin-bottom: 20px !important;
  transition: 0.25s all;
  opacity: ${({ disabled }) => disabled ? 0.5 : 1};
  
  input[type=checkbox] {
    margin: 0;
  }
`;

type Props = {
  blanks: Blanks;
  checked: boolean;
  disabled: boolean;
  label: string;
  onBlankChange: (blanks: Blanks) => void;
  onBlur: () => void;
  onCheckboxChange: (id: number) => void;
}

const CheckboxItem = ({ checked, disabled, onBlur, onCheckboxChange, onBlankChange, label, blanks }: Props) => {
  return (
    <Container
      checked={checked}
      disabled={disabled}
    >
      <Label
        blanks={blanks}
        onBlankChange={onBlankChange}
        label={label}
        checked={checked}
        disabled={disabled}
      >
        <Checkbox
          checked={checked}
          onChange={onCheckboxChange}
          onBlur={onBlur}
          disabled={disabled}
        />
      </Label>
    </Container>
  );
};

export default CheckboxItem;
