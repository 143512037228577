import EthicalJobsAPI from '@ethical-jobs/sdk-js';
import { Amplitude } from '@ethical-jobs/sdk-js/types/amplitude';

export const loadOrganisations = () => async (dispatch: (action: { type: string, payload?: any }) => void, getState: () => Object, { api, amplitude }: { api: EthicalJobsAPI, amplitude: Amplitude }) => {
  try {
    let organisations = await api.api.v1.__getOrganisationsFast();

    dispatch({
        type: 'LOAD_ORGANISATIONS',
        payload: organisations
    });
  } catch (error) {
    amplitude.logError('actions/organisations/index/getOrganisations', error);
  }
};
