import React from 'react';
import Card from 'components/Card';
import FormField from 'fields/FormField';
import TaxonomySelect from 'fields/TaxonomySelect';
import TaxonomyCheckboxGroup from 'fields/TaxonomyCheckboxGroup';

const Taxonomies = () => (
  <div>
    <Card title="Job Location" required>
      <FormField
        name="location"
        component={TaxonomySelect}
        taxonomy="locations"
        orderBy="id"
        required
      />
    </Card>
    <Card title="Sectors" required>
      <FormField
        name="sectors"
        component={TaxonomyCheckboxGroup}
        taxonomy="sectors"
        required
      />
    </Card>
    <Card title="Work Type" required>
      <FormField
        name="workTypes"
        component={TaxonomyCheckboxGroup}
        taxonomy="workTypes"
        required
      />
    </Card>
    <Card title="Classifications" required>
      <FormField
        name="categories"
        component={TaxonomyCheckboxGroup}
        taxonomy="categories"
        required
      />
    </Card>
  </div>
);

export default Taxonomies;
