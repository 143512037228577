import React, { useState, useMemo, useContext } from 'react';

import { Link } from 'react-router-dom';
import { Column } from 'react-virtualized';
import { Select } from '@ethical-jobs/design-system-react';

import { usePromise } from 'hooks';
import { APIContext } from 'lib/contexts';

import PageTitle from 'components/PageTitle';
import DataTable from 'components/DataTable';
import DateTimeRenderer from 'components/DataTableRenderers/DateTimeRenderer';
import OrganisationSelect from 'fields/OrganisationSelect';
import Spinner from 'components/Spinner';

const ActivityLog = () => {
  const api = useContext(APIContext);

  const [selectedOrg, setSelectedOrg] = useState(undefined);
  const [numResults, setNumResults] = useState(100);

  const getActivities = useMemo(() => api.getActivities({ organisation: selectedOrg || undefined, limit: numResults }), [selectedOrg, api, numResults]);
  const [activities, , isLoading] = usePromise(getActivities, [], { component: 'ActivityLog' });

  return (
    <div className="activities-collection-container">
      <PageTitle title="Activity Log" subTitle="Filterable list of Activity on the site" />
      <div className="entity-listing-container">
        <div className="data-table-filters">
          <ul className="clearfix button-list">
            <div className="filter-component" style={{ paddingBottom: 10, display: 'flex', alignItems: 'center' }}>
              <OrganisationSelect
                onChange={setSelectedOrg}
                value={selectedOrg}
                isClearable
              />
              <div style={{ paddingLeft: 15 }}>
                <Select
                  value={`${numResults}`}
                  onChange={({ value }) => setNumResults(Number(value))}
                  isClearable={false}
                  isSearchable={false}
                  width={180}
                  label="# Results"
                  options={[{
                    value: '100',
                    label: '100'
                  }, {
                    value: '200',
                    label: '200'
                  }, {
                    value: '500',
                    label: '500'
                  }, {
                    value: '1000',
                    label: '1000'
                  }]}
                />
              </div>
              <Spinner show={isLoading} />
            </div>
          </ul>
        </div>
        <DataTable rows={activities}>
          <Column
            label="Type"
            dataKey="description"
            width={120}
          />
          <Column
            label="Amount"
            dataKey="properties"
            width={70}
            cellRenderer={data => data.cellData.amount}
          />
          <Column
            label="Organisation"
            dataKey="subject"
            width={140}
            cellRenderer={data => <Link to={`/admin/organisation/${data.cellData.id}/view`}>{data.cellData.name}</Link>}
          />
          <Column
            label="Who"
            dataKey="causer"
            width={120}
            cellRenderer={data => <Link to={`/admin/staff/${data.cellData.id}`}>{data.cellData.username}</Link>}
          />
          <Column
            label="Reason"
            dataKey="properties"
            width={200}
            flexGrow={1}
            cellRenderer={data => data.cellData.reason}
          />
          <Column
            label="Created"
            dataKey="createdAt"
            width={150}
            cellRenderer={data => <DateTimeRenderer timestamp={data.cellData} format="DD/MM/YYYY h:mm a" />}
          />
        </DataTable>
      </div>
    </div>
  );
};

export default React.memo(ActivityLog);
