import React from 'react';

import moment from 'moment';
import { Label as BsLabel } from 'react-bootstrap';
import { Invoice as InvoiceType } from '@ethical-jobs/sdk-js/types';

import { formatMoney } from 'lib/formatters';

type Props = {
  invoice: InvoiceType
};

const Invoice = ({ invoice }: Props) => (
  <div className="invoice">
    <h3>{`Invoice: ${invoice.uid}`}</h3>
    <div className="row">
      <div className="col-md-6">
        <table>
          <tbody>
            <tr>
              <td>Organisation</td>
              <td>{invoice.organisationName}</td>
            </tr>
            <tr>
              <td>Item title</td>
              <td>{invoice.serviceName}</td>
            </tr>
            <tr>
              <td>Item description</td>
              <td>{invoice.serviceDescription}</td>
            </tr>
            <tr>
              <td>Quantity</td>
              <td>{invoice.quantity}</td>
            </tr>
            <tr>
              <td>Total</td>
              <td>{formatMoney(invoice.total)}</td>
            </tr>
          </tbody>
        </table>
      </div>
      <div className="col-md-6">
        <table>
          <tbody>
            <tr>
              <td>Status</td>
              <td>
                <BsLabel className={invoice.paidAt ? "label-success" : "label-danger"}>
                  {invoice.paidAt ? "Paid" : "Due"}
                </BsLabel>
              </td>
            </tr>
            <tr>
              <td>Created</td>
              <td>{moment(invoice.createdAt).format('DD/MM/YYYY @ hh:mm a')}</td>
            </tr>
            <tr>
              <td>Updated</td>
              <td>{moment(invoice.updatedAt).format('DD/MM/YYYY @ hh:mm a')}</td>
            </tr>
            <tr>
              <td>Paid</td>
              <td>{invoice.paidAt ? moment(invoice.paidAt).format('DD/MM/YYYY @ hh:mm a') : 'No'}</td>
            </tr>
            <tr>
              <td>Archived</td>
              <td>{invoice.deletedAt ? moment(invoice.deletedAt).format('DD/MM/YYYY @ hh:mm a') : 'No'}</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
);

export default Invoice;