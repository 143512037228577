import { combineReducers } from 'redux';
import { reducer as form } from 'redux-form';
import { connectRouter } from 'connected-react-router';

import { History } from 'history';

import {
  AuthReducer,
  JobsReducer,
  OrganisationsReducer,
  SearchReducer,
  TaxonomiesReducer
} from 'reducers';

const createRootReducer = (history: History) => combineReducers({
    router: connectRouter(history),
    auth: AuthReducer,
    jobs: JobsReducer,
    taxonomies: TaxonomiesReducer,
    search: SearchReducer,
    form,
    organisations: OrganisationsReducer
  });

export default createRootReducer;
