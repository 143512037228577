import React, { useCallback } from 'react';

import _ from 'lodash';
import { change } from 'redux-form';
import { useDispatch, useSelector } from 'react-redux';

import Card from 'components/Card';
import Icon from 'components/Icon';

import FormField from 'fields/FormField';

type Props = {
  creditBalance: number;
  admin: boolean;
  showCloneFeaturedMessage?: boolean;
  updatingFeaturedWillChargeCredit?: boolean;
};

const Featured = ({ creditBalance, admin, showCloneFeaturedMessage = false, updatingFeaturedWillChargeCredit = false }: Props) => {
  const dispatch = useDispatch();

  const isFeatured = useSelector(state => _.get(state, ['form', 'jobForm', 'values', 'isFeatured'], false));

  let disabled = (creditBalance < 2 && !admin) || (creditBalance === 0 && updatingFeaturedWillChargeCredit);

  const toggleFeatured = useCallback(() => dispatch(change('jobForm', 'isFeatured', !isFeatured)), [isFeatured, dispatch]);

  return (
    <Card
      title="Feature this ad"
      subTitle="Would you like to feature your job at the top of search results pages to increase the number of people who see it?"
      className={disabled ? 'feature-disabled' : ''}
    >
      <p className="featured-note">
        <strong>Please note:</strong>
        &nbsp;This will deduct one additional advertising credit from your account.
      </p>
      {showCloneFeaturedMessage && (
        <div style={{ borderStyle: 'solid', borderWidth: 2, borderColor: '#3E8420', padding: '10px 15px', borderRadius: 5, marginBottom: 15, backgroundColor: '#DBEED3', display: 'flex', alignItems: 'center' }}>
          <Icon type="warning" style={{ color: '#333' }} />
          <p style={{ marginBottom: 0, paddingLeft: 5, color: '#333' }}>This is a cloned job that was originally featured. Please check if the cloned version should be featured</p>
        </div>
      )}
      {updatingFeaturedWillChargeCredit && !disabled && (
        <div style={{ borderStyle: 'solid', borderWidth: 2, borderColor: '#3E8420', padding: '10px 15px', borderRadius: 5, marginBottom: 15, backgroundColor: '#DBEED3', display: 'flex', alignItems: 'center' }}>
          <Icon type="warning" style={{ color: '#333' }} />
          <p style={{ marginBottom: 0, paddingLeft: 5, color: '#333' }}>Setting this job to be featured will charge the customer 1 credit</p>
        </div>
      )}
      <FormField
        name="isFeatured"
        component="input"
        type="checkbox"
        label={disabled ? (
          <span><strong>Disabled:</strong> Insufficient credits to feature your job ad. An additional credit is required.</span>
        ) : (
          <span onClick={toggleFeatured}>Yes feature my job ad!</span>
        )}
        disabled={disabled}
      />
    </Card>
  );
};

export default Featured;
