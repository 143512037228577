import styled from 'styled-components';

export const HeaderContainer = styled.div`
  padding: 15px 30px 5px;
  display: flex;
  justify-content: space-between;
  border-width: 0;
  border-bottom: 1px solid #DDD;
`;

export const FooterContainer = styled.div`
  padding: 15px 30px 5px;
  display: flex;
  justify-content: space-between;
`;