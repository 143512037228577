
interface Env {
  EJ_ENV: string;
  API_HOST: string;
  EJ_CDN: string;
  SITE: string;
  STRIPE_KEY: string;
  WEB_ATS_HOST: string;
  VERSION_TAG: string;
  AMPLITUDE_KEY: string;
  FILE_DOWNLOAD_API_SECRET: string;
}

const env: Env = {
  EJ_ENV: `${process.env.REACT_APP_EJ_ENV}` || '',
  API_HOST: `${process.env.REACT_APP_API_HOST}` || '',
  EJ_CDN: `${process.env.REACT_APP_EJ_CDN}` || '',
  STRIPE_KEY: `${process.env.REACT_APP_STRIPE_KEY}` || '',
  WEB_ATS_HOST: `${process.env.REACT_APP_WEB_ATS_HOST}` || '',
  VERSION_TAG: `${process.env.REACT_APP_VERSION_TAG}` || '',
  AMPLITUDE_KEY: `${process.env.REACT_APP_AMPLITUDE_KEY}` || '',
  FILE_DOWNLOAD_API_SECRET: `${process.env.REACT_APP_FILE_DOWNLOAD_API_SECRET}` || '',
  SITE: 'Admin Portal'
};

export default env;
