import React from 'react';

import _ from 'lodash';
import { useSelector, shallowEqual } from 'react-redux';
import classNames from 'classnames';

import PublicHoliday from 'components/PublicHoliday';
import 'react-dates/initialize';
import 'index.css';

type Props = {
  children: React.ReactNode
};

const App = ({ children }: Props) => {
  // @ts-ignore
  const roles = useSelector(state => _.get(state.auth, ['user', 'roles'], []), shallowEqual);

  const classes = classNames('child-container', roles);

  return (
    <div className={classes}>
      {children}
      <PublicHoliday />
    </div>
  );
};

export default React.memo(App);
