import {
  getFormSubmitErrors,
  getFormSyncErrors,
  formValueSelector,
  getFormInitialValues
} from 'redux-form';
import { createSelector } from 'reselect';
import _ from 'lodash';
import {
  getMailtoLinks,
  getEmailAddresses,
  cleanEmailAddress
} from 'lib/links';
import { getTextContent } from 'lib/dom';
import { serialize as uriSerialize, URIComponents } from 'uri-js';

export const formErrorsSelector = (form: string, state: Object): Array<string> => {
  const syncErrors = getFormSyncErrors(form)(state);
  const submitErrors = getFormSubmitErrors(form)(state);

  const errors: Array<string> = [];

  _.forEach(syncErrors, error => {
    if (error) errors.push(error);
  });

  _.forEach(submitErrors, error => {
    if (error) errors.push(error);
  });
  return errors;
};


export const suggestedApplicationTemplatesSelector = (formName: string) => {
  const jobTitle = (state: Object): string => formValueSelector(formName)(state, 'title');

  return createSelector([jobTitle], jobTitle => {
    return [
      {
        title: 'Application w/ title',
        description:
          "Inserts 'using the subject line: " +
          jobTitle +
          " application via EthicalJobs' at the cursor.",
        content:
          'using the subject line: <strong>' +
          jobTitle +
          ' application via EthicalJobs</strong>'
      },
      {
        title: 'Enquiry w/ title',
        description:
          "Inserts 'using the subject line: " +
          jobTitle +
          " enquiry via EthicalJobs' at the cursor.",
        content:
          'using the subject line: <strong>' +
          jobTitle +
          ' enquiry via EthicalJobs</strong>'
      }
    ];
  });
};

export const suggestedTextTemplatesSelector = (formName: string) => {
  const jobTitle = (state: Object): string => formValueSelector(formName)(state, 'title');

  return createSelector([jobTitle], jobTitle => {
    return [
      {
        title: 'Enquiry w/ title',
        description:
          "Inserts 'using the subject line: " +
          jobTitle +
          " enquiry via EthicalJobs' at the cursor.",
        content:
          'using the subject line: <strong>' +
          jobTitle +
          ' enquiry via EthicalJobs</strong>'
      },
      {
        title: 'Position description',
        description: "Inserts 'A position description is attached.",
        content: '<strong>A position description is attached.</strong>'
      }
    ];
  });
};

export const suggestedMailtoLinksSelector = (formName: string) => {
  const jobTitle = (state: Object): string => formValueSelector(formName)(state, 'title');

  const initialValues = (state: Object) => getFormInitialValues(formName)(state) as { application: string, description: string };

  const variants = ['application', 'enquiry'];

  const firstTo = (link: URIComponents & { to: Array<string>, linkLabel: string; }): string => link.to[0];


  const dedupeLinks = (links: Array<URIComponents & { to: Array<string>, linkLabel: string; }>): Array<URIComponents & { to: Array<string>, linkLabel: string; }> => {
    let deduped: { [key: string]: URIComponents & { to: Array<string>, linkLabel: string; } } = {};
    for (const link of links) {
      const to = firstTo(link);
      if (deduped[to]) {
        continue;
      }
      deduped[to] = link;
    }
    const values = Object.keys(deduped).map(key => deduped[key]);
    return values;
  };

  const plainEmailToLink = (email: string) => {
    return {
      to: [email],
      scheme: 'mailto',
      linkLabel: email
    };
  };

  return createSelector(
    [jobTitle, initialValues],
    (jobTitle, initialValues) => {
      if (!initialValues) initialValues = {
        application: '',
        description: ''
      };

      const applicationHtml = initialValues.application || '';
      const descriptionHtml = initialValues.description || '';
      const applicationText = getTextContent(applicationHtml);
      const descriptionText = getTextContent(descriptionHtml);

      const linksFromHtml = [
        ...getMailtoLinks(applicationHtml),
        ...getMailtoLinks(descriptionHtml)
      ];

      const linksFromText = [
        ...getEmailAddresses(applicationText),
        ...getEmailAddresses(descriptionText)
      ]
        .map(email => cleanEmailAddress(email))
        .map(email => plainEmailToLink(email));

      const links = dedupeLinks([...linksFromHtml, ...linksFromText]);

      let suggested = [];

      for (const link of links) {
        for (const variant of variants) {
          suggested.push({
            title: firstTo(link) + ' | ' + variant,
            value: uriSerialize({
              ...link,
              // @ts-ignore
              subject: `${jobTitle} ${variant} via EthicalJobs`
            })
          });
        }
      }

      return suggested;
    }
  );
};
