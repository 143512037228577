import React, { useState, useCallback } from 'react';

import StaffOrganisationAssigner from 'components/StaffOrganisationAssigner';
import PageTitle from 'components/PageTitle';

import EnumerableFilter from 'components/CollectionViewControls/EnumerableFilter';
import StatesFilter from 'components/CollectionViewControls/StatesFilter';
import QueryFilter from 'components/CollectionViewControls/QueryFilter';

const StaffRelationsManager = () => {
  const [filters, setFilters]: [{ [key: string]: string }, React.Dispatch<React.SetStateAction<{ [key: string]: string }>>] = useState({});

  const updateFilters = useCallback((filter: { [key: string]: string }) => {
    setFilters(prevFilters => ({ ...prevFilters, ...filter }));
  }, []);
  
  return (
    <div className="staff-relations-manager-container">
      <PageTitle title="Staff relations manager" subTitle="Assign staff members to organisations" />
      <div className="data-table-filters">
        <ul className="clearfix button-list">
          <li><QueryFilter onChange={updateFilters} filterKey="keywords" placeholder="Filter by keyword" /></li>
          <li><QueryFilter onChange={updateFilters} filterKey="postcode" placeholder="Filter by postcode" /></li>
          <li><QueryFilter onChange={updateFilters} filterKey="paidStaff" placeholder="Filter by paid staff" /></li>
          <li><QueryFilter onChange={updateFilters} filterKey="volunteers" placeholder="Filter by volunteers" /></li>
          <li><StatesFilter updateFilters={updateFilters} filters={filters} /></li>
          <li><EnumerableFilter enumerableType="countries" updateFilters={updateFilters} filters={filters} /></li>
        </ul>
      </div>
      <StaffOrganisationAssigner
        filters={filters}
      />
    </div>
  );
};

export default React.memo(StaffRelationsManager);
