import React from 'react';

import { Link } from 'react-router-dom';

import { Job as JobType } from '@ethical-jobs/sdk-js/types';

type Props = {
  job: JobType
};

const Job = ({ job }: Props) => (
  <li className="job">
    <Link to={`/organisation/job/${job.id}/view`}>
      {job.title}
    </Link>
  </li>
);

export default Job;
