import React, { useContext, useState, useCallback, useEffect } from 'react';
import { Button } from '@ethical-jobs/design-system-react';
import { APIContext } from 'lib/contexts';
import _ from 'lodash';
import cogoToast from 'cogo-toast';
import { Contact } from '@ethical-jobs/sdk-js/types';
import { loadUserDetails } from 'actions/auth';
import { useDispatch } from 'react-redux';
import { P, FlexDiv, ActionsDiv, HalfDiv, LeftDiv, RightDiv, Asterix, ErrorMessage, Label, Input } from './ReadOnly.styles';
import { object, string } from 'yup';
import { Formik } from "formik";

const schema = object().shape({
  email: string().email('Email must be valid').required('Email is required'),
  name: string().required('Name is required')
});

interface FinanceActionsProps {
  financeContact: Contact;
  onCreate: () => void;
  onUpdate: () => void;
  onCancel: () => void;
}

const FinanceActions = (props: FinanceActionsProps) => {
  if (_.isEmpty(props.financeContact.id) ) {
    return (<Button
      type="primary"
      label="+ Add"
      onClick={props.onCreate}
    />)
  } else {
    return (
      <ActionsDiv>
        <Button
          style={{ marginRight: '10px' }}
          type="primary"
          label="Change Finance Contact"
          onClick={props.onUpdate}
        />
        <Button
          style={{
            background: '#fff',
            color: 'rgb(8, 19, 38)',
            border: '1px solid rgb(8, 19, 38)'
          }}
          type="secondary"
          label="Cancel"
          onClick={props.onCancel}
        />
      </ActionsDiv>
    )
  }
}

interface FinanceContactProps {
  financeContact: Contact;
  organisationId: number;
  onUpdate: (p: Contact) => void;
  onCancel: () => void;
  onSave: () => void;
  onLoading: () => void;
};

const FinanceContact = (props: FinanceContactProps) => {
  const {
    financeContact,
    organisationId,
    onUpdate,
    onCancel,
    onSave,
    onLoading,
  } = props;
  const api = useContext(APIContext);
  const dispatch = useDispatch();

  const createFinanceContact = useCallback(async (values: Contact) => {
    onLoading();
    const updatedFinanceContact = await api.createFinanceContact({
      name: values.name,
      email: values.email,
      phone: values.phone
    }, organisationId).catch(() => {
      cogoToast.error('There was an error creating the finance contact.', { hideAfter: 3, position: 'top-right' });
    });
    onUpdate(updatedFinanceContact);
    cogoToast.success('Successfully saved finance contact.', { hideAfter: 3, position: 'top-right' });
    dispatch(loadUserDetails());
    onSave();
  }, [financeContact]);

  const updateFinanceContact = useCallback(async (values: Contact) => {
    onLoading();
    const updatedFinanceContact = await api.updateFinanceContact(values, organisationId).catch(() => {
      cogoToast.error('There was an error updating the finance contact.', { hideAfter: 3, position: 'top-right' });
    });
    onUpdate(updatedFinanceContact);
    cogoToast.success('Successfully updated finance contact.', { hideAfter: 3, position: 'top-right' });
    onSave();
  }, [financeContact]);

  return (
    <Formik
      initialValues={financeContact}
      onSubmit={async values => {
        if (_.isEmpty(financeContact.id) ) {
          createFinanceContact(values);
        } else {
          updateFinanceContact(values);
        }
      }}
      validationSchema={schema}
      enableReinitialize
    >
      {props => {
        const {
          values,
          handleChange,
          handleSubmit,
          errors,
          touched,
        } = props;
        return (
          <>
            <HalfDiv>
              <Label invalid={errors.name && touched.name}>Name <Asterix>*</Asterix></Label><br />
              <Input
                name="name"
                type="text"
                value={values.name}
                onChange={handleChange}
                invalid={errors.name && touched.name}
              />
              {errors.name && touched.name && (<ErrorMessage>{errors.name}</ErrorMessage>)}
              <FlexDiv>
                <LeftDiv>
                  <Label invalid={errors.email && touched.email}>Email <Asterix>*</Asterix></Label><br />
                  <Input
                    name="email"
                    type="text"
                    value={values.email}
                    onChange={handleChange}
                    invalid={errors.email && touched.email}
                  />
                  {errors.email && touched.email && (<ErrorMessage>{errors.email}</ErrorMessage>)}
                </LeftDiv>
                <RightDiv>
                  <Label>Phone</Label><br />
                  <Input
                    name="phone"
                    type="text"
                    value={values.phone}
                    onChange={handleChange}
                  />
                </RightDiv>
              </FlexDiv>
              <FinanceActions
                financeContact={financeContact}
                onCreate={handleSubmit}
                onUpdate={handleSubmit}
                onCancel={onCancel}
              />
            </HalfDiv>
          </>
        )}}
    </Formik>
  );
}

export default FinanceContact;