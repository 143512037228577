import React, { useState, useContext, useCallback } from 'react';

import { Input, Heading, FileUpload, Row, Button } from '@ethical-jobs/design-system-react';
import { Attachment, Applicant } from '@ethical-jobs/sdk-js/types';

import Modal from 'components/Modal';
import Icon from 'components/Icon';
import { APIContext, AmplitudeContext } from 'lib/contexts';

import { HeadingContainer } from './AddApplicantModal.styles';

const ACCEPTED_DOCS = [
  'application/msword',
  'application/pdf',
  'application/vnd.openxmlformats-officedocument.wordprocessingml.document'
].join(', ');

const MAX_FILE_SIZE = 5242880;

type Props = {
  jobId: number;
  organisationId: number;
  isVisible: boolean;
  close: () => void;
  onSave: (applicant: Applicant) => void;
}

const AddApplicantModal = ({ isVisible, close, jobId, organisationId, onSave }: Props) => {
  const api = useContext(APIContext);
  const amplitude = useContext(AmplitudeContext);

  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [phone, setPhone] = useState('');
  const [email, setEmail] = useState('');
  const [recentEmployer, setRecentEmployer] = useState('');
  const [recentRole, setRecentRole] = useState('');
  const [attachments, setAttachments] = useState<Array<Attachment & { signature: string }>>([]);

  const [isSaving, setIsSaving] = useState(false);

  const saveApplicant = useCallback(async () => {
    setIsSaving(true)
    try {
      let applicant = await api.createApplicant(jobId, {
        firstName,
        lastName,
        phone,
        email,
        recentRole,
        recentEmployer,
        attachments,
        organisationId: organisationId,
        answers: [],
      }, false);
      onSave(applicant);
      setAttachments([]);
      close();
    } catch (error) {
      console.log(error);
    }

    setIsSaving(false);
  }, [api, jobId, firstName, lastName, phone, email, recentEmployer, recentRole, close, onSave, organisationId, attachments]);

  const uploadFile = useCallback(async (attachment: File) => {
    try {
      let response = await api.uploadApplicationAttachment(jobId, attachment);
      setAttachments(prevAttachments => [...prevAttachments, response]);
    } catch (error) {
      amplitude.logEvent('Error', { location: 'containers/_organisation/ApplicantManagementJob/AddApplicantModal:uploadFile', message: error.message });
    }
  }, [amplitude, api, jobId]);

  return (
    <Modal show={isVisible} onHide={close}>
      <HeadingContainer>
        <p style={{ fontSize: 24, fontWeight: 'bold', marginBottom: 0 }}>
          ADD APPLICANT
        </p>

        <div style={{ display: 'flex', cursor: 'pointer', alignItems: 'center' }} onClick={close}>
          <p
            // @ts-ignore
            style={{ color: '#999', fontWeight: '400', marginBottom: 0 }}
          >
            CLOSE
          </p>
          <Icon type="clear" style={{ color: '#999', paddingLeft: 10 }}/>
        </div>
      </HeadingContainer>

      <div style={{ padding: 15 }}>
        <Heading size="small" style={{ padding: '15px 0px' }}>Personal Details</Heading>

        <div style={{ display: 'flex', flexWrap: 'wrap', marginBottom: 15 }}>
          <div style={{ flex: 1, marginRight: 10 }}>
            <Heading size="xsmall">First Name*</Heading>
            <div style={{ display: 'flex', paddingTop: 10 }}>
              <Input
                placeholder="e.g. Mary"
                onChange={setFirstName}
                size="large"
              />
            </div>
          </div>
          <div style={{ flex: 1 }}>
            <Heading size="xsmall">Last Name*</Heading>
            <div style={{ display: 'flex', paddingTop: 10 }}>
              <Input
                placeholder="e.g. Smith"
                onChange={setLastName}
                size="large"
              />
            </div>
          </div>
        </div>

        <div style={{ display: 'flex', flexWrap: 'wrap', marginBottom: 15 }}>
          <div style={{ flex: 1, marginRight: 10 }}>
            <Heading size="xsmall">Email Address*</Heading>
            <div style={{ display: 'flex', paddingTop: 10 }}>
              <Input
                placeholder="e.g. mary.smith@workplace.com"
                onChange={setEmail}
                size="large"
              />
            </div>
          </div>

          <div style={{ flex: 1 }}>
            <div style={{ display: 'flex', alignItems: 'baseline' }}>
              <Heading size="xsmall">Phone Number*</Heading>
              <Heading size="xsmall" style={{ color: '#888', paddingLeft: 10 }}>(mobile preferred)</Heading>
            </div>
            <div style={{ display: 'flex', paddingTop: 10 }}>
              <Input
                placeholder="e.g. 0412 345 678"
                onChange={setPhone}
                size="large"
              />
            </div>
          </div>
        </div>


        <Heading size="small" style={{ padding: '15px 0px' }}>Most Recent Role</Heading>
        <div style={{ display: 'flex', flexWrap: 'wrap', marginBottom: 15 }}>
          <div style={{ flex: 1, marginRight: 10 }}>
            <Heading size="xsmall">Job Title*</Heading>
            <div style={{ display: 'flex', paddingTop: 10 }}>
              <Input
                placeholder="e.g. Administrative Officer"
                onChange={setRecentRole}
                size="large"
              />
            </div>
          </div>

          <div style={{ flex: 1 }}>
            <Heading size="xsmall">Company Name*</Heading>
            <div style={{ display: 'flex', paddingTop: 10 }}>
              <Input
                placeholder="e.g. Workplace"
                onChange={setRecentEmployer}
                size="large"
              />
            </div>
          </div>
        </div>

        <Heading size="small" style={{ padding: '15px 0px' }}>Attach Documents</Heading>
        <div style={{ display: 'flex', flexWrap: 'wrap' }}>
          <FileUpload
            items={attachments}
            accept={ACCEPTED_DOCS}
            maxSize={MAX_FILE_SIZE}
            fileLimit={5}
            description="Click here to upload a PDF / Word document (maximum file size 5MB)"
            uploadFile={uploadFile}
            onRemove={(attachmentId: string) => setAttachments(prevAttachments => prevAttachments.filter(attachment => attachment.id !== attachmentId))}
          />
        </div>

        <Row align="right" style={{ paddingTop: 15 }}>
          <Button
            type="action"
            onClick={saveApplicant}
            label="Create Applicant"
            disabled={isSaving}
            loading={isSaving}
          />
        </Row>
      </div>
    </Modal>
  );
}

export default AddApplicantModal;