import React from 'react';
import { Route, Switch } from 'react-router-dom';
import OrganisationRoutes from 'containers/_organisation/routes';
import AdminRoutes from 'containers/_admin/routes';
import App from 'containers/App';
import NotFound from 'containers/NotFound';
import Login from 'containers/Login';
import PasswordRecover from 'containers/PasswordRecover';
import PasswordReset from 'containers/PasswordReset';
import OrganisationSignup from 'containers/OrganisationSignup';
import Signup from 'containers/Signup';
import MobileUnavailable from 'containers/MobileUnavailable';

const Routes = () => (
  <App>
    <Switch>
      <Route path="/" exact component={Login} />
      <Route path="/login" component={Login} />
      <Route path="/mobile-unavailable" component={MobileUnavailable} />
      <Route path="/recover-password" component={PasswordRecover} />
      <Route path="/reset-password/:tokenAndUsername" component={PasswordReset} />
      <Route path="/organisation-signup" component={OrganisationSignup} />
      <Route path="/signup" component={Signup} />
      {OrganisationRoutes()}
      {AdminRoutes()}
      <Route path="*" component={NotFound} status={404} />
    </Switch>
  </App>
);

export default Routes;
