import React, { useState, useMemo, useContext, useCallback } from 'react';

import _ from 'lodash';
import moment from 'moment';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import { Column } from 'react-virtualized';

import { usePromise } from 'hooks';
import { APIContext } from 'lib/contexts';
import { searchInvoicesAdmin } from 'actions/search';

import Button from 'components/Button';
import Spinner from 'components/Spinner';
import PageTitle from 'components/PageTitle';
import OrganisationSelect from 'fields/OrganisationSelect';
import QueryFilter from 'components/CollectionViewControls/QueryFilter';

import DataTable from 'components/DataTable';
import RouteRenderer from 'components/DataTableRenderers/RouteRenderer';
import InvoiceQuantityRenderer from 'components/DataTableRenderers/InvoiceQuantityRenderer';
import MoneyRenderer from 'components/DataTableRenderers/MoneyRenderer';
import InvoiceLink from 'components/InvoiceLink';

const InvoiceCollection = () => {
  const dispatch = useDispatch();
  const api = useContext(APIContext);

  // @ts-ignore
  const defaultSearch = useSelector(state => state.search.admin.invoices, shallowEqual);

  const [organisationId, setOrganisationId] = useState<number | null>(defaultSearch.organisation);
  const [query, setQuery] = useState(defaultSearch.query);
  const [sort, setSort] = useState<{ field: string, direction: 'asc' | 'desc' }> (defaultSearch.sort);

  const getInvoices = useMemo(async () => {
    dispatch(searchInvoicesAdmin({ query, organisation: organisationId, sort }));

    let invoices = await api.getInvoices({
      limit: 100,
      orderBy: [sort],
      keywords: query,
      organisationId: organisationId || undefined,
      fields: ['id', 'organisationUid', 'organisationId', 'serviceDescription', 'uid', 'quantity', 'total', 'createdAt', 'paidAt']
    });

    return _.orderBy(invoices, [sort.field], [sort.direction]);
  }, [api, query, organisationId, sort, dispatch]);

  const onSortChange = useCallback((newSort: { sortBy: string; sortDirection: 'ASC' | 'DESC' }) => {
    // @ts-ignore
    setSort({ field: newSort.sortBy, direction: newSort.sortDirection.toLowerCase() });
  }, []);

  const [invoices, , isFetching] = usePromise(getInvoices, [], { component: 'InvoiceCollection' });

  const debounceQuery = _.debounce(setQuery, 500);

  return (
    <div className="invoices-collection-container">
      <PageTitle title="Invoice List" subTitle="Filterble list of invoices" />
      <div className="entity-listing-container">
        <div className="data-table-filters">
          <div className="meta clearfix">
            <div className="result">{invoices.length || 'No'} results</div>
            <Spinner show={isFetching} />
          </div>
          <ul className="clearfix button-list">
            <div style={{ display: 'flex', padding: '10px 0px' }}>
              <OrganisationSelect
                onChange={setOrganisationId}
                value={organisationId}
                isClearable
              />
              <span style={{ paddingLeft: 10 }}>
                <QueryFilter
                  onChange={({ keyword }) => debounceQuery(keyword)}
                  filterKey="keyword"
                  placeholder="Filter by keyword"
                  defaultValue={query}
                />
              </span>
              <Button
                icon="clear"
                label="Clear Filters"
                onClick={() => {
                  setOrganisationId(null);
                  setQuery('');
                  setSort({ field: 'createdAt', direction: 'desc' })
                }}
                size="small"
                style={{ marginLeft: 10 }}
              />
            </div>
          </ul>
        </div>
        {!isFetching && (
          <DataTable
            rows={invoices}
            // @ts-ignore
            defaultSort={{ sortBy: sort.field, sortDirection: sort.direction.toUpperCase() }}
            onSortChange={onSortChange}
          >
            <Column
              label="Invoice no."
              dataKey="uid"
              width={170}
              cellRenderer={props => (
                <RouteRenderer
                  to={`/admin/invoice/${props.rowData.id}/update`}
                  text={props.cellData}
                  {...props}
                />
              )}
            />
            <Column
              label="Description"
              dataKey="serviceDescription"
              width={120}
              flexGrow={1}
            />
            <Column
              label="Qty"
              dataKey="quantity"
              width={70}
              cellRenderer={data => <InvoiceQuantityRenderer qty={data.cellData} />}
            />
            <Column
              label="Total"
              dataKey="total"
              width={120}
              cellRenderer={data => <MoneyRenderer money={data.cellData} />}
              disableSort
            />
            <Column
              label="Created"
              dataKey="createdAt"
              width={150}
              cellRenderer={data => <span>{moment(data.cellData).format('YYYY-MM-DD h:mm a')}</span>}
            />
            <Column
              label="Organisation"
              dataKey="organisationUid"
              width={160}
              disableSort
            />
            <Column
              label="Paid"
              dataKey="paidAt"
              width={150}
              cellRenderer={data => (
                <RouteRenderer
                  to={`/admin/invoice/${data.rowData.id}/payment`}
                  text={data.cellData ? (
                    <span>{moment(data.cellData).format('YYYY-MM-DD h:mm a')}</span>
                  ) : (
                    "Pay Invoice"
                  )}
                />
              )}
              disableSort
            />
            <Column
              label="Download"
              dataKey="downloadUrl"
              width={140}
              cellRenderer={(data) => <InvoiceLink invoiceId={data.rowData.id} />}
              disableSort
            />
          </DataTable>
        )}
      </div>
    </div>
  );
};

export default React.memo(InvoiceCollection);
