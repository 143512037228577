import React from 'react';

import { Attachment, AttachmentLinks } from '@ethical-jobs/sdk-js/types';

type Props = {
    attachments: Array<Attachment & AttachmentLinks>;
};

const ApplicantAttachments = ({ attachments }: Props) => {
  if (attachments.length === 0) return null;

  return (
    <div style={{ width: '100%', justifyContent: 'center', display: 'flex' }}>
      <iframe
        title={attachments[0].id}
        src={attachments[0].previewLink}
        style={{ width: '100%', height: 600 }}
      />
    </div>
  );
};

export default ApplicantAttachments;