import styled from 'styled-components';

type TabContainerProps = {
  isActiveTab: boolean;
}

export const TabContainer = styled.div<TabContainerProps>`
  cursor: pointer;
  padding: 8px 15px;
  position: relative;
  background-color: ${({ isActiveTab }) => isActiveTab ? '#ECEDF2' : '#777'};
  margin-right: 8px;
  border-style: solid;
  border-width: 1px;
  border-color: ${({ isActiveTab }) => isActiveTab ? '#DDE3E8' : 'transparent'};
  border-bottom-color: ${({ isActiveTab }) => isActiveTab ? '#ECEDF2' : 'transparent'};
  top: ${({ isActiveTab }) => isActiveTab ? 1 : 0};
`;