import React, { Fragment } from 'react';
import { useFeatureFlag } from 'hooks';

import { FeatureFlag as FeatureFlagType } from '../../featureFlags';

type Props = {
  feature: FeatureFlagType;
  organisationId?: number;
  children: React.ReactNode;
};

const FeatureFlag = ({ feature, organisationId, children }: Props) => {
  const isFeatureEnabled = useFeatureFlag(feature, organisationId);

  return isFeatureEnabled ? (
    <Fragment>
      {children}
    </Fragment>
   ) : null;
};

export default FeatureFlag;
