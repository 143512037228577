import React, { useCallback } from 'react';

import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import { reduxForm } from 'redux-form';
import validation from './validation';
import submissionError from 'lib/submissionError';
import ValidationMessages from 'components/ValidationMessages';
import Button from 'components/Button';
import FormField from 'fields/FormField';
import { Contact } from '@ethical-jobs/sdk-js/types';
import _ from 'lodash';
import Icon from 'components/Icon';

type PurchaseParams = {
  name: string
  position: string
  organisationName: string
  email: string
  method: string
  description: string
}

type InvoicePurchaseFormProps = {
  disabled?: boolean;
  error?: string;
  purchase: (values: PurchaseParams) => Promise<Object>;
  handleSubmit: any;
  submitting: boolean;
  financeContact?: Contact;
}

const InvoicePurchaseForm = (props: InvoicePurchaseFormProps) => {
  const { disabled = false, error, purchase, handleSubmit, submitting, financeContact } = props;
  const submit = useCallback(async (values: PurchaseParams) => {
    try {
      let response = await purchase({
        ...values,
        method: 'invoice'
      });
      return response;
    } catch (error) {
      submissionError(error);
    }
  }, [purchase]);

  return (
    <form
      className="invoice-purchase-form form-horizontal"
      onSubmit={handleSubmit(submit)}
    >
      <div className="row">
        <FormField
          type="text"
          component="input"
          disabled={disabled || submitting}
          name="name"
          label="Name on invoice"
          placeholder="Enter your first and last name"
          wrapClassName="col-md-6"
        />
        <FormField
          type="text"
          component="input"
          disabled={disabled || submitting}
          name="position"
          label="Role in the organisation"
          placeholder="Enter your position title"
          wrapClassName="col-md-6"
        />
        <FormField
          type="text"
          component="input"
          disabled={disabled || submitting}
          name="organisationName"
          label="Organisation name"
          placeholder="Organisation name to appear on invoice"
          wrapClassName="col-md-6"
        />
        <FormField
          type="text"
          component="input"
          disabled={disabled || submitting}
          name="email"
          label="Email to"
          placeholder="Email address to receive this invoice"
          wrapClassName="col-md-6"
        />
        {_.has(financeContact, 'id') && !_.isEmpty(financeContact.id) && (
          <div className="row">
            <div className="col-md-6"></div>
            <div className="col-md-6" style={{ display: "flex" }}>
              <small style={{ display: "flex", position: "absolute", top: "-10px" }}>
                CC: {financeContact.name}, {financeContact.email}
                <OverlayTrigger
                  placement="top"
                  overlay={(
                    <Tooltip id="email-help">
                      The Finance Contact can be updated on the organisations Users section under Finance Contact
                    </Tooltip>
                  )}
                >
                  <span>
                    <Icon type="help" style={{ paddingLeft: 5, fontSize: 20 }} />
                  </span>
                </OverlayTrigger>
              </small>

            </div>
          </div>
        )}
        <FormField
          type="text"
          component="input"
          disabled={disabled || submitting}
          name="description"
          label="(Optional) Your order number / description"
          placeholder=""
          wrapClassName="col-md-12"
          style={{ marginBottom: 20 }}
        />
        <ValidationMessages error={error} />
        <Button
          kind="primary"
          type="submit"
          loading={submitting}
          disabled={disabled || submitting}
          label="Email invoice"
          block
        />
      </div>
    </form>
  );
};

export default reduxForm({
  form: 'invoicePurchaseForm',
  validate: validation
  // @ts-ignore
})(InvoicePurchaseForm);
