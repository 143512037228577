import React, { useContext, useMemo, useState, useCallback } from 'react';

import cogoToast from 'cogo-toast';

import { Organisation, CreditPack } from '@ethical-jobs/sdk-js/types';

import { usePromise } from 'hooks';
import { APIContext, AmplitudeContext } from 'lib/contexts';

import Card from 'components/Card';
import PageTitle from 'components/PageTitle';
import PricingTable from 'components/PricingTable';
import AdminPurchaseOptions from 'components/AdminPurchaseOptions';
import OrganisationSelect from 'fields/OrganisationSelect';

const Purchase = () => {
  const api = useContext(APIContext);
  const amplitude = useContext(AmplitudeContext);

  const [creditPack, setCreditPack] = useState<CreditPack | null>(null);
  const [organisationId, setOrganisationId] = useState<number | null>(null);

  const getOrganisation: Promise<Organisation | null> = useMemo(() => api.getOrganisation(Number(organisationId)), [api, organisationId]);
  
  const [organisation] = usePromise(getOrganisation, null, { component: 'Purchase' });

  const purchase = useCallback(async ({ method, ...params }) => {
    if (!creditPack || !organisation) return;

    try {
      let invoice = await api.purchaseCredits(organisation.id, creditPack.id, method, params);
      cogoToast.success(`Credit purchase was successful`, { hideAfter: 4, position: 'top-right' })
      setCreditPack(null);
      amplitude.logEvent('API', { function: 'purchaseCredits', arguments: { organisationId: organisation.id, creditPack: creditPack.id, method }});
      return invoice;
    } catch (error) {
      amplitude.logError('containers/_admin/Purchase/index/purchase', error);

      // INFO: Must rethrow so form can handle
      throw error;
    }
  }, [organisation, creditPack, api, amplitude]);

  return (
    <div className="admin-purchase-container">
      <PageTitle title="Purchase Job Ad credits" subTitle="Select a credit pack and organisation" />
      <Card>
        <PricingTable selectPack={setCreditPack} />
      </Card>
      {creditPack && (
        <Card title="Select organisation" subTitle="Purchase credits for the selected organisation">
          <OrganisationSelect onChange={setOrganisationId} value={organisationId} />
        </Card>
      )}
      {creditPack && organisation && (
        <AdminPurchaseOptions
          purchase={purchase}
          creditPack={creditPack}
          organisation={organisation}
        />
      )}
    </div>
  );
}

export default React.memo(Purchase);
