import React from 'react';
import moment from 'moment';
import StatsPanel from 'components/StatsPanel';

const JobViewsAndClicksFigure = () => (
  <StatsPanel
    title="Views & Clicks this week"
    figures={[
      {
        reportName: '7DayJobViews',
        dataKey: 'total_job_views',
        icon: 'visibility'
      },
      {
        reportName: '7DayJobClicks',
        dataKey: 'total_job_clicks',
        icon: 'mouse'
      }
    ]}
    query={{
      reports: [{
        reportName: '7DayJobViews',
        dateRanges: [
          {
            from: moment().startOf('week').format('YYYY-MM-DD HH:mm:ss'),
            to: moment().endOf('week').format('YYYY-MM-DD HH:mm:ss')
          }
        ],
        metric: 'metric:totalJobViews'
      },
      {
        reportName: '7DayJobClicks',
        dateRanges: [
          {
            from: moment().startOf('week').format('YYYY-MM-DD HH:mm:ss'),
            to: moment().endOf('week').format('YYYY-MM-DD HH:mm:ss')
          }
        ],
        metric: 'metric:totalJobClicks'
      }]
    }}
  />
);

JobViewsAndClicksFigure.displayName = 'JobViewsAndClicksFigure';

export default JobViewsAndClicksFigure;
