import React from 'react';

import { LinkContainer } from 'react-router-bootstrap';

import { Job as JobType } from '@ethical-jobs/sdk-js/types';

import Button from 'components/Button';
import Card from 'components/Card';
import Spinner from 'components/Spinner';
import Job from './Job';

type Props = {
  jobs: Array<JobType>;
  title: string;
  fetching: boolean;
  to: string;
};

const JobList = ({ jobs, title, fetching, to }: Props) => (
  <Card title={title}>
    <div className="job-list-container">
      <Spinner show={fetching} />
      {jobs.length > 0 && (
        <ul className="job-list">
          {jobs.map(job => <Job key={job.id} job={job} />)}
        </ul>
      )}
      {jobs.length === 0 && !fetching && (
        <p className="job-list empty">You do not currently have job ads of this type.</p>
      )}
    </div>
    <LinkContainer to={`/organisation/jobs/${to}`}>
      <Button size="small" kind="primary" label="&larr; View All" block />
    </LinkContainer>
  </Card>
);

export default JobList;
