import React, { useState, useEffect } from 'react';

import { useSelector, shallowEqual } from 'react-redux';
import { isDirty } from 'redux-form';
import { useParams, Prompt } from 'react-router-dom';

import { isSubmitting } from 'store/selectors';

import BaseJobUpdate from 'containers/JobUpdate';
import Container from 'components/Container';
import PageTitle from 'components/PageTitle';
import InsufficientCredits from 'components/InsufficientCredits';
import JobAdStepProgressBar from 'components/JobAdStepProgressBar';

export const JobUpdate = ({ exitWarning = 'WARNING: Do you really want to leave? Your work will be lost.' }) => {
  const { jobId, previewed } = useParams();
  
  const [enableLeaveBlocker, setEnableLeaveBlocker] = useState(false);

  // @ts-ignore
  const organisation = useSelector(state => state.auth.user.organisation, shallowEqual);
  const isFormDirty = useSelector(isDirty('jobForm'), shallowEqual);
  const isFormSubmitting = useSelector(isSubmitting('jobForm'), shallowEqual);

  useEffect(() => {
    let leaveBlockerTimeout = setTimeout(() => setEnableLeaveBlocker(true), 3000);
    return () => clearTimeout(leaveBlockerTimeout);
  }, []);

  const creditBalance = organisation.numCredits;

  const isBlocked = creditBalance > 0 && (isFormDirty || !!previewed) && !isFormSubmitting && enableLeaveBlocker;

  return (
    <Container className="admin-job-update-container">
      <PageTitle title="Create Your Job Ad" subTitle="Submit a new job ad for approval" />
      <JobAdStepProgressBar activeStep={0} />
      {creditBalance > 0 && (
        <BaseJobUpdate
          isAdmin={false}
          jobId={jobId !== undefined ? Number(jobId) : undefined}
          organisation={organisation}
        />
      )}
      <InsufficientCredits creditBalance={creditBalance} />
      <Prompt when={isBlocked} message={exitWarning} />
    </Container>
  );
};

export default React.memo(JobUpdate);
