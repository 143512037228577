import React, { useCallback, useContext, useState } from 'react';

import _ from 'lodash';
import moment from 'moment';
import cogoToast from 'cogo-toast';
import { useHistory } from 'react-router';
import { useDispatch } from 'react-redux';

import { Switch } from '@ethical-jobs/design-system-react';
import { Organisation, Media } from '@ethical-jobs/sdk-js/types';

import { APIContext, AmplitudeContext } from 'lib/contexts';
import { loadOrganisations } from 'actions/organisations';
import { searchJobsAdmin } from 'actions/search';

import Card from 'components/Card';
import OrganisationInfo from 'components/OrganisationInfo';
import Button from 'components/Button';
import Modal from 'components/Modal';
import Icon from 'components/Icon';

type Props = {
  organisation: Organisation;
  logos: Array<Media>;
  fetching: boolean;
  refresh: () => void;
};

const InfoAndActions = ({ organisation, fetching, refresh, logos }: Props) => {
  const dispatch = useDispatch();
  const history = useHistory();

  const api = useContext(APIContext);
  const amplitude = useContext(AmplitudeContext);

  const [viewPreviousLogos, setViewPreviousLogos] = useState(false);

  const toggleArchive = useCallback(async (checked) => {
    if (checked) {
      try {
        let updatedOrganisation = await api.archiveOrganisation(organisation.id);
        amplitude.logEvent('API', { function: 'archiveOrganisation', arguments: { organisationId: organisation.id }});
        cogoToast.success('Successfully archived organisation', { hideAfter: 3, position: 'top-right' });
        refresh();
        return updatedOrganisation;
      } catch (error) {
        cogoToast.error('Error archiving organisation', { hideAfter: 3, position: 'top-right' });
        amplitude.logError('containers/_admin/OrganisationUpdate/InfoAndActions/toggleArchive:archive', error);
      }
    } else {
      try {
        let updatedOrganisation = await api.restoreOrganisation(organisation.id);
        amplitude.logEvent('API', { function: 'restoreOrganisation', arguments: { organisationId: organisation.id }});
        cogoToast.success('Successfully restored organisation', { hideAfter: 3, position: 'top-right' });
        refresh();
        return updatedOrganisation;
      } catch (error) {
        cogoToast.error('Error restoring organisation', { hideAfter: 3, position: 'top-right' });
        amplitude.logError('containers/_admin/OrganisationUpdate/InfoAndActions/toggleArchive:restore', error);
      }
    }

    dispatch(loadOrganisations());
    refresh();
  }, [organisation, api, refresh, amplitude, dispatch]);

  const uploadLogo = useCallback(async (file, organisationId) => {
    try {
      let media = await api.attachMediaToOrganisation(organisationId, file);
      amplitude.logEvent('API', { function: 'attachMediaToOrganisations', arguments: { organisationId }});
      cogoToast.success('Successfully uploaded logo', { hideAfter: 3, position: 'top-right' });
      refresh();
      return media;
    } catch (error) {
      cogoToast.error('Error uploading logo', { hideAfter: 3, position: 'top-right' });
      amplitude.logError('containers/_admin/OrganisationUpdate/InfoAndActions/uploadLogo', error);
    }
    refresh();
  }, [refresh, api, amplitude]);

  const setLogo = useCallback(async (logoId) => {
    try {
      // @ts-ignore
      let updatedOrganisation = await api.updateOrganisation(organisation.id, { logoId });
      amplitude.logEvent('API', { function: 'updateOrganisation', arguments: { organisationId: organisation.id, logoId }});
      cogoToast.success('Successfully updated logo', { hideAfter: 3, position: 'top-right' });
      refresh();
      return updatedOrganisation;
    } catch (error) {
      cogoToast.error('Error updating logo', { hideAfter: 3, position: 'top-right' });
      amplitude.logError('containers/_admin/OrganisationUpdate/InfoAndActions/setLogo', error);
    }
    
    setViewPreviousLogos(false);
    refresh();
  }, [organisation, refresh, api, amplitude]);

  const viewJobs = useCallback(() => {
    dispatch(searchJobsAdmin({ query: '', sort: { field: 'submittedAt', direction: 'desc' }, organisation: organisation.id, status: 'APPROVED' }));
    history.push('/admin/jobs');
  }, [organisation, dispatch, history]);

  return (
    <Card>
      <OrganisationInfo
        isArchived={!!organisation.deletedAt}
        organisation={organisation}
        uploadLogo={uploadLogo}
      />
      <div className="organisation-actions">
        <Switch
          label="Archived"
          checked={!!organisation.deletedAt}
          disabled={fetching}
          onChange={toggleArchive}
        />
        <Button
          style={{ maxWidth: 150, marginRight: 15 }}
          kind="info"
          label="View Logos"
          onClick={() => setViewPreviousLogos(true)}
        />
        <Button
          style={{ maxWidth: 150, marginRight: 15 }}
          kind="primary"
          label="View Jobs"
          onClick={viewJobs}
        />
      </div>
      <Modal
        show={viewPreviousLogos}
        onHide={() => setViewPreviousLogos(false)}
      >
        <div style={{ padding: 15 }}>
          <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'flex-start' }}>
            <h3 style={{ marginTop: 0 }}>Organisation Logos</h3>
            <div onClick={() => setViewPreviousLogos(false)} style={{ cursor: 'pointer' }}>
              <Icon type="close" />
            </div>
          </div>
          <table>
            <thead>
              <tr>
                <th>Created</th>
                <th>Type</th>
                <th>Link</th>
                <th />
              </tr>
            </thead>
            <tbody>
              {_.sortBy(logos, logo => logo.createdAt).reverse().map(logo => (
                <tr key={logo.id}>
                  <td>{moment(logo.createdAt).format('Do MMM YYYY')}</td>
                  <td>{logo.mimeType}</td>
                  <td><a href={logo.url} target="_blank" rel="noopener noreferrer"><img src={logo.url} width="100" height="100" alt={logo.originalFileName} /></a></td>
                  <td>
                    {organisation.logos.standard && organisation.logos.standard.includes(logo.fileName) ? (
                        <p>Active Logo</p>
                    ) : (
                      <Button disabled kind="primary" size="sm" label="Set As Logo" onClick={() => setLogo(logo.id)} />
                    )}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </Modal>
    </Card>
  );
}

export default InfoAndActions;
