import React, { useState, useCallback, useContext, useEffect } from 'react';
import { useSelector, useDispatch, shallowEqual } from 'react-redux';

import { useHistory } from 'react-router-dom';

import { APIContext } from 'lib/contexts';
import { logout } from 'actions/auth';

import CheckingAuth from './CheckingAuth';

import Root from './Root';

type Props = {
  children: React.ReactNode
};

const RootContainer = ({ children }: Props) => {
  const dispatch = useDispatch();
  const history = useHistory();

  const api = useContext(APIContext);

  // @ts-ignore
  const user = useSelector(state => state.auth.user, shallowEqual);

  const [checkedAuth, setCheckedAuth] = useState(false);

  const logoutFn = useCallback(() => {
    dispatch(logout());
    history.push('/login');
  }, [dispatch, history]);

  useEffect(() => {
    const fn = async () => {
      const isUserLoggedIn = await api.isUserLoggedIn();
      if (!isUserLoggedIn) {
        history.push(`/login?redirect_to=${window.location.pathname}`);
      } else {
        setCheckedAuth(true);
      }
    }

    fn();
  }, [api, history]);
  
  if (!checkedAuth) {
    return <CheckingAuth />;
  }

  return (
    <Root
      user={user}
      logout={logoutFn}
    >
      {children}
    </Root>
  );
};

export default RootContainer;
