import React, { useContext, useMemo, useCallback } from 'react';

import _ from 'lodash';
import cogoToast from 'cogo-toast';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';

import { usePromise } from 'hooks';
import { loadUserDetails } from 'actions/auth';
import { APIContext, AmplitudeContext } from 'lib/contexts';

import UserListDisplay from './UserListDisplay';

type Props = {
  organisationId: number;
  forceRefresh: number;
  reloadUsers: () => void;
};

const UserList = ({ organisationId, forceRefresh, reloadUsers }: Props) => {
  const dispatch = useDispatch();
  const api = useContext(APIContext);
  const amplitude = useContext(AmplitudeContext);

  const getUsers = useMemo(() => api.getUsers({
    organisationId,
    fields: [
      'name',
      'email',
      'position',
      'username'
    ]
  }), [organisationId, api, forceRefresh]);

  const getInvitations = useMemo(async () => {
    return [];

    // TODO: Add back once approved
    const invitionsResult = await api.getInvitations();
    return invitionsResult.map((invited) => ({
      id: invited.id,
      name: 'invited',
      email: invited.email,
      position: '',
      username: ''
    }));
  }, [api, forceRefresh]);
  
  const getOrganisation = useMemo<Promise<{ ownerId: null | number }>>(() => api.getOrganisation(organisationId), [api, organisationId, forceRefresh]);

  const [users] = usePromise(getUsers, [], { component: 'UserList' });

  const [invitedUsers] = usePromise(getInvitations, []);

  const potentialUsers = _.concat(users, invitedUsers);

  const [{ ownerId }] = usePromise(getOrganisation, { ownerId: null }, { component: 'UserList' })

  const isStaffMember = useSelector(state => _.get(state, ['auth', 'user', 'roles'], []).includes('staff-member'));

  // @ts-ignore
  const authUserId = useSelector(state => state.auth.user.id, shallowEqual);

  const setUserRole = useCallback((async (userId, role) => {
    // INFO: Only owner can make the other users and owner at the moment
    if (role !== 'owner') return;

    try {
      const updatedOrganisation = await api.updateOrganisation(organisationId, { ownerId: userId });
      cogoToast.success('Changing owner was successful', { hideAfter: 4, position: 'top-right' });
      dispatch(loadUserDetails());
      amplitude.logEvent('API', { function: 'updateOrganisation', arguments: { organisationId, ownerId: userId }});
      reloadUsers();
      return updatedOrganisation;
    } catch (error) {
      cogoToast.error('Error updating organisation owner', { hideAfter: 4, position: 'top-right' });
      amplitude.logError('components/UserList/index/updateOrganisation', error);
    }
  }), [api, amplitude, organisationId, dispatch, reloadUsers]);

  const requestArchive = useCallback(async (userId: number) => {
    try {
      let user = await api.archiveUser(userId);
      cogoToast.success('Removing user was successful', { hideAfter: 4, position: 'top-right' });
      amplitude.logEvent('API', { function: 'archiveUser', arguments: { userId }});
      reloadUsers();
      return user;
    } catch (error) {
      cogoToast.error('Error removing user', { hideAfter: 4, position: 'top-right' });
      amplitude.logError('components/UserList/index/archiveUser', error);
    }
  }, [api, amplitude, reloadUsers]);

  const deleteInvitation = useCallback(async (inviteId: string) => {
    try {
      let user = await api.deleteInvitation(inviteId);
      cogoToast.success('Removing invite was successful', { hideAfter: 4, position: 'top-right' });
      amplitude.logEvent('API', { function: 'deleteInvitation', arguments: { inviteId }});
      reloadUsers();
      return user;
    } catch (error) {
      cogoToast.error('Error removing invitation', { hideAfter: 4, position: 'top-right' });
      amplitude.logError('components/UserList/index/deleteInvitation', error);
    }
  }, [api, amplitude]);

  return (
    <UserListDisplay
      users={potentialUsers}
      isStaffMember={isStaffMember}
      ownerId={ownerId}
      authUserId={authUserId}
      setUserRole={setUserRole}
      archiveUser={requestArchive}
      deleteInvitation={deleteInvitation}
    />
  );
};

export default React.memo(UserList);
