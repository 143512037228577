import React from 'react';

import EnumerablesSelect from 'fields/EnumerablesSelect';

type Props = {
  filters: {
    [filterKey: string]: string;
  };
  updateFilters: (filters: { [filterKey: string]: string }) => void;
  enumerableType: string;
};

const EnumerableFilter = ({ enumerableType, updateFilters, filters }: Props) => (
  <div className="filter-component">
    <EnumerablesSelect
      value={filters[enumerableType]}
      enumerableType={enumerableType}
      onChange={value => updateFilters({ [enumerableType]: value })}
      placeholder={`Filter by ${enumerableType}`}
    />
  </div>
);

export default EnumerableFilter;
