import React from 'react';

import Icon from 'components/Icon';

type Props = {
  label: string;
  icon?: string;
  value: string;
  color?: string;
  minWidth?: number;
}

const JobStatistic = ({ label, icon, value, color = 'black', minWidth = 0 }: Props) => (
  <div style={{ minWidth }}>
    <p style={{ marginBottom: 0, color: '#AAA', fontSize: 16 }}>{label}</p>
    <div style={{ display: 'flex', alignItems: 'center' }}>
      {icon && (
        <Icon type={icon} style={{ color: '#3E8420' }} />
      )}
      <p style={{ marginBottom: 0, fontSize: 18, paddingLeft: icon ? 5 : 0, color }}>
        {value}
      </p>
    </div>
  </div>
);

export default JobStatistic;
