import React, { useContext, useState, useMemo } from 'react';

import { Column } from 'react-virtualized';

import { usePromise } from 'hooks';
import { APIContext } from 'lib/contexts';

import DataTable from 'components/DataTable';
import Spinner from 'components/Spinner';
import PageTitle from 'components/PageTitle';

import ImageRenderer from 'components/DataTableRenderers/ImageRenderer';
import ListRenderer from 'components/DataTableRenderers/ListRenderer';
import UserRolesFilter from 'components/CollectionViewControls/UserRolesFilter';


const StaffCollection = () => {
  const api = useContext(APIContext);

  const [selectedRole, setSelectedRole] = useState('staff-member');

  const getUsers = useMemo(() => api.getUsers({
    orderBy: [{ field: 'firstName', direction: 'asc' }],
    fields: ['id', 'name', 'email', 'username', 'roles', 'avatar'],
    roles: [selectedRole]
  }), [selectedRole, api]);

  const [users, , isFetching] = usePromise(getUsers, [], { component: 'StaffCollection' });

  return (
    <div className="user-collection-container">
      <PageTitle title="Staff Users" subTitle="Filterble list of staff users" />
      <div className="entity-listing-container">
        <div className="data-table-filters">
          <div className="meta clearfix">
            <div className="result">{`${users.length || 'No'} results`}</div>
            <Spinner show={isFetching} />
          </div>
          <ul className="clearfix button-list">
            <UserRolesFilter onChange={setSelectedRole} value={selectedRole} />
          </ul>
        </div>
        <DataTable rows={users}>
          <Column
            label=""
            dataKey="avatar"
            key="avatar"
            width={80}
            cellRenderer={data => (
              <ImageRenderer
                src={data.rowData.avatar}
                style={{ maxWidth: '40px', borderRadius: '50%', padding: '2px' }}
                alt={data.rowData.fullName}
              />
            )}
          />
          <Column
            label="Name"
            dataKey="name"
            key="name"
            width={170}
          />
          <Column
            label="Email address"
            dataKey="email"
            key="email"
            width={220}
          />
          <Column
            label="Roles"
            dataKey="roles"
            key="roles"
            width={80}
            flexGrow={1}
            cellRenderer={data => <ListRenderer list={data.rowData.roles} />}
          />
        </DataTable>
      </div>
    </div>
  );
}

export default React.memo(StaffCollection);
