import React from 'react';
import moment from 'moment';
import LineChart from 'components/LineChart';
import { sortReportByCurrentMonth } from 'lib/date';

const QUERY = {
  dateRanges: [{
    from: moment().subtract(1, 'year').format('YYYY-MM-DD HH:mm:ss'),
    to: moment().format('YYYY-MM-DD HH:mm:ss')
  },
  {
    from:  moment().subtract(2, 'years').format('YYYY-MM-DD HH:mm:ss'),
    to:  moment().subtract(1, 'year').format('YYYY-MM-DD HH:mm:ss')
  }],
  metric: 'metric:totalApprovedJobs',
  dimensions: ['dimension:month']
}

const ApprovedJobsByMonthChart = () => (
  <LineChart
    title="Total approved jobs by month"
    xAxisKey="month"
    primary={{ key: 'total_approved_jobs_1', label: 'Current 12 months' }}
    secondary={{ key: 'total_approved_jobs_2', label: 'Previous 12 months' }}
    // @ts-ignore
    reportCallback={report => sortReportByCurrentMonth(report, 'month', true)}
    query={QUERY}
  />
);

export default React.memo(ApprovedJobsByMonthChart);
