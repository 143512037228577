import React from 'react';

import Icon from 'components/Icon';

import { StageContainer, StageHoverContainer } from './Stage.styles';

type Props = {
  label: string;
  index: number;
  numTabs: number;
  isActiveTab: boolean;
  activeColor?: string;
  inactiveColor?: string;
  hoverColor?: string;
  icon?: string;
  onClick: () => void;
  isDragging?: boolean;
  isDraggingOver?: boolean;
  fontStyle: Object
  disabled?: boolean;
}

const Stage = ({ label, index, numTabs, isActiveTab, onClick, isDragging = false, isDraggingOver = false, disabled = false, activeColor = '#57AD34', inactiveColor = '#555', hoverColor = '#0079c1', icon, fontStyle }: Props) => (
  <StageContainer
    isLast={index === numTabs - 1}
    isFirst={index === 0}
    isActive={isActiveTab}
    activeColor={activeColor}
    onClick={disabled ? () => {} : onClick}
  >
    <StageHoverContainer
      hasIcon={!!icon}
      isDragging={isDragging}
      isActive={isActiveTab}
      isDraggingOver={isDraggingOver}
      hoverColor={hoverColor}
    >
      {icon && (
        <Icon type={icon} style={{ color: isActiveTab ? 'white' : activeColor, fontSize: 18, paddingRight: 5 }} />
      )}
      <p
        style={{
          textAlign: 'center',
          fontSize: 14,
          color: isActiveTab ? 'white' : (isDragging && !isActiveTab) ? hoverColor : inactiveColor,
          letterSpacing: 1.2,
          margin: 0,
          ...fontStyle
        }}
      >
        {label.toUpperCase()}
      </p>
    </StageHoverContainer>
  </StageContainer>
);

export default Stage;