import _ from 'lodash';

export function monthsOrderedByCurrentMonth(): Array<number> {
  const months = [1,2,3,4,5,6,7,8,9,10,11,12];
  const currentMonth = ((new Date()).getMonth() + 1);
  return months
    .slice(currentMonth)
    .concat(months.slice(0, currentMonth))
    .reverse();
}

export function sortByCurrentMonth(rows: Array<Object>, monthKey: string = 'month'): Array<Object> {
  const order = monthsOrderedByCurrentMonth();
  return rows.sort((a, b) => order.indexOf(a[monthKey]) > order.indexOf(b[monthKey]) ? 1 : -1);
}

export function monthNumberToName(monthNumber: number): string {
  const months = { 1: 'Jan', 2: 'Feb', 3: 'Mar', 4: 'Apr', 5: 'May', 6: 'Jun', 7: 'Jul', 8: 'Aug', 9: 'Sep', 10: 'Oct', 11: 'Nov', 12: 'Dec' };

  if (!_.has(months, monthNumber)) throw new Error('Invalid month number');
  return months[monthNumber];
}

export function sortReportByCurrentMonth(report: Array<Object>, monthKey: string, rename: boolean = false) {
  const sorted = sortByCurrentMonth(report, monthKey);
  if (rename === true) {
    return sorted.map(row => ({ ...row, [monthKey]: monthNumberToName(row[monthKey])}));
  }
  return sorted;
}