import EthicalJobsAPI from '@ethical-jobs/sdk-js';
import { Amplitude } from '@ethical-jobs/sdk-js/types/amplitude';

type Action = {
  type: string;
  payload?: any;
}
type Dispatch = (action: Action) => void
type GetState = () => Object
type ApiAmplitude = { api: EthicalJobsAPI, amplitude: Amplitude }

export const login = (username: string, password: string) => async (dispatch: Dispatch, getState: GetState, { api, amplitude }: ApiAmplitude) => {
  try {
    let user = await api.login(username, password);

    amplitude.logEvent('User', { type: 'Login', username });

    amplitude.setUserProperties({
      userId: user.id,
      username: user.username,
      email: user.email,
      organisationId: user.organisation.id,
      roles: user.roles,
      position: user.position,
      name: user.name,
      isOrgOwner: user.id === user.organisation.id
    });

    dispatch({
      type: 'AUTH/LOGIN',
      payload: user
    });

    return user;
  } catch (error) {
    if (error.message === 'Request failed with status code 401') {
      amplitude.logEvent('User', { type: 'Failed Login', username });
    } else {
      amplitude.logError('actions/auth/index/login', error);
    }
  }
};

export const logout = () => async (dispatch: Dispatch, getState: GetState, { api, amplitude }: ApiAmplitude) => {
  try {
    await api.logout();
    amplitude.logEvent('User', { type: 'Log Out' });

    dispatch({
      type: 'AUTH/LOGOUT',
    });
  } catch (error) {
    amplitude.logError('actions/auth/index/logout', error);
  }
}

export const loadUserDetails = () => async (dispatch: Dispatch, getState: GetState, { api, amplitude }: ApiAmplitude) => {
  try {
    let user = await api.getUserDetails();

    amplitude.setUserProperties({
      userId: user.id,
      username: user.username,
      email: user.email,
      organisationId: user.organisation.id,
      roles: user.roles,
      position: user.position,
      name: user.name,
      isOrgOwner: user.id === user.organisation.id
    });

    dispatch({
      type: 'AUTH/LOAD_USER_DETAILS',
      payload: user
    });

    return user;
  } catch (error) {
    amplitude.logError('actions/auth/index/loadUserDetails', error);
  }
};