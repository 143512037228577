import { object, string } from 'yup';

import { MESSAGE } from 'lib/validation';

export default object().shape({
  firstName: string().required(MESSAGE.REQUIRED('first name')),
  lastName: string().required(MESSAGE.REQUIRED('last name')),
  position: string().required(MESSAGE.REQUIRED('your position')),
  username: string()
    .min(5, MESSAGE.MIN('Username'))
    .required(MESSAGE.REQUIRED('a username')),
  password: string()
    .min(6, MESSAGE.MIN('Password'))
    .required(MESSAGE.REQUIRED('a password'))
});
