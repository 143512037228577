import React, { useState, useContext, useCallback, useEffect, Fragment } from 'react';

import _ from 'lodash';
import moment from 'moment';
import cogoToast from 'cogo-toast';
import { Column } from 'react-virtualized';
import CopyToClipboard from 'react-copy-to-clipboard';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';

import { Heading, Text } from '@ethical-jobs/design-system-react';
import { Job, JobStatus, JobFields, User } from '@ethical-jobs/sdk-js/types';

import { APIContext } from 'lib/contexts';
import { searchJobsAdmin } from 'actions/search';

import Icon from 'components/Icon';
import Button from 'components/Button';
import Spinner from 'components/Spinner';
import DataTable from 'components/DataTable';
import PageTitle from 'components/PageTitle';
import JobComments, { COMMENT_ICONS } from 'components/JobComments';

import OrganisationSelect from 'fields/OrganisationSelect';
import JobStatusFilter from 'components/CollectionViewControls/JobStatusFilter';
import QueryFilter from 'components/CollectionViewControls/QueryFilter';
import DateTimeRenderer from 'components/DataTableRenderers/DateTimeRenderer';
import LockRenderer from 'components/DataTableRenderers/LockRenderer';
import JobNameRenderer from 'components/DataTableRenderers/JobNameRenderer';
import Modal from 'components/Modal';

const JobCollection = () => {
  const api = useContext(APIContext);
  const dispatch = useDispatch();

  // @ts-ignore
  const defaultSearch = useSelector(state => state.search.admin.jobs, shallowEqual);

  const [statusFilter, setStatusFilter] = useState<JobStatus | 'EXPIRED'>(defaultSearch.status);
  const [organisationFilter, setOrganisationFilter] = useState<number | null>(defaultSearch.organisation);
  const [query, setQuery] = useState(defaultSearch.query);
  const [sort, setSort] = useState<{ field: string, direction: 'asc' | 'desc' }> (defaultSearch.sort);
  const [jobs, setJobs] = useState<Array<Job & { lockedUser: User }>>([]);
  const [isFetching, setIsFetching] = useState(false);
  const [jobCommentsId, setJobCommentsId] = useState(null);

  const onSortChange = useCallback((newSort: { sortBy: string; sortDirection: 'ASC' | 'DESC' }) => {
    // @ts-ignore
    setSort({ field: newSort.sortBy, direction: newSort.sortDirection.toLowerCase() });
  }, []);

  const isViewingPending = statusFilter === 'PENDING';

  const debounceQuery = _.debounce(setQuery, 800);

  const loadRows = useCallback(async (startIndex) => {
    // Only get more rows if we're only a multiple of 50 (meaning we've probably not got all of them)
    if (startIndex % 50 !== 0) return;

    setIsFetching(true);

    let fields: Array<JobFields> = ['_score', 'id', 'title', 'isExpired', 'location', 'status', 'isLocked', 'lockedByAvatar', 'lockedBy', 'createdAt', 'organisationUid', 'organisationId', 'views', 'clicks', 'organisationHasFirstJobPost', 'submittedAt', 'approvedAt', 'expiresAt'];
    try {
      let response = await api.getJobs({
        keywords: query || undefined,
        status: statusFilter === 'EXPIRED' ? ['APPROVED'] : statusFilter,
        organisations: organisationFilter ? [organisationFilter] : undefined,
        fields,
        useElasticSearch: false,
        from: startIndex,
        limit: 50,
        orderBy: [sort],
        // INFO: Pending jobs should include expired & pending, if includeExpired is not set or false
        // it sets the expiresFrom >= now (api-app/JobRepository), so need to overwrite with expiresFrom as in the past
        includeExpired: statusFilter === 'EXPIRED' ? true : statusFilter === 'APPROVED' ? false : undefined,
        expiresFrom: statusFilter === 'PENDING' ? '2000-01-01' : undefined,
        include: ['organisation', 'comments']
      });

      let userIds = _.uniq(response.jobs.map(job => job.lockedBy)).filter(userId => !!userId);
      const jobIds = response.jobs.map(job => job.id);

      const [applicantCountsByJob, ...users] = await Promise.all([
        api.getApplicantCounts(jobIds),
        ...userIds.map(api.getUser),
      ]);

      let keyedUsers = _.keyBy(users, user => user.id);

      let formattedJobs = response.jobs.map(job => ({
        ...job,
        numApplicants: applicantCountsByJob[job.id]['total'],
        lockedUser: job.lockedBy ? keyedUsers[job.lockedBy] : null,
      }));

      if(response.jobs.length > 0) {
        setJobs(prevJobs => _.uniqBy([...prevJobs, ...formattedJobs], job => job.id));
      }
    } catch (error) {
      // INFO: Can be remove once getting 0 jobs stops throwing 404
    }

    setIsFetching(false);
  }, [api, query, statusFilter, organisationFilter, sort]);

  useEffect(() => {
    dispatch(searchJobsAdmin({ query, organisation: organisationFilter, status: statusFilter, sort }));
    setJobs([]);
    loadRows(0);
  }, [query, statusFilter, organisationFilter, sort, loadRows, dispatch]);

  let selectedJob = jobCommentsId ? jobs.find(job => job.id === jobCommentsId) : null;

  return (
    <div className="jobs-collection-container">
      <PageTitle title="Job List" subTitle="Filterable list of jobs" />
      <div className="entity-listing-container">
        <div className="data-table-filters">
          <div className="meta clearfix">
            <div className="result">{`${jobs.length || 'No'} results`}</div>
            <Spinner show={isFetching} />
          </div>
          <ul className="clearfix button-list">
            <li><JobStatusFilter value={statusFilter} onChange={setStatusFilter} /></li>
            <li><OrganisationSelect value={organisationFilter} onChange={setOrganisationFilter} isClearable /></li>
            <li style={{ flexBasis: 0 }}>
              <QueryFilter
                onChange={({ keyword }) => debounceQuery(keyword)}
                filterKey="keyword"
                placeholder="Filter by keyword"
                defaultValue={query}
              />
            </li>
            <li style={{ flexBasis: 0 }}>
              <Button
                icon="clear"
                label="Clear Filters"
                onClick={() => {
                  setOrganisationFilter(null);
                  setStatusFilter('PENDING');
                  setQuery('');
                  setSort({ field: 'submittedAt', direction: 'desc' });
                }}
                size="small"
              />
            </li>
          </ul>
        </div>
        <DataTable
          rows={jobs}
          // @ts-ignore
          defaultSort={{ sortBy: sort.field, sortDirection: sort.direction.toUpperCase() }}
          onSortChange={onSortChange}
          loadMoreRows={loadRows}
        >
          <Column
            label="Title"
            dataKey="title"
            key="title"
            width={350}
            flexGrow={1}
            cellRenderer={({ rowData }: { rowData: Job }) => {
              return (
                <div style={{ display: 'flex', alignItems: 'center' }}>
                  {statusFilter === 'PENDING' && rowData.organisation.jobs.numPending > 0 && rowData.organisation.jobs.numApproved === 0 && rowData.organisation.jobs.numExpired === 0 && (
                    <div title="First job for this organisation" style={{ paddingRight: 5, paddingTop: 8 }}>
                      <Icon type="new_releases" style={{ color: '#0079C1', fontSize: 18 }} />
                    </div>
                  )}
                  <JobNameRenderer job={rowData} />
                </div>
              )
            }}
          />
          <Column
            label="Organisation"
            dataKey="organisation.uid"
            key="organisation.uid"
            width={isViewingPending ? 200 : 150}
            cellRenderer={({ rowData }: { rowData: Job }) => (
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <CopyToClipboard
                  text={rowData.organisation?.uid || ''}
                  onCopy={(text: string) => cogoToast.success(`Copied '${text}' to clipboard`, { hideAfter: 2, position: 'top-right' })}
                >
                  <span style={{ paddingRight: 5, cursor: 'pointer' }} title="Copy UID to Clipboard">
                    <Icon type="file_copy" style={{ fontSize: 16 }} />
                  </span>
                </CopyToClipboard>
                <a href={`/admin/organisation/${rowData.organisation?.id}/view`}>{rowData.organisation?.uid}</a>
              </div>
            )}
            disableSort
          />
          <Column
            label="Location"
            dataKey="location"
            key="location"
            cellRenderer={({ rowData }: { rowData: Job }) => <span>{_.get(rowData, ['location', 'title'], '')}</span>}
            width={120}
          />
          <Column
            label={isViewingPending ? 'Submitted' : 'Approved'}
            dataKey={isViewingPending ? 'submittedAt' : 'approvedAt'}
            key={isViewingPending ? 'submittedAt' : 'approvedAt'}
            width={190}
            cellRenderer={({ cellData }) => <DateTimeRenderer timestamp={cellData} format="DD/MM/YYYY h:mm A" />}
          />
          {statusFilter === 'EXPIRED' && (
            <Column
              label="Expired"
              dataKey="expiresAt"
              key="expiresAt"
              width={100}
              cellRenderer={({ cellData }) => <DateTimeRenderer timestamp={cellData} format="DD/MM/YYYY" />}
            />
          )}
          {!isViewingPending && [
            <Column
              label="Views"
              dataKey="views"
              key="views"
              width={50}
            />,
            <Column
              label="Applications"
              dataKey="total"
              key="total"
              cellDataGetter={({ rowData }: { rowData: Job & { numApplicants: number } }) => rowData.numApplicants}
              width={100}
              disableSort
            />
          ]}
          <Column
            label="Comments"
            dataKey="comments"
            width={100}
            cellRenderer={({ rowData }: { rowData: Job }) => (
              <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', cursor: 'pointer' }} onClick={() => setJobCommentsId(rowData.id)}>
                {_.isArray(rowData.comments) && rowData.comments.filter(comment => !comment.isResolved).map((comment, i, v) => (
                  <OverlayTrigger
                    placement="top"
                    overlay={(
                      <Tooltip id="comment-details">
                        {comment.author.name} - {moment(comment.updatedAt).format('MMM Do @ HH:mm ')}
                        {comment.comment}
                      </Tooltip>)
                    }
                  >
                    <span>
                      <Icon
                        key={comment.id}
                        type={COMMENT_ICONS[comment.type]}
                        style={{ color: '#0079C1', paddingRight: i < v.length - 1 ? 5 : 0 }}
                      />
                    </span>
                  </OverlayTrigger>
                ))}
              </div>
            )}
          />
          <Column
            dataKey="__"
            key="__"
            width={60}
            cellRenderer={({ rowData }: { rowData: Job & { lockedUser: User } }) => (
              <LockRenderer
                url={`/admin/job/${rowData.id}/update`}
                locked={rowData.isLocked}
                lockedByAvatar={rowData.lockedByAvatar}
                lockedUser={rowData.lockedUser}
              />
            )}
          />
        </DataTable>
      </div>
      <Modal
        show={jobCommentsId !== null}
        onHide={() => setJobCommentsId(null)}
      >
        {!!selectedJob && (
          <Fragment>
            <div style={{ padding: '15px 30px', display: 'flex', justifyContent: 'space-between', borderStyle: 'solid', borderWidth: 0, borderBottomWidth: 1, borderBottomColor: '#DDD' }}>
              <div>
                <Heading size="medium">Comments for {selectedJob.title}</Heading>
                <Text>{selectedJob.organisation.name}</Text>
              </div>
              <div style={{ cursor: 'pointer' }} onClick={() => setJobCommentsId(null)}>
                <Icon type="close" />
              </div>
            </div>
            <JobComments jobId={jobCommentsId} />
          </Fragment>
        )}
      </Modal>
    </div>
  );
}

export default React.memo(JobCollection);
