import React from 'react';
import moment from 'moment';

type Props = {
  style?: Object;
  timestamp: number | Date
  format?: string;
};

const DateTimeRenderer = ({ style, timestamp, format = 'D/MM/YYYY' }: Props) => (
  <span style={style}>{timestamp ? moment(timestamp).format(format) : null}</span>
);

export default DateTimeRenderer;
