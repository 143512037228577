import React, { useState, useCallback, useContext } from 'react';

import { CardElement, useStripe, useElements } from '@stripe/react-stripe-js';
import { Invoice } from '@ethical-jobs/sdk-js/types';

import Button from 'components/Button';
import Card from 'components/Card';
import { AmplitudeContext } from 'lib/contexts';

type Props = {
  disabled: boolean;
  purchase: (tokenId: string) => Promise<Invoice>;
  onSubmittingChange: (isSubmitting: boolean) => void;
};

const CardForm = ({ disabled, purchase, onSubmittingChange }: Props) => {
  const amplitude = useContext(AmplitudeContext);
  const stripe = useStripe();
  const elements = useElements();
  const [error, setError] = useState('');
  const [submitting, setSubmitting] = useState(false);

  const handleSubmit = useCallback(async (event) => {
    event.preventDefault();
    setSubmitting(true);
    onSubmittingChange(true);
    try {
      const cardElement = elements.getElement(CardElement);
      const { token } = await stripe.createToken(cardElement);
      await purchase(token.id);
    } catch (error) {
      setError(error.message);
      amplitude.logError('src/forms/CreditCardForm/CardForm.tsx', error);
    }

    setSubmitting(false);
    onSubmittingChange(false);
  }, [purchase, amplitude, elements, stripe, onSubmittingChange]);

  return (
    <form onSubmit={handleSubmit}>
      <label>Credit card details</label>
      <CardElement />
      {!!error && (
        <Card className="error center">
          <p>{error}</p>
        </Card>
      )}
      <Button
        kind="primary"
        disabled={disabled || submitting}
        label="Pay now"
        type="submit"
        loading={submitting}
      />
    </form>
  );
};

export default CardForm;
