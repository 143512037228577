import React from 'react';

import { Media, Questions } from '@ethical-jobs/sdk-js/types';

import RenderField from 'fields/RenderField';

import { Field } from 'redux-form';

type Props = {
  name: string;
  type?: string;
  children?: React.ReactNode;
  component: React.ReactNode | "input" | "select" | "textarea";
  placeholder?: string;
  wrapClassName?: string;
  label?: string | React.ReactNode;
  disabled?: boolean;
  enumerableType?: string;
  accept?: string;
  required?: boolean;
  optional?: boolean;
  taxonomy?: string;
  description?: string;
  orderBy?: string;
  id?: string;
  attach?: (logo: File[]) => Promise<Media>;
  maxDate?: Date;
  minDate?: Date;
  limit?: number;
  quickLinks?: Array<string>;
  options?: Array<{ value: string; label: string }>;
  templates?: Array<Object>;
  dateFormat?: string;
  includeArchived?: boolean;
  onBlur?: (value: any) => void;
  isAdmin?: boolean;
  style?: Object
  multi?: boolean;
  defaultEmail?: string;
  onFocus?: () => void;
  times?: Array<{ value: string; label: string }>;
  questions?: Questions;
};

const FormField = ({ name, component, children, type, ...otherProps }: Props) => (
  <Field name={name} type={type} field={component} component={RenderField} {...otherProps}>
    {children}
  </Field>
);

export default React.memo(FormField);
