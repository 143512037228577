import React from 'react';

import Nav from 'react-bootstrap/lib/Nav';
import Navbar, {
  Header as NavbarHeader,
  Collapse as NavbarCollapse,
  Brand as NavbarBrand
} from 'react-bootstrap/lib/Navbar';
import env from 'lib/env';

type Props = {
  children: React.ReactNode;
}

const TopNav = ({ children }: Props) => (
  <Navbar className="top-nav" fluid style={{ top: 0, maxHeight: 67 }}>
    <NavbarHeader>
      <NavbarBrand>
        <span className="navbar-brand">
          <img src={`${env.EJ_CDN}/statics/logo-transparent.png`} alt="EthicalJobs.com.au" />
        </span>
      </NavbarBrand>
    </NavbarHeader>

    <NavbarCollapse>
      <Nav navbar />
      {children}
    </NavbarCollapse>
  </Navbar>
);

export default TopNav;