import styled from 'styled-components';

export const ApplicantStageContainer = styled.div`
  background-color: white;
  margin: 30px 50px 0px;
  box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.1);
  padding: 15px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-radius: 5px;
`;