import React, { useCallback } from 'react';

import { useSelector } from 'react-redux';
import ReactPlayer from 'react-player';
import { formValueSelector, reduxForm } from 'redux-form';

import submissionError from 'lib/submissionError';
import FormField from 'fields/FormField';
import Button from 'components/Button';

const selector = formValueSelector('organisationMediaForm');

type Props = {
  action: (values: Object) => Promise<Object>;
  handleSubmit: any;
  error?: string;
  submitting: boolean;
};

const OrganisationMediaForm = ({ action, handleSubmit, submitting }: Props) => {
  const videoUrl = useSelector(state => selector(state, 'videoUrl'));

  const submitForm = useCallback(async(values: { videoUrl: string }) => {
    try {
      await action(values);
    } catch (error) {
      submissionError(error);
    }
  }, [action]);

  return (
    <form
      className="organisation-admin-form form-horizontal"
      onSubmit={handleSubmit(submitForm)}
    >
      <div className="row form-group video">
        <FormField
          name="videoUrl"
          component="input"
          type="text"
          label="Recruitment video"
          placeholder="E.g. https://www.youtube.com/watch?v=MDMWsTXON-E"
          wrapClassName="col-md-12"
        />
      </div>
      {videoUrl && <ReactPlayer url={videoUrl} control />}
      <div className="row form-group">
        <div className="col-md-12">
          <Button
            kind="primary"
            type="submit"
            loading={submitting}
            label="Save"
          />
        </div>
      </div>
    </form>
  );
}

export default reduxForm({
  form: 'organisationMediaForm'
  // @ts-ignore
})(OrganisationMediaForm);