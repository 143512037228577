import React from 'react';
import moment from 'moment';
import StatsPanel from 'components/StatsPanel';

const CurrentApprovedJobsFigure = () => (
  <StatsPanel
    title="Current approved jobs"
    figures={[
      {
        reportName: 'current-jobs',
        dataKey: 'current_approved_jobs',
        icon: 'visibility'
      }
    ]}
    charts={[
      {
        reportName: 'average-jobs',
        dataKey: 'average_approved_jobs',
        stroke: ''
      }
    ]}
    query={{
      reports: [{
        reportName: 'current-jobs',
        metric: 'metric:currentApprovedJobs',
        dateRanges: [{
          from: moment().format('YYYY-MM-DD HH:mm:ss'),
          to: moment().format('YYYY-MM-DD HH:mm:ss')
        }]
      },
      {
        reportName: 'average-jobs',
        metric: 'metric:averageApprovedJobs',
        dateRanges: [{
          from: moment().startOf('year').subtract(1, 'year').format('YYYY-MM-DD HH:mm:ss'),
          to: moment().endOf('year').format('YYYY-MM-DD HH:mm:ss'),
        }],
        dimensions: ['dimension:week']
      }]
    }}
  />
);

CurrentApprovedJobsFigure.displayName = 'CurrentApprovedJobsFigure';

export default CurrentApprovedJobsFigure;
