import React from 'react';

import Icon from 'components/Icon';

import { TabContainer } from './Tabs.styles';

export type Tab = {
  id: string;
  label: string;
  activeColor?: string;
  inactiveColor?: string;
  hoverColor?: string;
  icon?: string;
  disabled?: boolean;
};

type Props = {
  activeTab: string;
  tabs: Array<Tab>;
  onChange: (selectedTab: string) => void;
  fontStyle?: Object
};

const Tabs = ({ tabs, activeTab, onChange, fontStyle = {} }: Props) => (
  <div style={{ display: 'flex' }}>
    {tabs.map(tab => (
      <TabContainer
        key={tab.id}
        onClick={tab.disabled ? () => {} : () => onChange(tab.id)}
        isActiveTab={activeTab === tab.id}
      >
        {!!tab.icon && (
          <Icon type={tab.icon} style={{ color: activeTab === tab.id ? 'white' : tab.activeColor, fontSize: 18, paddingRight: 5 }} />
        )}
        <p
          style={{
            textAlign: 'center',
            fontSize: 15,
            color: activeTab === tab.id ? tab.inactiveColor : 'white',
            letterSpacing: 1.2,
            margin: 0,
            ...fontStyle
          }}
        >
          {tab.label.toUpperCase()}
        </p>
      </TabContainer>
    ))}
  </div>
);

export default Tabs;
