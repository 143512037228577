import React, { useState, useEffect, useCallback } from 'react';

import _ from 'lodash';
import { Table, AutoSizer, InfiniteLoader } from 'react-virtualized';

import Spinner from 'components/Spinner';

const ROW_HEIGHT = 50;

type Props = {
  rows: Array<Object>;
  children: React.ReactNode;
  noDataTitle?: string;
  noDataSubTitle?: string;
  loading?: boolean;
  maxHeight?: number;
  defaultSort?: {
    sortBy: string;
    sortDirection: 'ASC' | 'DESC';
  };
  onSortChange?: (sort: { sortBy: string; sortDirection: 'ASC' | 'DESC' }) => void;
  loadMoreRows?: (startIndex: number) => Promise<any>;
};

const DataTable = ({ rows, children, noDataTitle, noDataSubTitle, loading = false, onSortChange, defaultSort = { sortBy: 'submittedAt', sortDirection: 'ASC' }, loadMoreRows, maxHeight = 800 }: Props) => {
  const [isLoadingMoreRows, setIsLoadingMoreRows] = useState(false);
  const [sort, setSort] = useState<{ sortBy: string, sortDirection: 'ASC' | 'DESC' }>(defaultSort);
  const [sortedRows, setSortedRows] = useState(rows);

  useEffect(() => {
    if (_.isFunction(onSortChange)) {
      setSortedRows(rows);
    } else {
      setSortedRows(_.orderBy(rows, [sort.sortBy], [sort.sortDirection.toLowerCase() as 'asc' | 'desc']));
    }
  }, [rows, sort, onSortChange]);

  const changeSort = useCallback((newSort) => {
    if (_.isFunction(onSortChange)) onSortChange(newSort);
    setSort(newSort);
  }, [onSortChange]);

  const isRowLoaded = useCallback(({ index }) => index < rows.length - 1, [rows]);

  const loadMoreRowsFn = useCallback(async ({ startIndex }) => {
    if (!_.isFunction(loadMoreRows)) return;

    setIsLoadingMoreRows(true);
    await loadMoreRows(startIndex + 1);
    setIsLoadingMoreRows(false);
  }, [loadMoreRows]);

  if (!sortedRows) {
    return null;
  }

  return (
    <div className="virtual-data-table">
      {loading ? (
        <div className="loading-data animated bounce infinite">
          <h3>Loading results...</h3>
          <h4>Please wait while we fetch your results</h4>
        </div>
      ) : (
        <div>
          {sortedRows.length === 0 ? (
            <div className="no-data">
              <h3>{noDataTitle || 'No data available'}</h3>
              <h4>{noDataSubTitle || 'There are no results to display'}</h4>
            </div>
          ) : (
            <div className="has-data">
              <InfiniteLoader
                isRowLoaded={isRowLoaded}
                loadMoreRows={loadMoreRowsFn}
                rowCount={sortedRows.length}
              >
                {({ onRowsRendered, registerChild }) => (
                  <AutoSizer disableHeight>
                    {({ width }) => (
                      <Table
                        ref={registerChild}
                        headerHeight={ROW_HEIGHT}
                        width={width}
                        height={Math.min(maxHeight, ROW_HEIGHT * (sortedRows.length + 1))}
                        rowHeight={ROW_HEIGHT}
                        onRowsRendered={onRowsRendered}
                        rowCount={sortedRows.length}
                        // @ts-ignore
                        rowGetter={({ index }) => sortedRows[index]}
                        sort={changeSort}
                        sortBy={sort.sortBy}
                        // @ts-ignore
                        sortDirection={sort.sortDirection}
                      >
                        {children}
                      </Table>
                    )}
                  </AutoSizer>
                )}
              </InfiniteLoader>
              {isLoadingMoreRows ? (
                <div style={{ paddingTop: 25 }}>
                  <Spinner show />
                </div>
              ) : (
                <h3 className="strike-through">&darr;</h3>
              )}
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default React.memo(DataTable);
