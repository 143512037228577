import _ from 'lodash';
import { useSelector } from 'react-redux';

import env from 'lib/env';
import { FeatureFlag } from '../../featureFlags';

import featureFlags from 'featureFlags';

const useFeatureFlag = (feature: FeatureFlag, organisationId?: number): boolean => {
  // @ts-ignore
  const userOrgId = useSelector(state => _.get(state.auth.user, ['organisation', 'id'], null));

  if (organisationId === null) return false;

  const selectedOrgId = organisationId || userOrgId;

  let envFlags = env.EJ_ENV === 'production'
    ? featureFlags.production
    : featureFlags.staging;

  // Feature enabled if organisation included, or feature set to true
  // @ts-ignore
  return _.get(envFlags, [feature, 'organisations'], []).includes(selectedOrgId) || _.get(envFlags, [feature], false) === true;
};

export default useFeatureFlag;
