import React, { useCallback } from 'react';

import { reduxForm } from 'redux-form';

import submissionError from 'lib/submissionError';
import validation from './validation';
import FormField from 'fields/FormField';
import MultiSelectField from 'fields/MultiSelect';
import ValidationMessages from 'components/ValidationMessages';
import Button from 'components/Button';

type Props = {
  handleSubmit: any;
  submitting: boolean;
  error?: string;
  createCredits: (values: Object) => Promise<Object>;
  deductCredits: (values: Object) => Promise<Object>;
  reset: () => void;
};

const CreditsForm = ({ createCredits, deductCredits, handleSubmit, error, submitting, reset }: Props) => {
  const submit = useCallback(async (values) => {
    try {
      let response = values.serviceType === 'DEDUCT'
        ? await deductCredits(values)
        : await createCredits(values);
      reset();
      return response;
    } catch (error) {
      submissionError(error);
    }
  }, [deductCredits, createCredits, reset]);

  return (
    <form className="create-credits-form form-horizontal" onSubmit={handleSubmit(submit)}>
      <div className="form-group">
        <FormField
          name="volume"
          component="input"
          type="number"
          label="Number of credits"
          wrapClassName="col-md-2"
        />
        <FormField
          name="serviceType"
          component={MultiSelectField}
          options={[
            { label: 'Add Regular credits', value: 'REGULAR' },
            { label: 'Add Managed credits', value: 'MANAGED' },
            { label: 'Deduct credits', value: 'DEDUCT' },
          ]}
          label="Add / Deduct"
          wrapClassName="col-md-4"
          multi={false}
        />
        <FormField
          name="reason"
          component="textarea"
          type="text"
          label="Reason why credits are being changed"
          wrapClassName="col-md-6"
        />
        <div className="col-md-3">
          <label>&nbsp;</label>
          <Button
            loading={submitting}
            kind="primary"
            type="submit"
            label="Update Credits"
            block
          />
        </div>
      </div>
      <ValidationMessages
        // @ts-ignore
        error={error}
        heading=""
      />
    </form>
  );
};

export default reduxForm({
  form: 'createCreditsForm',
  validate: validation
  // @ts-ignore
})(CreditsForm);
