import React, { useContext } from 'react';

import { APIContext } from 'lib/contexts';

import ApplicantManagementJob from './ApplicantManagementJob';

const Main = () => {
  const api = useContext(APIContext);

  return (
    // @ts-ignore
    <APIContext.Provider value={api}>
        <ApplicantManagementJob />
    </APIContext.Provider>
  );
}

export default Main;