import { Parser } from 'htmlparser2/lib';

export const getTextContent = (html: string) => {
  let result: Array<string> = [];

  const parser = new Parser({
    ontext: function(text) {
      result.push(text);
    }
  }, {
    decodeEntities: true
  });

  parser.write(html || '');
  parser.end();

  return result.join(' ');
};
