import React, { useState, useCallback, useContext } from 'react';

import _ from 'lodash';
import cogoToast from 'cogo-toast';

import Tabs from 'react-bootstrap/lib/Tabs';
import Tab from 'react-bootstrap/lib/Tab';

import { Organisation, User } from '@ethical-jobs/sdk-js/types';

import { APIContext, AmplitudeContext } from 'lib/contexts';

import CreditsForm from 'forms/CreditsForm';
import OrganisationProfileForm from 'forms/OrganisationProfileForm';
import OrganisationAdminForm from 'forms/OrganisationAdminForm';
import OrganisationMediaForm from 'forms/OrganisationMediaForm';
import InvitationForm from 'forms/InvitationForm';
import UserProfileForm from 'forms/UserProfileForm';

import UserList from 'components/UserList';
import UserProfileSmall from 'components/UserProfileSmall';
import Card from 'components/Card';
import Spinner from 'components/Spinner';

type Props = {
  organisation: Organisation;
  fetching: boolean;
  refresh: () => void;
};

const ManagementTabs = ({ organisation, fetching, refresh }: Props) => {
  const api = useContext(APIContext);
  const amplitude = useContext(AmplitudeContext);
  const [forceRefresh, setForceRefresh] = useState(0);

  const addCredits = useCallback(async (values) => {
    try {
      await api.addCredits(organisation.id, values.serviceType, values.volume, values.reason);
      cogoToast.success('Successfully added credits', { hideAfter: 3, position: 'top-right' });
      refresh();
      amplitude.logEvent('API', { function: 'addCredits', arguments: { organisationId: organisation.id, ...values }});
    } catch (error) {
      amplitude.logError('containers/_admin/OrganisationUpdate/ManagementTabs/addCredits', error);

      // INFO: Must rethrow so form can handle
      throw error;
    }
  }, [api, refresh, organisation, amplitude]);

  const removeCredits = useCallback(async (values) => {
    try {
      await api.deductCredits(organisation.id, values.volume, values.reason);
      cogoToast.success('Successfully removed credits', { hideAfter: 3, position: 'top-right' });
      refresh();
      amplitude.logEvent('API', { function: 'removeCredits', arguments: { organisationId: organisation.id, ...values }});
    } catch (error) {
      amplitude.logError('containers/_admin/OrganisationUpdate/ManagementTabs/removeCredits', error);

      // INFO: Must rethrow so form can handle
      throw error;
    }
  }, [api, refresh, organisation, amplitude]);

  const updateOrganisation = useCallback(async (values) => {
    try {
      let updatedOrganisation = await api.updateOrganisation(organisation.id, values);
      refresh();
      amplitude.logEvent('API', { function: 'updateOrganisation', arguments: { organisationId: organisation.id, ...values }});
      cogoToast.success('Successfully updated organisation', { hideAfter: 3, position: 'top-right' });
      return updatedOrganisation;
    } catch (error) {
      amplitude.logError('containers/_admin/OrganisationUpdate/ManagementTabs/updateOrganisation', error);
      cogoToast.error('Error updating organisation', { hideAfter: 3, position: 'top-right' });

      // INFO: Must rethrow so form can handle
      throw error;
    }
  }, [organisation, api, refresh, amplitude]);

  const updateUserProfile = useCallback(async (values) => {
    try {
      let updatedUser = await api.updateUser(organisation.ownerId, values);
      cogoToast.success('Successfully updated user', { hideAfter: 3, position: 'top-right' });
      refresh();
      amplitude.logEvent('API', { function: 'updateUser', arguments: { userId: organisation.ownerId, ..._.mapValues(values, (value, key) => key.includes('password') ? 'REDACTED' : value) }});
      setForceRefresh(Math.random());
      return updatedUser;
    } catch (error) {
      amplitude.logError('containers/_admin/OrganisationUpdate/ManagementTabs:updateUserProfile', error);
      cogoToast.error('Error updating user', { hideAfter: 3, position: 'top-right' });

      // Needs to rethrow so form can handle errors
      throw error;
    }
  }, [organisation, api, refresh, amplitude]);

  const orgIsArchived = !!organisation.deletedAt;

  return (
    <Tabs defaultActiveKey={1} id="org-dashboard-tabs">
      <Spinner show={fetching} />
      {organisation && !orgIsArchived && [
        <Tab eventKey={1} key={1} title="Organisation Profile">
          <Card title="Organisation Profile" subTitle="Manage an organisation's profile information">
            <OrganisationProfileForm
              action={updateOrganisation}
              initialValues={{
                ..._.pick(organisation, ['name', 'uid', 'phone']),
                ..._.pick(organisation.address, ['suburb', 'postcode', 'state', 'country']),
                types: organisation.types.map(type => type.id),
                address: organisation.address.street,
                numPaidStaff: `${organisation.staff.paid}`,
                numVolunteers: `${organisation.staff.volunteer}`,
              }}
              enableReinitialize
            />
          </Card>
        </Tab>,
        <Tab eventKey={5} key={5} title="Organisation Users">
          <UserList
            organisationId={organisation.id}
            forceRefresh={forceRefresh}
            reloadUsers={() => {
              setForceRefresh(Math.random());
              refresh();
            }}
          />
          <div className="invitation-create-container">
            <Card title="Invite someone to this organisation">
              <InvitationForm
                organisationId={organisation.id}
              />
            </Card>
            <Card title="Owner User">
              <UserProfileForm
                admin
                action={updateUserProfile}
                initialValues={organisation.owner}
                enableReinitialize
              />
            </Card>
          </div>
        </Tab>,
        <Tab eventKey={3} key={3} title="Admin User">
          <Card title="Relations Manager" subTitle="Current organisation's relations manager">
            <UserProfileSmall user={organisation.admin as User} />
            <OrganisationAdminForm
              action={updateOrganisation}
              initialValues={{ adminId: organisation.adminId }}
              enableReinitialize
            />
          </Card>
        </Tab>,
        <Tab eventKey={4} key={4} title="Credits">
          <Card title="Manage credits" subTitle="No invoices will be created or sent">
            <CreditsForm
              createCredits={addCredits}
              deductCredits={removeCredits}
            />
          </Card>
        </Tab>,
        <Tab eventKey={6} key={6} title="Media">
          <Card title="Recruitment Video" subTitle="Default recruitment video for new job postings.">
            <OrganisationMediaForm
              action={updateOrganisation}
              initialValues={{ videoUrl: organisation.videoUrl }}
            />
          </Card>
        </Tab>
      ]}
    </Tabs>
  );
}

export default React.memo(ManagementTabs);
