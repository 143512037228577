import React, { useState, useCallback, useContext, Fragment } from 'react';

import { APIContext, AmplitudeContext } from 'lib/contexts';

import Button from 'components/Button';
import Icon from 'components/Icon';

const PasswordRecoverForm = () => {
  const api = useContext(APIContext);
  const amplitude = useContext(AmplitudeContext);

  const [email, setEmail] = useState('');
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [error, setError] = useState('');

  const [isSuccess, setIsSuccess] = useState(false);

  const submit = useCallback(async () => {
    try {
      setIsSubmitting(true);
      await api.recoverPassword(email);
      amplitude.logEvent('API', { function: 'recoverPassword', arguments: { email }});
      setIsSuccess(true);
    } catch (error) {
      amplitude.logError('forms/PasswordRecoverForm/index/submit', error);
      setError('Unable to reset your password, please check your e-mail and try again');
    }

    setIsSubmitting(false);
  }, [api, amplitude, email]);

  const isValidEmail = api.validateEmail(email);

  return (
    <div className="recovery-form">
      {isSuccess ? (
        <p className="text-success">
          Done! We&apos;ve just emailed you with instructions on how to reset your password (make sure to check your junk folder too).
        </p>
      ) : (
        <Fragment>
          <p>Please enter your email address below, and we’ll send you instructions on how to reset your password:</p>
          <div style={{ position: 'relative' }}>
            <input
              value={email}
              className="form-group"
              name="email"
              type="text"
              placeholder="Enter your email address here"
              onChange={({ target: { value } }) => {
                if (error) setError('');
                setEmail(value.trim())
              }}
              style={{ padding: '0px 15px', width: '100%' }}
            />
            {!!email && (
              <Icon type={isValidEmail ? 'done' : 'close'} style={{ position: 'absolute', right: 10, top: 10, color: isValidEmail ? 'green' : 'red' }} />
            )}
          </div>
          
          <Button
            loading={isSubmitting}
            disabled={!isValidEmail || isSubmitting}
            onClick={submit}
            label="Continue"
            kind="primary"
            type="submit"
            block
          />

          {error && <p className="text-danger">{error}</p>}
        </Fragment>
      )}
    </div>
  );
};

export default PasswordRecoverForm;
