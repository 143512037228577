import React from 'react';

import moment from 'moment';
import sanitizeHtml from 'sanitize-html';
import { Text } from '@ethical-jobs/design-system-react';
import { ApplicationEmail } from '@ethical-jobs/sdk-js/types';

type Props = {
  email: ApplicationEmail;
};

const ApplicantEmail = ({ email }: Props) => (
  <div key={email.id} style={{ padding: '10px 15px', display: 'flex', borderRadius: 5 }}>
    <div style={{ flex: 1 }}>
      <span style={{ display: 'flex' }}>
        <Text style={{ fontWeight: 'bold', fontSize: 14, paddingRight: 10 }}>Subject</Text>
        <Text style={{ fontSize: 14 }}>{email.subject}</Text>
      </span>
      <span style={{ display: 'flex' }}>
        <Text style={{ fontWeight: 'bold', fontSize: 14, paddingRight: 10 }}>Replies</Text>
        <Text style={{ fontSize: 14 }}>{email.from || 'No Reply'}</Text>
      </span>
      <Text style={{ fontWeight: 'bold', fontSize: 14 }}>Message</Text>
      {/* Default options: https://www.npmjs.com/package/sanitize-html#what-are-the-default-options */}
      <div dangerouslySetInnerHTML={{ __html: sanitizeHtml(email.body) }} />
    </div>
    <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-end', paddingRight: 10 }}>
      <Text style={{ fontSize: 14, color: '#999' }}>{moment(email.createdAt).format('MMM Do @ HH:mm A')}</Text>
    </div>
  </div>
);

export default ApplicantEmail;