import React, { useEffect, useCallback, useState, useRef, Fragment } from 'react';

import _ from 'lodash';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import { useSelector } from 'react-redux';

import Icon from 'components/Icon';
import Spinner from 'components/Spinner';

import FormField from 'fields/FormField';
import { isReachableUrl } from 'lib/links';

const ButtonOption = () => {
  const formUrl: string = useSelector(state => _.get(state, 'form.jobForm.values.applyButton', ''));

  const [isValidLink, setIsValidLink] = useState(true);
  const [showLoading, setShowLoading] = useState(false);
  const [url, setUrl] = useState(formUrl || '');

  const checkValidLink = useCallback(async (value) => {
    let isReachableLink = await isReachableUrl(value);
    
    setIsValidLink(isReachableLink);
    setShowLoading(false);
  }, []);
  
  const debounceCheck = useRef(_.debounce(checkValidLink, 2000)).current;

  useEffect(() => {
    if (url.trim().length === 0) {
      debounceCheck.cancel();
      setShowLoading(false);
      setIsValidLink(true);
      return;
    }

    setShowLoading(true);
    debounceCheck(url);
  }, [url]);

  return (
    <Fragment>
      <div style={{ position: 'relative' }}>
        <FormField
          required
          name="applyButton"
          component="input"
          placeholder="https://www.url.com/link"
          // @ts-ignore
          onChange={(event, value) => setUrl(value)}
          style={{ width: 500, margin: '5px 0px 0px 0px', paddingRight: 50 }}
          label={(
            <span>
              Recruitment platform link for applications
              <OverlayTrigger
                placement="top"
                overlay={(
                  <Tooltip id="email-help">
                    If you use a third-party e-recruitment system, simply paste the relevant link here and candidates will be directed straight to your application form after they click "Apply Now" on your job ad.
                  </Tooltip>
                )}
                >
                <span>
                  <Icon type="help" style={{ paddingLeft: 5, fontSize: 20 }} />
                </span>
              </OverlayTrigger>
            </span>
          )}
        />
        {showLoading && (
          <div style={{ position: 'absolute', top: 37, left: 425 }}>
            <Spinner show={showLoading} />
          </div>
        )}
        {!showLoading && isValidLink && url.trim().length > 0 && (
          <div style={{ position: 'absolute', top: 45, left: 460 }}>
            <Icon type="check" style={{ paddingLeft: 5, fontSize: 24, color: '#57ad34' }} />
          </div>
        )}
      </div>
      {!showLoading && !isValidLink && (
        <span style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
          <p style={{ fontSize: 18, color: '#d9534f', margin: 0, paddingTop: 5 }}>The link you’ve added may be incorrect, please check that it is valid</p>
        </span>
      )}
    </Fragment>
  );
};

export default ButtonOption;