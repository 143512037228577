import React from 'react';
import _ from 'lodash';
import Icon from 'components/Icon';
import { numberWithCommas } from 'lib/formatters';

type Props = {
  icon: string;
  report: Array<{ [key: string]: string }>;
  dataKey: string;
};

const Figure = ({ icon, report, dataKey }: Props) => {
  const figure = numberWithCommas(_.sumBy(report, dataKey));
  return (
    <h1>
      <Icon type={icon} className="md-18" />
      <span>{figure}</span>
    </h1>
  );
};

export default Figure;
