import React, { useEffect } from 'react';

import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';

import { loadUserDetails } from 'actions/auth';

import PurchaseSuccess from './PurchaseSuccess';

const PurchaseSuccessContainer = () => {
  const dispatch = useDispatch();

  const { serviceType = '' } = useParams();

  useEffect(() => {
    dispatch(loadUserDetails());
  }, [dispatch]);

  return <PurchaseSuccess serviceType={serviceType} />;
};

export default React.memo(PurchaseSuccessContainer);
