import React from 'react';
import Card from 'components/Card';
import FormField from 'fields/FormField';
import MultiSelectField from 'fields/MultiSelect';
import OrganisationSelect from 'fields/OrganisationSelect';
import DateField from 'fields/DateField';

type Props = {
  isUpdate: boolean;
};

const InvoiceFields = ({ isUpdate }: Props) => (
  <Card>
    <div className="row">
      <FormField
        name="organisationId"
        component={OrganisationSelect}
        label="Invoiced organisation"
        wrapClassName="col-md-12"
        required
      />
    </div>
    <div className="row">
      <FormField
        name="paymentMethod"
        component={MultiSelectField}
        options={[
          { label: 'Credit Card', value: 'credit_card' },
          { label: 'Invoice', value: 'invoice' }
        ]}
        label="Payment method"
        placeholder="Select payment method"
        wrapClassName="col-md-6"
        disabled={isUpdate}
        required
      />
      <FormField
        name="generatedByName"
        component="input"
        type="text"
        label="Generated By"
        wrapClassName="col-md-6"
        disabled
      />
    </div>
    <div className="row">
      <FormField
        name="serviceName"
        component="input"
        type="text"
        label="Item title"
        placeholder="Title of the invoice charges"
        wrapClassName="col-md-6"
        disabled={isUpdate}
        required
      />
      <FormField
        name="serviceDescription"
        component="input"
        type="text"
        label="Item description"
        placeholder="Describe the invoice charges"
        wrapClassName="col-md-6"
        disabled={isUpdate}
      />
    </div>
    <div className="row">
      <FormField
        name="quantity"
        component="input"
        type="number"
        label="Quantity"
        placeholder="Numeric values only"
        wrapClassName="col-md-6"
        disabled={isUpdate}
        required
      />
      <FormField
        name="amount"
        component="input"
        type="number"
        label="Amount"
        placeholder="Invoice total excluding GST"
        wrapClassName="col-md-6"
        disabled={isUpdate}
        required
      />
    </div>
    <div className="row">
      <FormField
        name="paidAt"
        component={DateField}
        label="Paid date"
        wrapClassName="col-md-6"
        disabled={isUpdate}
      />
    </div>
  </Card>
);

export default InvoiceFields;
