import React, { useEffect, useState, useContext } from 'react';

import { useSelector } from 'react-redux';

import moment from 'moment';

import { User, Organisation, Job } from '@ethical-jobs/sdk-js/types';
import { APIContext } from 'lib/contexts';

import ApplicantManagementDashboard from './ApplicantManagementDashboard';

export type JobWithApplicants = Job & { numApplicants: number, numNewApplicants: number };

const ApplicantManagementDashboardContainer = () => {
  const api = useContext(APIContext);

  const [jobs, setJobs] = useState<Array<JobWithApplicants>>([]);

  // @ts-ignore
  const user: User & { organisation: Organisation } = useSelector(state => state.auth.user);

  useEffect(() => {
    const fn = async () => {
      const defaultFilters = {
        fields: ['id', 'location', 'views', 'clicks', 'status', 'title', 'createdAt', 'expiresAt', 'isFeatured'],
        include: ['organisation', 'author'],
        organisations: user.organisation.id,
        useElasticSearch: false,
        limit: 100
      };

      let [approvedJobs, pendingJobs, expiredJobs] = await Promise.all([
        api.getJobs({ ...defaultFilters, status: 'APPROVED', includeExpired: false }).catch(() => ({ jobs: [], total: 0, title: '' })),
        api.getJobs({ ...defaultFilters, status: 'PENDING' }).catch(() => ({ jobs: [], total: 0, title: '' })),
        api.getJobs({ ...defaultFilters, includeExpired: true }).catch(() => ({ jobs: [], total: 0, title: '' }))
      ]);

      let jobs = [...approvedJobs.jobs, ...pendingJobs.jobs, ...expiredJobs.jobs];

      let jobIds = jobs.map(job => job.id);

      let [applicantCounts, applicantsWithinLastDay] = await Promise.all([
        api.getApplicantCounts(jobIds),
        api.getApplicantCounts(jobIds, moment().subtract(1, 'days').startOf('day').valueOf())
      ]);

      setJobs(jobs.map(job => ({
        ...job,
        numApplicants: applicantCounts[job.id]['total'],
        numNewApplicants: applicantsWithinLastDay[job.id]['total']
      })));
    }

    fn();
  }, [api, user]);

  return (
    <ApplicantManagementDashboard
      jobs={jobs}
      userFirstName={user.firstName}
    />
  );
};

export default ApplicantManagementDashboardContainer;
