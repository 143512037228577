import React, { useCallback } from 'react';

import { reduxForm } from 'redux-form';
import classNames from 'classnames';
import submissionError from './submissionError';
import FormField from 'fields/FormField';
import Button from 'components/Button';

type Props = {
  login: (values: Object) => Promise<Object>;
  handleSubmit: any;
  error?: string | { invalidToken: boolean };
  submitting: boolean;
};

const LoginForm = ({ login, handleSubmit, error, submitting }: Props) => {
  const submit = useCallback(async (values: { username: string, password: string }) => {
    try {
      await login(values);
    } catch (error) {
      submissionError(error);
    }
  }, [login]);

  return (
    <form className={classNames('login-form', { submitting })} onSubmit={handleSubmit(submit)}>
      <FormField
        name="username"
        component="input"
        type="text"
        placeholder="Enter your username"
        wrapClassName="form-group"
      />
      <FormField
        name="password"
        component="input"
        type="password"
        placeholder="Enter your password"
        wrapClassName="form-group"
      />
      <p className={classNames('text-danger', { hidden: !error })}>
        Incorrect username or password.
      </p>
      <Button
        loading={submitting}
        label="Sign in"
        kind="primary"
        type="submit"
        block
      />
    </form>
  );
};

export default reduxForm({
  form: 'login'
  // @ts-ignore
})(LoginForm);
