import styled from 'styled-components';

type StageContainerProps = {
  isLast: boolean;
  isFirst: boolean;
  isActive: boolean;
  activeColor: string;
};

export const StageContainer = styled.div<StageContainerProps>`
  cursor: pointer;
  padding: ${({ isLast, isFirst }) => `5px ${isLast ? '10' : '20'}px 5px ${isFirst ? '10' : '20'}px`};
  position: relative;
  clip-path: ${({ isLast, isFirst }) => `polygon(calc(100% - ${isLast ? '0' : '15'}px) 0%, 100% 50%, calc(100% - ${isLast ? '0' : '15'}px) 100%, 0% 100%, ${isFirst ? '0' : '15'}px 50%, 0% 0%)`};
  background-color: ${({ isActive, activeColor }) => isActive ? activeColor : '#ECEDF2'};
  margin-right: ${({ isLast }) => isLast ? '0' : '-12px'};
`;

type StageHoverContainerProps = {
  hasIcon: boolean;
  isDragging: boolean;
  isDraggingOver: boolean;
  isActive: boolean;
  hoverColor: string;
}

export const StageHoverContainer = styled.div<StageHoverContainerProps>`
  padding: ${({ hasIcon }) => hasIcon ? '2px 10px 2px 5px' : `2px 5px 2px 5px`};
  border-radius: 5px;
  border-style: solid;
  border-width: 1px;
  border-color: ${({ isDragging, isActive, hoverColor }) => isDragging && !isActive ? hoverColor : 'transparent'};
  box-Shadow: ${({ isDragging, isActive }) => isDragging && !isActive ? '0 2px 4px 0 rgba(0, 0, 0, 0.1)' : 'none'};
  backgroundColor: ${({ isDraggingOver }) => isDraggingOver ? '#DDD' : 'transparent'};
  display: flex;
  align-items: center;
`