import React from 'react';
import styled from 'styled-components';
import { Answer, JobQuestion } from '@ethical-jobs/sdk-js/types';

import AnswerItem from './AnswerItem';

const Container = styled.div`
  padding: 20px 30px;
`;

const Inner = styled.div`
  background-color: #fafafa;
  padding: 40px; 
`;

type Props = {
  answers: Array<Answer>;
  jobQuestions: Array<JobQuestion>;
};

const Answers = ({answers, jobQuestions}: Props) => (
  <Container>
    <Inner>
      {answers.map((answer: Answer, index: number) => (
        <AnswerItem
          key={answer.id}
          jobQuestions={jobQuestions}
          answer={answer}
          isLast={index === answers.length - 1}
        />
      ))}
    </Inner>
  </Container>
);

export default Answers;
