import React from 'react';
import moment from 'moment';
import StatsPanel from 'components/StatsPanel';

const QUERY = {
  reports: [{
    reportName: 'total-purchases',
    metric: 'metric:totalPurchasesValue',
    dateRanges: [{
      from: moment().startOf('week').format('YYYY-MM-DD HH:mm:ss'),
      to: moment().endOf('week').format('YYYY-MM-DD HH:mm:ss'),
    }]
  },
  {
    reportName: 'total-credits',
    metric: 'metric:totalCreditsPurchased',
    dateRanges: [{
      from: moment().startOf('week').format('YYYY-MM-DD HH:mm:ss'),
      to: moment().endOf('week').format('YYYY-MM-DD HH:mm:ss'),
    }]
  }]
};

const FIGURES = [{
  reportName: 'total-purchases',
  dataKey: 'total_purchases_value',
  icon: 'local_atm'
},
{
  reportName: 'total-credits',
  dataKey: 'total_credits_purchased',
  icon: 'star'
}];

const PurchasesFigure = () => (
  <StatsPanel
    title="Income / Credits this week"
    figures={FIGURES}
    query={QUERY}
  />
);

export default React.memo(PurchasesFigure);
